<template>

    <BaseListbox
        v-model="model"
        :ajax="true"
        :filter="true"
        :searchable="true"
        :value="option => option.id"
        :label="option => trans({ en: option.name_en, fr: option.name_fr })"
        :search="search"/>

</template>

<script setup>
    import { options } from '@/api'
    import { useTrans } from '@/composables/i18n'
    
    const emit = defineEmits(['update:modelValue'])
    const props = defineProps({ modelValue: [Number, Object, String] })

    const model = useVModel(props, 'modelValue', emit)
    const trans = useTrans()

    const search = async (args) => {
        let params = {}

        if (args.query) {
            params.search = args.query
        }

        if (args.values) {
            params.ids = args.values
        }

        const response = await options.grades(params)

        return response.data
    }

    defineExpose({ search })  
</script>
