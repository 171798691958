<template>
    <BaseListbox v-model="model"
        :ajax="true"
        :filter="true"
        :searchable="true"
        :value="option => option.id"
        :label="option => option.name"
        :search="search"/>
</template>

<script setup>
    import { options } from '@/api'
    import { watchEffect, ref } from 'vue'

    const props = defineProps({
        modelValue: [Number, String],
        first: { type: Boolean, default: false },
    })
    const emit = defineEmits(['update:modelValue'])
    const model = useVModel(props, 'modelValue', emit)

    const data = ref([])
    const firstSelected = ref(false) // Track whether the first selection has been done

    const search = async (args) => {
        let params = {}

        if (args.query) {
            params.search = args.query
        }

        if (args.values) {
            params.ids = args.values
        }

        const response = await options.sessions(params)

        data.value = response.data

        return response.data
    }

    // Only select the first item by default if selectFirst is true and the first selection has not been done
    watchEffect(() => {
        if (props.first && data.value.length > 0 && model.value === null && !firstSelected.value) {
            model.value = data.value[0].id
            firstSelected.value = true // Set firstSelected to true after the first selection
        }
    })

    defineExpose({ search })  
</script>