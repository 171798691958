<template>

    <BaseForm ref="form" @submitted="submit()">

        <LoadingOverlay :loading="loading">
                
            <TabGroup class="mb-6">

                <TabList class="-mt-6 -mx-6 mb-6"/>

                <TabPanel :title="$t('labels.general')">

                    <div class="grid gap-6">

                        <BaseInputGroup :label="$t('labels.name')" :error="form?.error('name')">
                            <BaseInput v-model="payload.name"/>
                        </BaseInputGroup>

                        <BaseInputGroup :label="$t('labels.email')" :error="form?.error('email')">
                            <BaseInput v-model="payload.email" type="email"/>
                        </BaseInputGroup>

                        <div class="grid gap-6 md:grid-cols-3">

                            <BaseInputGroup :label="$t('labels.phone1')" :error="form?.error('phone1')">
                                <BaseInput v-model="payload.phone1" v-maska="MASK_PHONE" placeholder="(###) ###-#### x ######"/>
                            </BaseInputGroup>

                            <BaseInputGroup :label="$t('labels.phone2')" :error="form?.error('phone2')">
                                <BaseInput v-model="payload.phone2" v-maska="MASK_PHONE" placeholder="(###) ###-#### x ######"/>
                            </BaseInputGroup>

                            <BaseInputGroup :label="$t('labels.phone3')" :error="form?.error('phone3')">
                                <BaseInput v-model="payload.phone3" v-maska="MASK_PHONE" placeholder="(###) ###-#### x ######"/>
                            </BaseInputGroup>

                        </div>

                        <div class="grid gap-6 md:grid-cols-2">

                            <BaseInputGroup :label="$t('labels.password')" :error="form?.error('password')">
                                <BasePassword v-model="payload.password"/>
                            </BaseInputGroup>

                            <BaseInputGroup :label="$t('labels.password')" :error="form?.error('password_confirmation')">
                                <BasePassword v-model="payload.password_confirmation"/>
                            </BaseInputGroup>

                        </div>
                        <div v-if="isEdit">
                            <BaseInputGroup :label="$t('labels.role')"  :error="form?.error('role')">
                                    <BaseListbox v-model="payload.role" :searchable="false" :options="roles"/>
                                </BaseInputGroup>
                        </div>

                        <BaseInputGroup :label="$t('labels.color')" :error="form?.error('color')">
                            <BaseInput v-model="payload.color" class="form-control-color" type="color" />
                        </BaseInputGroup>

                        <BaseToggle v-model="payload.archived">{{ $t('labels.archived') }}</BaseToggle>
                        
                    </div>

                </TabPanel>
                
                <TabPanel :title="$t('labels.address')">

                    <div class="grid gap-6">

                        <BaseInputGroup :label="$t('labels.address-line1')" :error="form?.error('address1')">
                            <BaseInput v-model="payload.address1"/>
                        </BaseInputGroup>

                        <BaseInputGroup :label="$t('labels.address-line2')" :error="form?.error('address2')">
                            <BaseInput v-model="payload.address2"/>
                        </BaseInputGroup>

                        <div class="grid gap-6 md:grid-cols-2">

                            <BaseInputGroup :label="$t('labels.address-city')" :error="form?.error('city')">
                                <BaseInput v-model="payload.city"/>
                            </BaseInputGroup>

                            <BaseInputGroup :label="$t('labels.address-region')" :error="form?.error('region')">
                                <BaseInput v-model="payload.region"/>
                            </BaseInputGroup>

                            <BaseInputGroup :label="$t('labels.address-postal')" :error="form?.error('postal_code')">
                                <BaseInput v-model="payload.postal_code"/>
                            </BaseInputGroup>

                            <BaseInputGroup :label="$t('labels.address-country')" :error="form?.error('country')">
                                <BaseInput v-model="payload.country"/>
                            </BaseInputGroup>

                        </div>

                    </div>

                </TabPanel>

                <TabPanel :title="$t('labels.contacts')">
                    
                    <Repeater
                        v-model="contacts"
                        :create="$t('labels.contact-create')"
                        :empty="$t('labels.empty')"
                        :title="$t('labels.contacts')">

                        <template #default="{ index, item }">
            
                            <div class="flex flex-1 items-center gap-6">
                                
                                <BaseInputGroup>
                                    <BaseToggle v-model="item.is_primary">{{ $t('labels.primary') }}</BaseToggle>
                                </BaseInputGroup>

                                <BaseInputGroup class="flex-1" :error="form?.error(`contacts.${index}.name`)">
                                    <BaseInput v-model="item.name" :placeholder="$t('labels.name')"/>
                                </BaseInputGroup>

                                <BaseInputGroup class="flex-1" :error="form?.error(`contacts.${index}.email`)">
                                    <BaseInput v-model="item.email" :placeholder="$t('labels.email')"/>
                                </BaseInputGroup>

                                <BaseInputGroup class="flex-1" :error="form?.error(`contacts.${index}.phone`)">
                                    <BaseInput v-model="item.phone" :placeholder="$t('labels.phone')" v-maska="MASK_PHONE"/>
                                </BaseInputGroup>
            
                            </div>
            
                        </template>

                    </Repeater>

                </TabPanel>

                <TabPanel :title="$t('labels.notes')">

                    <div class="grid gap-6">

                        <BaseInputGroup :label="$t('labels.notes')" :error="form?.error('notes')">
                            <Tiptap v-model="payload.notes"/>
                        </BaseInputGroup>

                    </div>

                </TabPanel>

            </TabGroup>

            <BaseButton :disabled="submitting" :loading="submitting">
                {{ $t(action) }}
            </BaseButton>
            
        </LoadingOverlay>

    </BaseForm>

</template>

<script setup>
    import { MASK_PHONE } from '@/constants/mask'
    import { useI18n, useUserRoles } from '@/composables/i18n'
    import { useModelForm } from '@/composables/model-ui'
    import { useSupplierStore } from '@/store/model'

    const { t } = useI18n()
    const store = useSupplierStore()

    const { form, id, payload, loading, submitting, create, edit, submit, mode } = useModelForm({ 
        store: store,
        messages: {
            created: t('message.supplier-created'),
            updated: t('message.supplier-updated'),
            destroyed: t('message.supplier-destroyed')
        }
    })

    const roles = useUserRoles().filter(role => 'employee' === role.id || 'supplier' === role.id)

    
    const contacts = computed({
        get: () => payload.value?.contacts,
        set: (value) => { payload.value.contacts = value }
    })
    
    const action = computed(() => id.value  ? 'labels.submit-supplier-edit' : 'labels.submit-supplier-create')
    const primaryContacts = computed(() => contacts.value?.map(contact => contact.is_primary))
    
    const isRole = (role) => role === payload.value?.role
    const isEdit = computed(()=> id.value);

    watch(() => primaryContacts.value, (newValue, oldValue) => {
        newValue?.forEach((primary, i) => {
            if (primary === oldValue?.[i]) {
                return
            }

            if (primary) {
                contacts.value.forEach((contact, j) => { contact.is_primary = i === j })
            }
        })
    })
    defineExpose({ mode, create, edit })
</script>
