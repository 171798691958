<template>

    <BaseCard>

        <template #header>
        
            <div v-if="archivable" class="flex items-stretch -mb-[2px]">
                <button class="border-b-[3px] px-6" :class="!state.filters.archived ? 'border-primary font-semibold' : 'border-transparent'" @click.prevent="setArchived(false)">
                    {{ $t('component.datatable.tab-active') }}</button>
                <button class="border-b-[3px] px-6" :class="state.filters.archived ? 'border-primary font-semibold' : 'border-transparent'" @click.prevent="setArchived(true)">
                    {{ $t('component.datatable.tab-archived') }}
                </button>
            </div>

            <div v-if="searchable" class="relative bg-gray-100 rounded-md md:w-64">
                
                <span class="flex items-center justify-center absolute inset-y-0 pointer-events-none left-0 w-10 text-gray-500">
                    <i class="fa-duotone fa-magnifying-glass"></i>
                </span>
                    
                <input
                    v-model="search"
                    class="bg-transparent px-3 pl-10 py-3 w-full outline-none focus:placeholder-transparent"
                    :placeholder="$t('component.table.search')">

            </div>        
        
        </template>

        <template #actions>

            <div v-if="$slots.headerActionsPrepend" class="flex flex-col">
                <slot name="headerActionsPrepend" :state="state"/>
            </div>
            
            <div class="flex flex-col md:hidden w-full">
                <BaseButton :block="true" modifier="light" @click.prevent="$refs.filters?.open()">
                    <i class="fa-solid fa-sliders mr-2"></i>{{ $t('component.datatable.filters') }}
                </BaseButton>
            </div>

            <BaseButton modifier="light" @click.prevent="resetFilters()">
                <i class="fa-duotone fa-arrows-rotate mr-2"></i>{{ $t('component.datatable.filters-reset') }}
            </BaseButton>
            
            <BaseButton modifier="light" @click.prevent="$refs.settings?.open()">
                <i class="fa-duotone fa-gear mr-2"></i>{{ $t('component.datatable.settings') }}
            </BaseButton>
            
            <slot name="headerActionsAppend" :state="state"/>

            <slot name="actions" :state="state"/>

        </template>

        <template #footer>
            <span class="block text-gray-700 font-medium">{{ $t('component.datatable.range', { from, to, total }) }}</span>      
            <Paginator v-model="page" :pages="pages"/>
        </template>

        <LoadingOverlay :loading="loading">

            <div class="flex md:hidden flex-col gap-6">

                <div v-for="row in rows" :key="row.id" class="flex flex-col gap-4 bg-light rounded p-6">

                    <template v-for="column in display" :key="column.id">

                        <template v-if="'actions' !== column.id">

                            <div class="flex flex-col">

                                <div class="fw-bold">{{ column.text }}</div>

                                <slot :name="column.id" :row="row" :value="format(column.id, row)">
                                    {{ format(column.id, row) }}
                                </slot>

                            </div>

                        </template>
                        
                    </template>

                    <template v-for="column in display" :key="column.id">

                        <template v-if="'actions' === column.id">

                            <div class="inline-flex items-center gap-1 border-t-2 border-t-white pt-3">

                                <slot name="actionsPrepend" :row="row"/>

                                <button v-if="viewable" class="flex items-center justify-center w-8 h-8 rounded hover:bg-gray-100 hover:text-primary" @click.prevent="view(row.id)">
                                    <i class="fa-duotone fa-file-magnifying-glass"></i>
                                </button>

                                <button v-if="editable" class="flex items-center justify-center w-8 h-8 rounded hover:bg-gray-100 hover:text-primary" @click.prevent="edit(row.id)">
                                    <i class="fa-duotone fa-pen"></i>
                                </button>

                                <Popconfirm v-if="destroyable" @confirm="destroy(row.id, row)">
                                    <button class="flex items-center justify-center w-8 h-8 rounded hover:bg-gray-100 hover:text-danger">
                                        <i class="fa-duotone fa-trash"></i>
                                    </button>
                                </Popconfirm>

                                <slot name="actionsAppend" :row="row"/>

                            </div>

                        </template>

                    </template>

                </div>

            </div>

            <div class="hidden md:block w-full">
                    
                <BaseTable>

                    <template v-slot:header>

                        <tr>
                    
                            <template v-for="column in display" :key="column.id">

                                <BaseTableCell v-if="isSortable(column)" :header="true" :width="widths.filter?.(i => i.id === column.id).map?.(i => i.width).shift?.()" :data-id="column.id">

                                    <button class="flex items-center gap-2" @click.prevent="sort(column)">

                                        {{ column.text }}                            

                                        <div v-if="sortable && state.sort?.key === column.id">
                                            <i :class="'asc' === state.sort?.order ? 'fa-duotone fa-caret-up' : 'fa-duotone fa-caret-down'"></i>
                                        </div>

                                    </button>

                                </BaseTableCell>

                                <BaseTableCell v-else :header="true" v-text="column.text" :width="widths.filter?.(i => i.id === column.id).map?.(i => i.width).shift?.()" :data-id="column.id"/>

                            </template>

                        </tr>
                    
                    </template>

                    <tr>

                        <template v-for="column in display" :key="column.id">
                            
                                <BaseTableCell>

                                    <slot :name="`filter_${column.id}`" :mobile="false">

                                        <input 
                                            v-if="isTextFilter(column)"
                                            v-model="state.filters[column.id]"
                                            class="outline-none focus:placeholder-transparent field-search bg-transparent w-full"
                                            :placeholder="column.text"/>
                            
                                        <div v-if="isSelectFilter(column)" class="flex relative">
                            
                                            <select
                                                class="outline-none focus:placeholder-transparent appearance-none bg-transparent w-full"
                                                v-model="state.filters[column.id]">
                                                    
                                                <option v-for="option in column.options" :key="option.value" :value="option.value">
                                                    {{ option.text }}
                                                </option>
                            
                                            </select>
                            
                                            <button class="text-xs text-gray-400 p-1.5" @click.stop.prevent="clear(column)">
                                                <i class="fa-duotone fa-xmark"></i>
                                            </button>

                                            <div v-if="isNull(column)" class="flex items-center bg-white absolute inset-x-0 inset-y-0 pointer-events-none text-gray-400">
                                                {{ column.text }}
                                            </div>

                                        </div>

                                    </slot>

                                </BaseTableCell>

                        </template>

                    </tr>

                    <BaseTableRow v-for="row in rows" :key="row.id">

                        <template v-for="column in display" :key="column.id">

                            <template v-if="'actions' !== column.id">

                                <BaseTableCell>

                                    <slot :name="column.id" :row="row" :value="format(column.id, row)">
                                        {{ format(column.id, row) }}
                                    </slot>

                                </BaseTableCell>
                
                            </template>
                
                            <template v-if="'actions' === column.id">
                                
                                <BaseTableCell align="none" class="text-center first:text-left last:text-right">

                                    <div class="inline-flex items-center gap-1">

                                        <slot name="actionsPrepend" :row="row"/>

                                        <button v-if="viewable" class="flex items-center justify-center w-8 h-8 rounded hover:bg-gray-100 hover:text-primary" @click.prevent="view(row.id)">
                                            <i class="fa-duotone fa-file-magnifying-glass"></i>
                                        </button>

                                        <button v-if="editable" class="flex items-center justify-center w-8 h-8 rounded hover:bg-gray-100 hover:text-primary" @click.prevent="edit(row.id)">
                                            <i class="fa-duotone fa-pen"></i>
                                        </button>

                                        <Popconfirm v-if="destroyable" @confirm="destroy(row.id, row)">
                                            <button class="flex items-center justify-center w-8 h-8 rounded hover:bg-gray-100 hover:text-danger">
                                                <i class="fa-duotone fa-trash"></i>
                                            </button>
                                        </Popconfirm>

                                        <slot name="actionsAppend" :row="row"/>
                                    
                                    </div>
                
                                </BaseTableCell>
                
                            </template>
                
                        </template>
                
                    </BaseTableRow>

                    <BaseTableRow v-if="!rows?.length">
                        <BaseTableCell :colspan="1 + columns.length" v-text="$t('component.datatable.empty')"/>
                    </BaseTableRow>

                </BaseTable>

            </div>

        </LoadingOverlay>

        <BaseDrawer ref="settings" :title="$t('labels.settings')">

            <div class="flex flex-col divide-y divide-gray-200 space-y-4 -m-6">

                <div class="p-6">

                    <h4 class="mb-4 font-semibold">{{ $t('component.datatable.per-page') }}</h4>

                    <BaseListbox v-model="state.perPage" :clearable="false" :searchable="false" :options="perPageOptions"/>

                </div>

                <div class="p-6">

                    <h4 class="mb-4 font-semibold">{{ $t('labels.columns') }}</h4>

                    <Draggable v-model="state.order" tag="ul" class="flex flex-col bg-gray-100 p-4 space-y-2" :item-key="(element) => element">

                        <template #item="{element}">

                            <li class="flex items-center cursor-move bg-white px-4 py-2 font-medium rounded">
                                <i class="fa-duotone fa-grip-dots-vertical mr-2 text-gray-400 text-xs"></i> {{ text(element) }}
                                <label class="inline-flex p-2 rounded ml-auto cursor-pointer">
                                    <input v-model="state.active" class="appearance-none" type="checkbox" :value-true="[]" :value="element">
                                    <i class="fa-duotone fa-circle-check" :class="state.active.includes(element) ? 'text-success' : 'text-gray-400'"></i>
                                </label>
                            </li>

                        </template>
        
                    </Draggable>

                </div>

            </div>

        </BaseDrawer>

        <BaseDrawer ref="filters" :title="$t('component.datatable.filters')">

            <div class="flex flex-col gap-6">

                <BaseInputGroup v-for="column in display.filter(column => column.id !== 'actions')" :key="column.id" :label="column.text">

                    <slot :name="`filter_${column.id}`" :mobile="true">

                        <BaseInput
                            v-if="isTextFilter(column)"
                            v-model="state.filters[column.id]"
                            :placeholder="column.text"/>

                        <BaseListbox
                            v-if="isSelectFilter(column)"
                            v-model="state.filters[column.id]"
                            :label="option => option.text"
                            :value="option => option.value"
                            :options="column.options"
                            :searchable="false"/>

                    </slot>

                </BaseInputGroup>

            </div>
        </BaseDrawer>

    </BaseCard>

</template>

<script setup>
    import Draggable from 'vuedraggable'
    import { useI18n } from '@/composables/i18n'
    import { datetime } from '@/composables/dates.js'    
    import { useViewInterval } from '@/composables/interval'
import { isNullish } from '@/composables/utility'

    const emit = defineEmits(['edit', 'destroy', 'update:filters', 'update:page', 'view'])

    const props = defineProps({        
        actions: { type: Boolean, default: true },
        archivable: { type: Boolean, default: false },
        id: { type: String, required: true },
        loading: { type: Boolean, default: false },
        columns: { type: Array, default: [] },
        defaults: { type: Array, default: [] },
        rows: { type: Array, default: [] },
        editable: { type: Boolean, default: true },
        destroyable: { type: Boolean, default: true },
        filterable: { type: Boolean, default: true },
        sortable: { type: Boolean, default: true },
        searchable: { type: Boolean, default: false },
        viewable: { type: Boolean, default: true },
        transform: { type: Function, default: (column, row) => row[column] },
        from: { type: Number },
        to: { type: Number },
        total: { type: Number },
        pages: { type: [Array, Number] },
        page: { type: Number, required: true },
        filter: { type: Function },
    })

    const { t } = useI18n()

    const actions = toRef(props, 'actions')
    const archivable = toRef(props, 'archivable')
    const filter = toRef(props, 'filter')
    const id = toRef(props, 'id')
    const loading = toRef(props, 'loading')
    const columns = toRef(props, 'columns')
    const defaults = toRef(props, 'defaults')
    const sortable = toRef(props, 'sortable')
    const rows = toRef(props, 'rows')
    const transform = toRef(props, 'transform')
    const search = ref('')
    const settings = ref()
    const widths = ref([])
    
    const page = useVModel(props, 'page', emit)

    const key = computed(() => `table:${id.value}`)

    const perPageOptions = [
        { id: 10, text: '10' },
        { id: 25, text: '25' },
        { id: 50, text: '50' },
        { id: 100, text: '100' }
    ]

    const computedColumns = computed(() => {
        const results = []

        if (actions.value) {
            results.push({ id: 'actions', text: t('labels.actions') })
        }

        columns.value.forEach(column => {
            if (typeof column.condition === 'function') {
                if (!column.condition()) {
                    return
                }
            }
        
            results.push(column)
        })

        return results
    })

    const computedDefaults = computed(() => {
        const results = []

        if (actions.value) {
            results.push('actions')
        }

        if (defaults.value.length > 0) {
            defaults.value.forEach(i => results.push(i))
        } else {
            columns.value.forEach(i => results.push(i.id))
        }

        return results
    })

    const state = useStorage(`table:${id.value}`, {
        filters: {},
        order:  computedColumns.value.map(column => column.id),
        active: computedDefaults.value,
        archived: false,
        perPage: 10,
    })

    if (!state.value?.sort) {
        state.value.sort = { key: 'id', order: 'asc' }
    }

    watch(() => search.value,  useDebounceFn(() => reload(), 300))
    watch(() => state.value?.filters, useDebounceFn(() => reload(), 300), { deep: true })
    watch(() => state.value?.perPage, () => reload(), { deep: true })
    //watch(() => state.value?.order, () => verify(), { deep: true })
    
    onMounted(() => {
        refresh()
        reload()
        verify()
    })

    useViewInterval(() => {
        if (rows.value?.length === 0) {
            return
        }
        
        if (widths.value.length !== 0) {
            return
        }

        let results = []

        display.value.forEach(column => {
            const el = document.querySelector(`[data-id="${column.id}"]`)

            if (el) {
                const rect = el.getBoundingClientRect()

                if (rect) {
                    results.push({ id: column.id, width: rect.width })
                }
            } else {
                console.log('failed to find:', column.id)
            }
        })

        if (results.length === display.value.length) {
            widths.value = results
        }
    }, 500)

    const display = computed(() => {
        return state.value.order
            .map(id => computedColumns.value?.find(column => column.id === id))
            .filter(column => state.value.active.includes(column?.id))
    })

    const text = (id) => {
        const column = computedColumns.value.find(column => id === column.id)
        var result = ''

        if (column) {
            if (column.column) {
                result = column.column
            } else if (column.text) {
                result = column.text
            }
        }

        return result
    }

    const view = (id, data) => emit('view', id, data)
    const edit = (id, data) => emit('edit', id, data)
    const destroy = (id, data) => emit('destroy', id, data)

    watch(columns, () => refresh())

    function verify() {
        const ids = computedColumns.value?.map(column => column.id)
        
        state.value.order = state.value?.order.filter(id => ids.includes(id))
        state.value.perPage = state.value?.perPage || 10
    }

    function reload() {
        if (typeof filter.value === 'function') {
            filter.value({
                perPage: state.value.perPage,
                search: search.value,
                sort_key: state.value.sort?.key || 'id',
                sort_order: state.value.sort?.order || 'desc',
                archived: !isNullish(state.value.archived) ? state.value.archived : false,
                ...state.value.filters
            })
        }
    }

    function refresh() {
        computedColumns.value.forEach(column => {
            if (!state.value.order.includes(column.id)) {
                state.value.order.push(column.id)
            }
        })
    }

    function refreshWidths() {
        
    }

    function isFilterable(column) {
        return column.id !== 'actions'
    }

    function isSelectFilter(column) {
        return isFilterable(column) && Array.isArray(column.options)
    }

    function isTextFilter(column) {
        return isFilterable(column) && !isSelectFilter(column)
    }

    function isSortable(column) {
        return sortable.value && 'actions' !== column.id
    }

    function clear(column) {
        state.value.filters[column.id] = null
    }

    function isNull(column) {
        const value = state.value.filters[column.id]

        return value === null || typeof(value) === 'undefined'
    }

    function format(id, row) {
        switch (id) {
            case 'created_at': return datetime(row.created_at)
            case 'updated_at': return datetime(row.updated_at)
        }

        return transform.value(id, row)
    }

    function sort(column) {
        let order = 'desc'

        if (column.id === state.value?.sort?.key) {
            order = 'asc' === state.value?.sort?.order ? 'desc' : 'asc'
        }

        state.value.sort = { key: column.id, order: order }

        reload()
    }

    function resetFilters() {
        state.value.filters = {}
    }

    function setArchived(archived) {
        state.value.filters.archived = archived
    }
</script>
