<template>

    <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
        <template v-for="link, index in links" :key="index">
            <li v-if="index > 0" class="breadcrumb-item"><span class="bullet bg-gray-400 w-5px h-2px"></span></li>
            <li class="breadcrumb-item text-muted">
                <router-link v-if="link.to" :to="{ name: link.to }" class="text-muted">{{ $t(link.label) }}</router-link>
                <span v-else>{{ $t(link.label) }}</span>
            </li>
        </template>
    </ul>

</template>

<script setup>
    import Route from '@/constants/route'

    const props = defineProps({
        current: { type: String, required: true },
        label: { type: Function, default: (l) => l }
    })

    const current = toRef(props, 'current') 
    const label = toRef(props, 'label')

    const links = computed(() => {
        const results = []

        results.push({ to: Route.HOME, label: label.value('component.breadcrumbs.home') })

        const matches = current.value.match(/(\w+)-(\w+)$/i)

        if (matches) {
            const model = matches[1]
            const action = matches[2]

            if ('index' !== action) {
                results.push({ to: `${model}-index`, label: label.value(`labels.${model}-index`) })
            }

            results.push({ label: label.value(`labels.${model}-${action}`) })
        }

        return results
    })
</script>
