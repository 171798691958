<template>

    <BaseContainer>

        <header class="flex flex-col md:flex-row md:items-center md:justify-between gap-4 md:gap-6 mb-6">

            <div class="block">
                <h1 class="text-gray-800 font-bold text-xl leading-none mb-2" v-text="$t('labels.children')"></h1>
                <Breadcrumbs :current="Route.CHILD_VIEW"/>
            </div>

            <div class="flex flex-col md:flex-row md:justify-end gap-4">
                <BaseButton @click.prevent="edit()">{{ $t('labels.child-edit') }}</BaseButton>
                <BaseButton variant="secondary" @click.prevent="index()">{{ $t('labels.back') }}</BaseButton>
            </div>

        </header>

        <div class="flex flex-col gap-6">
                
            <LoadingOverlay :loading="loading">
            
                <BaseCard>

                    <template #header>
                        <span class="card-title fs-6 fw-bold">{{ $t('labels.general') }}</span>
                    </template>

                    <dl class="flex flex-col">

                        <div class="pb-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.parent') }}</dt>
                            <dd class="block">
                                <span v-if="model?.user"><RouterLink class="text-primary" :to="{ name: Route.PARENT_VIEW, params: { id: model?.user.id } }">{{ `${model.user.first_name} ${model.user.last_name}` }}</RouterLink></span>
                                <span v-else>-</span>
                            </dd>
                        </div>

                        <div class="py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.name') }}</dt>
                            <dd class="block">{{ `${model?.first_name} ${model?.last_name}` || '-' }}</dd>
                        </div>

                        <div class="py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.birthdate') }}</dt>
                            <dd class="block">{{ date(model?.birthdate) || '-' }}</dd>
                        </div>

                        <div class="py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.update-required') }}</dt>
                            <dd class="block">{{ model?.update_required  ? $t('enum.boolean.true') : $t('enum.boolean.false') }}</dd>
                        </div>

                    </dl>

                </BaseCard>

            </LoadingOverlay>

            <LoadingOverlay :loading="loading">
            
                <BaseCard>

                    <template #header>
                        <span class="card-title fs-6 fw-bold">{{ $t('labels.school') }}</span>
                    </template>

                    <dl class="flex flex-col">

                        <div class="pb-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.client') }}</dt>
                            <dd class="block">
                                <span v-if="model?.user"><RouterLink v-if="model.client" class="text-primary" :to="{ name: Route.CLIENT_VIEW, params: { id: model.client.id } }">{{ model.client.name }}</RouterLink></span>
                                <span v-else>-</span>
                            </dd>
                        </div>

                        <div class="py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.grade') }}</dt>
                            <dd class="block">{{ model?.grade?.name || '-' }}</dd>
                        </div>

                        <div class="py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.bus-number') }}</dt>
                            <dd class="block">{{ model?.bus_number || '-' }}</dd>
                        </div>

                        <div class="py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.group') }}</dt>
                            <dd class="block">{{ model?.group || '-' }}</dd>
                        </div>

                        <div class="py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.return-type') }}</dt>
                            <dd class="block">{{ model?.return_type ? $t(`enum.return-type.${model.return_type}`) : '-' }}</dd>
                        </div>

                    </dl>

                </BaseCard>

            </LoadingOverlay>

            <LoadingOverlay :loading="loading">
            
                <BaseCard>

                    <template #header>
                        <span class="card-title fs-6 fw-bold">{{ $t('labels.medical') }}</span>
                    </template>

                    <dl class="flex flex-col">
                        
                        <div v-if="conditions?.length > 0" class="py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.conditions') }}</dt>
                            <dd class="block">
                                <ul class="list-disc pl-4">
                                    <li v-for="condition in model?.conditions" :key="condition.id">
                                        {{ trans({ en: condition.name_en, fr: condition.name_fr }) }}
                                    </li>
                                </ul>
                            </dd>
                        </div>

                        <div class="py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.notes-conditions') }}</dt>
                            <div class="prose max-w-none" v-html="model?.notes_medical || '-'"></div>
                        </div>

                    </dl>

                </BaseCard>

            </LoadingOverlay>

            <div class="flex md:justify-end gap-2 mb-6">

                <BaseButton @click.prevent="edit()">{{ $t('labels.child-edit') }}</BaseButton>

                <BaseButton variant="secondary" @click.prevent="index()">{{ $t('labels.back') }}</BaseButton>

            </div>

        </div>        


    </BaseContainer>

</template>

<script setup>
    import Route from '@/constants/route'
    import { date, time } from '@/composables/dates'
    import { useTrans } from '@/composables/i18n'
    import { useModelView } from '@/composables/model-ui'
    import { useChildStore } from '@/store/model'

    const store = useChildStore()
    const trans = useTrans()

    const { loading, model, index, edit } = useModelView({ 
        store: store, 
        routes: {
            edit: Route.CHILD_EDIT,
            index: Route.CHILD_INDEX
        }
    })
</script>
