import ToastType from '@/constants/toast-type'

export function toast (type, message, duration = 3500) {
    const options = { 
        showDuration: duration,
        positionClass: 'toast-bottom-left'
    }

    switch (type) {
        case ToastType.ERROR:
            toastr.error(message, null, options)
            break

        case ToastType.SUCCESS:
            toastr.success(message, null, options)
            break        

        case ToastType.WARNING:
            toastr.warning(message, null, options)
            break

        default:
            toastr.info(message, null, options)
            break
    }    
}
