export function isNullish(v) {
    return !v ? true : false
}

export function isEmail(v) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v)
}

export function capitalizeFirstLetter(string) {
    return string.replace(/\b\w/g, function(char) {
        return char.toUpperCase();
    });
}
