<template>

    <BaseForm ref="form" @submitted="submit()">

        <LoadingOverlay :loading="loading">

            <div class="grid gap-6 mb-6">

                <div class="grid md:grid-cols-2 gap-6">

                    <BaseInputGroup :label="$t('labels.parent')" :error="form?.error('parent')">
                        <ParentListbox v-model="payload.user_id"/> 
                    </BaseInputGroup>

                    <BaseInputGroup :label="$t('labels.status')" :error="form?.error('status')">
                        <BaseListbox v-model="payload.status" :searchable="false" :options="statuses"/> 
                    </BaseInputGroup>

                    <BaseInputGroup :label="$t('labels.taxes')" :error="form?.error(`taxes`)">
                        <BaseInput v-model="payload.taxes"/>
                    </BaseInputGroup>

                    <BaseInputGroup :label="$t('labels.total')" :error="form?.error(`total`)">
                        <BaseInput v-model="payload.total"/>
                    </BaseInputGroup>

                </div>
                
                <Repeater
                    v-model="payload.items"
                    :create="$t('labels.order-item-add')"
                    :empty="$t('labels.empty')"
                    :title="$t('labels.order-items')">

                    <template #default="{ index, item }">    

                        <div class="flex-1 grid md:grid-cols-4 gap-6">

                            <BaseInputGroup 
                                class="md:col-span-2"
                                :translatable="true" 
                                :translatable-labels="{ en: $t('labels.description-en'), fr: $t('labels.description-fr') }"
                                :translatable-errors="{ en: form?.error(`items.${index}.description_en`), fr: form?.error(`items.${index}.description_fr`) }">

                                <template #default="{ isLocale }">
                                    <BaseInput v-show="isLocale('en')" v-model="item.description_en"/>
                                    <BaseInput v-show="isLocale('fr')" v-model="item.description_fr"/>
                                </template>

                            </BaseInputGroup>
                            
                            <BaseInputGroup :label="$t('labels.quantity')" :error="form?.error(`items.${index}.quantity`)">
                                <BaseInput v-model="item.quantity"/>
                            </BaseInputGroup>

                            <BaseInputGroup :label="$t('labels.total')" :error="form?.error(`items.${index}.total`)">
                                <BaseInput v-model="item.total"/>
                            </BaseInputGroup>

                        </div>

                    </template>

                </Repeater>

            </div>

            <BaseButton :disabled="submitting" :loading="submitting">
                {{ $t(action) }}
            </BaseButton>

        </LoadingOverlay>

    </BaseForm>

</template>

<script setup>
    import { useI18n, useOrderStatuses, useTrans } from '@/composables/i18n'
    import { useModelForm } from '@/composables/model-ui'
    import { useOrderStore } from '@/store/model'
    
    const { t } = useI18n()
    const store = useOrderStore()
    const statuses = useOrderStatuses()
    const trans = useTrans()

    const { form, id, payload, loading, submitting, create, edit, submit, mode } = useModelForm({ 
        store: store, 
        messages: {
            created: t('message.order-created'),
            updated: t('message.order-updated'),
            destroyed: t('message.order-destroyed')
        },
        editing: (data) => {
            payload.value.items = payload.value?.items?.map(item => ({
                id: item.id,
                description_en: item.description_en,
                description_fr: item.description_fr,
                quantity: item.quantity,
                total: item.total
            }))
        }
    })

    const action = computed(() => id.value  ? 'labels.submit-order-edit' : 'labels.submit-order-create')

    function activity(item) {
        return trans({ en: item.activity?.name_en, fr: item.activity?.name_fr })
    }

    function children(item) {
        return payload.value?.user?.children.map(child => ({
            id: child.id,
            text: `${child.first_name} ${child.last_name}`
        }))
    }

    function options(item) {    
        return item.activity?.course?.options?.map(option => ({
            id: option.id,
            text: trans({ en: option.name_en, fr: option.name_fr })
        }))
    }

    defineExpose({ mode, create, edit })
</script>
