import { defineModelStore } from '@/composables/store'

import {
    activities,
    categories,
    children,
    clients,
    conditions,
    courses,
    grades,
    messages,
    orders,
    registrations,
    sectors,
    sessions,
    taxRates,
    transactions,
    users
} from '@/api'
import { unset } from 'lodash'

export const useActivityStore = defineModelStore('activity', activities)
export const useCategoryStore = defineModelStore('category', categories)
export const useClientStore = defineModelStore('client', clients)
export const useChildStore = defineModelStore('child', children)
export const useConditionStore = defineModelStore('condition', conditions)
export const useCourseStore = defineModelStore('course', courses)
export const useGradeStore = defineModelStore('grade', grades)
export const useMessageStore = defineModelStore('message', messages)
export const useOrderStore = defineModelStore('order', orders)
export const useRegistrationStore = defineModelStore('registration', registrations)
export const useSectorStore = defineModelStore('sector', sectors)
export const useSessionStore = defineModelStore('session', sessions)
export const useTaxRateStore = defineModelStore('taxRate', taxRates)
export const useTransactionStore = defineModelStore('transaction', transactions)
export const useUserStore = defineModelStore('user', users)

export const useAdminStore = defineModelStore('admin', users, {
    index: (params) => { params.role = 'admin' },
    store: (payload) => { payload.role = 'admin' }
})

export const useAssignableStore = defineModelStore('assignable', users, {
    index: (params) => { params.role = ['employee', 'supplier'] },
    store: (payload) => { payload.role = payload.role || 'employee' }
})

export const useEmployeeStore = defineModelStore('employee', users, {
    index: (params) => { params.role = 'employee' },
    store: (payload) => { payload.role = 'employee' },
    update:(payload) => {
        if(payload.role =='supplier'){
            payload.name =payload.first_name + ' ' +payload.last_name;
        }
    }
})

export const useParentStore = defineModelStore('parent', users, {
    index: (params) => { params.role = 'parent' },
    store: (payload) => { payload.role = 'parent' }
})

export const useSupplierStore = defineModelStore('supplier', users, {
    index: (params) => { params.role = 'supplier' },
    store: (payload) => { payload.role = 'supplier' },
    update: (payload) => {
        if(payload.role=='employee'){
            let name = payload.name;
            let names = name.split(' ');

            if(names.length >=1){
                payload.first_name=names[0];
            }

            if(names.length >2){
                names.shift();
                payload.last_name = names.join(' ');

            }else if(names.length == 2){
                payload.last_name = names[1];
            }

         
           
        }
    }
})

export const useSchoolboardStore = defineModelStore('schoolboard', categories, {
    index: (params) => { params.type = 'schoolboard' },
    store: (payload) => { params.type = 'schoolboard' }
})
