<template>

    <div class="d-block">

        <div class="d-flex flex-col gap-6">

            <template v-for="row, index in rows" v-bind:key="index">

                <DuplicatorRow :index="index" v-model="rows[index]">

                    <slot :index="index" :item="rows[index]"/>                    

                </DuplicatorRow>                

            </template>

            <div v-if="0 === count" class="alert alert-dismissible bg-danger d-flex flex-column flex-sm-row w-100 p-5 mb-10">
                <div class="d-flex align-items-center">
                    <i class="ki-duotone ki-message-text-2 fs-2hx text-light me-4 mb-5 mb-sm-0"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                    <div class="text-light">{{ empty }}</div>
                </div>
            </div>

        </div>

    </div>
</template>

<script setup>
    import { createRepeater } from '@/composables/repeater'

    const emit = defineEmits(['update:modelValue'])

    const props = defineProps({
        create: { type: String, default: '' },
        empty: { type: String, default: '' },
        modelValue: { required: true },
        title: { type: String, default: '' },
        template: { type: Function, default: () => ({}) },
        minimum: { type: Number, default: 1 },
    })

    const template = toRef(props, 'template')
    const minimum = toRef(props, 'minimum')
    const rows = useVModel(props, 'modelValue', emit)
    const count = computed(() => rows.value ? rows.value.length : 0)    
    const repeater = createRepeater(rows, template)

    const duplicated = computed({
        get() { return rows.value?.length > 1 },
        set(value) {
            if (value) {
                repeater.add()
            } else {
                repeater.remove(1)
            }
        }
    })

    onMounted(() => {
        
    })

    defineExpose({ duplicated })
    
    onMounted(() => update())
    watch(rows, () => update(), { deep: true })
    watch(minimum, () => update())
    watch(duplicated, () => update())

    function update() {
        if (!rows.value) {
            rows.value = []
        }

        while (rows.value?.length < minimum.value) {
            repeater.add()
        }
    }
</script>
