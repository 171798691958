<template>
    
    <div class="mb-5 mb-lg-10">

        <div id="kt_app_toolbar" class="app-toolbar  py-3 py-lg-6 ">
            <div id="kt_app_toolbar_container" class="app-container container-xxl d-flex flex-stack ">
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                    <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{{ $t('labels.activities') }}</h1>
                    <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li class="breadcrumb-item text-muted">
                            <RouterLink :to="{ name: Route.HOME }" class="text-muted text-hover-primary">{{ $t('component.breadcrumbs.home') }}</RouterLink>
                        </li>                    
                        <li class="breadcrumb-item"><span class="bullet bg-gray-400 w-5px h-2px"></span></li>        
                        <li class="breadcrumb-item text-muted">{{ $t('labels.activities') }}</li>
                    </ul>
                </div>
            </div>
        </div>

        <div id="kt_app_content_container" class="app-container container-xxl">

            <ParentHeader/>

            <div class="card mb-6">

                <div class="card-body py-9 px-0">

                    <div class="stepper stepper-pills" id="kt_stepper_example_basic">

                        <div class="stepper-nav flex-col flex-lg-row">
    
                            <div class="stepper-item mx-8 my-4" :class="step >= 1 ? 'current' : ''" data-kt-stepper-element="nav" @click.prevent="navigate(1)">
                                <div class="stepper-wrapper d-flex align-items-start">
                                    <div class="stepper-icon w-40px h-40px">
                                        <i class="stepper-check fas fa-check"></i>
                                        <span class="stepper-number">1</span>
                                    </div>
                                    <div class="stepper-label">
                                        <h3 class="stepper-title">{{ $t('steps.step1-title') }}</h3>
                                        <div class="stepper-desc">{{ $t('steps.step1-description') }}</div>
                                    </div>
                                </div>
                                <div class="stepper-line h-40px"></div>
                            </div>

                            <div class="stepper-item mx-8 my-4" :class="step >= 2 ? 'current' : ''" data-kt-stepper-element="nav" @click.prevent="navigate(2)">
                                <div class="stepper-wrapper d-flex align-items-start">
                                    <div class="stepper-icon w-40px h-40px">
                                        <i class="stepper-check fas fa-check"></i>
                                        <span class="stepper-number">2</span>
                                    </div>
                                    <div class="stepper-label">
                                        <h3 class="stepper-title">{{ $t('steps.step2-title') }}</h3>
                                        <div class="stepper-desc">{{ $t('steps.step2-description') }}</div>
                                    </div>
                                </div>
                                <div class="stepper-line h-40px"></div>
                            </div>

                            <div class="stepper-item mx-8 my-4" :class="step >= 3 ? 'current' : ''" data-kt-stepper-element="nav" @click.prevent="navigate(3)">
                                <div class="stepper-wrapper d-flex align-items-start">
                                    <div class="stepper-icon w-40px h-40px">
                                        <i class="stepper-check fas fa-check"></i>
                                        <span class="stepper-number">3</span>
                                    </div>
                                    <div class="stepper-label">
                                        <h3 class="stepper-title">{{ $t('steps.step3-title') }}</h3>
                                        <div class="stepper-desc">{{ $t('steps.step3-description') }}</div>
                                    </div>
                                </div>
                                <div class="stepper-line h-40px"></div>
                            </div>

                            <div class="stepper-item mx-8 my-4" :class="step >= 4 ? 'current' : ''" data-kt-stepper-element="nav" @click.prevent="navigate(4)">
                                <div class="stepper-wrapper d-flex align-items-start">
                                    <div class="stepper-icon w-40px h-40px">
                                        <i class="stepper-check fas fa-check"></i>
                                        <span class="stepper-number">4</span>
                                    </div>
                                    <div class="stepper-label">
                                        <h3 class="stepper-title">{{ $t('steps.step4-title') }}</h3>
                                        <div class="stepper-desc">{{ $t('steps.step4-description') }}</div>
                                    </div>
                                </div>
                                <div class="stepper-line h-40px"></div>
                            </div>

                            <div class="stepper-item mx-8 my-4" :class="[step >= 5 ? 'current' : '', (hasItems || (debit > 0)) && step < 5 ? 'has-items' : '']" data-kt-stepper-element="nav" @click.prevent="navigate(5)">
                                <div class="stepper-wrapper d-flex align-items-start">
                                    <div class="stepper-icon w-40px h-40px">
                                        <i class="stepper-check fas fa-check"></i>
                                        <span class="stepper-number">5</span>
                                    </div>
                                    <div class="stepper-label">
                                        <h3 class="stepper-title">{{ $t('steps.step5-title') }}</h3>
                                        <div class="stepper-desc">{{ $t('steps.step5-description') }}</div>
                                    </div>
                                </div>
                                <div class="stepper-line h-40px"></div>
                            </div>

                        </div>

                    </div>

                </div>

            </div>

            <div id="shop_routes">
                <RouterView/>
            </div>

        </div>

    </div>

    <!--BaseContainer>        

        
        
        <header class="flex items-center justify-between gap-6 mb-6">
                
            <h1 class="text-gray-800 font-bold text-xl leading-none" v-text="$t('labels.activities')"></h1>
        
            <div class="inline-block bg-success px-3 py-1.5 text-white font-semibold text-sm">
                {{ $t('labels.credit') }}: ${{ available }}                    
            </div>
            
        </header>
        
        <LoadingOverlay :loading="loading">            
            
            <BaseCard class="p-6 mb-6">
                
                <ul class="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 w-full">
                    
                    <li class="flex items-center shrink-0">
                        <RouterLink :to="{ name: Route.SHOP_STEP_1 }">
                            <span class="inline-flex w-6 h-6 rounded-full items-center justify-center text-white text-xs" :class="step === 1 ? 'bg-primary' : 'bg-gray-800'">1</span>
                            <span class="inline-flex ml-3 font-medium text-sm">{{ $t('labels.school') }}</span>
                        </RouterLink>
                    </li>

                    <li class="flex items-center justify-center grow w-6">
                        <hr class="block w-[2px] md:w-full bg-gray-400 h-4 md:h-[2px] bg-gray-300"/>
                    </li>
                    
                    <li class="flex items-center shrink-0">
                        <RouterLink :to="{ name: Route.SHOP_STEP_2 }">
                            <span class="inline-flex w-6 h-6 rounded-full items-center justify-center text-white text-xs" :class="step === 2 ? 'bg-primary' : 'bg-gray-800'">2</span>
                            <span class="inline-flex ml-3 font-medium text-sm">{{ $t('labels.activity') }}</span>
                        </RouterLink>
                    </li>

                    <li class="flex items-center justify-center grow w-6">
                        <hr class="block w-[2px] md:w-full bg-gray-400 h-4 md:h-[2px] bg-gray-300"/>
                    </li>

                    <li class="flex items-center shrink-0">
                        <RouterLink :to="{ name: Route.SHOP_STEP_3 }">
                            <span class="inline-flex w-6 h-6 rounded-full items-center justify-center text-white text-xs" :class="step === 3 ? 'bg-primary' : 'bg-gray-800'">3</span>
                            <span class="inline-flex ml-3 font-medium text-sm">{{ $t('labels.details') }}</span>
                        </RouterLink>
                    </li>

                </ul>

            </BaseCard>
            
        </LoadingOverlay>        

        <div v-show="!loading">
            <RouterView/>
        </div>

    </BaseContainer-->

</template>

<script setup>
    import { useAuth } from '@/composables/auth'
    import { useMetronic } from '@/composables/keenthemes'
    import Route from '@/constants/route'
    import { useShopStore } from '@/store/shop'
    import { useCartStore } from '@/store/cart'

    const shop = useShopStore()
    const cart = useCartStore()
    
    const { user } = useAuth()
    const router = useRouter()
    
    const loading = ref(false)

    const step = computed(() => shop.step)
    const debit = computed(() => cart.debit)
    const hasItems = computed(() => cart.items.length > 0)

    useMetronic()

    /**
     * Set the current step.
     * 
     * @param {number} value
     * @return {void}
     */
    function setStep(value) {
        shop.setStep(value)
    }

    function setLoading(value) {
        loading.value = value
    }

    function navigate(to) {
        let accept = to <= step.value

        if (5 === to && (hasItems.value || debit.value > 0)) {
            accept = true
        }

        if (!accept) {
            return
        }

        switch (to) {
            case 1: 
                router.push({ name: Route.SHOP_STEP_1 })
                break

            case 2:
                router.push({ name: Route.SHOP_STEP_2 })
                break

            case 3:
                router.push({ name: Route.SHOP_STEP_3 })
                break

            case 4:
                router.push({ name: Route.SHOP_STEP_4 })
                break

            case 5:
                router.push({ name: Route.CHECKOUT })
            
        }
    }

    provide('shop', { setStep, setLoading, loading })
</script>
