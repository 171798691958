<template>

    <BaseContainer>

        <header class="mb-6">

            <h1 class="text-gray-800 font-bold text-xl leading-none mb-2" v-text="$t('labels.media-manager')"></h1>
            <div class="sm:flex justify-between">
                <Breadcrumbs :current="Route.MEDIA_MANAGER_INDEX" />
                <SearchForm v-model="search" @clearSearch="clearSearch" />
            </div>



        </header>
        <MediaList :query="search"></MediaList>

    </BaseContainer>

</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { attachments as api } from '@/api'
import MediaList from '@/views/media/partials/MediaList.vue'
import SearchForm from '@/views/media/partials/SearchForm.vue'
import Route from '@/constants/route'

const loading = ref(false)
const attachments = ref([])
const search = ref()    

const clearSearch = () => {
    search.value = '';
};


const ids = computed(() => attachments.value.map(item => item.id))

const filtered = computed(() => {


    return attachments.value
})    

function refresh() {
 
    loading.value = true

    api.index({ exclude: ids.value }).then(response => {
        if (response.data.data.length > 0) {
            attachments.value.push(...response.data.data)
        }
    }).finally(() => {
        loading.value = false
    })
}
const edit = (item) => {
    console.log(item);
}

const destroyed = (item) => {
        const index = ids.value.findIndex(id => id == item.id)
       
        if (index !== -1) {
            attachments.value.splice(index, 1);
        }
}

onMounted(() => nextTick(() => refresh()))


</script>
