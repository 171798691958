<template>

    <BaseForm ref="form" @submitted="submit()">

        <LoadingOverlay :loading="loading">
        
            <TabGroup class="mb-6">

                <TabList class="-mt-6 -mx-6 mb-6"/>

                <TabPanel :title="$t('labels.general')">

                    <div class="grid gap-6">
                        
                        <div class="grid gap-6 md:grid-cols-2">

                            <BaseInputGroup :label="$t('labels.name-first')" :error="form?.error('first_name')">
                                <BaseInput v-model="payload.first_name"/>
                            </BaseInputGroup>

                            <BaseInputGroup :label="$t('labels.name-last')" :error="form?.error('last_name')">
                                <BaseInput v-model="payload.last_name"/>
                            </BaseInputGroup>

                        </div>

                        <BaseInputGroup :label="$t('labels.email')" :error="form?.error('email')">
                            <BaseInput v-model="payload.email" type="email"/>
                        </BaseInputGroup>

                        <div class="grid gap-6 md:grid-cols-3">
                            
                            <BaseInputGroup :label="$t('labels.phone1')" :error="form?.error('phone1')">
                                <BaseInput v-model="payload.phone1" v-maska="MASK_PHONE" placeholder="(###) ###-#### x ######"/>
                            </BaseInputGroup>

                            <BaseInputGroup :label="$t('labels.phone2')" :error="form?.error('phone2')">
                                <BaseInput v-model="payload.phone2" v-maska="MASK_PHONE" placeholder="(###) ###-#### x ######"/>
                            </BaseInputGroup>

                            <BaseInputGroup :label="$t('labels.phone3')" :error="form?.error('phone3')">
                                <BaseInput v-model="payload.phone3" v-maska="MASK_PHONE" placeholder="(###) ###-#### x ######"/>
                            </BaseInputGroup>

                        </div>

                        <div class="grid gap-6 md:grid-cols-2">
                            
                            <BaseInputGroup :label="$t('labels.password')" :error="form?.error('password')">
                                <BasePassword v-model="payload.password"/>
                            </BaseInputGroup>

                            <BaseInputGroup :label="$t('labels.password')" :error="form?.error('password_confirmation')">
                                <BasePassword v-model="payload.password_confirmation"/>
                            </BaseInputGroup>
                        
                        </div>
                    
                    </div>
                
                </TabPanel>

                <TabPanel :title="$t('labels.address')">

                    <div class="grid gap-6">

                        <BaseInputGroup :label="$t('labels.address-line1')" :error="form?.error('address1')">
                            <BaseInput v-model="payload.address1"/>
                        </BaseInputGroup>

                        <BaseInputGroup :label="$t('labels.address-line2')" :error="form?.error('address2')">
                            <BaseInput v-model="payload.address2"/>
                        </BaseInputGroup>

                        <div class="grid gap-6 md:grid-cols-2">

                            <BaseInputGroup :label="$t('labels.address-city')" :error="form?.error('city')">
                                <BaseInput v-model="payload.city"/>
                            </BaseInputGroup>

                            <BaseInputGroup :label="$t('labels.address-region')" :error="form?.error('region')">
                                <BaseInput v-model="payload.region"/>
                            </BaseInputGroup>

                            <BaseInputGroup :label="$t('labels.address-postal')" :error="form?.error('postal_code')">
                                <BaseInput v-model="payload.postal_code"/>
                            </BaseInputGroup>

                            <BaseInputGroup :label="$t('labels.address-country')" :error="form?.error('country')">
                                <BaseInput v-model="payload.country"/>
                            </BaseInputGroup>

                        </div>

                    </div>
                
                </TabPanel>

                <TabPanel :title="$t('labels.notes')">
                    
                    <div class="grid gap-6">
                        
                        <BaseInputGroup :label="$t('labels.notes')" :error="form?.error('notes_fr')">
                            <Tiptap v-model="payload.description_en"/>
                        </BaseInputGroup>
                    
                    </div>
                
                </TabPanel>

            </TabGroup>
            
            <BaseButton :disabled="submitting" :loading="submitting">
                {{ $t(action) }}
            </BaseButton>

        </LoadingOverlay>

    </BaseForm>

</template>

<script setup>
    import { MASK_PHONE } from '@/constants/mask'
    import { useI18n } from '@/composables/i18n'
    import { useModelForm } from '@/composables/model-ui'
    import { useAdminStore } from '@/store/model'

    const { t } = useI18n()
    const store = useAdminStore()

    const { form, id, payload, loading, submitting, create, edit, submit, mode } = useModelForm({ 
        store: store,
        messages: {
            created: t('message.user-created'),
            updated: t('message.user-updated'),
            destroyed: t('message.user-destroyed')
        }
    })

    const action = computed(() => id.value  ? 'labels.submit-user-edit' : 'labels.submit-user-create')

    defineExpose({ mode, create, edit })
</script>
