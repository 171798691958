<template>

    <BaseForm ref="form" @submitted="submit()">

        <LoadingOverlay :loading="loading">

            <div class="d-flex flex-col flex-md-row gap-10">

                <div class="w-full w-md-350px shrink-0">

                    <div class="d-flex flex-col gap-10">

                        <BaseCard>

                            <div class="d-flex flex-col gap-6">

                                <BaseInputGroup :translatable="true"
                                    :translatable-labels="{ en: $t('labels.subject-en'), fr: $t('labels.subject-fr') }"
                                    :translatable-errors="{ en: form?.error('name_en'), fr: form?.error('name_fr') }">

                                    <template #default="{ isLocale }">
                                        <BaseInput v-show="isLocale('en')" v-model="payload.name_en" />
                                        <BaseInput v-show="isLocale('fr')" v-model="payload.name_fr" />
                                    </template>

                                </BaseInputGroup>

                                <BaseInputGroup :label="$t('labels.send-date')" :error="form?.error('send_date')">
                                    <Datepicker v-model="payload.send_date" />
                                </BaseInputGroup>

                                <BaseInputGroup :label="$t('labels.send-time')" :error="form?.error('send_time')">
                                    <BaseInput v-model="payload.send_time" v-maska="'##:##'" type="time" />
                                </BaseInputGroup>

                            </div>

                        </BaseCard>

                    </div>

                </div>

                <div class="w-100 mb-6">

                    <BaseCard>

                        <div class="d-flex flex-col gap-6">

                            <div class="row gx-10">

                                <div class="col-12 col-md-6">

                                    <div class="d-flex flex-col gap-6">

                                        <BaseInputGroup :label="$t('labels.target')" :error="form?.error('target')">
                                            <BaseListbox v-model="payload.target" :options="targets"
                                                :searchable="false" />
                                        </BaseInputGroup>
                                        
                                        <BaseInputGroup v-if="hasClient" :label="$t('labels.client')"
                                            :error="form?.error('client_id')">
                                            <BaseListbox v-model="payload.client_id" :options="clients"
                                                :value="option => option.id" :label="option => option.name" />
                                        </BaseInputGroup>

                                        <BaseInputGroup v-if="hasActivity" :label="$t('labels.group')"
                                            :error="form?.error('activity_group_id')">
                                            <BaseListbox v-model="payload.activity_group_id" :searchable="true"
                                                :options="groups" :value="option => option.group"
                                                :label="option => option.name">
                                                <template #label="{ option }">
                                                    <div class="d-flex flex-col w-100">
                                                        <span class="d-block font-medium">{{
                                                            option.internal.client_name
                                                            }}: {{ option.label }}</span>
                                                        <span class="d-block fs-7 text-muted">{{
                                                            option.internal.dates
                                                            }}</span>
                                                    </div>
                                                </template>
                                            </BaseListbox>
                                        </BaseInputGroup>

                                        <BaseInputGroup v-if="isSpecificEmployees"
                                            :label="$t('labels.employees-suppliers')" :error="form?.error('user_ids')">
                                            <EmployeeListbox v-model="payload.user_ids" :autoclose="false"
                                                :multiple="true" />
                                        </BaseInputGroup>

                                        <BaseInputGroup v-if="isSpecificParents" :label="$t('labels.parents')"
                                            :error="form?.error('user_ids')">
                                            <ParentListbox v-model="payload.user_ids" :autoclose="false"
                                                :multiple="true" />
                                        </BaseInputGroup>

                                        <BaseInputGroup v-if="isParents" :label="$t('labels.type')"
                                            :error="form?.error(`type`)">
                                            <BaseListbox v-model="payload.type" :options="types" :searchable="false" />
                                        </BaseInputGroup>

                                        <BaseInputGroup :label="$t('labels.emails-additional')" :error="form?.error('emails-additional') ">
                                            <Multiselect v-model="payload.emails" :options="emailList" mode="tags"
                                                searchable="true" createOption="true"
                                                :noOptionsText="$t('labels.emails-additional-no-options')" 
                                                :noResultsText="$t('labels.emails-additional-no-results')" />
                                            <span class="fs-7 text-muted">{{ $t('labels.emails-additional-instructions') }}</span>
                                        </BaseInputGroup>

                                        <div
                                            class="d-flex align-items-center gap-4 bg-light-primary border border-primary rounded p-4">
                                            <i class="ki-duotone ki-send flex-shrink-0 fs-1 text-primary">
                                                <i class="path1"></i>
                                                <i class="path2"></i>
                                            </i>
                                            <div class="d-flex flex-col">
                                                <p class="text-primary m-0"><strong>{{ $t('labels.targeting')
                                                        }}</strong></p>
                                                <p class="text-primary m-0">{{
                                                    $t('labels.targeting-email').replace(':count', emailsCount) }}
                                                </p>
                                            </div>
                                        </div>

                                        <div class="flex-col space-y-2 mb-6">

                                            <BaseCard>

                                                <div class="row gx-10">

                                                    <div class="col-12">

                                                        <LoadingOverlay :loading="uploading">

                                                            <BaseInputGroup :translatable="true"
                                                                :translatable-labels="{ en: $t('labels.attachments-en'), fr: $t('labels.attachments-fr') }"
                                                                :translatable-errors="{ en: form?.error('attachments_en'), fr: form?.error('attachments_fr') }">

                                                                <template #default="{ isLocale }">
                                                                    <Repeater v-if="isLocale('en')"
                                                                        v-model="payload.attachments_en"
                                                                        :create="$t('labels.upload')"
                                                                        :empty="$t('labels.empty')">
                                                                        <template #actions>
                                                                            <label class="btn btn-secondary btn-sm">
                                                                                <input class="absolute invisible"
                                                                                    type="file"
                                                                                    @change="addAttachment($event, 'en')">
                                                                                <span>{{ $t('labels.upload')
                                                                                    }}</span>
                                                                            </label>
                                                                        </template>
                                                                        <template #default="{ index, item }">
                                                                            {{ item.name }}
                                                                        </template>
                                                                    </Repeater>
                                                                    <Repeater v-if="isLocale('fr')"
                                                                        v-model="payload.attachments_fr"
                                                                        :create="$t('labels.upload')"
                                                                        :empty="$t('labels.empty')">
                                                                        <template #actions>
                                                                            <label class="btn btn-secondary btn-sm">
                                                                                <input class="absolute invisible"
                                                                                    type="file"
                                                                                    @change="addAttachment($event, 'fr')">
                                                                                <span>{{ $t('labels.upload')
                                                                                    }}</span>
                                                                            </label>
                                                                        </template>
                                                                        <template #default="{ index, item }">
                                                                            {{ item.name }}
                                                                        </template>
                                                                    </Repeater>
                                                                </template>

                                                            </BaseInputGroup>

                                                        </LoadingOverlay>

                                                    </div>

                                                </div>

                                            </BaseCard>

                                        </div>

                                    </div>

                                </div>

                                <div class="col-12 col-md-6">

                                    <div class="d-flex flex-col gap-6">

                                        <BaseCard>

                                            <div class="row gx-10">

                                                <div class="col-12">

                                                    <BaseInputGroup :translatable="true"
                                                        :translatable-labels="{ en: $t('labels.content-en'), fr: $t('labels.content-fr') }"
                                                        :translatable-errors="{ en: form?.error('content_en'), fr: form?.error('content_fr') }">

                                                        <template #default="{ isLocale }">
                                                            <Tiptap v-show="isLocale('en')" v-model="payload.content_en"
                                                                :enable-images="true" />
                                                            <Tiptap v-show="isLocale('fr')" v-model="payload.content_fr"
                                                                :enable-images="true" />
                                                        </template>

                                                    </BaseInputGroup>

                                                </div>

                                            </div>

                                        </BaseCard>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </BaseCard>

                </div>

            </div>

            <div class="d-flex justify-content-end mb-6">

                <BaseButton :disabled="submitting" :loading="submitting">
                    {{ $t(action) }}
                </BaseButton>

            </div>

        </LoadingOverlay>

    </BaseForm>

</template>

<script setup>
    import { date, datetime } from '@/composables/dates'
    import { useI18n, useOrderStatuses, useTrans } from '@/composables/i18n'
    import { useModelForm } from '@/composables/model-ui'
    import { toast } from '@/composables/toast'
    import { isNullish } from '@/composables/utility'
    import ToastType from '@/constants/toast-type'
    import { useClientStore, useMessageStore } from '@/store/model'
    import Multiselect from '@vueform/multiselect'
    import { options } from '@/api' 

    const { t } = useI18n()
    const store = useMessageStore()
    const clientStore = useClientStore()
    const trans = useTrans()

    const emailList = ref([]);
    
      
    const uploading = ref(false)

    const targets = [
        { id: 'employees:all', text: t('enum.message-target.employees') },
        { id: 'employees:activity', text: t('enum.message-target.employees-activity') },
        { id: 'employees:client', text: t('enum.message-target.employees-client') },        
        { id: 'employees:ids', text: t('enum.message-target.employees-ids') },
        //{ id: 'parents:all', text: t('enum.message-target.parents') },
        { id: 'parents:activity', text: t('enum.message-target.parents-activity') },
        { id: 'parents:client', text: t('enum.message-target.parents-client') },
        { id: 'parents:ids', text: t('enum.message-target.parents-ids') }
    ]

    const types = [
        { id: 'informative', text: t('enum.message-type.informative') },
        { id: 'promotional', text: t('enum.message-type.promotional') }
    ]

    onMounted(() => {
        clientStore.index({ perPage: -1 })
        options.adminMessageEmails().then(response => {
            emailList.value = response.data;
        })

    })    

    const { form, id, payload, loading, submitting, create, edit, submit, mode } = useModelForm({ 
        store: store, 
        messages: {
            created: t('message.message-created'),
            updated: t('message.message-updated'),
            destroyed: t('message.message-destroyed')
        },
        editing: (data) => {
            payload.value.items = payload.value?.items?.map(item => ({
                id: item.id,
                description_en: item.description_en,
                description_fr: item.description_fr,
                quantity: item.quantity,
                total: item.total
            }))
        }
    })

    const clients = computed(() => clientStore.rows)
    
    const groups = computed(() => {
        let results = []

        clients.value.forEach(client => {
            client.activities?.forEach(activity => {
                activity.groups?.forEach(group => {
                    const dates = group.dates
                        .map(d => dayjs(d.date))
                        .sort((a, b) => a.isAfter(b) ? 1 : -1)
                        .map(d => d.format('YYYY-MM-DD'))

                    if (dates.length > 2) {
                        dates.splice(1, dates.length - 2)
                    }

                    results.push({
                        client: client.id,
                        client_name: client.name,
                        activity: activity.id,
                        group: group.id,                        
                        name: trans({ en: activity.course?.name_en, fr: activity.course?.name_fr }),
                        dates: dates.join(' - '),                        
                    })
                })
            })
        })

        return results
    }) 

    const hasClient = computed(() => {
        return [
            'employees:client',
            'parents:client'
        ].includes(payload.value.target)
    })

    const hasActivity = computed(() => {
        return [
            'employees:activity',
            'parents:activity'
        ].includes(payload.value.target)
    })

    const isParents = computed(() => {
        return [
            'parents:all',
            'parents:activity',
            'parents:client',
            'parents:ids'
        ].includes(payload.value.target)
    })
    

    const isSpecificEmployees = computed(() => 'employees:ids' === payload.value.target)
    const isSpecificParents = computed(() => 'parents:ids' === payload.value.target)    

    const action = computed(() => id.value  ? 'labels.submit-message-edit' : 'labels.submit-message-create')

    const addAttachment = async (event, locale) => {
        uploading.value = true

        try {
            // Build form data
            const formData = new FormData()
            formData.append('files[]', event.target.files[0])

            // Send request with the locale
            const response = await axios.post('/api/message-attachments', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                params: {
                    locale: locale
                }
            })

            switch (locale) {
                case 'en':
                    payload.value.attachments_en = [...payload.value.attachments_en, ...response.data.data]
                    break

                case 'fr':
                    payload.value.attachments_fr = [...payload.value.attachments_fr, ...response.data.data]
                    break
            }            
        } catch (error) {
            console.log(error.response.data?.errors)
            if (error.response?.data?.message) {
                toast(ToastType.ERROR, error.response.data.message)
            }
        } finally {
            uploading.value = false
        }
    }

    const getCount = async () => {
        axios.post('/api/messages/count', payload.value)
            .then(function (response) {
                // handle success
                emailsCount.value = response.data.count;
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    }

    const emailsCount = ref(0)

    defineExpose({ mode, create, edit })
    watch(
        () => [payload.value.target, payload.value.client_id, payload.value.activity_group_id, payload.value.user_ids],
        getCount
    );
</script>
