<template>

    <BaseForm ref="form" @submitted="submit()">

        <LoadingOverlay :loading="loading">

            <div class="grid gap-6 mb-6">

                <div class="grid gap-6">

                    <BaseInputGroup :label="$t('labels.type')" :error="form?.error('type')">
                        <BaseListbox v-model="payload.type" :searchable="false" :options="types"/>
                    </BaseInputGroup>
                    
                    <BaseInputGroup :label="$t('labels.status')" :error="form?.error('status')">
                        <BaseListbox v-model="payload.status" :searchable="false" :options="statuses"/>
                    </BaseInputGroup> 

                    <BaseInputGroup :label="$t('labels.order')" :error="form?.error('order')">
                        <OrderListbox v-model="payload.order_id"/>
                    </BaseInputGroup>

                    <BaseInputGroup :label="$t('labels.parent')" :error="form?.error('user')">
                        <ParentListbox v-model="payload.user_id"/>
                    </BaseInputGroup>                    

                    <BaseInputGroup :label="$t('labels.amount')" :error="form?.error(`amount`)">
                        <BaseInput v-model="payload.amount"/>
                    </BaseInputGroup>

                    <BaseInputGroup :label="$t('labels.description')" :error="form?.error(`description`)">
                        <BaseInput v-model="payload.description"/>
                    </BaseInputGroup>
                    
                    <BaseInputGroup :label="$t('labels.notes-system')" :error="form?.error(`notes`)">
                        <BaseInput v-model="payload.notes"/>
                    </BaseInputGroup>

                </div>
                
            </div>

            <BaseButton :disabled="submitting" :loading="submitting">
                {{ $t(action) }}
            </BaseButton>

        </LoadingOverlay>

    </BaseForm>

</template>

<script setup>
    import { useI18n, useTrans, useTransactionStatuses, useTransactionTypes } from '@/composables/i18n'
    import { useModelForm } from '@/composables/model-ui'
    import { useTransactionStore } from '@/store/model'
    
    const { t } = useI18n()
    const store = useTransactionStore()
    const trans = useTrans()

    const statuses = useTransactionStatuses()
    const types = useTransactionTypes()

    const messages = {
        created: t('message.transaction-created'),
        updated: t('message.transaction-updated'),
        destroyed: t('message.transaction-destroyed')
    }

    const { form, id, payload, loading, submitting, create, edit, submit, mode } = useModelForm({ 
        store,
        messages,
        editing: (data) => {
            payload.value.amount = Math.abs(payload.value.amount)
        }
    })

    const action = computed(() => id.value  ? 'labels.submit-transaction-edit' : 'labels.submit-transaction-create')

    defineExpose({ mode, create, edit })
</script>
