<template>
    <form data-kt-search-element="form" class="w-200 mw-300 xs:mt-10  position-relative" autocomplete="off">
        <i class="ki-duotone ki-magnifier fs-3 text-gray-500 position-absolute top-50 translate-middle-y ms-0 z-index-3"
            style="left:0.75rem">
            <span class="path1"></span>
            <span class="path2"></span>
        </i>
        <input v-model="search" class="search-input form-control ps-10 pe-10" :placeholder="$t('labels.search') + '...'">
        
        <i @click="$emit('clearSearch')" v-show="search"
            class="bi bi-x-lg fs-3 text-gray-500 position-absolute top-50 translate-middle-y ms-0 z-index-3"
            style="right:0.75rem">
            <span class="path1"></span>
            <span class="path2"></span>
        </i>

    </form>
</template>

<script setup>
import { defineModel } from 'vue';

const search = defineModel();


</script>