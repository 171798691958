<template>
    
    <BaseContainer>

        <header class="mb-6">
            
            <h1 class="text-gray-800 font-bold text-xl leading-none mb-2" v-text="$t('labels.clients')"></h1>
        
            <Breadcrumbs :current="Route.CLIENT_INDEX"/>
        
        </header>

        <DataTable
            v-model:page="page"
            id="clients"
            :columns="columns"
            :rows="rows"
            :transform="transform"
            :to="to"
            :from="from"
            :total="total"
            :pages="pages"
            :loading="loading"
            :filter="filter"
            @view="view"
            @edit="edit"
            @destroy="destroy">

            <template #actions>
                <BaseButton @click.prevent="create()"><i class="fa-duotone fa-circle-plus mr-2"></i>{{ $t('labels.client-create') }}</BaseButton>
            </template>

        </DataTable>

    </BaseContainer>

</template>

<script setup>
    import Route from '@/constants/route'
    import { useClientTypes, useContactRoles, useI18n, useTrans } from '@/composables/i18n'
    import { useModelIndex } from '@/composables/model-ui'
    import { useClientStore } from '@/store/model'

    const { t } = useI18n()
    const trans = useTrans()
    const store = useClientStore()
    const router = useRouter()

    const roles = useContactRoles().map(item => ({
        value: item.id,
        text: item.text,
    }))

    const types = useClientTypes().map(item => ({
        value: item.id,
        text: item.text,
    }))
    
    const { rows, from, to, total, page, pages, loading, search, filter, destroy } = useModelIndex('client', store, {
        created: t('message.client-created'),
        updated: t('message.client-updated'),
        destroyed: t('message.client-destroyed')
    })

    const columns = computed(() => [
        { id: 'id', text: t('labels.id') },
        { id: 'type', text: t('labels.type'), options: types },
        { id: 'name', text: t('labels.name') },
        { id: 'phone', text: t('labels.phone') },
        { id: 'email', text: t('labels.email') },
        { id: 'address1', text: t('labels.address-line1') },
        { id: 'address2', text: t('labels.address-line2') },
        { id: 'city', text: t('labels.address-city') },
        { id: 'province', text: t('labels.address-region') },
        { id: 'country', text: t('labels.address-country') },
        { id: 'primary_contact_name', text: t('labels.primary-contact-name') },
        { id: 'primary_contact_email', text: t('labels.primary-contact-email') },
        { id: 'primary_contact_phone', text: t('labels.primary-contact-phone') },
        { id: 'primary_contact_role', text: t('labels.primary-contact-role'), options: roles },
        { id: 'created_at', text: t('labels.created') },
        { id: 'updated_at', text: t('labels.updated') },
    ])

    const form = ref()
    const create = () => router.push({ name: Route.CLIENT_CREATE })
    const edit = (id) => router.push({ name: Route.CLIENT_EDIT, params: { id } })
    const view = (id) => router.push({ name: Route.CLIENT_VIEW, params: { id } })

    /**
     * Transform the specified row.
     *
     * @param {String} column
     * @param {Object} row
     * @return {String}
     */
    function transform (column, row) {
        let primary = null
        let role = null

        if (column.startsWith('primary_contact_')) {
            primary = row.contacts?.filter(contact => contact.is_primary).at(0)
            role = roles.filter(role => role.value === primary?.role).at(0)
        }

        switch (column) {
            case 'type': return row.type ? t(`enum.client-type.${row.type}`) : ''
            case 'primary_contact_name': return primary?.name
            case 'primary_contact_email': return primary?.email
            case 'primary_contact_phone': return primary?.phone
            case 'primary_contact_role': return role?.text
        }

        return row[column]
    }
</script>
