<template>

    <div class="card card-flush py-4 flex-row-fluid overflow-hidden">
    
        <div class="card-header">
            <div class="card-title">
                <h2>{{ $t('labels.cart') }}</h2>
            </div>
        </div>
    
    
        <div class="card-body pt-0">
            
            <table class="table d-block d-md-table align-middle table-row-dashed fs-6 gy-5 mb-0">

                <tr class="d-none d-md-table-row text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                    <th class="min-w-175px">{{ $t('labels.order-items') }}</th>
                    <th class="min-w-100px text-end">{{ $t('labels.actions') }}</th>
                    <th class="min-w-70px text-end">{{ $t('labels.quantity') }}</th>
                    <th class="min-w-70px text-end">{{ $t('labels.total') }}</th>
                </tr>

                <LineItem v-for="item, index in items" :key="index" :item="item" />
            
                <tr v-if="!items.length" class="d-block d-md-table-row">
                    <td class="d-block d-md-table-cell text-center" colspan="4">{{ $t('labels.empty-items') }}</td>
                </tr>
                           
                <tr class="d-block d-md-table-row">
                    <td class="d-block d-md-table-cell text-md-end" colspan="3">
                        <span class="d-inline-block border-0 p-0 fw-bold text-dark">{{ $t('labels.subtotal') }}</span>
                        <span class="d-inline-block d-md-none border-0 p-0 ml-1">${{ subtotal }}</span>
                    </td>
                    <td class="d-none d-md-table-cell text-md-end">${{ subtotal }}</td>
                </tr>

                <tr class="d-block d-md-table-row">
                    <td class="d-block d-md-table-cell text-md-end" colspan="3">
                        <span class="d-inline-block border-0 p-0 fw-bold text-dark">{{ $t('labels.taxes') }}</span>
                        <span class="d-inline-block d-md-none border-0 p-0 ml-1">${{ taxes }}</span>
                    </td>
                    <td class="d-none d-md-table-cell text-md-end">
                        ${{ taxes }}
                    </td>
                </tr>

                <tr class="d-block d-md-table-row" v-if="total != amount">
                    <td class="d-block d-md-table-cell text-md-end" colspan="3">
                        <span class="d-inline-block border-0 p-0 fw-bold text-dark fs-md-4">{{ $t('labels.total') }}</span>
                        <span class="d-inline-block d-md-none border-0 p-0 ml-1">${{ total }}</span>
                    </td>
                    <td class="d-none d-md-table-cell text-dark fs-4 fw-bolder text-md-end">
                        ${{ total }}
                    </td>
                </tr>

                <tr class="d-block d-md-table-row" v-if="credit > 0">
                    <td class="d-block d-md-table-cell text-md-end" colspan="3">
                        <span class="d-inline-block border-0 p-0 fw-bold text-dark">{{ $t('labels.account-balance') }} <div class="badge badge-success">{{ $t('labels.credit') }}</div></span>
                        <span class="d-inline-block d-md-none border-0 p-0 ml-1">-${{ credit }}</span>
                    </td>
                    <td class="d-none d-md-table-cell text-md-end">
                        -${{ credit }}
                    </td>
                </tr>

                <tr class="d-block d-md-table-row" v-if="debit > 0">
                    <td class="d-block d-md-table-cell text-md-end" colspan="3">
                        <span class="d-inline-block border-0 p-0 fw-bold text-dark">{{ $t('labels.account-balance') }} <div class="badge badge-danger">{{ $t('labels.debit') }}</div></span>
                        <span class="d-inline-block d-md-none border-0 p-0 ml-1">${{ debit }}</span>
                    </td>
                    <td class="d-none d-md-table-cell text-md-end">
                        ${{ debit }}
                    </td>
                </tr>

                <tr class="d-block d-md-table-row">
                    <td class="d-block d-md-table-cell text-md-end" colspan="3">
                        <span class="d-inline-block border-0 p-0 fw-bold text-dark fs-md-3">{{ $t('labels.grand-total') }}</span>
                        <span class="d-inline-block d-md-none border-0 p-0 ml-1">${{ amount }}</span>
                    </td>
                    <td class="d-none d-md-table-cell text-dark fs-3 fw-bolder text-md-end">
                        ${{ amount }}
                    </td>
                </tr>

            </table>
            
            <div v-if="amount > 0" class="d-flex flex-row justify-content-end gap-6 mt-8">

                <div class="d-flex flex-column">
                
                    <div class="d-flex flex-column flex-md-row gap-6">
                    
                        <label class="nav-link btn btn-outline btn-outline-dashed btn-color-dark btn-active btn-active-primary d-flex flex-stack text-start p-6 m-0 " :class="'single' === payment ? 'active' : ''">
                                                                                        
                            <div class="d-flex align-items-center me-2">
                                
                                <div class="form-check form-check-custom form-check-solid form-check-success flex-shrink-0 me-6">
                                    <input v-model="payment" class="form-check-input" type="radio" value="single" @click="setPayments(1)">
                                </div>

                                <div class="flex-grow-1">
                                    <div class="d-flex align-items-center fs-2 fw-bold flex-wrap">{{ $t('labels.single-payment') }}</div>
                                    <div class="fw-semibold opacity-75">{{ $t('message.single-payment') }}</div>
                                </div>

                            </div>

                        </label>

                        <label class="nav-link btn btn-outline btn-outline-dashed btn-color-dark btn-active btn-active-primary d-flex flex-stack text-start p-6 m-0" :class="{ 'active': 'multiple' === payment, 'disabled': !enablePayments }">
                                                                                        
                            <div class="d-flex align-items-center me-2">
                                
                                <div class="form-check form-check-custom form-check-solid form-check-success flex-shrink-0 me-6">
                                    <input v-model="payment" class="form-check-input" type="radio" value="multiple" @click="setPayments(2)">
                                </div>

                                <div class="flex-grow-1">
                                    <div class="d-flex align-items-center fs-2 fw-bold flex-wrap">{{ $t('labels.multiple-payments') }}</div>
                                    <div class="fw-semibold opacity-75">{{ $t('labels.paid') }} {{ $t('labels.today') }}: ${{ payment1 }}<br>{{ $t('labels.paid') }} {{ date(paymentDate) }}: ${{ payment2 }}</div>
                                </div>

                            </div>

                        </label>

                    </div>

                    <div v-if="!enablePayments" class="text-right mt-2 text-muted fs-8">{{ $t('message.multiple-payments-disabled') }}</div>

                </div>
                            
            </div>            

            <div class="d-flex justify-content-end w-100 mt-8">
                <BaseButton @click.prevent="pay()"><i class="ki-duotone ki-credit-cart fs-3"><i class="path1"></i><i class="path2"></i></i>{{ $t('labels.pay') }}</BaseButton>
            </div>
            
        </div>
    
    </div>

    <BaseModal ref="modal" :options="{
        backdrop: 'static',
        keyboard: false
    }">

        <template #content>

            <div class="modal-content">

                <div class="alert alert-dismissible bg-warning d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-0">
                    <!--begin::Close-->
                    <!-- button type="button" class="position-absolute top-0 end-0 m-2 btn btn-icon btn-icon-dark" data-bs-dismiss="modal">
                        <i class="ki-duotone ki-cross fs-1"><span class="path1"></span><span class="path2"></span></i>
                    </button -->
                    <!--end::Close-->

                    <!--begin::Icon-->
                    <i class="ki-duotone ki-watch fs-5tx text-dark mb-5"><span class="path1"></span><span class="path2"></span></i>
                    <!--end::Icon-->

                    <!--begin::Wrapper-->
                    <div class="text-center">
                        <!--begin::Title-->
                        <h1 class="fw-bold mb-5" v-html="$t('labels.waiting-list-policy-procedures')"></h1>
                        <!--end::Title-->

                        <!--begin::Separator-->
                        <div class="separator separator-dashed border-danger opacity-25 mb-5"></div>
                        <!--end::Separator-->

                        <!--begin::Content-->
                        <div class="mb-9 text-dark text-left modal-text" v-html="waiting"></div>
                        <!--end::Content-->

                        <!--begin::Buttons-->
                        <div class="d-flex flex-center flex-wrap">
                            <a href="#" class="btn btn-dark m-2" data-bs-dismiss="modal">{{ $t('labels.i-understand') }}</a>
                        </div>
                        <!--end::Buttons-->
                    </div>
                    <!--end::Wrapper-->
                </div>
                <!--end::Alert-->

            </div>

        </template>

    </BaseModal>

</template>

<style scoped>
    .form-check.form-check-success .form-check-input:checked {
        background-color: #141c31;
    }

</style>

<script setup>
    import { products } from '@/api'
    import Route from '@/constants/route'
    import { useCartStore } from '@/store/cart'
    import { useAuthStore } from '@/store/auth'
    import { useActivityStore, useChildStore } from '@/store/model'
    import { useAuth } from '@/composables/auth'
    import { date } from '@/composables/dates'
    import { useShopStore } from '@/store/shop'
    import { useTrans } from '@/composables/i18n'
    import { nextTick } from 'vue'
    import dayjs from '@/plugins/dayjs'
    const { t } = useI18n()

    const auth = useAuthStore()
    const activityStore = useActivityStore()
    const cartStore = useCartStore()
    const childStore = useChildStore()
    const shopStore = useShopStore()

    const { user } = useAuth()
    const trans = useTrans()
    const router = useRouter()

    const shop = inject('shop', null) 

    const loading = ref(false)
    const modal = ref()
    const payment = ref('single')

    
    const activities = computed(() => activityStore.rows)
    const children = computed(() => childStore.rows)

    const client = computed(() => cartStore.client)
    const items = computed(() => cartStore.items)
    const subtotal = computed(() => cartStore.subtotal)    
    const taxes = computed(() => cartStore.taxes)
    const total = computed(() => cartStore.total)
    const amount = computed(() => cartStore.amount)
    const credit = computed(() => cartStore.credit)
    const debit = computed(() => cartStore.debit)

    const payment1 = computed(() => (Math.round((amount.value / 2) * 100 ) / 100).toFixed(2))
    const payment2 = computed(() => (amount.value - payment1.value).toFixed(2))
    const payments = computed(() => cartStore.payments)
    const paymentDate = computed(() => dayjs().add(1, 'month'))

    const error = ref()

    const enablePayments = computed(() => {
        let result = true

        items.value?.forEach(item => {
            if (item.data?.group?.activity?.client) {
                if (!item.data.group.activity.client.payments_enable) {
                    result = false
                }
            }
        })

        return result
    })

    const waitingCourses = computed(() => {
        let courses = []

        cartStore.items.forEach(item => {
            if (item.waiting) {
                let course = '<em>' + trans({ en: item.name_en, fr: item.name_fr }) + ' (' + item.session + ')' + '</em>'

                if (!courses.includes(course)) {
                    courses.push(course)
                }
            }
        })

        return courses.join(', ')
    })

    const waiting = computed(() => t(`instance.${parasco_template}.waiting_list`).replace(':courses', waitingCourses.value))

    onMounted(async () => {
        // Set the current step.
        shop?.setStep(5)
        loading.value = true

        if (payments.value > 1) {
            payment.value = 'multiple'
        }

        cartStore.refresh().finally(() => {
            loading.value = false

            if (cartStore.waiting > 0) {
                modal.value.open()
            }
        })
    })

    /**
     * Format children item children.
     * 
     * @param {array} children
     * @return {array}
     */
    function formatChildren(children) {
        return Object.values(children).map(child => child.name).join(', ')
    }

    /**
     * Format item options.
     * 
     * @param {array} options
     * @return {array}
     */
    function formatOptions(options) {
        return options.map(option => trans({ en: option.name_en, fr: option.name_fr })).join(', ')
    }

    /**
     * Sets number of payments.
     * 
     * @return {void}
     */
    function setPayments(number) {
        loading.value = true

        cartStore.setPayments(number).finally(() => {
            loading.value = false
        })
    }

    /**
     * Go to payment form.
     * 
     * @return {void}
     */
    function pay() {
        error.value = ''

        nextTick(() => {
            if (amount.value > 0) {
                router.push({ name: Route.PAYMENT })
            } else {
                cartStore.checkout().finally(() => {
                    // Reset the cart after successful checkout.
                    cartStore.refresh()
                    
                    // Redirect to the receipt view.
                    router.push({
                        name: Route.ORDER_VIEW,
                        params: { id: cartStore.order?.id }
                    })
                })
            }
        })
    }

    function symbolStyle(item) {
        if (!item.attachment) {
            return {}
        }

        return {
            'background-image': `url(${item.attachment})`
        }
    }
</script>
