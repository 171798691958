<template>

    <BaseForm ref="form" @submitted="submit()">

        <LoadingOverlay :loading="loading">
        
            <div class="grid gap-6 mb-6">

                <BaseInputGroup :label="$t('labels.name')" :error="form?.error('name')">
                    <BaseInput v-model="payload.name"/>
                </BaseInputGroup>

            </div>

            <BaseButton :disabled="submitting" :loading="submitting">
                {{ $t(action) }}
            </BaseButton>

            </LoadingOverlay>

    </BaseForm>

</template>

<script setup>
    import { useI18n } from '@/composables/i18n'
    import { useModelForm } from '@/composables/model-ui'
    import { useSessionStore } from '@/store/model'

    const { t } = useI18n()
    const store = useSessionStore()

    const { form, id, payload, loading, submitting, create, edit, submit, mode } = useModelForm({ 
        store: store, 
        messages: {
            created: t('message.session-created'),
            updated: t('message.session-updated'),
            destroyed: t('message.session-destroyed')
        }
    })

    const action = computed(() => id.value  ? 'labels.submit-session-edit' : 'labels.submit-session-create')

    defineExpose({ mode, create, edit })
</script>
