<template>

    <LoadingOverlay :loading="loading">
            
        <div class="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid" id="kt_modal_create_app_stepper" data-kt-stepper="true">
                        
            <div class="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">

                <div class="stepper-nav">

                    <div class="stepper-item" :class="stepClasses(1)" data-kt-stepper-element="nav">
                        <div class="stepper-wrapper">
                            <div class="stepper-icon w-40px h-40px">
                                <i class="ki-duotone ki-check stepper-check fs-2"></i>
                                <span class="stepper-number">1</span>
                            </div>
                            <div class="stepper-label">
                                <h3 class="stepper-title">{{ $t('labels.general') }}</h3>
                                <div class="stepper-desc">>{{ $t('labels.child-step-1') }}</div>
                            </div>
                        </div>    
                        <div class="stepper-line h-40px"></div>                                                       
                    </div>
                    
                    <div class="stepper-item" :class="stepClasses(2)" data-kt-stepper-element="nav">
                        <div class="stepper-wrapper">
                            <div class="stepper-icon w-40px h-40px">
                                <i class="ki-duotone ki-check stepper-check fs-2"></i>
                                <span class="stepper-number">2</span>
                            </div>
                            <div class="stepper-label">
                                <h3 class="stepper-title">{{ $t('labels.school') }}</h3>
                                <div class="stepper-desc">>{{ $t('labels.child-step-2') }}</div>
                            </div>
                        </div>    
                        <div class="stepper-line h-40px"></div>                                                       
                    </div>

                    <div class="stepper-item" :class="stepClasses(3)" data-kt-stepper-element="nav">
                        <div class="stepper-wrapper">
                            <div class="stepper-icon w-40px h-40px">
                                <i class="ki-duotone ki-check stepper-check fs-2"></i>
                                <span class="stepper-number">3</span>
                            </div>
                            <div class="stepper-label">
                                <h3 class="stepper-title">{{ $t('labels.conditions') }}</h3>
                                <div class="stepper-desc">>{{ $t('labels.child-step-3') }}</div>
                            </div>
                        </div>    
                        <div class="stepper-line h-40px"></div>                                                       
                    </div>

                </div>
                
            </div>

            <div class="flex-row-fluid">
                
                <BaseForm ref="form" @submitted="submit()">
                        
                    <div v-if="isStep(1)" class="d-flex flex-col gap-6">

                        <BaseInputGroup :label="$t('labels.name-first')" :error="form?.error('first_name')">
                            <BaseInput v-model="payload.first_name"/>
                        </BaseInputGroup>

                        <BaseInputGroup :label="$t('labels.name-last')" :error="form?.error('last_name')">
                            <BaseInput v-model="payload.last_name"/>
                        </BaseInputGroup>

                        <!-- BaseInputGroup :label="$t('labels.birthdate')" :error="form?.error('birthdate')">
                            <BaseInput v-model="payload.birthdate" v-maska="'####-##-##'"/>
                        </BaseInputGroup -->                    

                    </div>

                    <div v-if="isStep(2)" class="d-flex flex-col gap-6">

                        <BaseInputGroup :label="$t('labels.school')" :error="form?.error('client_id')">
                            <ClientListbox v-model="payload.client_id" :search-required="true"/>
                        </BaseInputGroup>

                        <BaseInputGroup :label="$t('labels.grade')" :error="form?.error('grade_id')">
                            <GradeListbox v-model="payload.grade_id"/>
                        </BaseInputGroup>

                        <BaseInputGroup :label="$t('labels.bus-number')" :error="form?.error('bus_number')">
                            <BaseInput v-model="payload.bus_number"/>
                        </BaseInputGroup>

                        <BaseInputGroup :label="$t('labels.group')" :error="form?.error('group')">
                            <BaseInput v-model="payload.group"/>
                        </BaseInputGroup>

                        <BaseInputGroup :label="$t('labels.return-type')" :error="form?.error('return_type')">
                            <BaseListbox v-model="payload.return_type" :searchable="false" :options="returnTypes">
                                <template #label="{ option }">
                                    <div class="flex flex-col">
                                        <span class="font-medium" v-text="option.internal.text"></span>
                                        <span v-if="option.internal.desc" class="font-medium text-muted fs-8" v-text="option.internal.desc"></span>
                                    </div>
                                </template>
                            </BaseListbox>
                        </BaseInputGroup>

                        <BaseInputGroup v-if="enableEducator" :label="$t('labels.educator')" :error="form?.error('educator')">
                            <BaseInput v-model="payload.educator"/>
                        </BaseInputGroup>

                    </div>

                    <div v-if="isStep(3)" class="d-flex flex-col gap-6">

                        <BaseInputGroup :label="$t('labels.conditions')" :error="form?.error('conditions')">
                            <ConditionListbox v-model="payload.conditions" :multiple="true"/>
                        </BaseInputGroup>

                        <BaseInputGroup v-if="enableNotes" :label="$t('labels.notes-conditions')" :error="form?.error('notes_medical')">
                            <BaseInput v-model="payload.notes_medical"/>
                        </BaseInputGroup>
                    
                    </div>

                    <div class="d-flex justify-content-end w-100 mt-6">
                        <BaseButton :disabled="loading" type="button" @click.prevent="next()">Continue<i class="ki-duotone ki-arrow-right fs-3 ms-1 me-0"><span class="path1"></span><span class="path2"></span></i></BaseButton>
                    </div>

                </BaseForm>

            </div>

        </div>

    </LoadingOverlay>

</template>

<script setup>
    import { useI18n, useReturnTypes, useTrans } from '@/composables/i18n'
    import { useRules } from '@/composables/rules'
    import { useModelForm } from '@/composables/model-ui'
    import { useChildStore, useConditionStore } from '@/store/model'
    import { date } from '@/composables/dates'
    
    const { t } = useI18n()
    const store = useChildStore()
    const conditionStore = useConditionStore()
    const returnTypes = computed(() => useReturnTypes())
    const trans = useTrans()

    const messages = {
        created: t('message.child-created'),
        updated: t('message.child-updated'),
        destroyed: t('message.child-destroyed')
    }

    const enableNotes = ref(false)
    const enableEducator = ref(false)    
    const step = ref(1)

    const { form, id, payload, loading, submitting, create, edit, submit, mode } = useModelForm({
        store,
        messages,
        reset: () => {
            step.value = 1
        },
        editing: (data) => {
            if (payload.value.birthdate) {
                payload.value.birthdate = date(payload.value.birthdate)
            }

            if (payload.value.conditions) {                
                payload.value.conditions = payload.value.conditions.map(item => item.id)
            }
        }
    })

    watch(() => payload.value?.conditions, async (ids) => {
        let result = false

        if (Array.isArray(ids)) {
            const models = await conditionStore?.find(ids)
            
            result = models?.filter(i => i.enable_notes).length > 0
        }

        enableNotes.value = result
    }, { deep: true })
    
    watch(() => payload.value?.return_type, (returnType) => {
        enableEducator.value = 'daycare' === returnType
    }, { deep: true })

    const action = computed(() => id.value  ? 'labels.submit-child-edit' : 'labels.submit-child-create')

    const stepClasses = (number) => ({
        'current': isStep(number)
    })

    const isStep = (number) => {
        return step.value === number
    }

    const next = async () => {
        // Assume the form is valid until it is invalid.
        let valid = true 

        // Clear any errors from the form.
        form.value?.setErrors({})

        switch (step.value) {
            case 1:
                valid = useRules({ first_name: 'required', last_name: 'required' }, t).apply(payload.value).fail(errors => form.value?.setErrors(errors)).valid()
                break

            case 2:
                valid = useRules({ client_id: 'required', grade_id: 'required', group: 'required', return_type: 'required' }, t).apply(payload.value).fail(errors => form.value?.setErrors(errors)).valid()
                break
        }

        if (3 === step.value) {
            await submit()
            return
        }

        if (valid) {
            step.value++
        }
    }

    defineExpose({ mode, create, edit })
</script>