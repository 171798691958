<template>
    <BaseContainer>

        <header class="flex items-center justify-between gap-6 mb-6">

            <h1 class="text-gray-800 font-bold text-xl leading-none mb-2" v-text="$t('labels.receipt')"></h1>

            <div class="block">
                <BaseButton size="sm" @click.prevent="download()">{{ $t('labels.download') }}</BaseButton>
            </div>
            
        </header>

        <div class="flex flex-col gap-6">
                
            <LoadingOverlay :loading="loading">
            
                <BaseCard>

                    <template #header>
                        <span class="card-title fs-6 fw-bold">{{ $t('labels.general') }}</span>
                    </template>

                    <dl class="flex flex-col">
                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block text-xs font-semibold mb-2">{{ $t('labels.status') }}</dt>
                            <dd class="block text-sm">{{ model?.status ? $t(`enum.order-status.${model.status}`) : '-' }}</dd>
                        </div>    
                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block text-xs font-semibold mb-2">{{ $t('labels.name-first') }}</dt>
                            <dd class="block text-sm">{{ model?.user?.first_name || '-' }}</dd>
                        </div>
                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block text-xs font-semibold mb-2">{{ $t('labels.name-last') }}</dt>
                            <dd class="block text-sm">{{ model?.user?.last_name || '-' }}</dd>
                        </div>
                        <div class="grid md:grid-cols-3">
                            <div class="px-6 py-3 border-t border-gray-200 grow">
                                <dt class="block text-xs font-semibold mb-2">{{ $t('labels.subtotal') }}</dt>
                                <dd class="block text-sm">{{ model?.subtotal ? `$${model.subtotal}` : '-' }}</dd>
                            </div>                  
                            <div class="px-6 py-3 border-t grow">
                                <dt class="block text-xs font-semibold mb-2">{{ $t('labels.taxes') }}</dt>
                                <dd class="block text-sm">{{ model?.taxes ? `$${model.taxes}` : '-' }}</dd>
                            </div>
                            <div class="px-6 py-3 border-t grow">
                                <dt class="block text-xs font-semibold mb-2">{{ $t('labels.total') }}</dt>
                                <dd class="block text-sm">{{ model?.total ? `$${model.total}` : '-' }}</dd>
                            </div>
                        </div>
                    </dl>

                </BaseCard>

            </LoadingOverlay>

            <LoadingOverlay :loading="loading">
                    
                <BaseCard>

                    <template #header>
                        <span class="card-title fs-6 fw-bold">{{ $t('labels.order-items') }}</span>
                    </template>

                    <BaseTable class="w-full">

                        <template #header>
                            <BaseTableCell :header="true">{{ $t('labels.description') }}</BaseTableCell>  
                            <BaseTableCell :header="true">{{ $t('labels.client') }}</BaseTableCell>                      
                            <BaseTableCell :header="true">{{ $t('labels.quantity') }}</BaseTableCell>
                            <BaseTableCell :header="true">{{ $t('labels.total') }}</BaseTableCell>
                        </template>

                        <BaseTableRow v-for="item, index in model.items" :key="index">

                            <BaseTableCell>{{ trans({ en: item.description_en, fr: item.description_fr }) }}</BaseTableCell>
                            <BaseTableCell>{{ item.activity_group?.activity?.client?.name || '-' }}</BaseTableCell>
                            <BaseTableCell>{{ item.quantity }}</BaseTableCell>
                            <BaseTableCell>
                                <s v-if="item.cancelled">${{ item.total }}</s>
                                <span v-else>${{ item.total }}</span>
                            </BaseTableCell>
                        </BaseTableRow>
                        
                    </BaseTable>   

                </BaseCard>

            </LoadingOverlay>

            <div class="flex flex-col md:flex-row md:items-center gap-2">

                <BaseButton @click.prevent="$router.push({ name: Route.SHOP_STEP_1 })">{{ $t('labels.back') }}</BaseButton>

            </div>

        </div>        

    </BaseContainer>
    
    <ReportModal ref="reportModal" />

</template>

<script setup>
    import Route from '@/constants/route'
    import { useAuth } from '@/composables/auth'
    import { date, time } from '@/composables/dates'
    import { useTrans } from '@/composables/i18n'
    import { useModelView } from '@/composables/model-ui'
    import { useOrderStore } from '@/store/model'

    const store = useOrderStore()
    const trans = useTrans()
    const { isAdmin } = useAuth()
    const route = useRoute()

    const loading = ref(false)
    const model = ref({})
    const reportModal = ref()

    onMounted(() => {
        loading.value = true

        const interval = setInterval(async () => {
            const result = await poll(route.params.id)

            if ('processing' !== result.status) {
                clearInterval(interval)

                loading.value = false
                model.value = result
            }                        
        }, 1500);
    })

    /**
     * Poll the order status.
     * 
     * @param {any} id
     * return {object}
     */
    async function poll(id) {
        return await store.show(id)
    }

    /**
     * Format an array of children.
     * 
     * @param {any} names 
     * @return {string}
     */
     function children(names) {
        if (!names) {
            return '-'
        }

        return names.join(',')
    }

    /**
     * Format an array of options.
     * 
     * @param {any} names
     * @return {string}
     */
    function options(names) {
        if (!names) {
            return '-'
        }

        return names.map(name => trans({ en: name.en, fr: name.fr })).join(',')
    }

    /**
     * Download the invoice.
     * 
     * @return {void}
     */
    function download() {
        reportModal.value?.generate?.('invoice', { order: route.params.id })
    }
</script>