<template>

    <BaseContainer>

        <header class="flex flex-col md:flex-row md:items-center md:justify-between gap-4 md:gap-6 mb-6">

            <div class="block">
                <h1 class="text-gray-800 font-bold text-xl leading-none mb-2" v-text="$t('labels.activities')"></h1>
                <Breadcrumbs :current="Route.ACTIVITY_VIEW"/>
            </div>

            <div class="flex flex-col md:flex-row md:justify-end gap-4">

                <BaseButton @click.prevent="edit()" class="mr-3">{{ $t('labels.activity-edit') }}</BaseButton>

                <BaseButton variant="secondary" @click.prevent="index()">{{ $t('labels.back') }}</BaseButton>

            </div>

        </header>

        <div class="flex flex-col gap-6">
            
            <ActivityDisplay/>

            <div class="flex md:justify-end gap-2 mb-6">

                <BaseButton @click.prevent="edit()" class="mr-3">{{ $t('labels.activity-edit') }}</BaseButton>

                <BaseButton variant="secondary" @click.prevent="index()">{{ $t('labels.back') }}</BaseButton>

            </div>

        </div>        

    </BaseContainer>

</template>

<script setup>
    import Route from '@/constants/route'
    import { useTrans } from '@/composables/i18n'
    import { useModelView } from '@/composables/model-ui'
    import { useActivityStore } from '@/store/model'
    
    const store = useActivityStore()
    const trans = useTrans()

    const { loading, model, index, edit, load } = useModelView({ 
        store: store, 
        routes: {
            edit: Route.ACTIVITY_EDIT,
            index: Route.ACTIVITY_INDEX
        }
    })
</script>
