<template>

    <BaseContainer>

        <header class="mb-6">
            
            <h1 class="text-gray-800 font-bold text-xl leading-none mb-2" v-text="$t('labels.transactions')"></h1>
        
            <Breadcrumbs :current="Route.TRANSACTION_INDEX"/>
        
        </header>

        <DataTable
            v-model:page="page"
            id="transactions-20240201"
            :columns="columns"
            :rows="rows"
            :transform="transform"
            :to="to"
            :from="from"
            :total="total"
            :pages="pages"
            :loading="loading"
            :filter="filter"
            :editable="false"
            :destroyable="false"
            @view="view"
            >

            <template #headerActionsPrepend="{ state }">
                <BaseListbox
                    v-model="state.filters.status"
                    class="md:max-w-[175px]"
                    :creatable="false"
                    :searchable="false"
                    :options="statuses"
                    :value="option => option.value"
                    :placeholder="$t('labels.status-filter')">
                </BaseListbox>
            </template>

            <template #status="{ row }">
                <select
                    v-model="row.status" 
                    class="outline-none"
                    @change="onStatusChange(row)">
                    <option v-for="option in statuses" :value="option.value">{{ option.text }}</option>
                </select>
            </template>

            <template #actions>
                <BaseButton @click.prevent="create()"><i class="fa-duotone fa-circle-plus mr-2"></i>{{ $t('labels.transaction-create') }}</BaseButton>
            </template>

            <template v-if="isAdmin" #order="{ row, value }">
                <RouterLink v-if="row.order_id" class="text-primary" :to="{ name: Route.ORDER_VIEW, params: { id: row.order_id } }">{{ value }}</RouterLink>
            </template>

            <template v-if="isAdmin" #parent="{ row, value }">
                <RouterLink v-if="row.user" class="text-primary" :to="{ name: Route.PARENT_VIEW, params: { id: row.user.id } }">{{ value }}</RouterLink>
            </template>

        </DataTable>

        <BaseDrawer :title="$t(form?.mode === 'edit' ? 'labels.transaction-edit' : 'labels.transaction-create')">
            <TransactionForm ref="form"/>
        </BaseDrawer>

    </BaseContainer>

</template>

<script setup>
    import { useI18n, useTrans, useTransactionSources, useTransactionStatuses, useTransactionTypes } from '@/composables/i18n'
    import { useModelIndex } from '@/composables/model-ui'
    import Route from '@/constants/route'
    import { useTransactionStore } from '@/store/model'
    import { useAuth } from '@/composables/auth'
    import { toast } from '@/composables/toast'
    import ToastType from '@/constants/toast-type'

    const { t } = useI18n()
    const trans = useTrans()
    const store = useTransactionStore()
    const router = useRouter()
    const { isAdmin } =  useAuth()

    const sources = useTransactionSources().map(source => ({
        value: source.id,
        text: source.text
    }))

    const statuses = useTransactionStatuses().map(status => ({
        value: status.id,
        text: status.text
    }))

    const types = useTransactionTypes().map(type => ({
        value: type.id,
        text: type.text
    }))

    const messages = computed(() => ({
        'updated': {
            message: t('message.transaction-updated'),
            type: ToastType.SUCCESS
        }
    }))

    const { rows, from, to, total, page, pages, loading, search, filter, destroy } = useModelIndex('transactions', store, {
        created: t('message.transaction-created'),
        updated: t('message.transaction-updated'),
        destroyed: t('message.transaction-destroyed')
    })

    const columns = computed(() => [
        { id: 'id', text: t('labels.id') },
        { id: 'order_id', text: t('labels.order') },
        { id: 'type', text: t('labels.type'), options: types },
        { id: 'category', text: t('labels.category') },
        { id: 'source', text: t('labels.source'), options: sources },
        { id: 'status', text: t('labels.status'), options: statuses },
        { id: 'description', text: t('labels.description') },
        { id: 'notes', text: t('labels.notes-system') },
        { id: 'parent', text: t('labels.parent') },
        { id: 'amount', text: t('labels.amount') },
        { id: 'created_at', text: t('labels.created') },
        { id: 'updated_at', text: t('labels.updated') },
    ])

    const form = ref()
    const create = () => form.value?.create()
    const edit = (id) => form.value?.edit(id)
    const view = (id) => router.push({ name: Route.TRANSACTION_VIEW, params: { id } })

    /**
     * Transform the specified row.
     *
     * @param {String} column
     * @param {Object} row
     * @return {String}
     */
     function transform (column, row) {
        switch (column) {
            case 'amount':     return currency(row.amount)
            case 'parent':     return row?.user ? `${row.user.id} - ${row.user.first_name} ${row.user.last_name}` : '-'
            case 'source':     return row.source ? t(`enum.transaction-source.${row.source}`) : '-'
            case 'status':     return row.status ? t(`enum.transaction-status.${row.status}`) : '-'
            case 'type':       return t(`enum.transaction-type.${row.type}`)
            case 'order':      return row.order ? row.order.id : '-'
        }

        return row[column]
    }

    /**
     * Format a curency value.
     * 
     * @param {*} value 
     */
    function currency (value) {
        const result = '$' + parseFloat(value).toFixed(2)

        return result?.replace('$-', '-$')
    }

    /**
     * Handles row status change.
     * 
     * @param {*} row 
     */
     async function onStatusChange(row) {
        let response = await store.update(row.id, row, true)
        let result = response.status?.replace?.(/.*\./, '')
        let message = unref(messages)?.[result]
        toast(message.type, message.message)
        await store.refresh()
    }
</script>
