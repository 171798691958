<template>
    <form @submit.prevent="submit()">
        <slot/>
    </form>
</template>

<script setup>
    import { useForm } from '@/composables/form'

    const emit = defineEmits(['failed', 'submitted', 'success'])

    const props = defineProps({
        toastEnable: { type: Boolean, default: true },
        toastDuration: { type: Number, default: 3500 },
    })

    const toastEnable = toRef(props, 'toastEnable')
    const toastDuration = toRef(props, 'toastDuration')

    const { error, errors, loading, reject, reset, resolve, setErrors, submit } = useForm({
        emit,
        toastEnable,
        toastDuration,
    })

    provide('form', {
        errors: readonly(errors),
        loading: readonly(loading),
        reset,
        error,
        reject,
        resolve
    })

    defineExpose({ loading, reset, error, reject, resolve, setErrors })
</script>

<script>export default { inheritAttrs: false }</script>
