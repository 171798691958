<template>

    <BaseContainer>

        <header class="mb-6">
            
            <h1 class="text-gray-800 font-bold text-xl leading-none mb-2" v-text="$t('labels.taxrates')"></h1>
        
            <Breadcrumbs :current="Route.TAX_RATE_INDEX"/>
        
        </header>

        <DataTable
            v-model:page="page"
            id="tax-rates"
            :columns="columns"
            :rows="rows"
            :transform="transform"
            :to="to"
            :from="from"
            :total="total"
            :pages="pages"
            :loading="loading"
            :filter="filter"
            :viewable="false"
            @edit="edit"
            @destroy="destroy">

            <template #actions>
                <BaseButton @click.prevent="create()"><i class="fa-duotone fa-circle-plus mr-2"></i>{{ $t('labels.taxrate-create') }}</BaseButton>
            </template>

        </DataTable>

        <BaseDrawer :title="$t(form?.mode === 'edit' ? 'labels.taxrate-edit' : 'labels.taxrate-create')">
            <TaxRateForm ref="form"/>
        </BaseDrawer>

    </BaseContainer>

</template>

<script setup>  
    import { useBoolean, useI18n, useTrans } from '@/composables/i18n'
    import { useModelIndex } from '@/composables/model-ui'
    import Route from '@/constants/route'
    import { useTaxRateStore } from '@/store/model'

    const { t } = useI18n()
    const trans = useTrans()
    const store = useTaxRateStore()    

    const { rows, from, to, total, page, pages, loading, search, filter, destroy } = useModelIndex('tax-rate', store, {
        created: t('message.tax-rate-created'),
        updated: t('message.tax-rate-updated'),
        destroyed: t('message.tax-rate-destroyed')
    })

    const columns = computed(() => [
        { id: 'id', text: t('labels.id') },
        { id: 'name', text: t('labels.name') },
        { id: 'rate', text: t('labels.rate') },
        { id: 'global', text: t('labels.global') },
        { id: 'created_at', text: t('labels.created') },
        { id: 'updated_at', text: t('labels.updated') },
    ])

    const form = ref()
    const create = () => form.value?.create()
    const edit = (id) => form.value?.edit(id)

    /**
     * Transform the specified row.
     *
     * @param {String} column
     * @param {Object} row
     * @return {String}
     */
    function transform (column, row) {
        switch (column) {
            case 'rate': return `${row.rate}%`
            case 'global': return row.global ? t('enum.boolean.true') : t('enum.boolean.false')
        }

        return row[column]
    }
</script>
