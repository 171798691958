import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import utc from 'dayjs/plugin/utc'
import localeData from 'dayjs/plugin/localeData'

import 'dayjs/locale/en'
import 'dayjs/locale/fr'

dayjs.extend(isoWeek)
dayjs.extend(utc)
dayjs.extend(localeData)

window.dayjs = dayjs

export default dayjs
