<template>

	<tr class="d-block d-md-table-row">

	    <td class="d-block d-md-table-cell">

	        <div class="d-flex align-items-center border-0 p-0">
	            
	            <div class="symbol symbol-50px">
	                <div class="symbol-label bg-primary" :style="symbolStyle(item)"></div>
	            </div>
	        
	            <div class="ms-5">
	                <div class="fw-bold text-gray-600">{{ trans({ en: item.name_en, fr: item.name_fr }) }} <div class="badge badge-dark">{{ item.session }}</div><div class="badge badge-danger ml-1" v-if="item.waiting">{{ $t('labels.waiting-list') }}</div></div>
	                <div class="fs-7 text-muted">{{ formatChildren(item.children) }}</div>
                    <div class="fs-7 text-muted">{{ dateStart }} {{ $t('labels.to') }} {{ dateEnd }}</div>
	            </div>
	        
	        </div>

            <ul class="d-flex flex-column d-md-none gap-2 list-unstyled border-0  p-0 my-4">
                <li><strong>{{ $t('labels.quantity') }}:</strong> 1</li>
                <li><strong>{{ $t('labels.total') }}:</strong> ${{ total(item) }}</li>
            </ul>

            <div class="d-flex d-md-none flex-column flex-md-row align-items-md-center justify-content-md-end gap-2 border-0 p-0">
	            <BaseButton @click.prevent="edit(item.key)"><i class="ki-duotone ki-pencil fs-3"><i class="path1"></i><i class="path2"></i></i>{{ $t('labels.edit') }}</BaseButton>
	            <BaseButton variant="secondary" @click.prevent="remove(item.key)"><i class="ki-duotone ki-trash fs-3"><i class="path1"></i><i class="path2"></i><i class="path3"></i><i class="path4"></i><i class="path5"></i></i>{{ $t('labels.delete') }}</BaseButton>
	        </div>

	    </td>

	    <td class="d-none d-md-table-cell text-md-end">
	        <div class="d-flex flex-column flex-md-row align-items-md-center justify-content-md-end gap-2">
	            <BaseButton @click.prevent="edit(item.key)"><i class="ki-duotone ki-pencil fs-3"><i class="path1"></i><i class="path2"></i></i>{{ $t('labels.edit') }}</BaseButton>
	            <BaseButton variant="secondary" @click.prevent="remove(item.key)"><i class="ki-duotone ki-trash fs-3"><i class="path1"></i><i class="path2"></i><i class="path3"></i><i class="path4"></i><i class="path5"></i></i>{{ $t('labels.delete') }}</BaseButton>
	        </div>
	    </td>
	    
        <td class="d-none d-md-table-cell text-md-end">1</td>
	    
        <td class="d-none d-md-table-cell text-md-end">${{ total(item) }}</td>

    </tr>

    <tr v-for="option, index in item.options" :key="index" class="d-block d-md-table-row">
        
        <td class="d-block d-md-table-cell">

            <div class="d-flex align-items-center border-0 p-0">
                
                <div class="d-none d-md-block symbol symbol-50px">
                    <div class="symbol-label bg-transparent"></div>
                </div>
            
                <div class="ms-md-5">
                    <div class="fw-bold text-gray-600">{{ trans({ en: option.name_en, fr: option.name_fr }) }}</div>
                    <div class="fs-7 text-muted">{{ $t('labels.option-for') }} {{ formatChildren(item.children) }}</div>
                </div>
            
            </div>

            <ul class="d-flex flex-column d-md-none gap-2 list-unstyled border-0 p-0 my-4">
                <li><strong>{{ $t('labels.quantity') }}:</strong> 1</li>
                <li><strong>{{ $t('labels.total') }}:</strong> ${{ option.price.toFixed(2) }}</li>
            </ul>

            <div class="d-flex d-md-none flex-column flex-md-row align-items-md-center justify-content-md-end border-0 p-0 gap-2">
	            <BaseButton variant="secondary" @click.prevent="removeOption(item.key, option.id)"><i class="ki-duotone ki-trash fs-3"><i class="path1"></i><i class="path2"></i><i class="path3"></i><i class="path4"></i><i class="path5"></i></i>{{ $t('labels.delete') }}</BaseButton>
	        </div>

        </td>

        <td class="d-none d-md-table-cell text-md-end">
            <div class="d-flex flex-column flex-md-row align-items-md-center justify-content-md-end gap-2">
                <BaseButton variant="secondary" @click.prevent="removeOption(item.key, option.id)"><i class="ki-duotone ki-trash fs-3"><i class="path1"></i><i class="path2"></i><i class="path3"></i><i class="path4"></i><i class="path5"></i></i>{{ $t('labels.delete') }}</BaseButton>
            </div>
        </td>

        <td class="d-none d-md-table-cell text-md-end">
            <span class="d-md-none fw-bold">{{ $t('labels.quantity') }}:</span> 1
        </td>

        <td class="d-none d-md-table-cell text-md-end">
            <span class="d-md-none fw-bold">{{ $t('labels.total') }}:</span> ${{ option.price.toFixed(2) }}
        </td>

    </tr>

</template>

<script setup>
    import Route from '@/constants/route'
	import { useTrans } from '@/composables/i18n'
    import { useCartStore } from '@/store/cart'
    import { useAuthStore } from '@/store/auth'
    import { date, time, weekday } from '@/composables/dates'

    const props = defineProps({
    	item: { type: Object }
    })

    const auth = useAuthStore()
    const cartStore = useCartStore()
    const trans = useTrans()
    const router = useRouter()

    const loading = ref(false)

    const dateStart = ref()
    const dateEnd = ref()

    onMounted(async () => {       
        cartStore.refresh().finally(() => {
            loading.value = false
        })

        dateStart.value = dayjs(props.item.date_start).format('YYYY-MM-DD')
        dateEnd.value = dayjs(props.item.date_end).format('YYYY-MM-DD')
    })

    function edit(key) {
        router.push({ name: Route.SHOP_STEP_3, params: { key: key } })        
    }

    /**
     * Remove the specified item from the cart.
     * 
     * @param {String} key
     * @return void
     */
    function remove(key) {
        loading.value = true

        cartStore.remove(key).finally(() => {
            loading.value = false
        })
    }

    /**
     * Remove the specified item from the cart.
     * 
     * @param {String} key
     * @return void
     */
    function removeOption(key, option) {
        loading.value = true

        cartStore.removeOption(key, option).finally(() => {
            loading.value = false
        })
    }

    /**
     * Format children item children.
     * 
     * @param {array} children
     * @return {array}
     */
    function formatChildren(children) {
        return Object.values(children).map(child => child.name).join(', ')
    }

    /**
     * Format item options.
     * 
     * @param {array} options
     * @return {array}
     */
    function formatOptions(options) {
        return options.map(option => trans({ en: option.name_en, fr: option.name_fr })).join(', ')
    }

    function symbolStyle(item) {
        if (!item.attachment) {
            return {}
        }

        return {
            'background-image': `url(${item.attachment})`
        }
    }

    function total(item) {
        let result = item.total

        if (item.options) {
            const options = Object.values(item.options).map?.(option => option.price).reduce?.((sum, accumulator) => sum + accumulator, 0)

            if (options > 0) {
                result = result - options
            }
        }

        return result.toFixed(2)
    }
</script>