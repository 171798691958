<template>

    <component :is="element" class="whitespace-nowrap overflow-hidden" :style="styles">
    
        <slot/>

    </component>

</template>

<script setup>
    import { toRef } from 'vue'

    const props = defineProps({
        align: { type: String, default: 'left' },        
        header: { type: Boolean, default: false },
        width: { required: false },
    })

    const align = toRef(props, 'align')    
    const header = toRef(props, 'header')
    const width = toRef(props, 'width')

    const element = computed(() => header.value ? 'th' : 'td')

    const styles = computed(() => {
        let result = {}

        if (width.value) {
            result['min-width'] = `${width.value}px`
            result['max-width'] = `${width.value}px`
        }

        return result
    })
</script>
