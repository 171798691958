<template>

    <BaseContainer>

        <header class="flex flex-col md:flex-row md:items-center md:justify-between gap-4 md:gap-6 mb-6">

            <div class="block">
                <h1 class="text-gray-800 font-bold text-xl leading-none my-0" v-text="$t('labels.orders')"></h1>
                <Breadcrumbs :current="Route.ORDER_VIEW" :label="l => label(l)"/>
            </div>

            <div class="flex flex-col md:flex-row md:justify-end gap-4">
                <BaseButton v-if="!unpaid" @click.prevent="download()">{{ $t('labels.download') }}</BaseButton>
                <BaseButton v-if="unpaid" @click.prevent="$refs.modal?.open()">{{ $t('labels.order-pay') }}</BaseButton>
                <BaseButton v-if="isAdmin" @click.prevent="edit()">{{ $t('labels.order-edit') }}</BaseButton>
                <BaseButton v-if="isAdmin" variant="secondary" @click.prevent="index()">{{ $t('labels.back') }}</BaseButton>
            </div>

        </header>

        <div class="flex flex-col gap-6">
                
            <LoadingOverlay :loading="loading">
            
                <BaseCard>

                    <template #header>
                        <span class="card-title fs-6 fw-bold">{{ $t('labels.general') }}</span>
                    </template>

                    <dl class="flex flex-col">
                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.status') }}</dt>
                            <dd class="block">{{ model?.status ? $t(`enum.order-status.${model.status}`) : '-' }}</dd>
                        </div>    
                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.name-first') }}</dt>
                            <dd class="block">{{ model?.user?.first_name || '-' }}</dd>
                        </div>
                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.name-last') }}</dt>
                            <dd class="block">{{ model?.user?.last_name || '-' }}</dd>
                        </div>
                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.email') }}</dt>
                            <dd class="block">{{ model?.user?.email || '-' }}</dd>
                        </div>
                        <div class="grid" :class="((model?.credit > 0) || (model?.debit > 0)) ? 'md:grid-cols-4' : 'md:grid-cols-3'">
                            <div class="px-6 py-3 border-t border-gray-200 grow">
                                <dt class="block font-semibold mb-2">{{ $t('labels.subtotal') }}</dt>
                                <dd class="block">{{ model?.subtotal ? `$${model.subtotal}` : '-' }}</dd>
                            </div>                  
                            <div class="px-6 py-3 border-t grow">
                                <dt class="block font-semibold mb-2">{{ $t('labels.taxes') }}</dt>
                                <dd class="block">{{ model?.taxes ? `$${model.taxes}` : '-' }}</dd>
                            </div>
                            <div class="px-6 py-3 border-t grow" v-if="model?.credit > 0">
                                <dt class="block font-semibold mb-2">{{ $t('labels.credit') }}</dt>
                                <dd class="block">{{ model?.credit ? `$-${model.credit}` : '-' }}</dd>
                            </div>
                            <div class="px-6 py-3 border-t grow" v-if="model?.debit > 0">
                                <dt class="block font-semibold mb-2">{{ $t('labels.debit') }}</dt>
                                <dd class="block">{{ model?.debit ? `$${model.debit}` : '-' }}</dd>
                            </div>
                            <div class="px-6 py-3 border-t grow">
                                <dt class="block font-semibold mb-2">{{ $t('labels.total') }}</dt>
                                <dd class="block">{{ model?.total ? `$${model.total}` : '-' }}</dd>
                            </div>
                        </div>
                    </dl>

                </BaseCard>

            </LoadingOverlay>

            <LoadingOverlay :loading="loading">
                    
                <BaseCard>

                    <template #header>
                        <span class="card-title fs-6 fw-bold">{{ $t('labels.order-items') }}</span>
                    </template>

                    <BaseTable class="w-full">

                        <template #header>
                            <BaseTableCell :header="true">{{ $t('labels.description') }}</BaseTableCell>
                            <BaseTableCell :header="true">{{ $t('labels.quantity') }}</BaseTableCell>                            
                            <BaseTableCell :header="true">{{ $t('labels.subtotal') }}</BaseTableCell>
                            <BaseTableCell :header="true">{{ $t('labels.taxes') }}</BaseTableCell>
                            <BaseTableCell :header="true">{{ $t('labels.total') }}</BaseTableCell>
                        </template>

                        <BaseTableRow v-for="item, index in model.items" :key="index" v-if="model && model.items.length">

                            <BaseTableCell>
                                <strong v-if="item.cancelled">({{ $t('labels.cancelled') }})</strong>
                                {{ trans({ en: item.description_en, fr: item.description_fr }) }}
                            </BaseTableCell>

                            <BaseTableCell>{{ item.quantity }}</BaseTableCell>

                            <BaseTableCell :class="{ 'cancelled': item.cancelled }">${{ item.subtotal }}</BaseTableCell>

                            <BaseTableCell :class="{ 'cancelled': item.cancelled }">${{ item.taxes }}</BaseTableCell>

                            <BaseTableCell :class="{ 'cancelled': item.cancelled }">${{ item.total }}</BaseTableCell>

                        </BaseTableRow>

                        <tr v-else>
                            <td class="whitespace-nowrap overflow-hidden text-center" colspan="5">{{ $t('labels.empty-items') }}</td>
                        </tr>
                        
                    </BaseTable>

                    <div class="text-end p-5 text-gray-600">
                        <span class="fst-italic">{{ $t('labels.taxes-rounding') }}</span>
                    </div>

                </BaseCard>

            </LoadingOverlay>

            <LoadingOverlay :loading="loading">
                    
                <BaseCard>

                    <template #header>
                        <span class="card-title fs-6 fw-bold">{{ $t('labels.registrations') }}</span>
                    </template>
    

                    <BaseTable class="w-full">

                        <template #header>
                            <BaseTableCell :header="true">{{ $t('labels.registration') }}</BaseTableCell>
                            <BaseTableCell :header="true">{{ $t('labels.school') }}</BaseTableCell>
                            <BaseTableCell :header="true">{{ $t('labels.time') }}</BaseTableCell>
                            <BaseTableCell :header="true">{{ $t('labels.actions') }}</BaseTableCell>  
                        </template>

                        <BaseTableRow v-for="row, index in model?.registrations" :key="index" v-if="model && model.registrations.length">

                            <BaseTableCell>
                                <div class="d-flex align-items-center border-0 p-0">
                                    <div class="symbol symbol-50px">
                                        <div class="symbol-label bg-primary" :style="symbolStyle(row)"></div>
                                    </div>
                                    <div class="ms-5">
                                        <div class="fw-bold text-gray-600">
                                            <span v-if="row.status == 'inactive'">({{ $t('labels.cancelled') }})</span>
                                            {{ trans({ en: row.group?.activity.course?.name_en, fr: row.group?.activity?.course?.name_fr }) }} 
                                            <div class="badge badge-dark">{{ row.group?.session?.name }}</div>
                                            <div class="badge badge-warning ml-1" v-if="row.status == 'waiting'">{{ $t('labels.waiting-list') }}</div>
                                            <div class="badge badge-danger ml-1" v-if="row.status == 'inactive'">{{ $t('labels.cancelled') }}</div>
                                        </div>
                                        <div class="fs-7 text-muted">{{ row.child?.first_name }} {{ row.child?.last_name }}</div>
                                        <div class="fs-7 text-muted">{{ getStartDate(row.group) }} {{ $t('labels.to') }} {{ getEndDate(row.group) }}</div>
                                    </div>
                                </div>
                            </BaseTableCell>

                            <BaseTableCell>{{ row.group.activity.client.name }}</BaseTableCell>

                            <BaseTableCell>{{ time(row.group.time_start) }} - {{ time(row.group.time_end) }}</BaseTableCell>

                            <BaseTableCell>
                                <RouterLink class="btn btn-secondary btn-sm" :to="{ name: Route.REGISTRATION_VIEW, params: { id: row.id } }">{{ $t('labels.details') }}</RouterLink>
                            </BaseTableCell>

                        </BaseTableRow>

                        <tr v-else>
                            <td class="whitespace-nowrap overflow-hidden text-center" colspan="5">{{ $t('labels.empty-subscriptions') }}</td>
                        </tr>
                        
                    </BaseTable>   

                </BaseCard>
    
            </LoadingOverlay>

            <LoadingOverlay :loading="loading">
                    
                <BaseCard>

                    <template #header>
                        <span class="card-title fs-6 fw-bold">{{ $t('labels.transactions') }}</span>
                    </template>

                    <BaseTable class="w-full">

                        <template #header>
                            <BaseTableCell :header="true">{{ $t('labels.type') }}</BaseTableCell>
                            <BaseTableCell :header="true">{{ $t('labels.status') }}</BaseTableCell>
                            <BaseTableCell :header="true">{{ $t('labels.source') }}</BaseTableCell>
                            <BaseTableCell :header="true">{{ $t('labels.amount') }}</BaseTableCell>  
                        </template>

                        <BaseTableRow v-for="transaction, index in model.transactions" :key="index">

                            <BaseTableCell>{{ $t(`enum.transaction-type.${transaction.type}`) }}</BaseTableCell>
                            <BaseTableCell><span :class="badgeClasses(transaction)">{{ badgeLabel(transaction) }}</span></BaseTableCell>
                            <BaseTableCell>{{ $t(`enum.transaction-source.${transaction.source}`) }}</BaseTableCell>    
                            <BaseTableCell>${{ transaction.amount }}</BaseTableCell>

                        </BaseTableRow>
                        
                    </BaseTable>   

                </BaseCard>

            </LoadingOverlay>

            <div v-if="isAdmin" class="flex md:justify-end gap-2 mb-6">

                <BaseButton @click.prevent="edit()">{{ $t('labels.order-edit') }}</BaseButton>

                <BaseButton variant="secondary" @click.prevent="index()">{{ $t('labels.back') }}</BaseButton>

            </div>

        </div>

    </BaseContainer>

    <BaseModal ref="modal" :title="$t('labels.order-pay')" class="max-w-[480px]">
        -
    </BaseModal>

    <ReportModal ref="reportModal" />

</template>

<script setup>
    import Route from '@/constants/route'
    import { useAuth } from '@/composables/auth'
    import { date, time } from '@/composables/dates'
    import { useI18n, useTrans } from '@/composables/i18n'
    import { useModelView } from '@/composables/model-ui'
    import { useOrderStore } from '@/store/model'
    import { getEndDate, getStartDate } from '@/composables/activities'

    const { t } = useI18n()
    const store = useOrderStore()
    const trans = useTrans()
    const { isAdmin, isParent } = useAuth()
    const route = useRoute()

    const reportModal = ref()

    const { loading, model, index, edit } = useModelView({ 
        store: store, 
        routes: {
            edit: Route.ORDER_EDIT,
            index: Route.ORDER_INDEX
        }
    })

    const unpaid = computed(() => isParent.value && 'pending' === model?.status)

    /**
     * Format an array of children.
     * 
     * @param {any} options 
     * @return {string}
     */
    function children(children) {
        if (!children) {
            return '-'
        }

        return children.join(',')
    }

    /**
     * Format an array of options.
     * 
     * @param {any} options 
     * @return {string}
     */
    function options(options) {
        if (!options) {
            return '-'
        }

        return options.map(option => trans({ en: option.en, fr: option.fr })).join(',')
    }

    function label(l) {
        if (isParent.value) {
            switch (l) {
                case 'labels.order-index': return 'labels.invoice-index'
                case 'labels.order-view': return 'labels.invoice-view'
            }
        }
        return l
    }

    function badgeLabel(row) {
        if (!row.status) {
            row.status = 'pending'
        }
        
        return t(`enum.transaction-status.${row.status}`)
    }

    function badgeClasses(row) {
        return {
            'badge': true,
            'badge-light-success': row.status === 'complete',
            'badge-light-danger': row.status === 'failed',
            'badge-light-warning': row.status !== 'complete' && row.status !== 'failed'
        }
    }

    function symbolStyle(row) {
        if (!row.group?.activity?.course?.attachment) {
            return {}
        }

        return {
            'background-image': `url(${row.group.activity.course.attachment.url})`
        }
    }

    /**
     * Download the invoice.
     * 
     * @return {void}
     */
     function download() {
        reportModal.value?.generate?.('invoice', { order: route.params.id })
    }
</script>
