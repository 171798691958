
<template>

    <div v-if="isParent" class="mb-5 mb-lg-10 w-100">
        <div id="kt_app_toolbar" class="app-toolbar  py-3 py-lg-6 ">
            <div id="kt_app_toolbar_container" class="app-container container-xxl d-flex flex-stack ">
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                    <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{{ $t('labels.registrations') }}</h1>
                    <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li class="breadcrumb-item text-muted">
                            <RouterLink :to="{ name: Route.HOME }" class="text-muted text-hover-primary">{{ $t('component.breadcrumbs.home') }}</RouterLink>
                        </li>                    
                        <li class="breadcrumb-item"><span class="bullet bg-gray-400 w-5px h-2px"></span></li>        
                        <li class="breadcrumb-item text-muted">{{ $t('labels.registrations') }}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div id="kt_app_content_container" class="app-container container-xxl">
            <ParentHeader/>
            <div class="card card-flush py-4 flex-row-fluid overflow-hidden">
                <div class="card-header">
                    <div class="card-title">
                        <h2>{{ $t('labels.registrations') }}</h2>
                    </div>
                </div>
                <div class="card-body pt-0">
                    <div class="table-responsive">               
                        <table class="table align-middle table-row-dashed gy-5 dataTable no-footer">
                            <thead class="border-bottom border-gray-200 fs-7 fw-bold">
                                <tr class="text-start text-muted text-uppercase gs-0">
                                    <th class="min-w-100px">{{ $t('labels.registration') }}</th>
                                    <th class="min-w-100px">{{ $t('labels.school') }}</th>
                                    <th class="min-w-100px">{{ $t('labels.time') }}</th>
                                    <th class="min-w-100px text-right">{{ $t('labels.actions') }}</th>
                                </tr>                            
                            </thead>
                            <tbody class="fs-6 fw-semibold text-gray-600">
                                <tr v-if="!rows?.length" class="d-block d-md-table-row">
                                    <td class="d-block d-md-table-cell text-center" colspan="5">{{ $t('labels.empty') }}</td>
                                </tr>
                                <tr v-for="row in rows" :key="row.id">
                                    <td>
                                        <div class="d-flex align-items-center border-0 p-0">                                        
                                            <div class="symbol symbol-50px">
                                                <div class="symbol-label bg-primary" :style="symbolStyle(row)"></div>
                                            </div>
                                            <div class="ms-5">
                                                <div class="fw-bold text-gray-600">
                                                    <span v-if="row.status == 'inactive'">({{ $t('labels.cancelled') }})</span>
                                                    {{ trans({ en: row.group?.activity.course?.name_en, fr: row.group?.activity?.course?.name_fr }) }} 
                                                    <div class="badge badge-dark">{{ row.group?.session?.name }}</div>
                                                    <div class="badge badge-danger ml-1" v-if="row.status == 'waiting'">{{ $t('labels.waiting-list') }}</div>
                                                    <div class="badge badge-danger ml-1" v-if="row.status == 'inactive'">{{ $t('labels.cancelled') }}</div>
                                                </div>
                                                <div class="fs-7 text-muted">{{ row.child?.first_name }} {{ row.child?.last_name }}</div>
                                                <div class="fs-7 text-muted">{{ getStartDate(row.group) }} {{ $t('labels.to') }} {{ getEndDate(row.group) }}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{{ row.group.activity.client.name }}</td>
                                    <td>{{ time(row.group.time_start) }} - {{ time(row.group.time_end) }}</td>
                                    <td class="text-right"><RouterLink class="btn btn-secondary btn-sm" :to="{ name: Route.REGISTRATION_VIEW, params: { id: row.id } }">{{ $t('labels.details') }}</RouterLink></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <BaseContainer v-if="isAdmin">

        <header class="mb-6">

            <h1 class="text-gray-800 font-bold text-xl leading-none mb-2" v-text="$t('labels.registrations')"></h1>

            <Breadcrumbs :current="Route.REGISTRATION_INDEX"/>

        </header>

        <DataTable
            v-model:page="page"
            id="registrations"
            :editable="isAdmin"
            :destroyable="isAdmin"
            :columns="columns"
            :rows="rows"
            :transform="transform"
            :to="to"
            :from="from"
            :total="total"
            :pages="pages"
            :loading="loading"
            :filter="filter"
            @view="view"
            @edit="edit"
            @destroy="destroy">

            <template v-if="isAdmin" #name="{ row, value }">
                <RouterLink v-if="row.child_id" class="text-primary" :to="{ name: Route.CHILD_VIEW, params: { id: row.child_id } }">{{ value }}</RouterLink>
            </template>

            <template v-if="isAdmin" #parent="{ row, value }">
                <RouterLink v-if="row.child?.user_id" class="text-primary" :to="{ name: Route.PARENT_VIEW, params: { id: row.child?.user_id } }">{{ value }}</RouterLink>
            </template>

            <template v-if="isAdmin" #client="{ row, value }">
                <RouterLink v-if="row?.group?.activity?.client" class="text-primary" :to="{ name: Route.CLIENT_VIEW, params: { id: row?.group.activity?.client.id } }">{{ value }}</RouterLink>
            </template>

            <template v-if="isAdmin" #course="{ row, value }">
                <RouterLink v-if="row?.group?.activity?.course" class="text-primary" :to="{ name: Route.COURSE_VIEW, params: { id: row?.group.activity?.course?.id } }">{{ value }}</RouterLink>
            </template>

            <template #headerActionsPrepend="{ state }">
                <BaseListbox
                    v-model="state.filters.status"
                    class="w-175px"
                    :creatable="false"
                    :searchable="false"
                    :options="statuses"
                    :value="option => option.value"
                    :placeholder="$t('labels.status-filter')">
                </BaseListbox>
            </template>

            <template #status="{ row }">
                <select
                    v-model="row.status" 
                    class="outline-none"
                    @change="onStatusChange(row)">
                    <option v-for="option in statuses" :value="option.value">{{ option.text }}</option>
                </select>
            </template>

            <template #actions>                
                <BaseButton @click.prevent="create()"><i class="fa-duotone fa-circle-plus mr-2"></i>{{ $t('labels.registration-create') }}</BaseButton>
            </template>

        </DataTable>

    </BaseContainer>

    <BaseDrawer :title="$t(form?.mode === 'edit' ? 'labels.registration-edit' : 'labels.registration-create')">
        <RegistrationForm ref="form"/>
    </BaseDrawer>
    
</template>

<script setup>
    import Route from '@/constants/route'
    import { useAuth } from '@/composables/auth'
    import { useBoolean, useI18n, useTrans } from '@/composables/i18n'    
    import { useModelIndex } from '@/composables/model-ui'
    import { useRegistrationStore } from '@/store/model'    
    import { getEndDate, getStartDate } from '@/composables/activities'
    import { time } from '@/composables/dates'
    import { toast } from '@/composables/toast'
    import ToastType from '@/constants/toast-type'

    const { t } = useI18n()
    const trans = useTrans()
    const store = useRegistrationStore()
    const router = useRouter()
    const { isAdmin, isParent, isImpersonating } =  useAuth()

    if (isImpersonating.value) {
        store.$reset();
    }
    
    const statuses = computed(() => ([
        { value: 'active', text: t('enum.registration-status.active') },
        { value: 'inactive', text: t('enum.registration-status.inactive') },
        { value: 'waiting', text: t('enum.registration-status.waiting') },
    ]))

    const messages = computed(() => ({
        'created': {
            message: t('message.registration-created'),
            type: ToastType.SUCCESS
        },
        'updated': {
            message: t('message.registration-updated'),
            type: ToastType.SUCCESS
        },
        'updated-existing': {
            message: t('message.registration-updated-existing'),
            type: ToastType.WARNING
        },
        'updated-manual': {
            message: t('message.registration-updated-manual'),
            type: ToastType.WARNING
        },
        'destroyed': {
            message: t('message.registration-destroyed'),
            type: ToastType.SUCCESS
        }
    }))

    const { rows, from, to, total, page, pages, loading, search, filter, destroy, list } = useModelIndex('registration', store, messages)

    const columns = computed(() => [
        { id: 'id', text: t('labels.id') },
        { id: 'name', text: t('labels.name') },
        { id: 'parent', text: t('labels.parent') },
        { id: 'client', text: t('labels.client') },
        { id: 'course', text: t('labels.course') },
        { id: 'session', text: t('labels.session') },
        { id: 'status', text: t('labels.status'),  options: statuses.value },
        { id: 'options', text: t('labels.options') },
        { id: 'created_at', text: t('labels.created') },
        { id: 'updated_at', text: t('labels.updated') },
    ])

    const form = ref()
    const create = () => form.value?.create()
    const edit = (id) => form.value?.edit(id)
    const view = (id) => router.push({ name: Route.REGISTRATION_VIEW, params: { id } })    

    onMounted(() => {
        if (isParent.value) {
            store.index({ perPage: -1 })
        }
    })

    /**
     * Transform the specified row.
     *
     * @param {String} column
     * @param {Object} row
     * @return {String}
     */
    function transform (column, row) {
        switch (column) {
            case 'name':       return row?.child ? `${row.child.first_name} ${row.child.last_name}` : ''
            case 'client':     return row?.group?.activity?.client ? `${row.group.activity.client.name}` : ''
            case 'course':     return row?.group?.activity?.course ? trans({ en: row.group.activity.course.name_en, fr: row.group.activity.course.name_fr }) : ''
            case 'session':    return row?.group?.session ? `${row.group.session.name}` : ''
            case 'first_name': return row?.child?.first_name || ''
            case 'last_name':  return row?.child?.last_name || '' 
            case 'options':    return list(row?.options?.map(option => trans({ en: option.name_en, fr: option.name_fr }))) || '-'
            case 'status':     return t(`enum.registration-status.${row.status}`)
        }

        return row[column]
    }

    /**
     * Handles row status change.
     * 
     * @param {*} row 
     */
    async function onStatusChange(row) {
        let response = await store.update(row.id, row, true)
        let result = response.status?.replace?.(/.*\./, '')
        let message = unref(messages)?.[result]
        toast(message.type, message.message)
        await store.refresh()
    }

    function symbolStyle(row) {        
        if (!row.group?.activity?.course?.attachment) {
            return {}
        }

        return {
            'background-image': `url(${row.group.activity.course.attachment.url})`
        }
    }
</script>
