<template>

    <div class="bg-white flex items-center justify-between p-4">

        <slot/>

        <button class="ml-4 inline-flex items-center justify-center hover:bg-danger hover:text-white hover:shadow z-10 w-12 h-12 rounded-md"
            native="button"
            @click.prevent="row.remove()">
            <i class="fa-duotone fa-trash"></i>
        </button>

    </div>
    
</template>

<script setup>
    import { createRepeaterRow } from '@/composables/repeater'

    const emit = defineEmits(['update:modelValue'])

    const props = defineProps({
        index: { required: true, type: Number },
        modelValue: { required: true, type: Object },
    })

    const index = toRef(props, 'index')
    const value = useVModel(props, 'modelValue', emit)
    const row = createRepeaterRow(index, value)
</script>
