<template>
    
    <BaseCard>

        <template #header>
            <span class="card-title fs-6 fw-bold">{{ $t('labels.dates') }}</span>
        </template>
        
        <div class="row gx-10">

            <div class="col-12 col-lg-6">
                <Calendar ref="calendar" v-model="dates" :enable-holidays="true" :multiple="true" class="shadow-sm"/>
            </div>

            <div class="col-12 col-lg-6">

                <div class="d-flex flex-col gap-6">

                    <div class="border border-dark border-dashed rounded min-w-125px py-3 px-4 bg-warning">
                        <div class="d-flex align-items-center gap-4">
                            <i class="ki-duotone ki-calendar-add fs-4x text-dark">
                                <i class="path1"></i>
                                <i class="path2"></i>
                                <i class="path3"></i>
                                <i class="path4"></i>
                                <i class="path5"></i>
                                <i class="path6"></i>
                            </i>
                            <div>
                                <dl class="m-0">
                                    <div>
                                        <dt class="font-bold fs-8">{{ $t('labels.date-start') }}</dt>
                                        <dd class="text-dark fs-8">{{ start }}</dd>
                                    </div>
                                    <div>
                                        <dt class="font-bold fs-8">{{ $t('labels.date-end') }}</dt>
                                        <dd class="text-dark fs-8">{{ end }}</dd>
                                    </div>
                                </dl>
                            </div>      
                        </div>                                                              
                    </div>

                    <div class="border border-gray-500 border-dashed rounded min-w-125px py-3 px-4 bg-gray-light">
                        <div class="d-flex align-items-center gap-4">
                            <i class="ki-duotone ki-calendar-remove fs-4x text-gray">
                                <i class="path1"></i>
                                <i class="path2"></i>
                                <i class="path3"></i>
                                <i class="path4"></i>
                                <i class="path5"></i>
                                <i class="path6"></i>
                            </i>
                            <div>
                                <div class="fw-semibold fs-6 fw-bold mb-2">{{ $t('labels.holidays') }}</div>
                                <dl v-if="$refs.calendar?.currentHolidays?.length > 0">
                                    <div v-for="(holiday, i) in $refs.calendar.currentHolidays" :key="i">
                                        <dt class="font-bold fs-8">{{ trans({ en: holiday.en, fr: holiday.fr }) }}</dt>
                                        <dd class="text-muted fs-8">{{ date(holiday.d) }}</dd>
                                    </div>
                                </dl>
                                <span v-else>-</span>
                            </div>      
                        </div>                                                 
                    </div>

                </div>

            </div>

        </div>

        
        <template #footer>
            <div class="d-flex flex-col flex-md-row align-items-md-center gap-6">
                <div class="flex items-center gap-2">
                    <div class="bg-gray-200 w-30px h-30px rounded"></div>
                    <div class="fw-bold">{{ $t('labels.holidays') }}</div>
                </div>
                <div class="flex items-center gap-2 mr-auto">
                    <div class="bg-warning w-30px h-30px rounded"></div>
                    <div class="fw-bold">{{ $t('labels.assigned') }}</div>
                </div>
                <div class="fw-bold">{{ dates?.length ||  0 }} {{ $t('labels.dates-assigned') }}</div>
            </div>
        </template>

    </BaseCard>
   
</template>

<script setup>
    import { date } from '@/composables/dates'
    import { useTrans } from '@/composables/i18n'

    const emit = defineEmits(['update:modelValue'])

    const props = defineProps({
        modelValue: { required: true }
    })

    const trans = useTrans()
    const model = useVModel(props, 'modelValue', emit)

    const calendar = ref()
    const ignore = ref()
    const dates = ref([])

    const start = computed(() => find('start'))
    const end = computed(() => find('end'))

    watch(model, (value) => syncModel(value), { deep: true })
    watch(dates, value => syncDates(value), { deep: true })        

    const find = (type) => {
        const d = dates.value  
            ?.map(d => dayjs(d))
            ?.sort((a, b) => a.isAfter(b) ? 1 : -1)
            
        if (d?.length >= 2) {
            
            d.splice(1, d.length - 2)

            switch (type) {
                case 'start': return d?.[0] ? date(d[0]) : '-'
                case 'end':   return d?.[0] ? date(d[1]) : '-'
            }
        }

        if (1 === d?.length) {
            return date(d[0])

        }
        
        return '-'
    }

    const syncModel = (value) => {
        if ('model' === ignore.value) {
            ignore.value = undefined
            return
        }

        ignore.value = 'dates'

        if (Array.isArray(value)) {
            dates.value = value.map(item => item.date)
        } else {
            dates.value = []
        }
    }

    const syncDates = (value) => {
        if ('dates' === ignore.value) {
            ignore.value = undefined
            return
        }

        ignore.value = 'model'

        if (!Array.isArray(value)) {
            model.value = []
            return
        }        

        model.value = value.map(date => {
            const users = model.value
                ?.filter(i => i.date === date)
                ?.map(i => i.users)
                ?.shift()

            return { date: date, users: users || [] }
        })
    }
    
    onMounted(() => {
        if (model.value) {
            syncModel(model.value)
        }
    })
</script>