<template>

    <BaseContainer>

        <header class="flex flex-col md:flex-row md:items-center md:justify-between gap-4 md:gap-6 mb-6">

            <div class="block">
                <h1 class="text-gray-800 font-bold text-xl leading-none mb-2" v-text="$t('labels.courses')"></h1>
                <Breadcrumbs :current="Route.COURSE_VIEW"/>
            </div>

            <div class="flex flex-col md:flex-row md:justify-end gap-4">
                <BaseButton @click.prevent="edit()">{{ $t('labels.course-edit') }}</BaseButton>
                <BaseButton variant="secondary" @click.prevent="index()">{{ $t('labels.back') }}</BaseButton>
            </div>

        </header>

        <div class="flex flex-col gap-6">

            <LoadingOverlay :loading="loading">
                    
                <BaseCard>

                    <template #header>
                        <span class="card-title fs-6 fw-bold">{{ $t('labels.general') }}</span>
                    </template>

                    <dl class="flex flex-col">

                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block text-xs font-semibold mb-2">{{ $t('labels.name') }}</dt>
                            <dd class="block text-sm">{{ trans({ en: model?.name_en, fr: model?.name_fr }) || '-' }}</dd>
                        </div>

                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block text-xs font-semibold mb-2">{{ $t('labels.video') }}</dt>
                            <dd class="block text-sm">{{ trans({ en: model?.video_en, fr: model?.video_fr }) || '-' }}</dd>
                        </div>

                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block text-xs font-semibold mb-2">{{ $t('labels.description') }}</dt>
                            <div class="prose max-w-none text-sm" v-html="trans({ en: model?.description_en, fr: model?.description_fr }) || '-'"></div>
                        </div>

                    </dl>

                </BaseCard>

            </LoadingOverlay>

            <div class="flex md:justify-end gap-2 mb-6">

                <BaseButton @click.prevent="edit()">{{ $t('labels.course-edit') }}</BaseButton>

                <BaseButton variant="secondary" @click.prevent="index()">{{ $t('labels.back') }}</BaseButton>

            </div>

        </div>        


    </BaseContainer>

</template>

<script setup>
    import Route from '@/constants/route'
    import { date, time } from '@/composables/dates'
    import { useTrans } from '@/composables/i18n'
    import { useModelView } from '@/composables/model-ui'
    import { useCourseStore } from '@/store/model'

    const store = useCourseStore()
    const trans = useTrans()

    const { loading, model, index, edit } = useModelView({ 
        store: store, 
        routes: {
            edit: Route.COURSE_EDIT,
            index: Route.COURSE_INDEX
        }
    })
</script>
