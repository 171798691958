<template>

    <BaseModal ref="modal" class="max-w-[360px]" @closed="reset()">

        <template #title>
            <div class="flex items-center text-sm">
                <i class="text-lg" :class="icon"></i><span class="font-semibold ml-3">{{ status }}</span>                
            </div>
        </template>

        <div class="report-content" v-if="url">
            <span v-text="$t('labels.please-click')"></span>: <a :href="url" target="_blank" v-text="url"></a>
        </div>
        
    </BaseModal>

</template>

<script setup>
    import { reports } from '@/api'    
    import ToastType from '@/constants/toast-type'
    import { toast } from '@/composables/toast'
    import { useI18n } from '@/composables/i18n'
    import { useViewInterval } from '@/composables/interval'

    const { t } = useI18n()

    const status = ref('')
    const icon = ref('')
    const generating = ref(false)
    const polling = ref(false)
    const id = ref()
    const active = ref(false)
    const modal = ref()
    const url = ref('')

    useViewInterval(async () => {
        if (!polling.value) {
            return;
        }

        try {
            const response = await reports.show(id.value)

            if (response.data.data.status === 'ready') {
                id.value = false
                polling.value = false
                generating.value = false
                status.value = t('message.report-ready') 
                icon.value = 'fa-duotone fa-circle-check text-success'

                // Download the report if the modal is currently active.
                if (active.value) {
                    //window.open(response.data.data.url, '_blank')
                    url.value = response.data.data.url
                }
            }

            if (response.data.data.status === 'failed') {
                onErrorResponse(response)
            }
        } catch (error) {
            onErrorResponse(error.response)
        }
    }, 1500);

    /**
     * Reset the report generator.
     * 
     * @return {void}
     */
    function reset() {
        status.value = ''
        icon.value = ''
        generating.value = false
        polling.value = false
        active.value = false
        url.value = null
    }

    /**
     * Generate a report.
     * 
     * @param {string} report
     * @param {string} arg
     * @return {void}
     */
    function generate(report, arg) {
        // Reset the report generator.
        reset()

        // Set the generating status.
        active.value = true
        generating.value = true
        status.value = t('message.report-generating')
        icon.value = 'fa-duotone fa-spinner fa-spin text-primary'

        // Open the modal.
        modal.value?.open()

        console.log(arg)

        reports.generate(report, arg).then(response => {
            // Update the status.
            id.value = response.data.id
            polling.value = true            
        }).catch(error => {
            onErrorResponse(error.response)
        })
    }

    function onErrorResponse(response) {
        // Update the status.
        status.value = t('message.report-error')
        icon.value = 'fa-duotone fa-circle-exclamation text-danger'            
        generating.value = false
        polling.value = false

        // Display the error message if provided.
        if (response?.data?.message) {
            toast(ToastType.ERROR, response?.data?.message)
        }
    }

    defineExpose({ generate })
</script>