import { cart } from '@/api'
import Store from '@/constants/store'
import ToastType from '@/constants/toast-type'
import { toast } from '@/composables/toast'

export const useCartStore = defineStore(Store.CART, {
    state: () => ({
        items: [],
        subtotal: 0.00,
        taxes: 0.00,
        total: 0.00,
        amount: 0.00,
        credit: 0.00,
        debit: 0.00,
        available: 0.00,
        scheduled: 0.00,
        payments: 1,
        override: 0,
        waiting: 0,
        orderData: [] // Not synched, only available after checkout
    }),

    getters: {
        order: (state) => state.orderData
    },

    actions: {
        sync (data) {
            this.items = data.items
            this.subtotal = data.subtotal
            this.taxes = data.taxes
            this.total = data.total
            this.amount = data.amount
            this.credit = data.credit
            this.debit = data.debit
            this.available = data.available
            this.scheduled = data.scheduled
            this.payments = data.payments
            this.override = data.override
            this.waiting = data.waiting
        },

        async refresh () {
            const response = await cart.show()

            this.sync(response.data)
        },

        async add (payload) {
            const response = await cart.add(payload)

            this.sync(response.data)
        },

        async update (payload) {
            const response = await cart.update(payload)

            this.sync(response.data)
        },

        async remove (key) {
            const response = await cart.remove(key)

            this.sync(response.data)
        },

        async removeOption (key, option) {
            const response = await cart.removeOption(key, option)

            this.sync(response.data)
        },

        async setPayments (number) {
            const response = await cart.payments(number)

            this.sync(response.data)
        },

        async setOverride (number) {
            const response = await cart.override(number)

            this.sync(response.data)
        },

        async checkout () {
            await cart.checkout({ items: this.items }).then(response => {
                const { data } = response?.data
                this.orderData = data
            }).catch(error => {
                if (error.response?.status !== 422 && 'message' in error.response?.data) {
                    toast(ToastType.ERROR, error.response.data.message, 3500)
                }
            })
        }
    }
})
