<template>

    <BaseForm ref="form" @submitted="submit()">

        <LoadingOverlay :loading="loading">

            <div class="grid gap-6 mb-6">

                <BaseInputGroup :label="$t('labels.name')" :error="form?.error('name')">
                    <BaseInput v-model="payload.name"/>
                </BaseInputGroup>
                
                <BaseInputGroup :label="$t('labels.rate')" :error="form?.error('rate')">
                    <BaseInput v-model="payload.rate"/>
                </BaseInputGroup>

                <BaseInputGroup>
                    <BaseToggle v-model="payload.global">{{ $t('labels.global') }}</BaseToggle>
                </BaseInputGroup>
                
            </div>

            <BaseButton :disabled="submitting" :loading="submitting">
                {{ $t(action) }}
            </BaseButton>

        </LoadingOverlay>

    </BaseForm>

</template>

<script setup>
    import { useI18n } from '@/composables/i18n'
    import { useModelForm } from '@/composables/model-ui'
    import { useTaxRateStore } from '@/store/model'

    const { t } = useI18n()
    const store = useTaxRateStore()

    const { form, id, payload, loading, submitting, create, edit, submit, mode } = useModelForm({ 
        store: store, 
        messages: {
            created: t('message.taxrate-created'),
            updated: t('message.taxrate-updated'),
            destroyed: t('message.taxrate-destroyed')
        }
    })

    const action = computed(() => id.value  ? 'labels.submit-taxrate-edit' : 'labels.submit-taxrate-create')

    defineExpose({ mode, create, edit })
</script>
