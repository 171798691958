<template>

    <BaseListbox v-model="model"
        :ajax="true"
        :filter="filter"
        :min-chars="minChars"
        :searchable="true"
        :value="option => option.id"
        :label="label"
        :search="search"/>

</template>

<script setup>
    import { useLocale } from '@/composables/i18n' 
    import { options } from '@/api'

    const locale = useLocale()
    const emit = defineEmits(['update:modelValue'])

    const props = defineProps({ 
        modelValue: [Number, String],
        searchRequired: { type: Boolean, default: false }
    })

    const modelValue = toRef(props, 'modelValue')
    const searchRequired = toRef(props, 'searchRequired')

    const model = useVModel(props, 'modelValue', emit)

    const filter = computed(() => searchRequired.value ? false : true)
    const minChars = computed(() => searchRequired.value ? 3 : -1)

    const label = computed(() => {
        // Use isLocale to determine which label to use based on the current locale
        return locale.isLocale('fr') ? (option => option.name_fr) : (option => option.name_en);
    });

    const search = async (args) => {
        let params = {}

        if (args.query) {
            params.search = args.query
        }

        if (args.values) {
            params.ids = args.values
        }

        const response = await options.courses(params)

        return response.data
    }

    defineExpose({ search })  
</script>
