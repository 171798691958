<template>

    <div class="d-flex align-items-center mb-7">

        <h3 class="text-dark me-6">{{ $t('labels.activities') }}</h3>

        <form data-kt-search-element="form" class="w-100 mw-200px position-relative" autocomplete="off">
            <i class="ki-duotone ki-magnifier fs-3 text-gray-500 position-absolute top-50 translate-middle-y ms-0 z-index-3"
                style="left:0.75rem"><span class="path1"></span><span class="path2"></span></i>
            <input v-model="search" class="search-input form-control ps-10" :placeholder="$t('labels.search') + '...'">
            <i @click="clearSearch" v-show="search"
                class="bi bi-x-lg fs-3 text-gray-500 position-absolute top-50 translate-middle-y ms-0 z-index-3"
                style="right:0.75rem"><span class="path1"></span><span class="path2"></span></i>

        </form>

    </div>

    <div class="row g-10">

        <div v-for="item in activities" :key="item.id" class="d-flex align-items-stretch col-md-4">

            <a class="card w-100 d-flex flex-col" href="#" @click.prevent="select(item)">

                <div class="card-body d-flex flex-col gap-6">

                    <div class="d-flex align-items-center justify-content-between gap-6">

                        <a href="#" @click.prevent="select(item)">
                            <div class="badge"
                                :class="isRegistrationsOpen(item.groups[0]) ? 'badge-success' :  'badge-danger'">
                                {{ isRegistrationsOpen(item.groups[0]) ? $t('labels.registrations-open') :
                                $t('labels.registrations-closed') }}
                            </div>
                        </a>

                        <div v-if="!item.groups[0]?.waiting_list" class="h-5px w-100 bg-light">
                            <div class="rounded h-5px" role="progressbar" aria-valuenow="50" aria-valuemin="0"
                                aria-valuemax="100" :class="progressClasses(item.groups[0])"
                                :style="progressStyle(item.groups[0])"></div>
                        </div>

                    </div>

                    <a class="d-block overlay" data-fslightbox="lightbox-hot-sales" href="#">
                        <div class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px bg-primary"
                            :style="imageStyle(item)"></div>
                        <div class="overlay-layer card-rounded bg-dark bg-opacity-25">
                            <i class="ki-duotone ki-eye fs-2x text-white">
                                <span class="path1"></span>
                                <span class="path2"></span>
                                <span class="path3"></span>
                            </i>
                        </div>
                    </a>
                    <div class="d-flex flex-col">
                        <h2 class="fs-2 fw-bold"><a class="text-gray-900 text-hover-primary" href="#">{{ trans({ en:
                                item.course?.name_en, fr: item.course?.name_fr }) }}</a></h2>
                        <p class="text-muted" v-html="trans({ en: item.course?.intro_en, fr: item.course?.intro_fr })">
                        </p>
                        <ul class="grade-list list-unstyled d-flex justify-content-center">
                            <li v-for="(grade, index)  in item?.grades" :key="grade.id">
                                <div class="symbol symbol-circle symbol-40px m-0">
                                    <div class="symbol-label fs-8 fw-semibold">{{ trans({ en: grade.abbreviation_en, fr:
                                        grade.abbreviation_fr }) }}</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="d-flex flex-col gap-2 mt-auto">

                        <div class="row g-12">
                            <div class="col">
                                <div class="border border-gray-300 border-dashed rounded w-full py-3 px-4 position-relative">
                                    <span v-if="item.groups.length === 2" class="position-absolute top-0 start-100 translate-middle badge badge-circle badge-primary" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="tooltip-inverse" :title="$t('labels.two-sessions-available')">2</span>
                                    <div class="fs-4 fw-bold counted">
                                        <a class="text-gray-900 text-hover-primary fs-5" href="#">
                                            <i class="ki-duotone ki-calendar-tick fs-4 text-gray-900 ">
                                                <i class="path1"></i>
                                                <i class="path2"></i>
                                                <i class="path3"></i>
                                                <i class="path4"></i>
                                                <i class="path5"></i>
                                                <i class="path6"></i>
                                            </i>
                                            {{ item.date_start }}
                                        </a>
                                    </div>
                                    <div class="fw-semibold fs-7 text-gray-400">{{ $t('labels.starts') }}</div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="border border-gray-300 border-dashed rounded w-full py-3 px-4 position-relative">
                                    <span v-if="item.groups.length === 2" class="position-absolute top-0 start-100 translate-middle badge badge-circle badge-primary" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="tooltip-inverse" :title="$t('labels.two-sessions-available')">2</span>
                                    <div class="fs-4 fw-bold counted">
                                        <a class="text-gray-900 text-hover-primary fs-5" href="#">
                                            <i class="ki-duotone ki-calendar-tick fs-4 text-gray-900 ">
                                                <i class="path1"></i>
                                                <i class="path2"></i>
                                                <i class="path3"></i>
                                                <i class="path4"></i>
                                                <i class="path5"></i>
                                                <i class="path6"></i>
                                            </i>
                                            {{ item.date_end }}
                                        </a>
                                    </div>
                                    <div class="fw-semibold fs-7 text-gray-400">{{ $t('labels.ends') }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row g-12">
                            <div class="col">
                                <div class="border border-gray-300 border-dashed rounded w-full py-3 px-4">
                                    <div class="fs-4 fw-bold counted">
                                        <a class="text-gray-900 text-hover-primary fs-5" href="#">
                                            <i class="ki-duotone ki-calendar-2 fs-4 text-gray-900 ">
                                                <i class="path1"></i>
                                                <i class="path2"></i>
                                                <i class="path3"></i>
                                                <i class="path4"></i>
                                                <i class="path5"></i>
                                            </i>
                                            {{ item.weekdays }}
                                        </a>
                                    </div>
                                    <div class="fw-semibold fs-7 text-gray-400">{{ $t('labels.week-days') }}</div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="border border-gray-300 border-dashed rounded w-full py-3 px-4">
                                    <div class="fs-4 fw-bold counted">
                                        <a class="text-gray-900 text-hover-primary fs-5" href="#">
                                            <i class="ki-duotone ki-time fs-4 text-gray-900 ">
                                                <i class="path1"></i>
                                                <i class="path2"></i>
                                            </i>
                                            {{ item.groups[0].time_start }} - {{ item.groups[0].time_end }}
                                        </a>
                                    </div>
                                    <div class="fw-semibold fs-7 text-gray-400">{{ $t('labels.time') }}</div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex align-items-center justify-content-between">
                            <div class="badge-group">
                                <div v-for="(child, index) in children(item)" :key="child.id"
                                    class="badge text-white px-3" :style="badgeStyle(index)">
                                    {{ child.first_name }}
                                </div>
                            </div>
                            <div class="ml-4 fs-1 fw-bold">
                                <a class="text-gray-900 text-hover-primary" href="#">${{ item.groups[0].price }}</a>
                            </div>
                        </div>

                    </div>

                </div>

            </a>

        </div>

    </div>

</template>

<style scoped>
    .badge-group .badge:not(:first-child) {
        margin-left: -15px;
    }

    .badge-group .badge {
        position: relative;
    }

    .badge-group .badge:hover {
        z-index: 9999;
    }

    .grade-list li:not(:first-child) {
        margin-left: -5px;
    }

    .grade-list .symbol-label {
        position: relative;
        z-index: 1;
    }
    .grade-list li .symbol-label {
        background-color: #f0f6ff;
    }

    .grade-list li:nth-child(even) .symbol-label {
        background-color: #a2a5b7;
        color: #fff;
    }
</style>

<script setup>
    import Route from '@/constants/route'
    import { isRegistrationsOpen, getRegistrationPercentage, getStartDate, getEndDate, getWeekdays } from '@/composables/activities'
    import { date, time, weekday } from '@/composables/dates'
    import { useTrans, useLocale } from '@/composables/i18n'    
    import { useShopStore } from '@/store/shop'
    import { watch } from 'vue'

    const store = useShopStore()
    const trans = useTrans()
    const locale = useLocale()
    const router = useRouter()

    const shop = inject('shop', null)    

    const search = ref()
    const client = computed(() => store.client)

    const colors = [
        '#9448fb',
        '#6295fc',
        '#e9c231',
        '#ff6384',
        '#36a2eb',
        '#ffce56',
        '#4bc0c0',
        '#9966ff',
        '#ff9f40'
    ]

    const activities = computed(() => {
        let results = []

        const q = search.value?.toLowerCase?.() || ''
      
        client.value?.activities?.forEach?.(activity => {
            let isMatched = true;
            if (q) {
                const name = locale.isLocale('fr') ? activity.course.name_fr : activity.course.name_en;
                console.log(name);
                isMatched = filterBykeyword(q, name)
            }
            if (activity.groups && activity.groups.length > 0 && isMatched) {
                let startDate = null;
                let endDate = null;
                let weekdays = new Set();

                activity.groups.forEach((group, index) => {
                    if (index === 0) {
                        startDate = getStartDate(group) || '-';
                    }

                    getWeekdays(group).forEach(day => weekdays.add(day));

                    if (index === activity.groups.length - 1) {
                        endDate = getEndDate(group) || '-';
                    }
                })

                activity.date_start = startDate;
                activity.date_end = endDate;
                activity.weekdays = Array.from(weekdays).join(', ') || '-';

                results.push(activity);
            }
        })

        return results;
    })
    const clearSearch = () => {
        search.value = '';
};
    const filterBykeyword = (q, name) => {
            let words = q.trim().split(" ");
            let uniquewords = [...new Set(words)];

            let searchStr = [];
            uniquewords.forEach(element => {
                searchStr.push("\\b" + element);
                searchStr.push(element + "\\b");
                searchStr.push(element);
            });

            var regex = new RegExp(searchStr.join("|"), "gim");

            let result = [...name?.matchAll?.(regex)]



            return result.length >= uniquewords.length

    }
        
    onMounted(() => {
        shop?.setStep(3)

        if (!client.value) {
            router.push({ name: Route.SHOP_STEP_2 })
        }

        // Required for tooltips
        KTApp.init();
    })

    function select(activity) {
        store.setActivity(activity)
        router.push({ name: Route.SHOP_STEP_4 })
    }

    function badgeStyle(index) {
        const color = colors[index % colors.length]

        return {
            'background-color': color
        }
    }

    function progressClasses(group) {
        const filled = group?.filled || 0

        return {
            'bg-success': filled < 100,
            'bg-danger': filled >= 100
        }
    }

    function progressStyle(group) {
        const filled = getRegistrationPercentage(group)
        
        if (-1 === filled) {
            return { display: 'none '}
        }

        return { width: `${filled}%` }
    }

    function imageStyle(activity) {
        if (!activity?.course?.attachment)
            return {}

        return { 'background-image': `url(${activity.course.attachment.url})` }
    }

    function children(activity) {
        const gradeIds = activity.grades.map(grade => grade.id)

        return client.value.children.filter(child => gradeIds.includes(child.grade_id))
}

watch(locale.locale, () => {
    clearSearch();
})
    
</script>