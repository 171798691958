<template>

    <BaseForm ref="form" @submitted="submit()">

        <LoadingOverlay :loading="loading">

            <div class="d-flex flex-col flex-md-row gap-10">

                <div class="w-full w-md-350px shrink-0"> 

                    <div class="d-flex flex-col gap-10">

                        <BaseCard>

                            <div class="d-flex flex-col gap-6">               

                                <BaseInputGroup :label="$t('labels.course')" :error="form?.error('course_id')">
                                    <div class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px bg-light mb-4" :style="courseImageStyle"></div>
                                    <CourseListbox v-model="payload.course_id" :creatable="true" :create="() => $refs.courses.create()"/>
                                </BaseInputGroup>
                            
                                <BaseInputGroup :label="$t('labels.client')" :error="form?.error('client_id')">
                                    <ClientListbox ref="client" v-model="payload.client_id" :creatable="true" :create="() => $refs.clients.create()"/>
                                </BaseInputGroup>
 
                                <BaseInputGroup :label="$t('labels.grade')" :error="form?.error('grade_id')">
                                    <GradeListbox ref="grade" v-model="payload.grades"
                                        :multiple="true"
                                        :autoclose="false"
                                        :creatable="true" :create="() => $refs.grades.create()"/>
                                </BaseInputGroup>

                                <BaseInputGroup :label="$t('labels.category')" :error="form?.error('category_id')">
                                    <CategoryListbox v-model="payload.categories"/>
                                </BaseInputGroup>

                            </div>
                                    
                        </BaseCard>

                    </div>

                </div>

                <div class="w-100"> 

                    <TabGroup class="mb-6">                  
                    
                        <TabList class="mb-6"/>

                        <TabPanel :title="$t('labels.general')">

                            <BaseCard>

                                <div class="d-flex flex-col gap-6">

                                    <div class="row gx-10">

                                        <div class="col-12 col-md-6">

                                            <div class="d-flex flex-col gap-6">

                                                <BaseInputGroup class="mw-350px" :label="$t('labels.type')" :error="form?.error('type')">
                                                    <BaseListbox v-model="payload.type" :searchable="false" :options="types"/>
                                                </BaseInputGroup>

                                                <BaseInputGroup class="mw-350px" :label="$t('labels.language')" :error="form?.error('language')">
                                                    <BaseListbox v-model="payload.language" :searchable="false" :options="languages"/>
                                                </BaseInputGroup>
                                                    
                                                <BaseInputGroup class="mw-500px" :label="$t('labels.representative')" :error="form?.error('representative')">
                                                    <BaseInput v-model="payload.representative"/>
                                                </BaseInputGroup>

                                                <BaseInputGroup class="mw-500px" :label="$t('labels.local')" :error="form?.error('location')">
                                                    <BaseInput v-model="payload.location"/>
                                                </BaseInputGroup>

                                                <BaseInputGroup class="mw-500px" :label="$t('labels.invoice-number')" :error="form?.error('invoice_number')">
                                                    <BaseInput v-model="payload.invoice_number"/>
                                                </BaseInputGroup>

                                            </div>

                                        </div>

                                        <div class="col-12 col-md-6">

                                            <div class="d-flex flex-col gap-6">

                                                <BaseCard>

                                                    <template #header>
                                                        <span class="card-title fs-6 fw-bold">{{ $t('labels.notes-public') }}</span>
                                                    </template>
                                                    
                                                    <div class="row gx-10">

                                                        <div class="col-12">

                                                            <BaseInputGroup :error="form?.error('notes_public')">
                                                                <div class="relative shadow-sm">
                                                                    <Tiptap v-model="payload.notes_public"/>
                                                                </div>
                                                            </BaseInputGroup>

                                                        </div>

                                                    </div>

                                                </BaseCard>

                                                <BaseCard>

                                                    <template #header>
                                                        <span class="card-title fs-6 fw-bold">{{ $t('labels.notes-private') }}</span>
                                                    </template>
                                                    
                                                    <div class="row gx-10">

                                                        <div class="col-12">

                                                            <BaseInputGroup :error="form?.error('notes_private')">
                                                                <div class="relative shadow-sm">
                                                                    <Tiptap v-model="payload.notes_private"/>
                                                                </div>
                                                            </BaseInputGroup>

                                                        </div>

                                                    </div>

                                                </BaseCard>

                                                <BaseCard>

                                                    <template #header>
                                                        <span class="card-title fs-6 fw-bold">{{ $t('labels.notes-employee') }}</span>
                                                    </template>

                                                    <div class="row gx-10">

                                                        <div class="col-12">

                                                            <BaseInputGroup :error="form?.error('notes_employee')">
                                                                <div class="relative shadow-sm">
                                                                    <Tiptap v-model="payload.notes_employee"/>
                                                                </div>
                                                            </BaseInputGroup>

                                                        </div>

                                                    </div>

                                                </BaseCard>

                                            </div>

                                        </div>                                               

                                    </div>

                                </div>

                            </BaseCard>

                        </TabPanel>

                        <TabPanel :title="$t('labels.schedule')">

                            <BaseCard>

                                <div class="d-flex flex-col gap-6">

                                    <Duplicator
                                        ref="groups"
                                        v-model="payload.groups"
                                        :create="$t('labels.group-add')"
                                        :empty="$t('labels.empty')"
                                        :title="$t('labels.groups')"
                                        :duplicated="duplicated"
                                        :template="() => ({ waiting_list: true })">

                                        <template #default="{ index, item }">

                                            <div :class="index > 0 ? 'card border border-gray-300' : ''">
                                            
                                                <div :class="index > 0 ? 'card-body' : ''">
                                                    
                                                    <h4 v-if="index > 0" class="fs-4 mb-8">{{ $t('labels.extension') }}</h4>

                                                    <div class="row gx-10">

                                                        <div class="col-12 col-md-6">

                                                            <div class="d-flex flex-col gap-6">

                                                                <BaseInputGroup class="mw-350px" :label="$t('labels.session')" :error="form?.error(`groups.${index}.session_id`)">
                                                                    <SessionListbox ref="session" v-model="item.session_id" :creatable="true" :create="() => $refs.sessions.create()"/>
                                                                </BaseInputGroup>
                                                                
                                                                <BaseInputGroup class="mw-500px" :label="$t('labels.price')" :error="form?.error(`groups.${index}.price`)">
                                                                    <BaseInput v-model="item.price"/>
                                                                </BaseInputGroup>

                                                                <BaseInputGroup v-if="0 !== index" class="mw-500px" :label="$t('labels.price-rebate')" :error="form?.error(`groups.${index}.price_rebate`)">
                                                                    <BaseInput v-model="item.price_rebate"/>
                                                                </BaseInputGroup>

                                                                <BaseInputGroup class="mw-350px" :label="$t('labels.registration-start')" :error="form?.error(`groups.${index}.registration_start`)">
                                                                    <Datepicker v-model="item.registration_start"/>
                                                                </BaseInputGroup>

                                                                <BaseInputGroup class="mw-350px" :label="$t('labels.registration-end')" :error="form?.error(`groups.${index}.registration_end`)">
                                                                    <Datepicker v-model="item.registration_end"/>
                                                                </BaseInputGroup>

                                                                <BaseInputGroup class="mw-500px" :label="$t('labels.registration-maximum')" :error="form?.error(`groups.${index}.registration_maximum`)">
                                                                    <BaseInput v-model="item.registration_maximum"/>
                                                                </BaseInputGroup>
                                                                
                                                                <BaseInputGroup class="mw-350px" :label="$t('labels.time-start')" :error="form?.error(`groups.${index}.time_start`)">
                                                                    <BaseInput v-model="item.time_start" type="time"/>
                                                                </BaseInputGroup>

                                                                <BaseInputGroup class="mw-350px" :label="$t('labels.time-end')" :error="form?.error(`groups.${index}.time_end`)">
                                                                    <BaseInput v-model="item.time_end" type="time"/>
                                                                </BaseInputGroup>

                                                                <div class="d-flex flex-col gap-2">

                                                                    <BaseToggle v-model="item.public">{{ $t('labels.visible') }}</BaseToggle>

                                                                    <BaseToggle v-model="item.waiting_list">{{ $t('labels.use-waiting-list') }}</BaseToggle>

                                                                    <BaseToggle v-if="0 === index" v-model="duplicated">{{ $t('labels.use-extension') }}</BaseToggle>

                                                                </div>

                                                            </div>

                                                        </div>

                                                        <div class="col-12 col-md-6 cards-right">

                                                            <BaseCard>

                                                                <template #header>
                                                                    <span class="card-title fs-6 fw-bold">{{ $t('labels.status-block') }}</span>
                                                                </template>

                                                                <div class="d-flex flex-col gap-6">

                                                                    <BaseInputGroup :label="$t('labels.status')"  :error="form?.error('status')">
                                                                        <BaseListbox v-model="item.status" :searchable="false" :options="statuses"/>
                                                                    </BaseInputGroup>

                                                                    <BaseInputGroup :label="$t('labels.notes-status')" :error="form?.error('notes_status')">
                                                                        <BaseInput v-model="item.notes_status"/>
                                                                    </BaseInputGroup>

                                                                </div>

                                                            </BaseCard>

                                                            <ActivityCalendar v-model="item.dates"/>

                                                        </div>

                                                    </div>        

                                                </div>
                                                
                                            </div>

                                        </template>

                                    </Duplicator>
                                    
                                </div>
                            
                            </BaseCard>

                        </TabPanel>

                        <TabPanel :title="$t('labels.employees-suppliers')">

                            <BaseCard>

                                <Repeater
                                    v-model="payload.users"
                                    :create="$t('labels.employee-supplier-add')"
                                    :empty="$t('labels.employees-empty')"
                                    :title="$t('labels.employees-suppliers')"
                                    :template="() => ({ pivot: {} })">

                                    <template #item="{ index, item, remove }">

                                        <BaseCard class="w-100" :shadow="false">

                                            <div class="row gx-10">

                                                <div class="col-12 col-md-6">

                                                    <div class="d-flex flex-col gap-6">
                                            
                                                        <BaseInputGroup class="mw-350px" :label="$t('labels.employee-supplier')" :error="form?.error(`users.${index}.id`)">
                                                            <EmployeeListbox v-model="item.id" :creatable="true" :create="onCreate" @select="option => onUserChanged(item, option)"/>
                                                        </BaseInputGroup>

                                                        <BaseInputGroup class="mw-500px" :label="$t('labels.rate-standard')" :error="form?.error(`users.${index}.pivot.rate_standard`)">
                                                            <BaseInput v-model="item.pivot.rate_standard"/>
                                                        </BaseInputGroup>

                                                        <BaseInputGroup class="mw-500px" :label="$t('labels.rate-transportation')" :error="form?.error(`users.${index}.pivot.rate_transportation`)">
                                                            <BaseInput v-model="item.pivot.rate_transportation"/>
                                                        </BaseInputGroup>

                                                        <BaseInputGroup class="mw-500px" :label="$t('labels.rate-others')" :error="form?.error(`users.${index}.pivot.rate_others`)">
                                                            <BaseInput v-model="item.pivot.rate_others"/>
                                                        </BaseInputGroup>

                                                        <BaseInputGroup :label="$t('labels.exclude')" :error="form?.error(`users?${index}.pivot.rate_others`)">
                                                            <BaseToggle v-model="item.pivot.exclude"/>
                                                        </BaseInputGroup>
                                            
                                                    </div>

                                                </div>

                                                <div class="d-flex align-items-stretch col-12 col-md-6">
                                                    
                                                    <EmployeeCalendar v-model="payload" :user="item.id"/>

                                                </div>

                                            </div>

                                            <div class="d-flex justify-content-end gap-6 mt-8">
                                                <BaseButton @click.prevent="remove()">{{ $t('labels.delete') }}</BaseButton>
                                            </div>

                                        </BaseCard>
                                        

                                    </template>

                                </Repeater>

                            </BaseCard>

                        </TabPanel>

                    </TabGroup>

                    <div class="d-flex justify-content-end mb-6">

                        <BaseButton :disabled="submitting" :loading="submitting">
                            {{ $t(action) }}
                        </BaseButton>

                    </div>

                </div>

            </div>

        </LoadingOverlay>

        <BaseDrawer ref="modal" :title="$t('labels.dates-add')">

            <div class="grid gap-6 mb-6">
                    
                <div>
                    <h4 class="mb-2 text-sm font-semibold">{{ $t('labels.dates') }}</h4>
                    <div class="bg-gray-100 p-4">                        
                        <calendar v-model="dates" :multiple="true"/>
                    </div>
                </div>

                <div v-if="users?.length > 0">
                    <h4 class="mb-2 text-sm font-semibold">{{ $t('labels.schedule-assign') }}</h4>
        
                    <ul class="grid gap-4 bg-gray-100 p-4">

                        <li v-for="user in users" :key="user.id">
                            <BaseToggle v-model="dateUsers" :value-true="[]" :value="user.id">
                                <span class="text-sm">{{ name(user) }}</span>
                            </BaseToggle>                            
                        </li>

                    </ul>

                </div>          
            
            </div>
        
            <BaseButton @click.prevent="onSaveDates()">{{ $t('labels.dates-add') }}</BaseButton>

        </BaseDrawer>

        <BaseModal :title="$t('labels.employee-create')" size="lg">
            <EmployeeForm ref="employees"/>            
        </BaseModal>

        <BaseModal :title="$t('labels.supplier-create')" size="lg">            
            <SupplierForm ref="suppliers"/>            
        </BaseModal>
        
        <BaseDrawer :title="$t('labels.grade-create')">
            <GradeForm ref="grades"/>
        </BaseDrawer>

        <BaseModal :title="$t('labels.client-create')" :fullscreen="true">
            <ClientForm ref="clients"/>
        </BaseModal>

        <BaseModal :title="$t('labels.course-create')" fullscreen="true">
            <CourseForm ref="courses"/>            
        </BaseModal>

        <BaseDrawer :title="$t('labels.session-create')">
            <SessionForm ref="sessions"/>
        </BaseDrawer>

    </BaseForm>

</template>

<script setup>
    import dayjs from '@/plugins/dayjs'
    import ToastType from '@/constants/toast-type'
    import { date, time } from '@/composables/dates'
    import { useActivityStatuses, useActivityTypes, useI18n, useLanguages, useTrans } from '@/composables/i18n'
    import { useModelForm } from '@/composables/model-ui'
    import { toast } from '@/composables/toast'
    import { useActivityStore, useCourseStore, useUserStore } from '@/store/model'

    const client = ref()
    const grade = ref()
    const session = ref()
    const dates = ref([])
    const group = ref()
    const dateUsers = ref([])
    const modal = ref()
    const users = ref([])
    const employees = ref()
    const suppliers = ref()
    const groups = ref()
    const currentHolidays = ref([])

    const { t } = useI18n()
    const trans = useTrans()
    const store = useActivityStore()
    const courseStore = useCourseStore()
    const userStore = useUserStore()
    const languages = useLanguages()
    const statuses = useActivityStatuses()
    const types = useActivityTypes()

    const duplicated = computed({
        get() { 
            return groups.value?.duplicated
        },
        set(value) {
            groups.value.duplicated = value
        }
    })
    
    const { form, id, payload, loading, submitting, create, edit, submit, mode } = useModelForm({
        store,
        messages: {
            created: t('message.activity-created'),
            updated: t('message.activity-updated'),
            destroyed: t('message.activity-destroyed')
        },
        editing: (data) => {
            if (Array.isArray(payload.value?.grades)) {
                payload.value.grades = payload.value.grades.map(grade => grade.id) || []
            } else {
                payload.value.grades = []
            }

            if (Array.isArray(payload.value?.groups)) {
                payload.value.groups = payload.value.groups.map(group => {
                    group.registration_start = date(group.registration_start)
                    group.registration_end = date(group.registration_end)                    
                    group.time_start = group.time_start
                    group.time_end = group.time_end
                    group.waiting_list = group.waiting_list // Map waiting_list from group data
                    return group
                })
            } else {              
                payload.value.groups = []
            }
            
            if (Array.isArray(payload.value?.users)) {
                payload.value.users = payload.value.users?.map(user => ({
                    id: user.id,
                    pivot: {
                        rate_standard: user.pivot.rate_standard,
                        rate_transportation: user.pivot.rate_transportation,
                        rate_others: user.pivot.rate_others,
                        exclude: user.pivot.exclude
                    }
                }))
            } else {
                payload.value.users = []
            }

            if (payload.value.categories) {                
                payload.value.categories = payload.value.categories.map(item => item.id)
            }
        }
    })

    const action = computed(() => id.value  ? 'labels.submit-activity-edit' : 'labels.submit-activity-create')

    const courseImageStyle = computed(() => {
        const course = courseStore.rows.filter(c => c.id == payload.value.course_id).shift()

        if (!course?.attachment) {
            return {}
        }

        return { 'background-image': `url(${course.attachment.url})` }
    })
    
    watch(() => payload?.value.users, async (value) => {
        if (value && Array.isArray(value)) {
            //console.log(value)
            const ids = value
                .filter(item => item && typeof item === 'object' && 'id' in item)
                .map(item => item.id)

            if (ids && ids.length > 0) {
                //console.log(ids)
                users.value = await userStore.find(ids)
            } else {
                users.value = []
            }
        } else {
            users.value = []
        }
    }, { deep: true })

    // Define the exposed properties.
    defineExpose({ mode, create, edit })

    /**
     * Create an entity.
     * 
     * @param {any} arg 
     * @return {any}
     */
    function onCreate(arg) {
        switch (arg) {
            case 'employee': return employees.value?.create()                
            case 'supplier': return suppliers.value?.create()
        }
    }

    /**
     * Get the user display name.
     * 
     * @param {object} user 
     * @return {string}
     */
    function name(user) {
        if ('supplier' === user.role) {
            return user.name
        }

        return `${user.first_name} ${user.last_name}`
    }

    function onUserChanged(item, option) {
        /*
            Notes:
                Rates might fluctuate for an employee depending of the date he's assigned to.
                For this reason, it was decided that assigning the same employee is accepted.

        */

        /*
        const matching = payload.value?.users?.filter?.(user => user.id == option.value)

        if (matching?.length > 1) {
            item.id = null
            toast(ToastType.ERROR, t('message.employee-already-present'))   
        }
        */
    }
</script>
