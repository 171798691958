<template>

    <div class="d-block">

        <slot/>

    </div>
    
</template>

<script setup>
    import { createRepeaterRow } from '@/composables/repeater'

    const emit = defineEmits(['update:modelValue'])

    const props = defineProps({
        index: { required: true, type: Number },
        modelValue: { required: true, type: Object },
    })

    const index = toRef(props, 'index')
    const value = useVModel(props, 'modelValue', emit)
    const row = createRepeaterRow(index, value)
</script>
