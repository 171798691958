<template>
    <RouterView/>
</template>

<script setup>
    import { useHead } from '@vueuse/head'
    import axios from '@/plugins/axios'    
    import { useAuth } from '@/composables/auth'
    import { useI18n } from '@/composables/i18n'
    import dayjs from '@/plugins/dayjs'

    import { useConfigStore } from "@/stores/config"
    import { useThemeStore } from "@/stores/theme"
    import { themeConfigValue } from "@/core/helpers/config"

    const { locale, fallbackLocale } = useI18n()
    const route = useRoute()
    const { user } = useAuth()

    const configStore = useConfigStore()
    const themeStore = useThemeStore()

    useHead({
         title: () => {
            switch (locale.value) {
                case 'en': return parasco_name_en
                case 'fr': return parasco_name_fr
            }
        }
    })

    watch(locale, (value) => { onLocaleChanged(value) })

    onMounted(() => {
        // Force the application to use light mode.
        KTThemeMode.setMode('light')
        
        // Refresh the locale.
        refreshLocale()
    })

    onBeforeMount(() => {
      /**
       * Overrides the layout config using saved data from localStorage
       * remove this to use static config (@/core/config/DefaultLayoutConfig.ts)
       */
      configStore.overrideLayoutConfig()

      /**
       *  Sets a mode from configuration
       */
      themeStore.setThemeMode(themeConfigValue.value)
    })

    function onLocaleChanged(value) {
        // Store the selected locale in local storage.
        localStorage.setItem('app:locale', value)

        // Set the "Accept-Language" header.
        axios.defaults.headers.common['Accept-Language'] = value
        
        // Set the dayjs locale.
        dayjs.locale(value)
    }

    function refreshLocale() {
        let value = localStorage.getItem('app:locale') || fallbackLocale.value

        if (user.value) {
            value = user.value?.preference_locale
        }

        locale.value = value

        onLocaleChanged(locale.value)
    }
</script>

