import * as _ from 'lodash'
import { date, weekday } from '@/composables/dates'
import { capitalizeFirstLetter } from '@/composables/utility'
import dayjs from '@/plugins/dayjs'

/**
 * Indicates if the specified activity group's registrations are open.
 * 
 * @param {object} group 
 * @returns {boolean}
 */
export function isRegistrationsOpen(group) {
    if (group?.waiting_list) {
        return true
    }

    return group?.filled < 100
}

/**
 * Indicates the percentage of the specified activity group's registrations that are filled.
 * 
 * @param {object} group 
 * @returns 
 */
export function getRegistrationPercentage(group) {
    if (group?.waiting_list) {
        return -1
    }

    return group?.filled || 0
}

export function getDates(group, splice = true) {
    const dates = group?.dates
        ?.map(d => dayjs(d.date))
        ?.sort((a, b) => a.isAfter(b) ? 1 : -1)
        ?.map(d => date(d))

    if (splice && dates && dates.length > 2) {
        dates.splice(1, dates.length - 2)
    }

    return dates || []
}

export function getStartDate(group) {
    const dates = getDates(group)

    return dates?.[0]
}


export function getEndDate(group) {
    const dates = getDates(group)

    if (dates?.length > 1) {
        return dates?.[1]
    }

    return dates?.[0]
}

export function getWeekdays(group) {
    let results = []

    const dates = getDates(group, false)
        .map(d => ({
            day: dayjs.utc(d).day(),
            text: dayjs.utc(d).format('ddd').toLowerCase().replace(/\.$/, '')
        }))

    results = _.uniqBy(dates, 'day')
    results = _.sortBy(results, 'day')
    results = results.map(d => capitalizeFirstLetter(d.text))

    return results
}
