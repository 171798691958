<template>

    <div class="relative w-ful">

        <div ref="reference" class="flex flex-col" @click.prevent.stop="open()">
        
            <BaseInput readonly v-model="model" v-bind="$attrs">
        
                <template #groupAppend>
                    
                    <button v-if="model" class="btn btn-secondary" type="button" @click.stop.prevent="clear()">
                        <i class="fa-duotone fa-xmark text-xs"></i>
                    </button>
                    
                </template>
        
            </BaseInput>
        
        </div>

        <div ref="popper" class="z-20" :class="{'pointer-events-none': !active}">
            
            <div class="bg-white shadow transition-all ease-expo duration-150" :class="{
                'opacity-100 scale-100': active,
                'opacity-0 scale-75': !active,
            }">
            
                <Calendar ref="cal" v-model="model"/>
                
            </div>

        </div>

    </div>

</template>

<script setup>
    import { usePopper, usePopperFlip, useSetPopperWidth } from '@/composables/popper'
    
    const emit = defineEmits(['update:modelValue'])

    const props = defineProps({
        modelValue: { type: [Array, String] }
    })

    const active = ref(false)
    const cal = ref()

    const model = useVModel(props, 'modelValue', emit)    

    const { instance, reference, popper } = usePopper({
        placement: 'bottom-start',
        modifiers: [
            usePopperFlip(),
            useSetPopperWidth()
        ]
    })

    const open = () => active.value = true
    const close = () => active.value = false
    const update = () => nextTick(() => instance.value?.forceUpdate())
    const clear = () => model.value = null

    watch(model, (value) => close())
    watch(active, (value) => update())
    onClickOutside(popper, () => close())

    provide('datepicker', { update })
</script>

<script>export default { inheritAttrs: false }</script>
