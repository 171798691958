<template>

    <BaseContainer>

        <header class="mb-6">

            <h1 class="text-gray-800 font-bold text-xl leading-none mb-2" v-text="$t('labels.sectors')"></h1>

            <Breadcrumbs :current="Route.SECTOR_INDEX"/>

        </header>

        <DataTable
            v-model:page="page"
            id="sectors"
            :filterable="false"
            :columns="columns"
            :rows="rows"
            :to="to"
            :from="from"
            :total="total"
            :pages="pages"
            :filter="filter"
            :viewable="false"
            @edit="edit"
            @destroy="destroy">

            <template #actions>
                <BaseButton @click.prevent="create()"><i class="fa-duotone fa-circle-plus mr-2"></i>{{ $t('labels.sector-create') }}</BaseButton>
            </template>

        </DataTable>

        <BaseDrawer :title="$t(form?.mode === 'edit' ? 'labels.sector-edit' : 'labels.sector-create')">
            <SectorForm ref="form"/>
        </BaseDrawer>

    </BaseContainer>

</template>

<script setup>  
    import Route from '@/constants/route'
    import { useI18n, useTrans } from '@/composables/i18n'
    import { useModelIndex } from '@/composables/model-ui'
    import { useSectorStore } from '@/store/model'

    const { t } = useI18n()
    const trans = useTrans()
    const store = useSectorStore()

    const { rows, from, to, total, page, pages, search, filter, destroy } = useModelIndex('condition', store, {
        created: t('message.sector-created'),
        updated: t('message.sector-updated'),
        destroyed: t('message.sector-destroyed')
    })
    
    const columns = computed(() => [
        { id: 'id', text: t('labels.id') },
        { id: 'name', text: t('labels.name') },
        { id: 'created_at', text: t('labels.created') },
        { id: 'updated_at', text: t('labels.updated') },
    ])

    const form = ref()
    const create = () => form.value?.create()
    const edit = (id) => form.value?.edit(id)
</script>
