import { profile, users } from '@/api'
import { useAuthStore } from '@/store/auth'
import { onMounted } from 'vue'
import { useI18n as i18n } from 'vue-i18n'

export function useLocale () {
    const { fallbackLocale, locale, t } = i18n({ useScope: 'global' })
    const authStore = useAuthStore()

    const isLocale = (value) => { return locale.value === value }
    
    const setLocale = async (value) => {
        locale.value = value
    }

    const translate = (labels) => {
        if (locale.value in labels) {
            return labels[locale.value]
        } else if (fallbackLocale.value in labels) {
            return labels[fallbackLocale.value]
        }

        return ''
    }

    const syncLocale = async () => {
        if (authStore.isAuthenticated) {
            await profile.locale(locale.value)
        }
    }

    // Sync the locale when the component is mounted.
    onMounted(() => { syncLocale() })

    // Sync the locale when the locale changes.
    watch(locale, () => { syncLocale() })

    return { fallbackLocale, locale, t, isLocale, setLocale, translate }
}

export function useI18n () {
    return i18n({ useScope: 'global' })
}

export function useI18nLocales () {
    const { t } = useI18n()

    return [
        { id: 'en', text: t('locales.en') },
        { id: 'fr', text: t('locales.fr') }
    ]
}

export function useTrans () {
    const { locale, fallbackLocale } = useI18n()

    return (labels) => {
        if (locale.value in labels) {
            return labels[locale.value]
        }

        return labels[fallbackLocale.value] || ''
    }
}

export function useActivityTypes () {
    const { t } = useI18n()

    return [
        { id: 'none', text: t('enum.activity-type.none') },
        { id: 'extracurricular', text: t('enum.activity-type.extracurricular') },
        { id: 'unique-event', text: t('enum.activity-type.unique-event') },
        { id: 'birthday', text: t('enum.activity-type.birthday') },
        { id: 'camp', text: t('enum.activity-type.camp') },
        { id: 'other', text: t('enum.activity-type.other') }
    ]
}

export function useActivityStatuses () {
    const { t } = useI18n()

    return [
        { id: 'standby', text: t('enum.activity-status.standby') },
        { id: 'confirmed', text: t('enum.activity-status.confirmed') },
        { id: 'cancelled', text: t('enum.activity-status.cancelled') },
        { id: 'additional-group', text: t('enum.activity-status.additional-group') }
    ]
}

/**
 * Returns condition type options.
 *
 * @returns array
 */
export function useConditionTypes () {
    const { t } = useI18n()

    return [
        { id: 'allergy', text: t('enum.condition-type.allergy') },
        { id: 'other', text: t('enum.condition-type.other') }
    ]
}

export function useUserRoles () {
    const { t } = useI18n()

    return [
        { id: 'admin', text: t('enum.role.admin') },
        { id: 'employee', text: t('enum.role.employee') },
        { id: 'parent', text: t('enum.role.parent') },
        { id: 'supplier', text: t('enum.role.supplier') }
    ]
}

export function useReturnTypes () {
    const { t } = useI18n()

    return [
        { id: 'daycare', text: t('enum.return-type.daycare'), desc: t('enum.return-type.daycare-desc') },
        { id: 'pickup', text: t('enum.return-type.pickup'), desc: t('enum.return-type.pickup-desc') },
        { id: 'walks', text: t('enum.return-type.walks'), desc: t('enum.return-type.walks-desc') }
    ]
}

export function useClientTypes () {
    const { t } = useI18n()

    return [
        { id: 'camp', text: t('enum.client-type.camp') },
        { id: 'city', text: t('enum.client-type.city') },
        { id: 'kindergarten', text: t('enum.client-type.kindergarten') },
        { id: 'leisure', text: t('enum.client-type.leisure') },
        { id: 'school', text: t('enum.client-type.school') },
        { id: 'schoolboard', text: t('enum.client-type.schoolboard') },
        { id: 'private', text: t('enum.client-type.private') },
        { id: 'other', text: t('enum.client-type.other') }
    ]
}

export function useLanguages () {
    const { t } = useI18n()

    return [
        { id: 'none', text: t('enum.language.none') },
        { id: 'english', text: t('enum.language.english') },
        { id: 'french', text: t('enum.language.french') },
        { id: 'bilingual', text: t('enum.language.bilingual') }
    ]
}

export function useContactRoles () {
    const { t } = useI18n()

    return [
        { id: 'camp-coordinator', text: t('enum.contact-role.camp-coordinator') },
        { id: 'daycare', text: t('enum.contact-role.daycare') },
        { id: 'parent', text: t('enum.contact-role.parent') },
        { id: 'secretary', text: t('enum.contact-role.secretary') },
        { id: 'teacher', text: t('enum.contact-role.teacher') },
        { id: 'principal', text: t('enum.contact-role.principal') },
        { id: 'vice-principal', text: t('enum.contact-role.vice-principal') },
        { id: 'other', text: t('enum.contact-role.other') }
    ]
}

export function useBoolean () {
    const { t } = useI18n()

    return [
        { id: '0', text: t('enum.boolean.false') },
        { id: '1', text: t('enum.boolean.true') }
    ]
}

export function useOrderStatuses () {
    const { t } = useI18n()

    return [
        { id: 'complete', text: t('enum.order-status.complete') },
        { id: 'credited', text: t('enum.order-status.credited') },
        { id: 'failed', text: t('enum.order-status.failed') },
        { id: 'pending', text: t('enum.order-status.pending') },
        { id: 'processing', text: t('enum.order-status.processing') },
        { id: 'refunded', text: t('enum.order-status.refunded') }        
    ]
}

export function useTransactionSources () {
    const { t } = useI18n()

    return [
        { id: 'card', text: t('enum.transaction-source.card') },
        { id: 'credit', text: t('enum.transaction-source.credit') }
    ]
}

export function useTransactionStatuses () {
    const { t } = useI18n()

    return [
        { id: 'cancelled', text: t('enum.transaction-status.cancelled') },
        { id: 'complete', text: t('enum.transaction-status.complete') },
        { id: 'failed', text: t('enum.transaction-status.failed') },
        { id: 'pending', text: t('enum.transaction-status.pending') },
        { id: 'scheduled', text: t('enum.transaction-status.scheduled') }        
    ]
}

export function useTransactionTypes () {
    const { t } = useI18n()

    return [
        { id: 'credit', text: t('enum.transaction-type.credit') },
        { id: 'debit', text: t('enum.transaction-type.debit') },
        { id: 'payment', text: t('enum.transaction-type.payment') }
    ]
}

export function useMonths () {
    const { t } = useI18n()

    return [
        { id: '01', text: t('months.january'), numeric: '01' },
        { id: '02', text: t('months.february'), numeric: '02' },
        { id: '03', text: t('months.march'), numeric: '03' },
        { id: '04', text: t('months.april'), numeric: '04' },
        { id: '05', text: t('months.may'), numeric: '05' },
        { id: '06', text: t('months.june'), numeric: '06' },
        { id: '07', text: t('months.july'), numeric: '07' },
        { id: '08', text: t('months.august'), numeric: '08' },
        { id: '09', text: t('months.september'), numeric: '09' },
        { id: '10', text: t('months.october'), numeric: '10' },
        { id: '11', text: t('months.november'), numeric: '11' },
        { id: '12', text: t('months.december'), numeric: '12' }
    ]
}

export function useAttachmentSortBy() {
    const { t } = useI18n()

    return [
        { id: "name", text: t("labels.attachments-sortby-name") },
        { id: "created_at", text: t("labels.attachments-sortby-created_at") },
        { id: "updated_at", text: t("labels.attachments-sortby-updated_at") },
    ];
}

export function useOrderBy() {
    const { t } = useI18n()

    return [
        { id: 'asc', text: t('labels.orderby-asc') },
        {id: 'desc', text: t('labels.orderby-desc') }
    ]
}