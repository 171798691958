import { provide, readonly, ref, watch } from 'vue'

export function createModal (emit) {
    const el = ref()
    const active = ref(false)

    const open = () => {
        active.value = true
    }

    const close = () => {
        active.value = false
    }

    const resolve = () => {
        close()
    }

    const reject = () => {
        close()
    }

    watch(active, (value) => {
        emit(value ? 'opened' : 'closed')
    })

    onClickOutside(el, (event) => {
        if (event.target.contains(el.value)) {
            close()
        }
    })

    provide('modal', { active: readonly(active), open, close, resolve, reject })

    return { el, active, open, close }
}
