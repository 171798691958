<template>

    <BaseContainer>

        <header class="mb-6">
            
            <h1 class="text-gray-800 font-bold text-xl leading-none mb-2" v-text="$t('labels.children')"></h1>
        
            <Breadcrumbs :current="Route.CHILD_EDIT"/>
        
        </header>

        <BaseCard class="p-6">            
            <ChildForm ref="form" @success="$router.push({ name: Route.CHILD_INDEX })"/>
        </BaseCard>

    </BaseContainer>

</template>

<script setup>
import Route from '@/constants/route'

const form = ref()
const route = useRoute()

onMounted(() => form.value?.edit(route.params.id))
</script>
