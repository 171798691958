<template>

    <BaseContainer>

        <header class="mb-6">
            
            <h1 class="text-gray-800 font-bold text-xl leading-none mb-2" v-text="$t('labels.categories')"></h1>
        
            <Breadcrumbs :current="Route.CATEGORY_INDEX"/>
        
        </header>

        <DataTable
            v-model:page="page"
            id="categories"
            :columns="columns"
            :rows="rows"
            :transform="transform"
            :to="to"
            :from="from"
            :total="total"
            :pages="pages"
            :loading="loading"
            :filter="filter"
            :viewable="false"
            @edit="edit"
            @destroy="destroy">

            <template #actions>
                <BaseButton @click.prevent="create()"><i class="fa-duotone fa-circle-plus mr-2"></i>{{ $t('labels.category-create') }}</BaseButton>
            </template>

        </DataTable>

        <BaseDrawer :title="$t(form?.mode === 'edit' ? 'labels.category-edit' : 'labels.category-create')">
            <CategoryForm ref="form"/>
        </BaseDrawer>

    </BaseContainer>

</template>

<script setup>  
    import { useI18n, useTrans } from '@/composables/i18n'
    import { useModelIndex } from '@/composables/model-ui'
    import Route from '@/constants/route'
    import { useCategoryStore } from '@/store/model'

    const { t } = useI18n()
    const trans = useTrans()
    const store = useCategoryStore()

    const { rows, from, to, total, page, pages, loading, search, filter, destroy } = useModelIndex('category', store, {
        created: t('message.category-created'),
        updated: t('message.category-updated'),
        destroyed: t('message.category-destroyed')
    })

    const columns = computed(() => [
        { id: 'id', text: t('labels.id') },
        { id: 'name', text: t('labels.name') },
        { id: 'name_en', text: t('labels.name-en') },
        { id: 'name_fr', text: t('labels.name-fr') },
        { id: 'updated_at', text: t('labels.updated') },
    ])

    const form = ref()
    const create = () => form.value?.create()
    const edit = (id) => form.value?.edit(id)

    /**
     * Transform the specified row.
     *
     * @param {String} column
     * @param {Object} row
     * @return {String}
     */
    function transform (column, row) {
        switch (column) {
            case 'name': return trans({ en: row.name_en, fr: row.name_fr })
        }

        return row[column]
    }
</script>
