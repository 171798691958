<template>
    <BaseModal ref="modal" class="max-w-[650px]" :title="$t('labels.generate-report')" @closed="onClosed()">
        <div v-if="isState('parameters')" class="flex flex-col gap-6">
            <div class="grid md:grid-cols-2 gap-6">
                <BaseInputGroup :label="$t('labels.date-start')" :error="errors.date_start?.[0]">
                    <Datepicker v-model="dateStart"/>
                </BaseInputGroup>
                <BaseInputGroup :label="$t('labels.date-end')" :error="errors.date_end?.[0]">
                    <Datepicker v-model="dateEnd"/>
                </BaseInputGroup>
            </div>
            <div class="flex flex-col">
                <BaseButton @click.prevent="onContinue()">{{ $t('labels.continue') }}</BaseButton>
            </div>
        </div>
        <div v-if="isState('generating')" class="block text-center">
            <i class="fa-duotone fa-spinner fa-spin text-primary text-xl"></i>
        </div>
        <div v-if="isState('complete')" class="flex flex-col">
            <a class="btn btn-primary" :href="url" target="_blank">{{ $t('labels.continue') }}</a>
        </div>
    </BaseModal>
</template>

<script setup>
    import { reports } from '@/api'
    import { date, object, string } from 'yup'
    import ToastType from '@/constants/toast-type'
    import { useI18n } from '@/composables/i18n'
    import { useViewInterval } from '@/composables/interval'
    import { toast } from '@/composables/toast'
    import dayjs from '@/plugins/dayjs'
    
    // Define component properties.
    const props = defineProps({
        role: {
            type: String,
            required: true
        }
    })

    const role = toRef(props, 'role')

    const { t } = useI18n()

    const modal = ref(null)
    const period = ref('1-week')
    const dateStart = ref()
    const dateEnd = ref()
    const errors = ref({})
    const state = ref('parameters')
    const report = ref(null)
    const polling = ref(false)
    const url = ref()

    const schema = object({
        date_start: date(t('enum.validation.date')).required(),
        date_end: date(t('enum.validation.date')).required()
    })

    useViewInterval(async () => {
        if (!polling.value) {
            return
        }

        try {
            const response = await reports.show(report.value.id)

            if (response.data.data.status === 'ready') {                
                polling.value = false                
                url.value = response.data.data.url
                setState('complete')
            }

            if (response.data.data.status === 'failed') {                
                polling.value = false
                toast(ToastType.ERROR, t('message.report-failed'))
                setState('select')
            }
        } catch (error) {
            setState('select')
        }
    }, 1500)

    const isState = (value) => {
        return state.value === value
    }

    const setState = (value) => {
        state.value = value
    }

    const onClosed = () => {
        state.value = 'parameters'
        period.value = '1-week'
        dateStart.value = undefined
        dateEnd.value = undefined
        errors.value = {}
        url.value = undefined
    }

    const onContinue = async () => {        
        try {
            const payload = await schema.validate({
                role: role.value,
                date_start: dateStart.value ? dayjs(dateStart.value).toDate() : null,
                date_end: dateEnd.value ? dayjs(dateEnd.value).toDate() : null
            }, { 
                abortEarly: false
            })

            state.value = 'generating'
            errors.value = {}

            await onGenerate(payload)
        } catch (error) {
            let results = {}

            error.inner.forEach(error => {
                if (!Array.isArray(results[error.path])) {
                    results[error.path] = []
                }

                results[error.path].push(error.message)
            })

            errors.value = results
        }
    }

    const onGenerate = async (payload) => {
        try {
            const response = await reports.generate('timesheet-date', payload)

            report.value = response.data
            polling.value = true
        } catch (error) {
            setState('parameters')

            // Display the error message if provided.
            if (error?.response?.data?.message) {
                toast(ToastType.ERROR, error.response.data.message)
            }
        }
    }

    defineExpose({
        open: () => modal.value?.open?.(),
        close: () => modal.value?.close?.()
    })
</script>