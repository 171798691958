<template>

    <div class="d-block">

        <div class="d-flex align-items-center justify-content-between mb-6">

            <h4 v-text="title"></h4>

            <div class="ml-4">
                <slot name="actions">
                    <RepeaterAction @click.prevent="repeater.add()">
                        <span v-text="create"></span>
                    </RepeaterAction>
                </slot>
            </div>
        </div>

        <div :class="(row) ? 'container p-0 mw-none' : 'd-flex gap-4 flex-col'">
            <div :class="(row) ? 'row' : ''">
                <template v-for="row, index in rows" v-bind:key="index">
                    <slot name="item" :index="index" :item="rows[index]" :remove="() => repeater.remove(index)">
                        <RepeaterRow :index="index" v-model="rows[index]">
                            <slot :index="index" :item="rows[index]"/>
                        </RepeaterRow>
                    </slot>
                </template>
            </div>

            <div v-if="0 === count" class="alert alert-dismissible bg-danger d-flex flex-column flex-sm-row w-100 p-5 mb-10">
                <div class="d-flex align-items-center">
                    <i class="ki-duotone ki-message-text-2 fs-2hx text-light me-4 mb-5 mb-sm-0"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>                    <!--end::Icon-->
                    <div class="text-light">{{ empty }}</div>
                </div>
            </div>

        </div>

    </div>
</template>

<script setup>
    import { createRepeater } from '@/composables/repeater'

    const emit = defineEmits(['update:modelValue'])

    const props = defineProps({
        create: { type: String, default: '' },
        empty: { type: String, default: '' },
        modelValue: { required: true },
        title: { type: String, default: '' },
        template: { type: Function, default: () => ({}) },
        row: { type: Boolean, default: false }
    })

    const template = toRef(props, 'template')
    const rows = useVModel(props, 'modelValue', emit)
    const count = computed(() => rows.value ? rows.value.length : 0)
    const repeater = createRepeater(rows, template)

    onMounted(() => {
        if (!rows.value) {
            rows.value = []
        }
    })
</script>
