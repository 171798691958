<template>
  <!--begin::Wrapper-->
  <div class="w-lg-500px">
    <div class="alert alert-primary d-flex align-items-center p-5 mb-10">
      <i class="ki-duotone ki-messages fs-1 mr-4 text-primary"><span class="path1"></span><span class="path2"></span></i>
      <div class="d-flex flex-column">
        <h4 class="mb-1 text-primary">{{ $t('labels.language-available') }}</h4>
        <span>{{ $t('labels.language-available-info') }}</span>
        <span>
          <i class="fa-solid fas fa-arrow-right mr-2 text-primary"></i>
          <a v-show="isLocale('en')" href="#" class="link-primary" @click.prevent="setLocale('fr')">{{ $t('labels.language-available-link') }}</a>
          <a v-show="isLocale('fr')" href="#" class="link-primary" @click.prevent="setLocale('en')">{{ $t('labels.language-available-link') }}</a>
        </span>
      </div>
    </div>
    <!--begin::Form-->
    <BaseForm ref="form" @submitted="login" @success="resolved()">
      <!--begin::Heading-->
      <div class="text-center mb-10">
        <!--begin::Title-->
        <h1 class="text-dark mb-3">{{ $t('labels.login') }}</h1>
        <!--end::Title-->

        <!--begin::Link-->
        <div class="text-gray-400 fw-semobold fs-4">
          {{ $t('labels.login-intro') }}

          <router-link :to="{ name: Route.REGISTER }" class="link-primary fw-bold">
            {{ $t('labels.register') }}
          </router-link>
        </div>
        <!--end::Link-->
      </div>
      <!--begin::Heading-->

      <!--begin::Input group-->
      <div class="fv-row mb-10">
        <!--begin::Input-->
        <BaseInputGroup :label="$t('labels.email')" :error="form?.error('email')">
            <BaseInput v-model="email"/>
        </BaseInputGroup>
        <!--end::Input-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="fv-row mb-10">
        <!--begin::Input-->
        <BaseInputGroup :label="$t('labels.password')" :error="form?.error('password')">
            <template #labelAppend>
              <router-link :to="{ name: Route.PASSWORD_RESET }" class="link-primary link-input-group fs-6 fw-bold" v-text="$t('labels.password-reset')"></router-link>
            </template>
            <BasePassword v-model="password"/>
        </BaseInputGroup>
        <!--end::Input-->
      </div>
      <!--end::Input group-->

      <!--begin::Actions-->
      <div class="text-center">
        <!--begin::Submit button-->
        <button
          tabindex="3"
          type="submit"
          ref="submitButton"
          id="kt_sign_in_submit"
          class="btn btn-lg btn-primary w-100 mb-5"
        >
          <span class="indicator-label">{{ $t('labels.login') }}</span>

          <span class="indicator-progress">
            Please wait...
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </button>
        <!--end::Submit button-->
      </div>
      <!--end::Actions-->
    </BaseForm>
    <!--end::Form-->
  </div>
  <!--end::Wrapper-->
</template>

<script setup>
    import { useI18n, useLocale } from '@/composables/i18n'
    import { useAuth } from '@/composables/auth'
    import Route from '@/constants/route'
    import { useAuthStore } from '@/store/auth'
    import { useRouter } from 'vue-router'

    const { locale } = useI18n()
    const { isLocale, setLocale } = useLocale()

    const authStore = useAuthStore()
    const { isEmployee, isParent, isSupplier } = useAuth()
    const router = useRouter()

    const form = ref()
    const email = ref()
    const password = ref()
    const remember = ref(true)

    /**
     * Handle the login request.
     * 
     * @return {void}
     */
    async function login() {
        try {
            const response = await authStore.login(email, password, remember)

            form.value?.resolve(response)
        } catch (error) {
            form.value?.reject(error)
        }
    }

    /**
     * Handle a successful login request.
     * 
     * @return {void}
     */
    function resolved() {
        let name = Route.ACTIVITY_INDEX 

        if (isParent.value) {
            name = Route.SHOP_STEP_1
        }

        if (isEmployee.value || isSupplier.value) {
            name = Route.SCHEDULE
        }

        router.push({ name })
    }
</script>
