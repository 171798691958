<template>

    <BaseForm ref="form" @submitted="submit()">

        <LoadingOverlay :loading="loading">

            <div class="grid gap-6 mb-6">

                <BaseInputGroup 
                        :translatable="true" 
                        :translatable-labels="{ en: $t('labels.name-en'), fr: $t('labels.name-fr') }"
                        :translatable-errors="{ en: form?.error('name_en'), fr: form?.error('name_fr') }">
                        
                        <template #default="{ isLocale }">
                            <BaseInput v-show="isLocale('en')" v-model="payload.name_en"/>
                            <BaseInput v-show="isLocale('fr')" v-model="payload.name_fr"/>
                        </template>

                </BaseInputGroup>

            </div>

            <BaseButton :disabled="submitting" :loading="submitting">
                {{ $t(action) }}
            </BaseButton>

        </LoadingOverlay>

    </BaseForm>
    
</template>

<script setup>
    import { useI18n, useConditionTypes } from '@/composables/i18n'
    import { useModelForm } from '@/composables/model-ui'
    import { useCategoryStore } from '@/store/model'
    
    const { t } = useI18n()
    const store = useCategoryStore()
    const conditionTypes = useConditionTypes()

    const { form, id, payload, loading, submitting, create, edit, submit, mode } = useModelForm({ 
        store: store, 
        messages: {
            created: t('message.category-created'),
            updated: t('message.category-updated'),
            destroyed: t('message.category-destroyed')
        }
    })

    const action = computed(() => id.value  ? 'labels.submit-category-edit' : 'labels.submit-category-create')

    defineExpose({ mode, create, edit })
</script>
