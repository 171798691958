import { defineStore } from 'pinia'
import { unref } from 'vue'

import { moneris } from '@/api'
import Route from '@/constants/route'
import Store from '@/constants/store'
import router from '@/router'

export const useMonerisStore = defineStore(Store.MONERIS, {
    state: () => ({
        success: null
    }),

    getters: {
    	isSuccessful: (state) => state.success
    },

    actions: {
        async send (params) {
            const { data } = await moneris.send(unref(params))
            console.log(data)
            this.success = data.success
        }
    }
})
