
<template>

    <BaseContainer>

        <header class="mb-6">

            <h1 class="text-gray-800 font-bold text-xl leading-none mb-2" v-text="$t('labels.parents')"></h1>

            <Breadcrumbs :current="Route.PARENT_INDEX"/>

        </header>

        <DataTable
            v-model:page="page"
            id="parents"
            :columns="columns"
            :rows="rows"
            :transform="transform"
            :to="to"
            :from="from"
            :total="total"
            :pages="pages"
            :loading="loading"
            :filter="filter"
            @view="view"
            @edit="edit"
            @destroy="destroy">

            <template #children="{ row, value }">
                <div class="comma-separated">
                    <RouterLink v-for="child in value" :to="{ name: Route.CHILD_VIEW, params: { id: child.id } }">
                        <span class="text-primary">{{ child.name }}</span>
                    </RouterLink>
                </div>
            </template>

            <template #actionsAppend="{ row }">
                <button
                    class="flex items-center justify-center w-8 h-8 hover:bg-action-edit text-action-edit hover:text-white focus:ring-2 focus:ring-offset-2 focus:ring-action-edit focus:outline-none"
                    @click.prevent="impersonate(row.id)">
                    <i class="fa-solid fa-play"></i>
                </button>
            </template>

            <template #actions>
                <BaseButton @click.prevent="create()"><i class="fa-duotone fa-circle-plus mr-2"></i>{{ $t('labels.parent-create') }}</BaseButton>
            </template>

        </DataTable>

    </BaseContainer>

</template>

<style scoped>
.comma-separated a + a:before {
    color: inherit;
    content: ", ";
}
</style>

<script setup>
    import Route from '@/constants/route'
    import { useImpersonate } from '@/composables/impersonate'
    import { useI18n, useTrans } from '@/composables/i18n'
    import { useModelIndex } from '@/composables/model-ui'    
    import { useParentStore } from '@/store/model'
    import { useRouter } from 'vue-router'

    const { t } = useI18n()
    const trans = useTrans()
    const store = useParentStore()
    const router = useRouter()
    const { impersonate } = useImpersonate()

    const { rows, from, to, total, page, pages, loading, search, filter, destroy, list } = useModelIndex('user', store, {
        created: t('message.parent-created'),
        updated: t('message.parent-updated'),
        destroyed: t('message.parent-destroyed')
    })

    const columns = computed(() => [
        { id: 'id', text: t('labels.id') },
        { id: 'first_name', text: t('labels.name-first') },
        { id: 'last_name', text: t('labels.name-last') },
        { id: 'email', text: t('labels.email') },
        { id: 'phone1', text: t('labels.phone1') },
        { id: 'phone2', text: t('labels.phone2') },
        { id: 'phone3', text: t('labels.phone3') },
        { id: 'children', text: t('labels.children') },
        { id: 'balance', text: t('labels.balance') },
        { id: 'scheduled', text: t('labels.scheduled') },
        { id: 'created_at', text: t('labels.created') },
        { id: 'updated_at', text: t('labels.updated') },
    ])

    const form = ref()
    const create = () => router.push({ name: Route.PARENT_CREATE })
    const edit = (id) => router.push({ name: Route.PARENT_EDIT, params: { id } })
    const view = (id) => router.push({ name: Route.PARENT_VIEW, params: { id } })

    /**
     * Transform the specified row.
     *
     * @param {String} column
     * @param {Object} row
     * @return {String}
     */
    function transform (column, row) {
        switch (column) {
            case 'has_car': return row.has_car ? t('enum.boolean.true') : t('enum.boolean.false')
            case 'children': return row.children?.map(child => ({ id: child.id, name: `${child.first_name} ${child.last_name}` }))
            case 'balance': return row.balance ? `$${row.balance}` : '$0.00'
            case 'scheduled': return row.scheduled ? `$${Math.abs(row.scheduled).toFixed(2)}` : '$0.00'
        }

        return row[column]
    }
</script>
