<template>

    <div class="d-flex flex-col w-100">

        <slot/>
        
    </div>

</template>

<script setup>
    const emit = defineEmits(['change'])
    
    const active = ref(0)
    const panels = ref([])

    const tabGroup = {
        active: readonly(active),
        panels: readonly(panels),

        findPanelIndex(panel) {
            return panels.value.indexOf(panel)
        },

        isPanelActive(panel) {
            return active.value === this.findPanelIndex(panel)
        },

        setActive(index) {
            active.value = Math.max(Math.min(index, panels.value.length - 1), 0)

            emit('change', index)
        },

        registerPanel(panel) {
            if (-1 === this.findPanelIndex(panel)) {
                panels.value.push(panel)
            }
        },

        unregisterPanel(panel) {
            const index = this.findPanelIndex(panel)

            if (-1 !== index) {
                panels.value.splice(index, 1)
            }
        }
    }

    onMounted(() => {
        emit('change', active.value)
    })

    provide('tabGroup', tabGroup)

    defineExpose({
        setActive: (index) => tabGroup.setActive(index)
    })
</script>