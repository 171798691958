export function useViewInterval(callback, ms) {
    const interval = setInterval(callback, ms)

    const stop = () => {
        clearInterval(interval)        
    }

    onUnmounted(() => stop())

    return { stop }
}
