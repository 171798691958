export default {
    ACTIVITY_CREATE: "activity-create",
    ACTIVITY_EDIT: "activity-edit",
    ACTIVITY_INDEX: "activity-index",
    ACTIVITY_VIEW: "activity-view",
    ACTIVITY_SEARCH: "activity-search",
    CATEGORY_CREATE: "category-create",
    CATEGORY_EDIT: "category-edit",
    CATEGORY_INDEX: "category-index",
    CATEGORY_VIEW: "category-view",
    CHECKOUT: "checkout",
    CLIENT_CREATE: "client-create",
    CLIENT_EDIT: "client-edit",
    CLIENT_INDEX: "client-index",
    CLIENT_VIEW: "client-view",
    CHILD_CREATE: "child-create",
    CHILD_EDIT: "child-edit",
    CHILD_INDEX: "child-index",
    CHILD_VIEW: "child-view",
    CONDITION_CREATE: "condition-create",
    CONDITION_EDIT: "condition-edit",
    CONDITION_INDEX: "condition-index",
    CONDITION_VIEW: "condition-view",
    COURSE_CREATE: "course-create",
    COURSE_EDIT: "course-edit",
    COURSE_INDEX: "course-index",
    COURSE_VIEW: "course-view",
    EMPLOYEE_CREATE: "employee-create",
    EMPLOYEE_EDIT: "employee-edit",
    EMPLOYEE_INDEX: "employee-index",
    EMPLOYEE_VIEW: "employee-view",
    HOME: "home",
    GRADE_INDEX: "grade-index",
    LOGIN: "login",
    MESSAGE_CREATE: "message-create",
    MESSAGE_EDIT: "message-edit",
    MESSAGE_INDEX: "message-index",
    MESSAGE_VIEW: "message-view",
    MEDIA_MANAGER_INDEX: "media-manager-index",
    ORDER_INDEX: "order-index",
    ORDER_CREATE: "order-create",
    ORDER_EDIT: "order-edit",
    ORDER_VIEW: "order-view",
    PARENT_CREATE: "parent-create",
    PARENT_EDIT: "parent-edit",
    PARENT_INDEX: "parent-index",
    PARENT_VIEW: "parent-view",
    PASSWORD_RESET: "password-reset",
    PASSWORD_UPDATE: "password-update",
    PAYMENT: "payment",
    RECEIPT: "receipt",
    REGISTER: "register",
    REGISTRATION_CREATE: "registration-create",
    REGISTRATION_EDIT: "registration-edit",
    REGISTRATION_INDEX: "registration-index",
    REGISTRATION_VIEW: "registration-view",
    SCHEDULE: "schedule",
    SECTOR_CREATE: "sector-create",
    SECTOR_EDIT: "sector-edit",
    SECTOR_INDEX: "sector-index",
    SECTOR_VIEW: "sector-view",
    SETTINGS: "settings",
    SESSION_CREATE: "session-create",
    SESSION_EDIT: "session-edit",
    SESSION_INDEX: "session-index",
    SHOP_STEP_1: "shop-step-1",
    SHOP_STEP_2: "shop-step-2",
    SHOP_STEP_3: "shop-step-3",
    SHOP_STEP_3: "shop-step-4",
    SHOP_STEP_4: "shop-step-5",
    SUPPLIER_CREATE: "supplier-create",
    SUPPLIER_EDIT: "supplier-edit",
    SUPPLIER_INDEX: "supplier-index",
    SUPPLIER_VIEW: "supplier-view",
    TAX_RATE_CREATE: "taxrate-create",
    TAX_RATE_EDIT: "taxrate-edit",
    TAX_RATE_INDEX: "taxrate-index",
    TAX_RATE_VIEW: "taxrate-view",
    TRANSACTION_INDEX: "transaction-index",
    TRANSACTION_VIEW: "transaction-view",
    USER_CREATE: "user-create",
    USER_EDIT: "user-edit",
    USER_INDEX: "user-index",
    USER_VIEW: "user-view",
};
