<template>

    <BaseListbox
        v-model="model"
        :ajax="true"
        :filter="false"
        :searchable="true"
        :min-chars="3"
        :value="option => option.id"
        :label="option => option.name"
        :search="search"/>

</template>

<script setup>
    import { options } from '@/api'    
    
    const emit = defineEmits(['update:modelValue'])
    const props = defineProps({ modelValue: [Number, Object, String] })
    
    const model = useVModel(props, 'modelValue', emit)
    
    const search = async (args) => {
        let params = {}

        if (args.query) {
            params.search = args.query
        }

        if (args.values) {
            params.ids = args.values
        }

        const response = await options.parents(params)

        return response.data
    }

    defineExpose({ search })
</script>