<template>

    <BaseContainer>

        <header class="flex flex-col md:flex-row md:items-center md:justify-between gap-4 md:gap-6 mb-6">

            <div class="block">
                <h1 class="text-gray-800 font-bold text-xl leading-none mb-2" v-text="$t('labels.transactions')"></h1>
                <Breadcrumbs :current="Route.TRANSACTION_VIEW"/>
            </div>

            <div class="flex flex-col md:flex-row md:justify-end gap-4">
                <BaseButton variant="secondary" @click.prevent="index()">{{ $t('labels.back') }}</BaseButton>
            </div>

        </header>

        <div class="flex flex-col gap-6">
                
            <LoadingOverlay :loading="loading">
                    
                <BaseCard>

                    <template #header>
                        <span class="card-title fs-6 fw-bold">{{ $t('labels.general') }}</span>
                    </template>

                    <dl class="flex flex-col">                    
                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block text-xs font-semibold mb-2">{{ $t('labels.order') }}</dt>
                            <dd class="block text-sm">
                                <RouterLink v-if="model?.order" :to="{ name: Route.ORDER_VIEW, params: { id: model.order.id } }" class="text-primary">
                                    {{ model.order.id }}
                                </RouterLink>
                                <span v-else>-</span>
                            </dd>
                        </div>
                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block text-xs font-semibold mb-2">{{ $t('labels.type') }}</dt>
                            <dd class="block text-sm">
                                {{ model?.type ? $t(`enum.transaction-type.${model.type}`) : '-' }}
                            </dd>
                        </div>
                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block text-xs font-semibold mb-2">{{ $t('labels.category') }}</dt>
                            <dd class="block text-sm">
                                {{ model?.category ? model.category : '-' }}
                            </dd>
                        </div>
                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block text-xs font-semibold mb-2">{{ $t('labels.source') }}</dt>
                            <dd class="block text-sm">
                                {{ model?.source ? $t(`enum.transaction-source.${model.source}`) : '-' }}
                            </dd>
                        </div>
                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block text-xs font-semibold mb-2">{{ $t('labels.parent') }}</dt>
                            <dd class="block text-sm">
                                <RouterLink v-if="model?.user" :to="{ name: Route.PARENT_VIEW, params: { id: model.user.id } }" class="text-primary">
                                    {{ model.user.first_name }} {{ model.user.last_name }}
                                </RouterLink>
                                <span v-else>-</span>
                            </dd>
                        </div>
                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block text-xs font-semibold mb-2">{{ $t('labels.total') }}</dt>
                            <dd class="block text-sm">
                                {{ model?.amount ? currency(model.amount) : '-' }}
                            </dd>
                        </div>
                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block text-xs font-semibold mb-2">{{ $t('labels.description') }}</dt>
                            <dd class="block text-sm">
                                {{ model?.description ? model.description : '-' }}
                            </dd>
                        </div>
                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block text-xs font-semibold mb-2">{{ $t('labels.notes-system') }}</dt>
                            <dd class="block text-sm">
                                {{ model?.notes ? model.notes : '-' }}
                            </dd>
                        </div>
                    </dl>

                </BaseCard>

            </LoadingOverlay>            

            <div class="flex md:justify-end gap-2 mb-6">

                <BaseButton variant="secondary" @click.prevent="index()">{{ $t('labels.back') }}</BaseButton>

            </div>

        </div>        


    </BaseContainer>

</template>

<script setup>
    import Route from '@/constants/route'
    import { date, time } from '@/composables/dates'
    import { useTrans } from '@/composables/i18n'
    import { useModelView } from '@/composables/model-ui'
    import { useTransactionStore } from '@/store/model'

    const store = useTransactionStore()
    const trans = useTrans()

    const { loading, model, index, edit } = useModelView({ 
        store: store, 
        routes: {
            edit: Route.USER_EDIT,
            index: Route.USER_INDEX
        }
    })

    /**
     * Format a curency value.
     * 
     * @param {*} value 
     */
     function currency (value) {
        const result = '$' + parseFloat(value).toFixed(2)

        return result?.replace('$-', '-$')
    }
</script>
