import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createHead } from '@vueuse/head'
import Maska from 'maska'

import App from '@/App.vue'
import i18n from '@/i18n'
import router from '@/router'

import '@/assets/js/metronic.js'

const head = createHead()
const pinia = createPinia()
const app = createApp(App)

app.config.globalProperties.parasco_template = parasco_template;

app.use(Maska)
app.use(head)
app.use(pinia)
app.use(i18n)
app.use(router)

app.mount('#app')
