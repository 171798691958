import { createPopper } from '@popperjs/core'
import toastr from 'toastr'
import KTComponents from '@/assets/js/components/_init'
import KTUtil from '@/assets/js/components/util'
import KTApp from '@/assets/js/components/app'
import KTEventHandler from '@/assets/js/components/event-handler'
import KTBlockUI from '@/assets/js/components/blockui'
import KTCookie from '@/assets/js/components/cookie'
import KTDialer from '@/assets/js/components/dialer'
import KTDrawer from '@/assets/js/components/drawer'
import KTFeedback from '@/assets/js/components/feedback'
import KTImageInput from '@/assets/js/components/image-input'
import KTMenu from '@/assets/js/components/menu'
import KTPasswordMeter from '@/assets/js/components/password-meter'
import KTScroll from '@/assets/js/components/scroll'
import KTScrolltop from '@/assets/js/components/scrolltop'
import KTSearch from '@/assets/js/components/search'
import KTStepper from '@/assets/js/components/stepper'
import KTSticky from '@/assets/js/components/sticky'
import KTSwapper from '@/assets/js/components/swapper'
import KTToggle from '@/assets/js/components/toggle'

import KTLayoutSearch from '@/assets/js/layout/search.js'
import KTThemeMode from '@/assets/js/layout/theme-mode.js'
import KTThemeModeUser from '@/assets/js/layout/theme-mode-user.js'

import Collapse from 'bootstrap/js/src/collapse'
import Modal from 'bootstrap/js/src/modal'
import Tab from 'bootstrap/js/src/tab'
import Toast from 'bootstrap/js/src/toast'
import Tooltip from 'bootstrap/js/src/tooltip'

// Expose bootstrap
window.bootstrap = { Collapse, Modal, Tab, Toast, Tooltip }

// Expose toastr globally.
window.toastr = toastr

// Expose Popper for Keenthemes components.
window.Popper = { createPopper }

// Expose Keenthemes components.
window.KTComponents = KTComponents
window.KTUtil = KTUtil
window.KTApp = KTApp
window.KTEventHandler = KTEventHandler
window.KTBlockUI == KTBlockUI
window.KTCookie = KTCookie
window.KTDialer = KTDialer
window.KTDrawer = KTDrawer
window.KTFeedback = KTFeedback
window.KTImageInput = KTImageInput
window.KTMenu = KTMenu
window.KTPasswordMeter = KTPasswordMeter
window.KTScroll = KTScroll
window.KTScrolltop = KTScrolltop
window.KTSearch = KTSearch
window.KTStepper = KTStepper
window.KTSticky = KTSticky
window.KTSwapper = KTSwapper
window.KTToggle = KTToggle

window.KTLayoutSearch = KTLayoutSearch
window.KTThemeMode = KTThemeMode
window.KTThemeModeUser = KTThemeModeUser
