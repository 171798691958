<template>

    <div ref="reference" class="popconfirm sh">

        <div class="inline-flex" @click.prevent="open()">
            <slot />
        </div>

        <div ref="popper" class="popconfirm-dialog dialog shadow">

            <div v-show="active" class="inline-flex items-center p-3  gap-2">
                <span v-if="message" class="text-sm">{{ message }}</span>

                <span v-else class="text-sm" v-text="$t('component.popconfirm.message')"></span>

                <button class="text-xl" @click.prevent="complete('cancel')">
                    <i class="fa-duotone fa-circle-xmark text-danger"></i>
                </button>

                <button class="text-xl" @click.prevent="complete('confirm')">
                    <i class="fa-duotone fa-circle-check text-success"></i>
                </button>

            </div>

        </div>

    </div>

</template>

<style scoped>
    .popconfirm {
        position: relative;
    }

    .popconfirm-dialog {
        background: #fff;
        z-index: 9999;

    }
</style>

<script setup>
    import { Tooltip, Toast, Popover } from 'bootstrap'
    import { usePopper, usePopperFlip } from '@/composables/popper'

    const emit = defineEmits(['cancel', 'confirm'])

    const active = ref(false)
    const props = defineProps({
        message: String
    })

    const update = () => nextTick(() => instance.value?.forceUpdate())    
    const open = () => active.value = true
    const close = () => active.value = false

    const complete = (event) => {
        emit(event)
        close()
    }

    const { instance, reference, popper } = usePopper({
        strategy: 'fixed',
        placement: 'left',
        modifiers: [
            usePopperFlip(),
            {
                name: "offset",
                options: { offset: [0, 8] }
            }
        ]
    })

    onClickOutside(reference, () => close())
    watch(active, () => update())
</script>
