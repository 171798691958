import ToastType from '@/constants/toast-type'
import { toast } from '@/composables/toast'

export function useForm (context) {
    context = Object.assign({
        toastEnable: true,
        toastDuration: 3500
    }, context)

    const errors = ref({})
    const loading = ref(false)

    const reset = () => {
        errors.value = {}
        loading.value = false
    }

    const resolve = (response) => {
        context.emit('success', response)
        reset()
    }

    const reject = (error) => {
        context.emit('failed', error)

        loading.value = false
        errors.value = {}

        if (context.toastEnable && error?.response?.data?.message) {
            if (error?.response?.status === 409) {
                toast(ToastType.INFO, error.response.data.message, context.toastDuration)
            } else {
                toast(ToastType.ERROR, error.response.data.message, context.toastDuration)
            }

        }

        if (error?.response?.status === 422) {
            errors.value = error.response.data.errors
        }
    }

    const submit = () => {
        context.emit('submitted')
        loading.value = true
    }

    const error = (key) => {
        if (typeof key === 'object') {
            return { en: error(key.en), fr: error(key.fr) }
        }

        return key in errors.value ? errors.value[key][0] : ''
    }

    const setErrors = (value) => {
        errors.value = value
    }

    return { error, errors, loading, reject, reset, resolve, setErrors, submit }
}
