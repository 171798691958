import Route from '@/constants/route'
import LayoutAuth from '@/layouts/LayoutAuth.vue'
import Login from '@/views/Login.vue'
import Register from '@/views/Register.vue'
import PasswordReset from '@/views/PasswordReset.vue'
import PasswordUpdate from '@/views/PasswordUpdate.vue'

export default {
    path: '/auth',
    meta: { middleware: 'guest' },
    component: LayoutAuth,    
    children: [
        {
            path: '/login',
            name: Route.LOGIN,
            component: Login
        },
        {
            path: '/register',
            name: Route.REGISTER,
            component: Register
        },
        {
            path: '/password/reset',
            name: Route.PASSWORD_RESET,
            component: PasswordReset
        },
        {
            path: '/password/update',
            name: Route.PASSWORD_UPDATE,
            component: PasswordUpdate
        },
    ]
}
