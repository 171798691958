import { inject, provide, reactive } from 'vue'

const KEY_REPEATER = 'repeater'
const KEY_REPEATER_ROW = 'repeaterRow'

export function createRepeater (rows, template) {
    const context = {
        add () {
            const row = reactive(template?.value?.())

            if (!Array.isArray(rows.value)) {
                rows.value = [row]
                return
            }

            rows.value.push(row)
        },

        insert (index) {
            const row = reactive({})

            rows.value.splice(index, 0, row)
        },

        remove (index) {
            rows.value.splice(index, 1)
        }
    }

    provide(KEY_REPEATER, context)

    return context
}

export function useRepeater () {
    const context = inject(KEY_REPEATER, null)

    if (!context) {
        throw new Error('Component is missing a parent <Repeater/> component.')
    }

    return context
}

export function createRepeaterRow (index, value) {
    const repeater = useRepeater()

    const context = {
        index,
        value,

        add () {
            repeater.add(index.value)
        },

        insert () {
            repeater.insert(index.value)
        },

        remove () {
            repeater.remove(index.value)
        }
    }

    provide(KEY_REPEATER_ROW, context)

    return context
}

export function useRepeaterRow () {
    const context = inject(KEY_REPEATER_ROW, null)

    if (!context) {
        throw new Error('Component is missing a parent <RepeaterRow/> component.')
    }

    return context
}
