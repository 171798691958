<template>

    <BaseListbox
        v-model="model"
        :ajax="true"
        :filter="true"
        :searchable="true"
        :value="option => option.id"
        :label="option => option.name + ' (' + option.rate + '%)'"
        :search="search"/>

</template>

<script setup>
    import { options } from '@/api'

    const emit = defineEmits(['update:modelValue'])

    const props = defineProps({
        modelValue: { required: true }
    })

    const model = useVModel(props, 'modelValue', emit)
    
    const search = async (args) => {
        let params = {}

        if (args.query) {
            params.search = args.query
        }

        if (args.values) {
            params.ids = args.values
        }

        const response = await options.taxRates(params)

        return response.data
    }

    defineExpose({ search })  
</script>
