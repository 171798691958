import { toast } from '@/composables/toast'
import ToastType from '@/constants/toast-type'

export function defineModelStore (id, api, context = {}) {
    return defineStore(id, {
        state: () => ({
            loading: false,
            rows: [],
            perPage: 10,
            page: 0,
            pages: 0,
            from: 0,
            to: 0,
            total: 0,
            filters: {}
        }),

        actions: {
            async filter (filters) {
                this.filters = filters
                this.page = 1
                this.perPage = filters.perPage || 10

                await this.refresh()
            },

            async index (args = {}) {
                this.loading = true

                const defaults = Object.assign({}, this.filters)

                if (args?.perPage !== -1) {
                    defaults.page = this.page
                    defaults.perPage = this.perPage
                }

                const params = Object.assign(defaults, args)

                if (typeof context.index === 'function') {
                    context.index(params)
                }

                try {
                    const { data } = await api.index(params)

                    this.rows = data.data
                    this.perPage = data.per_page
                    this.page = data.current_page
                    this.pages = data.last_page
                    this.from = data.from || 0
                    this.to = data.to || 0
                    this.total = data.total || 0
                } catch (e) {
                    if (e.response?.data?.message) {
                        toast(ToastType.ERROR, e.response.data.message)
                    }

                    throw e
                } finally {
                    this.loading = false
                }

                return this.rows
            },

            async all (ids = []) {
                return this.index({ perPage: -1, ids: ids })
            },

            async search (search) {
                return this.index({ search, perPage: -1 })
            },

            async find (ids) {
                const map = (id) => this.rows.find(row => row && row.id === id) || {}

                if (this.rows.length === 0) {
                    await this.all(ids)
                }

                if (Array.isArray(ids)) {
                    return ids.map(id => {
                        const row = map(id);
                        return row && row.id ? row : {};
                    })
                }

                return map(ids)
            },

            async refresh () {
                await this.index()
            },

            async show (id) {
                if (!id) {
                    return {}
                }

                const { data } = await api.show(unref(id))

                return data.data
            },

            async store (payload, raw = false) {
                const p = unref(payload)

                if (typeof context.store === 'function') {
                    context.store(p)
                }

                const { data } = await api.store(p)

                this.rows.unshift(data.data)

                if (raw) {
                    return data
                }

                return data.data
            },

            async update (id, payload, raw = false) {
                const p = unref(payload)

                if (typeof context.update === 'function') {
                    context.update(p)
                }

                const { data } = await api.update(unref(id), p)
                const index = this.rows.findIndex(row => row.id === unref(id))

                if (index !== -1) {
                    this.rows[index] = data.data
                }

                if (raw) {
                    return data
                }

                return data.data
            },

            async destroy (id) {
                await api.destroy(id)

                const index = this.rows.findIndex(i => unref(id) === i.id)

                if (index !== -1) {
                    this.rows.splice(index, 1)
                }
            }
        }
    })
}
