import { createPopper } from '@popperjs/core'

import { onBeforeUnmount, ref, unref, watch } from 'vue'

export function usePopperFlip () {
    return { name: 'flip' }
}

export function usePopperArrow () {
    return {
        name: 'arrow'
    }
}

export function useSetPopperWidth () {
    return {
        name: 'setPopperWidth',
        enabled: true,
        phase: 'beforeWrite',
        requires: ['computeStyles'],
        fn: ({ state, instance }) => {
            state.styles.popper.width = `${state.rects.reference.width}px`
            state.styles.popper.left = 0
        }
    }
}

export function usePopper (options) {
    const instance = ref()
    const reference = ref()
    const popper = ref()

    onBeforeUnmount(() => instance.value?.destroy())

    watch([reference, popper], (value) => {
        instance.value?.destroy()
        instance.value = createPopper(unref(reference), unref(popper), unref(options))
    })

    return { instance, reference, popper }
}
