<template>

    <BaseContainer>

        <header class="mb-6">
            
            <h1 class="text-gray-800 font-bold text-xl leading-none mb-2" v-text="$t('labels.clients')"></h1>
        
            <Breadcrumbs :current="Route.CLIENT_EDIT"/>
        
        </header>

        <BaseCard class="p-6">
            <ClientForm ref="form" @success="$router.push({ name: Route.CLIENT_INDEX })"/>
        </BaseCard>

    </BaseContainer>

</template>

<script setup>
    import Route from '@/constants/route'

    const form = ref()
    const route = useRoute()

    onMounted(() => form.value.edit(route.params.id))
</script>
