
<template>

    <BaseContainer>

        <header class="mb-6">

            <h1 class="text-gray-800 font-bold text-xl leading-none mb-2" v-text="$t('labels.courses')"></h1>

            <Breadcrumbs :current="Route.COURSE_INDEX"/>

        </header>

        <DataTable
            v-model:page="page"
            id="courses"
            :filterable="false"
            :columns="columns"
            :rows="rows"
            :transform="transform"
            :to="to"
            :from="from"
            :total="total"
            :pages="pages"
            :loading="loading"
            :filter="filter"
            @view="view"
            @edit="edit"
            @destroy="destroy">

            <template #actions>
                <BaseButton @click.prevent="create()"><i class="fa-duotone fa-circle-plus mr-2"></i>{{ $t('labels.course-create') }}</BaseButton>
            </template>

        </DataTable>

    </BaseContainer>

</template>

<script setup>
    import Route from '@/constants/route'
    import { useI18n, useTrans } from '@/composables/i18n'
    import { useModelIndex } from '@/composables/model-ui'
    import { useCourseStore } from '@/store/model'

    const { t } = useI18n()
    const trans = useTrans()
    const store = useCourseStore()
    const router = useRouter()

    const { rows, from, to, total, page, pages, loading, search, filter, destroy } = useModelIndex('course', store, {
        created: t('message.course-created'),
        updated: t('message.course-updated'),
        destroyed: t('message.course-destroyed')
    })

    const columns = computed(() => [
        { id: 'id', text: t('labels.id') },
        { id: 'name', text: t('labels.name') },
        { id: 'name_en', text: t('labels.name-en') },
        { id: 'name_fr', text: t('labels.name-fr') },
        { id: 'intro_en', text: t('labels.introduction-en') },
        { id: 'intro_fr', text: t('labels.introduction-fr') },
        { id: 'description_en', text: t('labels.description-en') },
        { id: 'description_fr', text: t('labels.description-fr') },
        { id: 'notes', text: t('labels.notes') },
        { id: 'created_at', text: t('labels.created') },
        { id: 'updated_at', text: t('labels.updated') },
    ])

    const form = ref()
    const create = () => router.push({ name: Route.COURSE_CREATE })
    const edit = (id) => router.push({ name: Route.COURSE_EDIT, params: { id } })
    const view = (id) => router.push({ name: Route.COURSE_VIEW, params: { id } })

    /**
     * Transform the specified row.
     *
     * @param {String} column
     * @param {Object} row
     * @return {String}
     */
    function transform (column, row) {
        switch (column) {
            case 'name': return trans({ en: row.name_en, fr: row.name_fr })
            case 'type': return t(`condition.type.${row.type}`)
            case 'intro_en': return row.intro_excerpt_en
            case 'intro_fr': return row.intro_excerpt_fr
            case 'description_en': return row.description_excerpt_en
            case 'description_fr': return row.description_excerpt_fr
            case 'notes': return row.excerpt_notes
        }

        return row[column]
    }
</script>
