<template>

	<div ref="reference">

		<div class="inline-flex" @click.prevent.stop="open()">
      		<slot name="activator" :active="active"/>
    	</div>

    	<div ref="popper" class="z-10">

      		<Transition
        		enter-active-class="transition duration-100 ease-out"
        		enter-from-class="transform scale-95 opacity-0"
        		enter-to-class="transform scale-100 opacity-100"
        		leave-active-class="transition duration-75 ease-in"
        		leave-from-class="transform scale-100 opacity-100"
        		leave-to-class="transform scale-95 opacity-0">

        		<div v-show="active">
					<slot/>
				</div>

      		</Transition>

    	</div>

  	</div>

</template>

<script setup>
	import { usePopper, usePopperFlip } from '@/composables/popper'

	const { instance, reference, popper } = usePopper({
		placement: 'bottom-end',
		modifiers: [ usePopperFlip() ],
	})

	const active = ref(false)

	const open = () => active.value = true
	const close = () => active.value = false

	onClickOutside(reference, () => close())

	defineExpose({ open, close })
	
	watch(active, () => nextTick(() => instance.value?.forceUpdate()))
</script>
