<template>

    <BaseListbox
        v-model="model"
        :ajax="true"
        :filter="false"
        :min-chars="3"
        :searchable="true"
        :value="option => option.id"
        :label="option => option.name"
        :search="search">

            <template #create="{ action }">
                
                <li>
                    <button 
                        class="block px-3 py-2 text-sm font-medium"
                        @click.prevent="action('employee')">
                        {{ $t('labels.employee-create') }}
                    </button>
                </li>

                <li>
                    <button 
                        class="block px-3 py-2 text-sm font-medium"
                        @click.prevent="action('supplier')">
                        {{ $t('labels.supplier-create') }}
                    </button>
                </li>                    

            </template>

        </BaseListbox>

</template>

<script setup>
    import { options } from '@/api'
    
    const emit = defineEmits(['update:modelValue'])
    const props = defineProps({ modelValue: [Number, Object, String] })

    const model = useVModel(props, 'modelValue', emit)

    const search = async (args) => {
        let params = {}

        if (args.query) {
            params.search = args.query
        }

        if (args.values) {
            params.ids = args.values
        }

        const response = await options.employees(params)

        return response.data
    }

    defineExpose({ search })  
</script>
