<template>

    <div class="card" :class="classes">

        <div v-if="$slots.header" class="card-header">
            
            <slot name="header"/>
            
            <div v-if="$slots.actions" class="flex flex-col md:flex-row md:items-center md:ml-auto gap-2 md:gap-4 w-full md:w-auto">
                <slot name="actions"/>
            </div>
        
        </div>

        <slot name="body">
            <div class="card-body">
                <slot/>
            </div>
        </slot>
        
        <div v-if="$slots.footer" class="card-footer">
            <slot name="footer"/>
        </div>

    </div>

</template>

<script setup>
    const props = defineProps({
        shadow: { type: Boolean, default: true }
    })

    const shadow = toRef(props, 'shadow')

    const classes = computed(() => ({
        'shadow-sm': shadow.value
    }))
</script>