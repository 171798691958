<template>

    <Teleport to="body">
  
        <div class="fixed inset-0 bg-dark/50 ease-expo transition-opacity duration-300 z-50" :class="active ? 'opacity-100' : 'opacity-0 pointer-events-none'">
    
            <div ref="el" class="flex flex-col fixed inset-y-0 bg-white w-full md:w-96 shadow-lg" :class="{
                'transition-all ease-expo duration-150': true,
                'left-0': 'left' === position,
                'right-0': 'right' === position,
                '-translate-x-full': 'left' === position && !active,
                'translate-x-full': 'right' === position && !active,
                'translate-x-0': active
            }">

                <slot name="header">
                    <div class="flex items-center justify-between px-6 py-3 border-b border-grey-200">
                        <span class="font-semibold" v-text="title"></span>
                        <div class="ml-4">
                            <button class="inline-flex items-center justify-center rounded-md text-gray-500 hover:bg-primary-light hover:text-primary w-8 h-8" @click.prevent="close()">
                                <i class="fa-duotone fa-xmark"></i>
                            </button>
                        </div>
                    </div>
                </slot>

                <div class="overflow-y-auto">

                    <div class="p-6">
                        <slot/>
                    </div>

                </div>

            </div>

        </div>

    </Teleport>
  
</template>
  
<script setup>
    import { createModal } from '@/composables/modal'
  
    const emit = defineEmits(['closed', 'opened'])
  
    const props = defineProps({
        position: { type: String, default: 'right' },
        title: { type: String, default: '' }
    })
  
    const { el, active, open, close } = createModal(emit)
  
    defineExpose({ open, close })
  </script>
  