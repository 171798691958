<template>

    <BaseContainer>

        <header class="mb-6">

            <h1 class="text-gray-800 font-bold text-xl leading-none mb-2" v-text="$t('labels.messages')"></h1>

            <Breadcrumbs :current="Route.MESSAGE_INDEX"/>

        </header>

        <DataTable
            v-model:page="page"
            id="messages-20240101"
            :filterable="false"
            :columns="columns"
            :rows="rows"
            :to="to"
            :from="from"
            :total="total"
            :pages="pages"
            :loading="loading"
            :filter="filter"
            :viewable="false"
            :transform="transform"
            @edit="edit"
            @destroy="destroy">

            <template #actions>
                <BaseButton @click.prevent="create()"><i class="fa-duotone fa-circle-plus mr-2"></i>{{ $t('labels.message-create') }}</BaseButton>
            </template>

        </DataTable>

        <BaseModal size="xl" :title="$t(form?.mode === 'edit' ? 'labels.message-edit' : 'labels.message-create')">
            <MessageForm ref="form"/>
        </BaseModal>

    </BaseContainer>

</template>

<script setup>
    import Route from '@/constants/route'
    import { useI18n, useTrans } from '@/composables/i18n'
    import { useModelIndex } from '@/composables/model-ui'
    import { useMessageStore } from '@/store/model'
    import { datetime } from '@/composables/dates'

    const { t } = useI18n()
    const trans = useTrans()
    const store = useMessageStore()
    const router = useRouter()

    const messageStatuses = [
        { value: 'failed', text: t('enum.message-status.failed') },
        { value: 'pending', text: t('enum.message-status.pending') },
        { value: 'queued', text: t('enum.message-status.queued') },
        { value: 'sent', text: t('enum.message-status.sent') },        
    ]

    const { rows, from, to, total, page, pages, loading, search, filter, destroy } = useModelIndex('grade', store, {
        created: t('message.message-created'),
        updated: t('message.message-updated'),
        destroyed: t('message.message-destroyed')
    })

    const columns = computed(() => [
        { id: 'id', text: t('labels.id') },
        { id: 'status', text: t('labels.status'), options: messageStatuses },
        { id: 'name_en', text: t('labels.subject-en') },
        { id: 'name_fr', text: t('labels.subject-fr') },
        { id: 'send_at', text: t('labels.send-at') },
        { id: 'sent_at', text: t('labels.sent-at') },        
        { id: 'created_at', text: t('labels.created') },
        { id: 'updated_at', text: t('labels.updated') },
    ])

    const form = ref()
    const create = () => router.push({ name: Route.MESSAGE_CREATE })
    const edit = (id) => router.push({ name: Route.MESSAGE_EDIT, params: { id } })

    /**
     * Transform the specified row.
     *
     * @param {String} column
     * @param {Object} row
     * @return {String}
     */
     function transform (column, row) {     
        switch (column) {
            case 'status': return t(`enum.message-status.${row?.status}`)
            case 'send_at': return row.send_at ? datetime(row.send_at) : ''
            case 'sent_at': return row.sent_at ? datetime(row.sent_at) : ''
        }
        
        return row?.[column] || ''
     }
</script>
