<template>

    <BaseForm ref="form" @submitted="submit()">

        <LoadingOverlay :loading="loading">

            <div class="grid gap-6 mb-6">

                <BaseInputGroup 
                    :translatable="true" 
                    :translatable-labels="{ en: $t('labels.name-en'), fr: $t('labels.name-fr') }"
                    :translatable-errors="{ en: form?.error('name_en'), fr: form?.error('name_fr') }">
                    
                    <template #default="{ isLocale }">
                        <BaseInput v-show="isLocale('en')" v-model="payload.name_en"/>
                        <BaseInput v-show="isLocale('fr')" v-model="payload.name_fr"/>
                    </template>

                </BaseInputGroup>

                <BaseInputGroup 
                    :translatable="true" 
                    :translatable-labels="{ en: $t('labels.abbreviation-en'), fr: $t('labels.abbreviation-fr') }"
                    :translatable-errors="{ en: form?.error('abbreviation_en'), fr: form?.error('abbreviation_fr') }">
                    
                    <template #default="{ isLocale }">
                        <BaseInput v-show="isLocale('en')" v-model="payload.abbreviation_en"/>
                        <BaseInput v-show="isLocale('fr')" v-model="payload.abbreviation_fr"/>
                    </template>

                </BaseInputGroup>

            </div>

            <BaseButton :disabled="submitting" :loading="submitting">
                {{ $t(action) }}
            </BaseButton>

        </LoadingOverlay>

    </BaseForm>

</template>

<script setup>
    import { useI18n } from '@/composables/i18n'
    import { useModelForm } from '@/composables/model-ui'
    import { useGradeStore } from '@/store/model'

    const { t } = useI18n()
    const store = useGradeStore()

    const { form, id, payload, loading, submitting, create, edit, submit, mode } = useModelForm({ 
        store: store,
        messages: {
            created: t('message.grade-created'),
            updated: t('message.grade-updated'),
            destroyed: t('message.grade-destroyed')
        }
    })

    const action = computed(() => id.value  ? 'labels.submit-grade-edit' : 'labels.submit-grade-create')

    defineExpose({ mode, create, edit })
</script>
