<template>

    <div class="d-flex flex-column flex-root">

        <div class="page d-flex flex-row flex-column-fluid">

            <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">

                <div id="kt_header" class="header align-items-stretch mb-5 mb-lg-10 bg-primary" data-kt-sticky="true"
                    data-kt-sticky-name="header" data-kt-sticky-offset="{default: '200px', lg: '300px'}">

                    <div class="container-xxl d-flex align-items-center" :class="{'container-admin' : isAdmin || isEmployee || isSupplier}">

                        <div class="d-flex topbar align-items-center d-lg-none ms-n2 me-3" title="Show aside menu">
                            <div class="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px"
                                id="kt_header_menu_mobile_toggle">
                                <i class="ki-duotone ki-abstract-14 fs-1"><span class="path1"></span><span
                                        class="path2"></span></i>
                            </div>
                        </div>

                        <div class="header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0">
                            <RouterLink :to="{ name: Route.HOME }">
                                <img alt="Logo" :src="'/img/' + parasco_template + '/logo.png'"
                                    class="logo-default h-50px">
                                <img alt="Logo" :src="'/img/' + parasco_template + '/logo.png'"
                                    class="logo-sticky h-25px">
                            </RouterLink>
                        </div>

                        <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1">

                            <div class="d-flex align-items-stretch" id="kt_header_nav">

                                <div class="header-menu align-items-stretch" data-kt-drawer="true"
                                    data-kt-drawer-name="header-menu"
                                    data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true"
                                    data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                                    data-kt-drawer-direction="start"
                                    data-kt-drawer-toggle="#kt_header_menu_mobile_toggle" data-kt-swapper="true"
                                    data-kt-swapper-mode="prepend"
                                    data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}">

                                    <div class="menu menu-rounded menu-column menu-lg-row menu-active-bg menu-title-gray-700 menu-state-primary menu-arrow-gray-400 fw-semibold my-5 my-lg-0 align-items-stretch px-2 px-lg-0"
                                        id="#kt_header_menu" data-kt-menu="true">

                                        <RouterLink v-if="isAdmin" :to="{ name: Route.ACTIVITY_INDEX }"
                                            class="menu-item menu-lg-down-accordion me-0 me-lg-2"
                                            active-class="menu-here-bg here"
                                            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                            data-kt-menu-placement="bottom-start">
                                            <div class="menu-link py-3">
                                                <span class="menu-title">{{ $t('labels.activities') }}</span>
                                            </div>
                                        </RouterLink>

                                        <RouterLink v-if="isParent" :to="{ name: Route.SHOP_STEP_1 }"
                                            class="menu-item menu-lg-down-accordion me-0 me-lg-2"
                                            active-class="menu-here-bg here"
                                            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                            data-kt-menu-placement="bottom-start">
                                            <div class="menu-link py-3">
                                                <span class="menu-title">{{ $t('labels.activities') }}</span>
                                            </div>
                                        </RouterLink>

                                        <RouterLink v-if="isAdmin || isParent" :to="{ name: Route.CHILD_INDEX }"
                                            class="menu-item menu-lg-down-accordion me-0 me-lg-2"
                                            active-class="menu-here-bg here"
                                            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                            data-kt-menu-placement="bottom-start">

                                            <div class="menu-link py-3">
                                                <span class="menu-title">{{ $t('labels.children') }}</span>
                                            </div>

                                        </RouterLink>

                                        <RouterLink v-if="isAdmin" :to="{ name: Route.CLIENT_INDEX }"
                                            class="menu-item menu-lg-down-accordion me-0 me-lg-2"
                                            active-class="menu-here-bg here"
                                            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                            data-kt-menu-placement="bottom-start">

                                            <div class="menu-link py-3">
                                                <span class="menu-title">{{ $t('labels.clients') }}</span>
                                            </div>

                                        </RouterLink>

                                        <RouterLink v-if="isAdmin" :to="{ name: Route.COURSE_INDEX }"
                                            class="menu-item menu-lg-down-accordion me-0 me-lg-2"
                                            active-class="menu-here-bg here"
                                            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                            data-kt-menu-placement="bottom-start">

                                            <div class="menu-link py-3">
                                                <span class="menu-title">{{ $t('labels.courses') }}</span>
                                            </div>

                                        </RouterLink>

                                        <RouterLink v-if="isAdmin" :to="{ name: Route.ORDER_INDEX }"
                                            class="menu-item menu-lg-down-accordion me-0 me-lg-2"
                                            active-class="menu-here-bg here"
                                            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                            data-kt-menu-placement="bottom-start">

                                            <div class="menu-link py-3">
                                                <span class="menu-title">{{ $t('labels.orders') }}</span>
                                            </div>

                                        </RouterLink>

                                        <RouterLink v-if="isAdmin" :to="{ name: Route.REGISTRATION_INDEX }"
                                            class="menu-item menu-lg-down-accordion me-0 me-lg-2"
                                            active-class="menu-here-bg here"
                                            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                            data-kt-menu-placement="bottom-start">

                                            <div class="menu-link py-3">
                                                <span class="menu-title">{{ $t('labels.registrations') }}</span>
                                            </div>

                                        </RouterLink>

                                        <RouterLink v-if="isAdmin" :to="{ name: Route.TRANSACTION_INDEX }"
                                            class="menu-item menu-lg-down-accordion me-0 me-lg-2"
                                            active-class="menu-here-bg here"
                                            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                            data-kt-menu-placement="bottom-start">

                                            <div class="menu-link py-3">
                                                <span class="menu-title">{{ $t('labels.transactions') }}</span>
                                            </div>

                                        </RouterLink>

                                        <RouterLink v-if="isAdmin" :to="{ name: Route.MESSAGE_INDEX }"
                                            class="menu-item menu-lg-down-accordion me-0 me-lg-2"
                                            active-class="menu-here-bg here"
                                            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                            data-kt-menu-placement="bottom-start">

                                            <div class="menu-link py-3">
                                                <span class="menu-title">{{ $t('labels.messages') }}</span>
                                            </div>

                                        </RouterLink>

                                        <div v-if="isAdmin" class="menu-item menu-lg-down-accordion me-0 me-lg-2"
                                            active-class="menu-here-bg here"
                                            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                            data-kt-menu-placement="bottom-start">

                                            <div class="menu-link py-3">
                                                <span class="menu-title">{{ $t('labels.users') }}</span>
                                            </div>

                                            <div
                                                class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown px-lg-2 py-lg-4 w-lg-200px">

                                                <div class="menu-item">
                                                    <RouterLink :to="{ name: Route.USER_INDEX }" class="menu-link py-3"
                                                        data-bs-toggle="tooltip" data-bs-trigger="hover"
                                                        data-bs-dismiss="click" data-bs-placement="right">
                                                        <span class="menu-title">{{ $t('labels.administrators')
                                                            }}</span>
                                                    </RouterLink>
                                                </div>

                                                <div class="menu-item">
                                                    <RouterLink :to="{ name: Route.EMPLOYEE_INDEX }"
                                                        class="menu-link py-3" data-bs-toggle="tooltip"
                                                        data-bs-trigger="hover" data-bs-dismiss="click"
                                                        data-bs-placement="right">
                                                        <span class="menu-title">{{ $t('labels.employees') }}</span>
                                                    </RouterLink>
                                                </div>

                                                <div class="menu-item">
                                                    <RouterLink :to="{ name: Route.PARENT_INDEX }"
                                                        class="menu-link py-3" data-bs-toggle="tooltip"
                                                        data-bs-trigger="hover" data-bs-dismiss="click"
                                                        data-bs-placement="right">
                                                        <span class="menu-title">{{ $t('labels.parents') }}</span>
                                                    </RouterLink>
                                                </div>

                                                <div class="menu-item">
                                                    <RouterLink :to="{ name: Route.SUPPLIER_INDEX }"
                                                        class="menu-link py-3" data-bs-toggle="tooltip"
                                                        data-bs-trigger="hover" data-bs-dismiss="click"
                                                        data-bs-placement="right">
                                                        <span class="menu-title">{{ $t('labels.suppliers') }}</span>
                                                    </RouterLink>
                                                </div>

                                            </div>

                                        </div>

                                        <RouterLink v-if="isAdmin" :to="{ name: Route.SCHEDULE }"
                                            class="menu-item menu-lg-down-accordion me-0 me-lg-2"
                                            active-class="menu-here-bg here"
                                            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                            data-kt-menu-placement="bottom-start">

                                            <div class="menu-link py-3">
                                                <span class="menu-title">{{ $t('labels.schedule') }}</span>
                                            </div>

                                        </RouterLink>

                                        <div v-if="isAdmin" class="menu-item menu-lg-down-accordion me-0 me-lg-2"
                                            active-class="menu-here-bg here"
                                            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                            data-kt-menu-placement="bottom-start">

                                            <div class="menu-link py-3">
                                                <span class="menu-title">{{ $t('labels.system') }}</span>
                                            </div>

                                            <div
                                                class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown px-lg-2 py-lg-4 w-lg-200px">

                                                <div class="menu-item">
                                                    <RouterLink :to="{ name: Route.CATEGORY_INDEX }"
                                                        class="menu-link py-3" data-bs-toggle="tooltip"
                                                        data-bs-trigger="hover" data-bs-dismiss="click"
                                                        data-bs-placement="right">
                                                        <span class="menu-title">{{ $t('labels.categories') }}</span>
                                                    </RouterLink>
                                                </div>

                                                <div class="menu-item">
                                                    <RouterLink :to="{ name: Route.CONDITION_INDEX }"
                                                        class="menu-link py-3" data-bs-toggle="tooltip"
                                                        data-bs-trigger="hover" data-bs-dismiss="click"
                                                        data-bs-placement="right">
                                                        <span class="menu-title">{{ $t('labels.conditions') }}</span>
                                                    </RouterLink>
                                                </div>

                                                <div class="menu-item">
                                                    <RouterLink :to="{ name: Route.GRADE_INDEX }" class="menu-link py-3"
                                                        data-bs-toggle="tooltip" data-bs-trigger="hover"
                                                        data-bs-dismiss="click" data-bs-placement="right">
                                                        <span class="menu-title">{{ $t('labels.grades') }}</span>
                                                    </RouterLink>
                                                </div>

                                                <div class="menu-item">
                                                    <RouterLink :to="{ name: Route.SECTOR_INDEX }"
                                                        class="menu-link py-3" data-bs-toggle="tooltip"
                                                        data-bs-trigger="hover" data-bs-dismiss="click"
                                                        data-bs-placement="right">
                                                        <span class="menu-title">{{ $t('labels.sectors') }}</span>
                                                    </RouterLink>
                                                </div>

                                                <div class="menu-item">
                                                    <RouterLink :to="{ name: Route.SESSION_INDEX }"
                                                        class="menu-link py-3" data-bs-toggle="tooltip"
                                                        data-bs-trigger="hover" data-bs-dismiss="click"
                                                        data-bs-placement="right">
                                                        <span class="menu-title">{{ $t('labels.sessions') }}</span>
                                                    </RouterLink>
                                                </div>

                                                <div class="menu-item">
                                                    <RouterLink :to="{ name: Route.TAX_RATE_INDEX }"
                                                        class="menu-link py-3" data-bs-toggle="tooltip"
                                                        data-bs-trigger="hover" data-bs-dismiss="click"
                                                        data-bs-placement="right">
                                                        <span class="menu-title">{{ $t('labels.taxrates') }}</span>
                                                    </RouterLink>
                                                </div>
                                                <div class="menu-item">
                                                    <RouterLink :to="{ name: Route.MEDIA_MANAGER_INDEX }"
                                                        class="menu-link py-3" data-bs-toggle="tooltip"
                                                        data-bs-trigger="hover" data-bs-dismiss="click"
                                                        data-bs-placement="right">
                                                        <span class="menu-title">{{ $t('labels.media-manager') }}</span>
                                                    </RouterLink>
                                                </div>

                                            </div>

                                        </div>

                                        <RouterLink v-if="isParent" :to="{ name: Route.SETTINGS }"
                                            class="menu-item menu-lg-down-accordion me-0 me-lg-2"
                                            active-class="menu-here-bg here"
                                            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                            data-kt-menu-placement="bottom-start">
                                            <div class="menu-link py-3">
                                                <span class="menu-title">{{ $t('labels.account') }}</span>
                                            </div>
                                        </RouterLink>

                                        <RouterLink v-if="isEmployee || isSupplier" :to="{ name: Route.SCHEDULE }"
                                            class="menu-item menu-lg-down-accordion me-0 me-lg-2"
                                            active-class="menu-here-bg here"
                                            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                            data-kt-menu-placement="bottom-start">
                                            <div class="menu-link py-3">
                                                <span class="menu-title">{{ $t('labels.schedule') }}</span>
                                            </div>
                                        </RouterLink>

                                        <RouterLink v-if="isEmployee || isSupplier" :to="{ name: Route.SETTINGS }"
                                            class="menu-item menu-lg-down-accordion me-0 me-lg-2"
                                            active-class="menu-here-bg here"
                                            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                            data-kt-menu-placement="bottom-start">
                                            <div class="menu-link py-3">
                                                <span class="menu-title">{{ $t('labels.settings') }}</span>
                                            </div>
                                        </RouterLink>

                                    </div>

                                </div>

                            </div>


                            <div class="topbar d-flex align-items-stretch flex-shrink-0">

                                <div v-if="isParent" class="d-flex align-items-stretch ms-1 ms-lg-3">
                                    <div class="d-flex align-items-center" id="kt_header_search_toggle">
                                        <RouterLink :to="{ name: Route.CHECKOUT }"
                                            class="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px">
                                            <i class="ki-duotone ki-handcart fs-1"></i>
                                        </RouterLink>
                                    </div>
                                </div>

                                <div class="d-flex align-items-stretch ms-1 ms-lg-3">
                                    <div class="d-flex align-items-center" id="kt_header_search_toggle">
                                        <div
                                            class="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px">
                                            <i class="ki-duotone ki-magnifier fs-1"><span class="path1"></span><span
                                                    class="path2"></span></i>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex align-items-center ms-1 ms-lg-3">

                                    <a href="#"
                                        class="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px"
                                        data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                                        data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                                        <i class="ki-duotone ki-night-day theme-light-show fs-1">
                                            <span class="path1"></span>
                                            <span class="path2"></span>
                                            <span class="path3"></span>
                                            <span class="path4"></span>
                                            <span class="path5"></span>
                                            <span class="path6"></span>
                                            <span class="path7"></span>
                                            <span class="path8"></span>
                                            <span class="path9"></span>
                                            <span class="path10"></span>
                                        </i>
                                        <i class="ki-duotone ki-moon theme-dark-show fs-1">
                                            <span class="path1"></span>
                                            <span class="path2"></span>
                                        </i>
                                    </a>

                                    <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-gray-500 menu-active-bg menu-state-color fw-semibold py-4 fs-base w-150px"
                                        data-kt-menu="true" data-kt-element="theme-mode-menu">

                                        <div class="menu-item px-3 my-0">
                                            <a href="#" class="menu-link px-3 py-2 active" data-kt-element="mode"
                                                data-kt-value="light">
                                                <span class="menu-icon" data-kt-element="icon">
                                                    <i class="ki-duotone ki-night-day fs-2">
                                                        <span class="path1"></span>
                                                        <span class="path2"></span>
                                                        <span class="path3"></span>
                                                        <span class="path4"></span>
                                                        <span class="path5"></span>
                                                        <span class="path6"></span>
                                                        <span class="path7"></span>
                                                        <span class="path8"></span>
                                                        <span class="path9"></span>
                                                        <span class="path10"></span>
                                                    </i>
                                                </span>
                                                <span class="menu-title">Light</span>
                                            </a>
                                        </div>

                                        <div class="menu-item px-3 my-0">
                                            <a href="#" class="menu-link px-3 py-2" data-kt-element="mode"
                                                data-kt-value="dark">
                                                <span class="menu-icon" data-kt-element="icon">
                                                    <i class="ki-duotone ki-moon fs-2">
                                                        <span class="path1"></span>
                                                        <span class="path2"></span>
                                                    </i>
                                                </span>
                                                <span class="menu-title">Dark</span>
                                            </a>
                                        </div>

                                        <div class="menu-item px-3 my-0">
                                            <a href="#" class="menu-link px-3 py-2" data-kt-element="mode"
                                                data-kt-value="system">
                                                <span class="menu-icon" data-kt-element="icon">
                                                    <i class="ki-duotone ki-screen fs-2">
                                                        <span class="path1"></span>
                                                        <span class="path2"></span>
                                                        <span class="path3"></span>
                                                        <span class="path4"></span>
                                                    </i>
                                                </span>
                                                <span class="menu-title">System</span>
                                            </a>
                                        </div>

                                    </div>

                                </div>

                                <div class="d-flex align-items-stretch ms-1 ms-lg-3">
                                    <div class="d-flex align-items-center" id="kt_header_search_toggle">
                                        <RouterLink :to="{ name: Route.SETTINGS }"
                                            class="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px">
                                            <i class="ki-duotone ki-user fs-1">
                                                <span class="path1"></span>
                                                <span class="path2"></span>
                                            </i>
                                        </RouterLink>
                                    </div>
                                </div>

                                <div class="d-flex align-items-stretch ms-1 ms-lg-3">
                                    <BaseDropdown>

                                        <template v-slot:activator="{ active }">
                                            <button
                                                class="btn btn-icon btn-custom w-30px h-30px w-md-40px h-md-40px position-relative"
                                                :class="active ? 'bg-primary text-white' : ''">
                                                <i class="ki-duotone ki-flag fs-1">
                                                    <span class="path1"></span>
                                                    <span class="path2"></span>
                                                </i>

                                                <span class="position-absolute bottom-[2px] right-[10px] fs-9">
                                                    <span class="text-uppercase text-white">{{ locale }}</span>
                                                </span>
                                            </button>
                                        </template>

                                        <div
                                            class="bg-white shadow-lg md:w-48 overflow-hidden border-t-4 border-primary">

                                            <ul class="pl-0">

                                                <li class="flex items-center justify-between px-4 py-2 font-medium text-gray-600 hover:bg-light hover:text-primary cursor-pointer"
                                                    @click.prevent="setLocale('en')">
                                                    <span>{{ $t('enum.locale.en') }}</span>
                                                    <i v-show="isLocale('en')"
                                                        class="fa-solid fa-circle-check text-sm text-success ml-3"></i>
                                                </li>

                                                <li class="flex items-center justify-between px-4 py-2 font-medium text-gray-600 hover:bg-light hover:text-primary cursor-pointer"
                                                    @click.prevent="setLocale('fr')">
                                                    <span>{{ $t('enum.locale.fr') }}</span>
                                                    <i v-show="isLocale('fr')"
                                                        class="fa-solid fa-circle-check text-sm text-success ml-3"></i>
                                                </li>

                                            </ul>

                                        </div>

                                    </BaseDropdown>
                                </div>

                                <div class="d-flex align-items-stretch ms-1 ms-lg-3" @click.prevent="logout()">
                                    <div class="d-flex align-items-center" data-kt-search-element="toggle"
                                        id="kt_header_search_toggle">
                                        <div
                                            class="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px">
                                            <i class="ki-duotone ki-entrance-left fs-1">
                                                <span class="path1"></span>
                                                <span class="path2"></span>
                                            </i>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                <div id="kt_content_container" class="d-flex flex-column-fluid align-items-start container-xxl mb-10"
                    :class="{'container-admin' : isAdmin || isEmployee || isSupplier}">
                    <RouterView />
                </div>

            </div>

        </div>

    </div>

    <!-- div class="bg-gray-200 min-h-screen">

        <header class="bg-white z-10 shadow-md">

            <BaseContainer class="flex items-center justify-between max-w-full">

                <div class="flex items-center">

                    <button class="inline-flex lg:hidden items-center justify-center w-14 h-14 hover:bg-primary text-primary hover:text-white" @click.prevent="$refs.drawer.open()">
                        <i class="fa-regular fa-bars text-xl"></i>
                    </button>

                    <RouterLink class="inline-flex items-center h-14 px-4" :to="{ name: Route.SETTINGS }">
                        <img class="h-10" :src="'/img/' + parasco_template + '/logo.png'" alt=""/>
                    </RouterLink>

                </div>

                <nav class="hidden lg:flex text-sm font-semibold text-gray-600">

                    <RouterLink v-if="isAdmin" :to="{ name: Route.ACTIVITY_INDEX }" class="inline-flex items-center px-4 h-14 hover:bg-primary hover:text-white" active-class="text-primary">{{ $t('labels.activities') }}</RouterLink>
                    <RouterLink v-if="isParent" :to="{ name: Route.SHOP_STEP_1 }" class="inline-flex items-center px-4 h-14 hover:bg-primary hover:text-white" active-class="text-primary">{{ $t('labels.activities') }}</RouterLink>
                    <RouterLink v-if="isAdmin || isParent" :to="{ name: Route.CHILD_INDEX }" class="inline-flex items-center px-4 h-14 hover:bg-primary hover:text-white" active-class="text-primary">{{ $t('labels.children') }}</RouterLink>
                    <RouterLink v-if="isAdmin" :to="{ name: Route.CLIENT_INDEX }" class="inline-flex items-center px-4 h-14 hover:bg-primary hover:text-white" active-class="text-primary">{{ $t('labels.clients') }}</RouterLink>          
                    <RouterLink v-if="isAdmin" :to="{ name: Route.COURSE_INDEX }" class="inline-flex items-center px-4 h-14 hover:bg-primary hover:text-white" active-class="text-primary">{{ $t('labels.courses') }}</RouterLink>
                    <RouterLink v-if="isAdmin" :to="{ name: Route.ORDER_INDEX }" class="inline-flex items-center px-4 h-14 hover:bg-primary hover:text-white" active-class="text-primary">{{ $t('labels.orders') }}</RouterLink>
                    <RouterLink v-if="isAdmin" :to="{ name: Route.REGISTRATION_INDEX }" class="inline-flex items-center px-4 h-14 hover:bg-primary hover:text-white" active-class="text-primary">{{ $t('labels.registrations') }}</RouterLink>
                    <RouterLink v-if="isEmployee || isSupplier" :to="{ name: Route.SCHEDULE }" class="inline-flex items-center px-4 h-14 hover:bg-primary hover:text-white" active-class="text-primary">{{ $t('labels.schedule') }}</RouterLink>
                    <RouterLink v-if="isParent" :to="{ name: Route.SETTINGS }" class="inline-flex items-center px-4 h-14 hover:bg-primary hover:text-white" active-class="text-primary">{{ $t('labels.account') }}</RouterLink>
                    <RouterLink v-if="isEmployee || isSupplier" :to="{ name: Route.SETTINGS }" class="inline-flex items-center px-4 h-14 hover:bg-primary hover:text-white" active-class="text-primary">{{ $t('labels.settings') }}</RouterLink>                    

                    <BaseDropdown v-if="isAdmin" ref="system">

                        <template #activator="{ active }">
                            <button class="block px-4 h-14" :class="active ? 'bg-primary text-white' : ''">
                                {{ $t('labels.system') }}<i class="fa-duotone fa-chevron-down text-xs ml-2"></i>
                            </button>
                        </template>

                        <div class="bg-white shadow-lg md:w-48 overflow-hidden text-sm border-t-4 border-primary">
                            <RouterLink :to="{ name: Route.CONDITION_INDEX }" class="block px-4 py-2 hover:bg-primary-light hover:text-primary" @click="system?.close()">{{ $t('labels.conditions') }}</RouterLink>
                            <RouterLink :to="{ name: Route.TRANSACTION_INDEX }" class="block px-4 py-2 hover:bg-primary-light hover:text-primary" @click="system?.close()">{{ $t('labels.transactions') }}</RouterLink>
                            <RouterLink :to="{ name: Route.GRADE_INDEX }" class="block px-4 py-2 hover:bg-primary-light hover:text-primary" @click="system?.close()">{{ $t('labels.grades') }}</RouterLink>
                            <RouterLink :to="{ name: Route.SECTOR_INDEX }" class="block px-4 py-2 hover:bg-primary-light hover:text-primary" @click="system?.close()">{{ $t('labels.sectors') }}</RouterLink>
                            <RouterLink :to="{ name: Route.SESSION_INDEX }" class="block px-4 py-2 hover:bg-primary-light hover:text-primary" @click="system?.close()">{{ $t('labels.sessions') }}</RouterLink>
                            <RouterLink :to="{ name: Route.TAX_RATE_INDEX }" class="block px-4 py-2 hover:bg-primary-light hover:text-primary" @click="system?.close()">{{ $t('labels.taxrates') }}</RouterLink>
                        </div>

                    </BaseDropdown>

                    <BaseDropdown v-if="isAdmin" ref="users">

                        <template #activator="{ active }">
                            <button class="block px-4 h-14" :class="active ? 'bg-primary text-white' : ''">                
                                {{ $t('labels.users') }}<i class="fa-duotone fa-chevron-down text-xs ml-2"></i>
                            </button>
                        </template>

                        <div class="bg-white shadow-lg md:w-48 overflow-hidden text-sm border-t-4 border-primary">
                            <RouterLink :to="{ name: Route.USER_INDEX }" class="block px-4 py-2 hover:bg-primary-light hover:text-primary" @click="users?.close()">{{ $t('labels.administrators') }}</RouterLink>
                            <RouterLink :to="{ name: Route.EMPLOYEE_INDEX }" class="block px-4 py-2 hover:bg-primary-light hover:text-primary" @click="users?.close()">{{ $t('labels.employees') }}</RouterLink>
                            <RouterLink :to="{ name: Route.PARENT_INDEX }" class="block px-4 py-2 hover:bg-primary-light hover:text-primary" @click="users?.close()">{{ $t('labels.parents') }}</RouterLink>
                            <RouterLink :to="{ name: Route.SUPPLIER_INDEX }" class="block px-4 py-2 hover:bg-primary-light hover:text-primary" @click="users?.close()">{{ $t('labels.suppliers') }}</RouterLink>      
                        </div>

                    </BaseDropdown>

                </nav>

                <div class="flex ml-4">
                    
                    <button
                        v-if="isParent"
                        class="flex items-center justify-center w-14 h-14 hover:bg-primary hover:text-white" 
                        @click.prevent="$refs.cart.open()">
                        <span class="fa-duotone fa-cart-shopping"></span>
                    </button>

                    <BaseDropdown>

                        <template v-slot:activator="{ active }">
                            <button class="block w-14 h-14" :class="active ? 'bg-primary text-white' : ''">
                                <span class="fa-duotone fa-language"></span>
                            </button>
                        </template>

                        <div class="bg-white shadow-lg md:w-48 overflow-hidden text-sm border-t-4 border-primary">
                            
                            <ul>

                                <li class="flex items-center justify-between px-4 py-2 font-medium text-gray-600 hover:bg-light hover:text-primary cursor-pointer" @click.prevent="setLocale('en')">
                                    <span>{{ $t('enum.locale.en') }}</span>
                                    <i v-show="isLocale('en')" class="fa-duotone fa-circle-check text-sm text-success ml-3"></i>
                                </li>

                                <li class="flex items-center justify-between px-4 py-2 font-medium text-gray-600 hover:bg-light hover:text-primary cursor-pointer" @click.prevent="setLocale('fr')">
                                    <span>{{ $t('enum.locale.fr') }}</span>
                                    <i v-show="isLocale('fr')" class="fa-duotone fa-circle-check text-sm text-success ml-3"></i>
                                </li>

                            </ul>

                        </div>

                    </BaseDropdown>

                    <BaseDropdown>

                        <template v-slot:activator="{ active }">
                            <button class="block w-14 h-14" :class="active ? 'bg-primary text-white' : ''">
                                <span class="fa-duotone fa-user"></span>
                            </button>
                        </template>

                        <div class="bg-white shadow-lg md:w-48 overflow-hidden text-sm border-t-4 border-primary">

                            <ul>

                                <li>
                                    <RouterLink class="block px-4 py-2 font-medium text-gray-600 hover:bg-light hover:text-primary" :to="{ name: Route.SETTINGS }">
                                        <i class="fa-duotone fa-gear fa-fw mr-2"></i>{{ $t('labels.settings') }}
                                    </RouterLink>
                                </li>

                                <li v-if="isParent">
                                    <RouterLink class="block px-4 py-2 font-medium text-gray-600 hover:bg-light hover:text-primary" :to="{ name: Route.ORDER_INDEX }">
                                        <i class="fa-duotone fa-money-simple-from-bracket fa-fw mr-2"></i>{{ $t('labels.orders') }}
                                    </RouterLink>
                                </li>

                                <li>
                                    <a class="block px-4 py-2 font-medium text-gray-600 hover:bg-light hover:text-primary" @click.prevent="logout()">
                                        <i class="fa-duotone fa-arrow-right-from-bracket fa-fw mr-2"></i>{{ $t('labels.logout') }}
                                    </a>
                                </li>

                            </ul>

                        </div>

                    </BaseDropdown>

                </div>
            
            </BaseContainer>
        
        </header>

        <div class="p-6">
            <RouterView/>
        </div>

        <BaseDrawer ref="cart" position="right" :title="$t('labels.cart')">

            <div v-if="items.length > 0" class="flex flex-col gap-6">
                                
                <ul class="flex flex-col divide-y divide-gray-300">

                    <li v-for="item in items" :key="item.key" class="py-4 first:pt-0 last:pb-0">

                        <div class="flex items-center">

                            <div class="aspect-square relative bg-gray-100 w-full max-w-[48px] shrink-0 grow-0">
                                <img v-if="item.attachment" class="absolute top-0 left-0 w-full h-full object-cover" :src="item.attachment" alt="">
                            </div>

                            <ul class="ml-4">
                                <li class="block font-medium text-sm">{{ trans({ en: item.name_en, fr: item.name_fr }) }}</li>
                                <li class="block text-sm">{{ formatChildren(item.children) }}</li>
                                <li v-if="item.options.length > 0" class="block text-sm">{{ $t('labels.options')}}: {{ formatOptions(item.options) }}</li>
                            </ul>

                        </div>

                    </li>

                </ul>

                <div class="bg-gray-100 p-3">
                    <table>
                        <tr>
                            <th class="px-3 py-1.5 text-sm text-left">{{ $t('labels.subtotal') }}:</th>
                            <td class="px-3 py-1.5 text-sm text-left">${{ subtotal }}</td>
                        </tr>
                        <tr>
                            <th class="px-3 py-1.5 text-sm text-left">{{ $t('labels.taxes') }}:</th>
                            <td class="px-3 py-1.5 text-sm text-left">${{ taxes }}</td>
                        </tr>
                        <tr>
                            <th class="px-3 py-1.5 text-sm text-left">{{ $t('labels.total') }}:</th>
                            <td class="px-3 py-1.5 text-sm text-left">${{ total }}</td>
                        </tr>
                    </table>
                </div>

                <BaseButton class="w-full" @click.prevent="checkout()">{{ $t('labels.cart-view') }}</BaseButton>

            </div>

            <div v-if="items.length === 0" class="text-center text-sm" v-text="$t('message.cart-empty')"></div>

        </BaseDrawer>
        
        <BaseDrawer ref="drawer" position="left" :title="$t('labels.navigation')" @closed="resetState()">
        
            <nav class="text-sm font-semibold text-gray-600 -m-6">

                <RouterLink v-if="isAdmin" :to="{ name: Route.ACTIVITY_INDEX }" class="block px-6 py-3 hover:bg-primary-light hover:text-primary rounded-md" @click="drawer?.close()">{{ $t('labels.activities') }}</RouterLink>
                <RouterLink v-if="isParent" :to="{ name: Route.SHOP_STEP_1 }" class="block px-6 py-3 hover:bg-primary-light hover:text-primary rounded-md" @click="drawer?.close()">{{ $t('labels.activities') }}</RouterLink>
                <RouterLink v-if="isAdmin || isParent" :to="{ name: Route.CHILD_INDEX }" class="block px-6 py-3 hover:bg-primary-light hover:text-primary rounded-md" @click="drawer?.close()">{{ $t('labels.children') }}</RouterLink>
                <RouterLink v-if="isAdmin" :to="{ name: Route.CLIENT_INDEX }" class="block px-6 py-3 hover:bg-primary-light hover:text-primary rounded-md" @click="drawer?.close()">{{ $t('labels.clients') }}</RouterLink>
                <RouterLink v-if="isAdmin" :to="{ name: Route.COURSE_INDEX }" class="block px-6 py-3 hover:bg-primary-light hover:text-primary rounded-md" @click="drawer?.close()">{{ $t('labels.courses') }}</RouterLink>
                <RouterLink v-if="isAdmin" :to="{ name: Route.ORDER_INDEX }" class="block px-6 py-3 hover:bg-primary-light hover:text-primary rounded-md" @click="drawer?.close()">{{ $t('labels.orders') }}</RouterLink>
                <RouterLink v-if="isAdmin" :to="{ name: Route.REGISTRATION_INDEX }" class="block px-6 py-3 hover:bg-primary-light hover:text-primary rounded-md" @click="drawer?.close()">{{ $t('labels.registrations') }}</RouterLink>
                <RouterLink v-if="isEmployee || isSupplier" :to="{ name: Route.SCHEDULE }" class="block px-6 py-3 hover:bg-primary-light hover:text-primary rounded-md" @click="drawer?.close()">{{ $t('labels.schedule') }}</RouterLink>
                <RouterLink v-if="isParent" :to="{ name: Route.SETTINGS }" class="block px-6 py-3 hover:bg-primary-light hover:text-primary rounded-md" @click="drawer?.close()">{{ $t('labels.account') }}</RouterLink>
                <RouterLink v-if="isEmployee || isSupplier" :to="{ name: Route.SETTINGS }" class="block px-6 py-3 hover:bg-primary-light hover:text-primary rounded-md" @click="drawer?.close()">{{ $t('labels.settings') }}</RouterLink>

                <div v-if="isAdmin">

                    <button class="flex items-center justify-between px-6 py-3 hover:bg-primary-light hover:text-primary w-full text-left" @click.prevent="state.system = !state.system">{{ $t('labels.system') }}<i class="fa-duotone text-xs ml-2" :class="state.system ? 'fa-chevron-up' : 'fa-chevron-down'"></i></button>
                
                    <div v-if="state.system" class="bg-gray-100">                        
                        <RouterLink v-if="isAdmin" :to="{ name: Route.CONDITION_INDEX }" class="block px-6 py-3 hover:bg-primary-light hover:text-primary rounded-md" @click="drawer?.close()">{{ $t('labels.conditions') }}</RouterLink>  
                        <RouterLink v-if="isAdmin" :to="{ name: Route.TRANSACTION_INDEX }" class="block px-6 py-3 hover:bg-primary-light hover:text-primary rounded-md" @click="drawer?.close()">{{ $t('labels.transactions') }}</RouterLink>
                        <RouterLink :to="{ name: Route.GRADE_INDEX }" class="block px-6 py-3 hover:bg-primary-light hover:text-primary rounded-md" @click="drawer?.close()">{{ $t('labels.grades') }}</RouterLink>
                        <RouterLink :to="{ name: Route.SECTOR_INDEX }" class="block px-6 py-3 hover:bg-primary-light hover:text-primary rounded-md" @click="drawer?.close()">{{ $t('labels.sectors') }}</RouterLink>
                        <RouterLink :to="{ name: Route.SESSION_INDEX }" class="block px-6 py-3 hover:bg-primary-light hover:text-primary rounded-md" @click="drawer?.close()">{{ $t('labels.sessions') }}</RouterLink>
                        <RouterLink :to="{ name: Route.TAX_RATE_INDEX }" class="block px-6 py-3 hover:bg-primary-light hover:text-primary rounded-md" @click="drawer?.close()">{{ $t('labels.taxrates') }}</RouterLink>
                    </div>

                </div>          

                <div v-if="isAdmin">
          
                    <button class="flex items-center justify-between px-6 py-3 hover:bg-primary-light hover:text-primary w-full text-left" @click.prevent="state.users = !state.users">{{ $t('labels.users') }}<i class="fa-duotone text-xs ml-2" :class="state.users ? 'fa-chevron-up' : 'fa-chevron-down'"></i></button>

                    <div v-if="state.users" class="bg-gray-100">
                        <RouterLink v-if="isAdmin" :to="{ name: Route.USER_INDEX }" class="block px-6 py-3 hover:bg-primary-light hover:text-primary rounded-md" @click="drawer?.close()">{{ $t('labels.administrators') }}</RouterLink>
                        <RouterLink v-if="isAdmin" :to="{ name: Route.EMPLOYEE_INDEX }" class="block px-6 py-3 hover:bg-primary-light hover:text-primary rounded-md" @click="drawer?.close()">{{ $t('labels.employees') }}</RouterLink>
                        <RouterLink v-if="isAdmin" :to="{ name: Route.PARENT_INDEX }" class="block px-6 py-3 hover:bg-primary-light hover:text-primary rounded-md" @click="drawer?.close()">{{ $t('labels.parents') }}</RouterLink>
                        <RouterLink v-if="isAdmin" :to="{ name: Route.SUPPLIER_INDEX }" class="block px-6 py-3 hover:bg-primary-light hover:text-primary rounded-md" @click="drawer?.close()">{{ $t('labels.suppliers') }}</RouterLink>  
                    </div>

                </div>

            </nav>

        </BaseDrawer>

        <Toasts/>

    </div -->

</template>

<script setup>
    import Route from '@/constants/route'
    import { useAuth } from '@/composables/auth'    
    import { useI18n, useLocale, useTrans } from '@/composables/i18n'
    import { useMetronic } from '@/composables/keenthemes'
    import { useCartStore } from '@/store/cart'

    const router = useRouter()
    const { store, user, isAdmin, isEmployee, isParent, isSupplier } =  useAuth()
    const { isLocale, setLocale } = useLocale()
    const cartStore = useCartStore()
    const trans = useTrans()

    const drawer = ref()
    const cart = ref()
    const system = ref()
    const users = ref()
    const state = reactive({ system: false, users: false })

    const items = computed(() => cartStore.items)
    const subtotal = computed(() => cartStore.subtotal)
    const taxes = computed(() => cartStore.taxes)
    const total = computed(() => cartStore.total)

    const { locale } = useI18n()
    
    const options = [
        { id: 'en', text: 'EN' },
        { id: 'fr', text: 'FR' }
    ]

    useMetronic()

    onMounted(async () => {
        cartStore.refresh()
    })

    /**
     * Sign out the current user.
     * 
     * @return {void}
     */
    async function logout () {
        return store.logout()
    }

    /**
     * Reset the dropdown navigation state.
     * 
     * @return {void}
     */
    function resetState() {
        state.system = false
        state.users = false  
    }

    /**
     * Show the checkout interface.
     * 
     * @return {void}
     */
    function checkout() {
        cart.value?.close()
        router.push({ name: Route.CHECKOUT })
    }

    /**
     * Format children item children.
     * 
     * @param {array} children
     * @return {array}
     */
     function formatChildren(children) {        
        return Object.values(children).map(child => child.name).join(', ') || ''
    }

    /**
     * Format item options.
     * 
     * @param {array} options
     * @return {array}
     */
    function formatOptions(options) {
        return options.map(option => trans({ en: option.name_en, fr: option.name_fr })).join(', ')
    }
</script>
