import Route from '@/constants/route'
import { useAuth } from '@/composables/auth'
import { useAuthStore } from '@/store/auth'

import AuthRoutes from '@/router/routes/auth'
import MainRoutes from '@/router/routes/main'

const getHomeRoute = async () => {
    const { store, isEmployee, isParent, isSupplier } = useAuth()

    await store.check()

    let name = Route.ACTIVITY_INDEX

    if (isParent.value) {
        name = Route.SHOP_STEP_1
    }

    if (isEmployee.value || isSupplier.value) {
        name = Route.SCHEDULE
    }

    return { name }   
}

const router = createRouter({
    history: createWebHistory(),
    routes: [        
        {
            path: '/:pathMatch(.*)*',
            component: () => import('@/views/Error404.vue')
        },
        AuthRoutes,
        MainRoutes
    ]
})

router.beforeEach(async (to, from, next) => {
    const auth = useAuthStore()

    if (to.matched.some(record => 'auth' === record.meta?.middleware)) {
        const authenticated = await auth.check()

        if (!authenticated) {
            return next({ name: Route.LOGIN })
        } else {
            let homeRoute = await getHomeRoute()

            if ((homeRoute.name !== Route.ACTIVITY_INDEX) && (to.name === Route.ACTIVITY_INDEX)) {
                return next({ name: homeRoute.name })
            }
        }
    }

    if (to.matched.some(record => 'guest' === record.meta?.middleware)) {
        const authenticated = await auth.check()

        if (authenticated) {
            let homeRoute = await getHomeRoute()
            return next({ name: homeRoute.name })
        }
    }

    return next()
})

export default router
