<template>

    <BaseContainer class="max-w-sm w-full">

        <header class="mb-8">
            <h1 class="text-gray-800 font-bold text-3xl" v-text="$t('labels.password-update')"></h1>
        </header>

        <BaseForm ref="form" @submitted="handle" @success="resolved()">

            <LoadingOverlay :loading="loading">

                <div class="grid gap-6">

                    <BaseInputGroup :label="$t('labels.password')" :error="form?.error('password')">
                        <BasePassword v-model="payload.password"/>
                    </BaseInputGroup>

                    <BaseInputGroup :label="$t('labels.password-confirm')" :error="form?.error('password_confirmation')">
                        <BasePassword v-model="payload.password_confirmation"/>
                    </BaseInputGroup>

                    <div class="grid gap-2 md:grid-cols-2">
                        <BaseButton class="w-full" v-text="$t('labels.password-update')"/>
                        <BaseButton class="w-full" modifier="light" v-text="$t('labels.back')" @click.prevent="$router.push({ name: Route.LOGIN })"/>
                    </div>

                </div>

            </LoadingOverlay>

        </BaseForm>

    </BaseContainer>

</template>

<script setup>
    import { password } from '@/api'
    import { toast } from '@/composables/toast'
    import Route from '@/constants/route'
    import ToastType from '@/constants/toast-type'
    import { useAuthStore } from '@/store/auth'

    const authStore = useAuthStore()
    const route = useRoute()

    const router = useRouter()
    const form = ref()
    const payload = ref({})
    const loading = ref(false)

    /**
     * Handle the password update request.
     * 
     * @return {void}
     */
    async function handle () {
        loading.value = true

        try {
            const response = await password.update({
                email: route.query?.email,
                token: route.query?.token,
                password: payload.value.password,
                password_confirmation: payload.value.password_confirmation
            })

            form.value?.resolve(response)

            toast(ToastType.SUCCESS, response.data.message)  
        } catch (error) {
            loading.value = false
            form.value?.reject(error)
        }
    }

    function resolved() {
        console.log('trigger!')
        router.push({ name: Route.LOGIN })
    }
</script>
