import axios from 'axios'
import Cookies from 'js-cookie'

import Cookie from '@/constants/cookie'
import { useAuthStore } from '@/store/auth'
import router from '@/router'
import { useLocale } from '@/composables/i18n'

const headers = {
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
}

const instance = axios.create({ headers, withCredentials: true })

function requestSuccess (config) {
    if (['put', 'post', 'delete'].includes(config.method)) {
        if (!Cookies.get(Cookie.XSRF_TOKEN)) {
            return instance.get('/sanctum/csrf-token').then(() => config)
        }
    }

    return config
}

function requestError (error) {
    return Promise.reject(error)
}

function responseSuccess (response) {
    return response
}

function responseError (error) {
    const status = error.response?.status

    if (status === 404) {
        router.push({ path: '/404' })
    } else if (status === 401 || status === 419) {
        if (router.currentRoute.value.meta?.requiresAuth) {
            useAuthStore().logout()
        }
    }

    return Promise.reject(error)
}

instance.interceptors.request.use(requestSuccess, requestError)
instance.interceptors.response.use(responseSuccess, responseError)

window.axios = instance

export default instance
