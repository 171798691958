<template>

    <BaseForm ref="form" @submitted="submit()">

        <LoadingOverlay :loading="loading">
                
            <TabGroup class="mb-6">

                <TabList class="mb-6 -mt-6 -mx-6"/>

                <div class="flex flex-col lg:flex-row gap-6">

                    <div class="flex-1">

                        <TabPanel :title="$t('labels.general')">

                            <div class="grid gap-6">
                    
                                <BaseInputGroup 
                                    :translatable="true" 
                                    :translatable-labels="{ en: $t('labels.name-en'), fr: $t('labels.name-fr') }"
                                    :translatable-errors="{ en: form?.error('name_en'), fr: form?.error('name_fr') }">

                                    <template #default="{ isLocale }">
                                        <BaseInput v-show="isLocale('en')" v-model="payload.name_en"/>
                                        <BaseInput v-show="isLocale('fr')" v-model="payload.name_fr"/>
                                    </template>

                                </BaseInputGroup>

                                <BaseInputGroup 
                                    :translatable="true" 
                                    :translatable-labels="{ en: $t('labels.introduction-en'), fr: $t('labels.introduction-fr') }"
                                    :translatable-errors="{ en: form?.error('intro_en'), fr: form?.error('intro_fr') }">

                                    <template #default="{ isLocale }">
                                        <Tiptap v-show="isLocale('en')" v-model="payload.intro_en"/>
                                        <Tiptap v-show="isLocale('fr')" v-model="payload.intro_fr"/>
                                    </template>

                                </BaseInputGroup>

                                <BaseInputGroup 
                                    :translatable="true" 
                                    :translatable-labels="{ en: $t('labels.description-en'), fr: $t('labels.description-fr') }"
                                    :translatable-errors="{ en: form?.error('description_en'), fr: form?.error('description_fr') }">

                                    <template #default="{ isLocale }">
                                        <Tiptap v-show="isLocale('en')" v-model="payload.description_en" :enable-images="true"/>
                                        <Tiptap v-show="isLocale('fr')" v-model="payload.description_fr" :enable-images="true"/>
                                    </template>

                                </BaseInputGroup>

                                <BaseInputGroup 
                                    :translatable="true" 
                                    :translatable-labels="{ en: $t('labels.video-en'), fr: $t('labels.video-fr') }"
                                    :translatable-errors="{ en: form?.error('video_en'), fr: form?.error('video_fr') }">

                                    <template #default="{ isLocale }">
                                        <BaseInput v-show="isLocale('en')" v-model="payload.video_en"/>
                                        <BaseInput v-show="isLocale('fr')" v-model="payload.video_fr"/>
                                    </template>

                                </BaseInputGroup>                            
                            
                            </div>
                        
                        </TabPanel>


                        <TabPanel :title="$t('labels.options')">
                            
                            <Repeater
                                v-model="payload.options"
                                :create="$t('labels.option-create')"
                                :empty="$t('labels.empty')"
                                :title="$t('labels.options')">

                                <template #default="{ index, item }">
            
                                    <div class="flex flex-1 items-center gap-6">
            
                                        <BaseInputGroup class="flex-1" :error="form?.error(`options.${index}.name_en`)">
                                            <BaseInput v-model="item.name_en" :placeholder="$t('labels.name-en')"/>
                                        </BaseInputGroup>

                                        <BaseInputGroup class="flex-1" :error="form?.error(`options.${index}.name_fr`)">
                                            <BaseInput v-model="item.name_fr" :placeholder="$t('labels.name-fr')"/>
                                        </BaseInputGroup>

                                        <BaseInputGroup class="flex-1" :error="form?.error(`options.${index}.price`)">
                                                <BaseInput v-model="item.price" :placeholder="$t('labels.price')"/>
                                        </BaseInputGroup>
                                    
                                    </div>
                                
                                </template>
                            
                            </Repeater>
                        
                        </TabPanel>

                        <TabPanel :title="$t('labels.notes')">

                            <div class="grid gap-6">
                
                                <BaseInputGroup :label="$t('labels.notes')" :error="form?.error('notes')">
                                    <Tiptap v-model="payload.notes"/>
                                </BaseInputGroup>
                            
                            </div>
                        
                        </TabPanel>

                    </div>

                    <div class="grow-0 shrink-0 lg:w-1/4">
                        <h4 class="mb-4 text-sm font-semibold">{{ $t('labels.featured-media') }}</h4>
                        <MediaLibrary v-model="payload.attachment_id"/>          
                    </div>

                </div>

            </TabGroup>

            <BaseButton :disabled="submitting" :loading="submitting">
                {{ $t(action) }}
            </BaseButton>

        </LoadingOverlay>

    </BaseForm>
  
</template>

<script setup>
    import { useI18n } from '@/composables/i18n'
    import { useModelForm } from '@/composables/model-ui'
    import { useCourseStore } from '@/store/model'

    const { t } = useI18n()
    const store = useCourseStore()

    const { form, id, payload, loading, submitting, create, edit, submit, mode } = useModelForm({ 
        store: store,
        messages: {
            created: t('message.course-created'),
            updated: t('message.course-updated'),
            destroyed: t('message.course-destroyed')
        }
    })

    const action = computed(() => id.value  ? 'labels.submit-course-edit' : 'labels.submit-course-create')

    defineExpose({ mode, create, edit })
</script>
