<template>
    <div class="group bg-white relative cursor-pointer p-3 border-t-4 hover:shadow-xl ease duration-150">

        <div v-show="!isEditing"
            class="bg-white aspect-square relative transition duration-150 rounded-md overflow-hidden" 
            @dblclick.prevent="$emit('selected', item)" @click.prevent="$emit('clicked',item)">

            <img loading="lazy" :alt="item.name" class="w-full h-full object-cover object-center" :src="item.thumbnail">

            <div class="flex justify-end gap-3 absolute inset-x-0 inset-y-0 p-3">
                <button class="inline-flex items-center justify-center bg-white w-8 h-8 rounded-full shadow text-sm"
                    @click.prevent="toggleEdit">
                    <i class="fa-solid fa-2xs fa-pencil"></i>
                </button>
                <button class="inline-flex items-center justify-center bg-white w-8 h-8 rounded-full shadow text-sm"
                    @click.prevent="$emit('crop', item)">
                    <i class="fa-solid fa-2xs  fa-crop"></i>
                </button>

                <Popconfirm
                    :message="item.total_usages > 0 ? $t('component.popconfirm.attachment-used') : $t('component.popconfirm.message')"
                    @confirm="destory">
                    <button
                        class="inline-flex items-center justify-center bg-white w-8 h-8 rounded-full shadow text-sm">
                        <i class="fa-solid fa-2xs fa-trash"></i>
                    </button>
                </Popconfirm>

            </div>

        </div>

        <form v-show="isEditing" @submit.prevent="submit">
            <BaseInputGroup :label="$t('labels.media-name')">
                <BaseInput v-model="mediaName" />
            </BaseInputGroup>
            <div class="d-flex justify-center gap-3 my-6">
                <BaseButton @click="toggleEdit" variant="secondary" size="sm">
                    {{ $t('cancel') }}
                </BaseButton>

                <BaseButton type="submit" size="sm" :disabled="submitting" :loading="submitting">
                    {{ $t('save') }}
                </BaseButton>

            </div>
        </form>

        <p v-show="!isEditing" class="block text-center text-xs mt-3">{{ mediaName }}

        </p>




    </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue'
import { attachments as api } from '@/api'

const emit = defineEmits(['destroyed', 'edited', 'crop','clicked']);
const item = defineProps({
    id: Number,
    url: String,
    name: String,
    thumbnail: String,
    total_usages: Number
});
const mediaName = toRef(item, "name");
const isEditing = ref(false);
const submitting = ref(false);

const toggleEdit = () => {
    isEditing.value = !isEditing.value;
}

const submit = () => {
    if (item.name != mediaName.value) {
        api.rename(item.id, { name: mediaName.value }).then(res => {
            let newItem = res.data.data
            item.name = newItem.name;
            emit('edited', newItem);
          
        }).finally(() => {
            isEditing.value = false;
        })
    } else {
        isEditing.value = false;
        
    }
    
}

const destory = () => {

    api.destroy(item.id).then(() => {
        emit('destroyed', item)
    })
}


</script>