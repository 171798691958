import dayjs from '@/plugins/dayjs'

const FORMAT_DATE = 'YYYY-MM-DD'
const FORMAT_TIME = 'H:mm'

export function date (d) {
    try {
        return d ? dayjs.utc(d, true).format(FORMAT_DATE) : ''
    } catch {

    }
}

export function time (d) {
    if (d) {
        if (d.match(/\d{2}:\d{2}:\d{2}/)) {
            return dayjs(`1972-01-01 ${d}`, 'HH:mm:ss').format(FORMAT_TIME)
        }

        if (d.match(/\d{2}:\d{2}/)) {
            return dayjs(`1972-01-01 ${d}:00`, 'HH:mm:ss').format(FORMAT_TIME)
        }

        return dayjs(d, 'HH:mm:ss').format(FORMAT_TIME)
    }

    return ''
}

export function datetime (d) {
    return dayjs.utc(d).format(`${FORMAT_DATE} ${FORMAT_TIME}`)
}

export function weekday (d) {
    let words = dayjs.utc(d).format('ddd').toLowerCase().split(' ')

    for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
    }
      
    return words.join(' ')      
}
