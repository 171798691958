import Route from '@/constants/route'
import { useAuth } from '@/composables/auth'
import LayoutDefault from '@/layouts/LayoutDefault.vue'
import ActivityIndex from '@/views/ActivityIndex.vue'
import ActivityCreate from '@/views/ActivityCreate.vue'
import ActivityView from '@/views/ActivityView.vue'
import ActivityEdit from '@/views/ActivityEdit.vue'
import CategoryIndex from '@/views/CategoryIndex.vue'
import ChildIndex from '@/views/ChildIndex.vue'
import ChildView from '@/views/ChildView.vue'
import ChildCreate from '@/views/ChildCreate.vue'
import ChildEdit from '@/views/ChildEdit.vue'
import ClientIndex from '@/views/ClientIndex.vue'
import ClientView from '@/views/ClientView.vue'
import ClientCreate from '@/views/ClientCreate.vue'
import ClientEdit from '@/views/ClientEdit.vue'
import ConditionIndex from '@/views/ConditionIndex.vue'
import CourseIndex from '@/views/CourseIndex.vue'
import CourseCreate from '@/views/CourseCreate.vue'
import CourseView from '@/views/CourseView.vue'
import CourseEdit from '@/views/CourseEdit.vue'
import EmployeeIndex from '@/views/EmployeeIndex.vue'
import EmployeeCreate from '@/views/EmployeeCreate.vue'
import EmployeeView from '@/views/EmployeeView.vue'
import EmployeeEdit from '@/views/EmployeeEdit.vue'
import MessageCreate from '@/views/MessageCreate.vue'
import MessageEdit from '@/views/MessageEdit.vue'
import MessageIndex from '@/views/MessageIndex.vue'
import GradeIndex from '@/views/GradeIndex.vue'
import OrderIndex from '@/views/OrderIndex.vue'
import OrderCreate from '@/views/OrderCreate.vue'
import OrderEdit from '@/views/OrderEdit.vue'
import OrderView from '@/views/OrderView.vue'
import ParentIndex from '@/views/ParentIndex.vue'
import ParentCreate from '@/views/ParentCreate.vue'
import ParentView from '@/views/ParentView.vue'
import ParentEdit from '@/views/ParentEdit.vue'
import Receipt from '@/views/Receipt.vue'
import RegistrationIndex from '@/views/RegistrationIndex.vue'
import RegistrationCreate from '@/views/RegistrationCreate.vue'
import RegistrationView from '@/views/RegistrationView.vue'
import RegistrationEdit from '@/views/RegistrationEdit.vue'
import Schedule from '@/views/Schedule.vue'
import SectorIndex from '@/views/SectorIndex.vue'
import SessionIndex from '@/views/SessionIndex.vue'
import Settings from '@/views/Settings.vue'
import Shop from '@/views/Shop.vue'
import Shop1 from '@/views/Shop1.vue'
import Shop2 from '@/views/Shop2.vue'
import Shop3 from '@/views/Shop3.vue'
import Checkout from '@/views/Checkout.vue'
import Payment from '@/views/Payment.vue'
import SupplierIndex from '@/views/SupplierIndex.vue'
import SupplierCreate from '@/views/SupplierCreate.vue'
import SupplierView from '@/views/SupplierView.vue'
import SupplierEdit from '@/views/SupplierEdit.vue'
import TaxRateIndex from '@/views/TaxRateIndex.vue'
import TransactionIndex from '@/views/TransactionIndex.vue'
import TransactionView from '@/views/TransactionView.vue'
import AdminIndex from '@/views/AdminIndex.vue'
import AdminCreate from '@/views/AdminCreate.vue'
import AdminView from '@/views/AdminView.vue'
import AdminEdit from '@/views/AdminEdit.vue'
import MediaManager from '@/views/media/MediaManager.vue'

const routes = {
    path: "/",
    name: Route.HOME,
    meta: { middleware: "auth" },
    component: LayoutDefault,
    redirect: () => {
        return { name: Route.ACTIVITY_INDEX };
    },
    children: [
        {
            path: "/activities",
            name: Route.ACTIVITY_INDEX,
            component: ActivityIndex,
        },
        {
            path: "/activities/create",
            name: Route.ACTIVITY_CREATE,
            component: ActivityCreate,
        },
        {
            path: "/activities/show/:id",
            name: Route.ACTIVITY_VIEW,
            component: ActivityView,
        },
        {
            path: "/activities/edit/:id",
            name: Route.ACTIVITY_EDIT,
            component: ActivityEdit,
        },
        {
            path: "/categories",
            name: Route.CATEGORY_INDEX,
            component: CategoryIndex,
        },
        {
            path: "/children",
            name: Route.CHILD_INDEX,
            component: ChildIndex,
        },
        {
            path: "/children/show/:id",
            name: Route.CHILD_VIEW,
            component: ChildView,
        },
        {
            path: "/children/create",
            name: Route.CHILD_CREATE,
            component: ChildCreate,
        },
        {
            path: "/children/edit/:id",
            name: Route.CHILD_EDIT,
            component: ChildEdit,
        },
        {
            path: "/clients",
            name: Route.CLIENT_INDEX,
            component: ClientIndex,
        },
        {
            path: "/clients/show/:id",
            name: Route.CLIENT_VIEW,
            component: ClientView,
        },
        {
            path: "/clients/create",
            name: Route.CLIENT_CREATE,
            component: ClientCreate,
        },
        {
            path: "/clients/edit/:id",
            name: Route.CLIENT_EDIT,
            component: ClientEdit,
        },
        {
            path: "/conditions",
            name: Route.CONDITION_INDEX,
            component: ConditionIndex,
        },
        {
            path: "/courses",
            name: Route.COURSE_INDEX,
            component: CourseIndex,
        },
        {
            path: "/courses/create",
            name: Route.COURSE_CREATE,
            component: CourseCreate,
        },
        {
            path: "/courses/show/:id",
            name: Route.COURSE_VIEW,
            component: CourseView,
        },
        {
            path: "/courses/edit/:id",
            name: Route.COURSE_EDIT,
            component: CourseEdit,
        },
        {
            path: "/employees",
            name: Route.EMPLOYEE_INDEX,
            component: EmployeeIndex,
        },
        {
            path: "/employees/create",
            name: Route.EMPLOYEE_CREATE,
            component: EmployeeCreate,
        },
        {
            path: "/employees/view/:id",
            name: Route.EMPLOYEE_VIEW,
            component: EmployeeView,
        },
        {
            path: "/employees/edit/:id",
            name: Route.EMPLOYEE_EDIT,
            component: EmployeeEdit,
        },
        {
            path: "/grades",
            name: Route.GRADE_INDEX,
            component: GradeIndex,
        },
        {
            path: "/orders",
            name: Route.ORDER_INDEX,
            component: OrderIndex,
        },
        {
            path: "/orders/create",
            name: Route.ORDER_CREATE,
            component: OrderCreate,
        },
        {
            path: "/orders/edit/:id",
            name: Route.ORDER_EDIT,
            component: OrderEdit,
        },
        {
            path: "/orders/show/:id",
            name: Route.ORDER_VIEW,
            component: OrderView,
        },
        {
            path: "/parents",
            name: Route.PARENT_INDEX,
            component: ParentIndex,
        },
        {
            path: "/parents/create",
            name: Route.PARENT_CREATE,
            component: ParentCreate,
        },
        {
            path: "/parents/show/:id",
            name: Route.PARENT_VIEW,
            component: ParentView,
        },
        {
            path: "/parents/edit/:id",
            name: Route.PARENT_EDIT,
            component: ParentEdit,
        },
        {
            path: "/receipt/:id",
            name: Route.RECEIPT,
            component: Receipt,
        },
        {
            path: "/registrations",
            name: Route.REGISTRATION_INDEX,
            component: RegistrationIndex,
        },
        {
            path: "/registrations/create",
            name: Route.REGISTRATION_CREATE,
            component: RegistrationCreate,
        },
        {
            path: "/registrations/show/:id",
            name: Route.REGISTRATION_VIEW,
            component: RegistrationView,
        },
        {
            path: "/registrations/edit/:id",
            name: Route.REGISTRATION_EDIT,
            component: RegistrationEdit,
        },
        {
            path: "/schedule",
            name: Route.SCHEDULE,
            component: Schedule,
        },
        {
            path: "/sectors",
            name: Route.SECTOR_INDEX,
            component: SectorIndex,
        },
        {
            path: "/sessions",
            name: Route.SESSION_INDEX,
            component: SessionIndex,
        },
        {
            path: "/settings",
            name: Route.SETTINGS,
            component: Settings,
        },
        {
            path: "/shop",
            component: Shop,
            children: [
                {
                    path: "step-1",
                    name: Route.SHOP_STEP_1,
                    component: ChildIndex,
                },
                {
                    path: "step-2",
                    name: Route.SHOP_STEP_2,
                    component: Shop1,
                },
                {
                    path: "step-3",
                    name: Route.SHOP_STEP_3,
                    component: Shop2,
                },
                {
                    path: "step-4/:key?",
                    name: Route.SHOP_STEP_4,
                    component: Shop3,
                },
                {
                    path: "/checkout",
                    name: Route.CHECKOUT,
                    component: Checkout,
                },
                {
                    path: "/payment",
                    name: Route.PAYMENT,
                    component: Payment,
                },
            ],
        },
        {
            path: "/suppliers",
            name: Route.SUPPLIER_INDEX,
            component: SupplierIndex,
        },
        {
            path: "/suppliers/create",
            name: Route.SUPPLIER_CREATE,
            component: SupplierCreate,
        },
        {
            path: "/suppliers/show/:id",
            name: Route.SUPPLIER_VIEW,
            component: SupplierView,
        },
        {
            path: "/suppliers/edit/:id",
            name: Route.SUPPLIER_EDIT,
            component: SupplierEdit,
        },
        {
            path: "/tax-rates",
            name: Route.TAX_RATE_INDEX,
            component: TaxRateIndex,
        },
        {
            path: "/transactions",
            name: Route.TRANSACTION_INDEX,
            component: TransactionIndex,
        },
        {
            path: "/transactions/show/:id",
            name: Route.TRANSACTION_VIEW,
            component: TransactionView,
        },
        {
            path: "/users",
            name: Route.USER_INDEX,
            component: AdminIndex,
        },
        {
            path: "/users/create",
            name: Route.USER_CREATE,
            component: AdminCreate,
        },
        {
            path: "/users/show/:id",
            name: Route.USER_VIEW,
            component: AdminView,
        },
        {
            path: "/users/edit/:id",
            name: Route.USER_EDIT,
            component: AdminEdit,
        },
        {
            path: "/messages",
            name: Route.MESSAGE_INDEX,
            component: MessageIndex,
        },
        {
            path: "/messages/create",
            name: Route.MESSAGE_CREATE,
            component: MessageCreate,
        },
        {
            path: "/messages/edit/:id",
            name: Route.MESSAGE_EDIT,
            component: MessageEdit,
        },
        {
            path: "/media-manager",
            name: Route.MEDIA_MANAGER_INDEX,
            component: MediaManager,
        }
    ],
};

export default routes
