<template>
    
    <div v-if="isParent" class="mb-5 mb-lg-10 w-100">
            
        <ParentHeader/>

        <div class="card w-100 d-flex flex-col">

            <LoadingOverlay :loading="loading">
            
                <div class="card-body d-flex flex-col gap-6">

                    <div class="row">

                        <div class="col-md-8">

                            <div class="d-flex align-items-center justify-content-between gap-6 mb-6">
                                <h1 class="fs-2" v-text="trans({ en: model?.group?.activity?.course?.name_en, fr: model?.group?.activity?.course?.name_fr })"></h1>
                                <ul class="grade-list list-unstyled d-flex justify-content-center m-0">
                                    <li v-for="(grade, index)  in model?.group?.activity?.grades" :key="grade.id">
                                        <div class="symbol symbol-circle symbol-40px m-0">
                                            <div class="symbol-label fs-8 fw-semibold">{{ trans({ en: grade.abbreviation_en, fr: grade.abbreviation_fr }) }}</div>
                                        </div>                                  
                                    </li>
                                </ul>   
                            </div>

                            <div class="d-block">
                                <div class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded bg-primary" :style="imageStyle(model?.group?.activity)"></div>                                    
                            </div>

                            <div class="my-6" v-html="description(model?.group?.activity?.course)"></div>

                            <div v-if="model?.group?.activity?.notes_public" class="border border-gray-300 border-dashed rounded p-6">
                                <h4 class="fs-6 mb-4">{{ $t('labels.notes') }}</h4>
                                <div v-html="activity?.notes_public"></div>
                            </div>

                        </div>

                        <div class="col-md-4">

                            <div class="d-flex flex-col gap-6">

                                <div class="d-flex flex-col gap-6 border border-gray-300 border-dashed rounded p-6">
                                        
                                    <!--div class="d-flex align-items-center justify-content-between gap-6">

                                        <div class="badge" :class="isRegistrationsOpen(group.internal) ? 'badge-success' :  'badge-danger'">
                                            {{ isRegistrationsOpen(group.internal) ? 'Open for registrations' : 'Registrations closed' }}
                                        </div>

                                        <div v-if="!activity.current_group?.waiting_list" class="h-5px w-100 bg-light"><div class="rounded h-5px" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" :class="progressClasses(group)" :style="progressStyle(group)"></div></div>

                                    </div-->

                                    <h4 v-if="model?.group?.session" class="m-0">{{ model.group.session.name }}</h4>

                                    <div class="row g-12">

                                        <div class="col">
                                            <div class="border border-gray-300 border-dashed rounded w-full py-3 px-4">
                                                <div class="fs-4 fw-bold counted">
                                                    <a class="text-gray-900 text-hover-primary fs-5" href="#">
                                                        <i class="ki-duotone ki-calendar-tick fs-4 text-gray-900 ">
                                                            <i class="path1"></i>
                                                            <i class="path2"></i>
                                                            <i class="path3"></i>
                                                            <i class="path4"></i>
                                                            <i class="path5"></i>
                                                            <i class="path6"></i>
                                                        </i>
                                                        {{ getStartDate(model?.group) }}
                                                    </a>
                                                </div>
                                                <div class="fw-semibold fs-7 text-gray-400">{{ $t('labels.starts') }}</div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="border border-gray-300 border-dashed rounded w-full py-3 px-4">
                                                <div class="fs-4 fw-bold counted">
                                                    <a class="text-gray-900 text-hover-primary fs-5" href="#">
                                                        <i class="ki-duotone ki-calendar-tick fs-4 text-gray-900 ">
                                                            <i class="path1"></i>
                                                            <i class="path2"></i>
                                                            <i class="path3"></i>
                                                            <i class="path4"></i>
                                                            <i class="path5"></i>
                                                            <i class="path6"></i>
                                                        </i>
                                                        {{ getEndDate(model?.group) }}
                                                    </a>
                                                </div>
                                                <div class="fw-semibold fs-7 text-gray-400">{{ $t('labels.ends') }}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row g-12">
                                        <div class="col">
                                            <div class="border border-gray-300 border-dashed rounded w-full py-3 px-4">
                                                <div class="fs-4 fw-bold counted">
                                                    <a class="text-gray-900 text-hover-primary fs-5" href="#">
                                                        <i class="ki-duotone ki-calendar-2 fs-4 text-gray-900 ">
                                                            <i class="path1"></i>
                                                            <i class="path2"></i>
                                                            <i class="path3"></i>
                                                            <i class="path4"></i>
                                                            <i class="path5"></i>
                                                        </i>
                                                        {{ getWeekdays(model?.group).join(', ') || '-' }}
                                                    </a>
                                                </div>
                                                <div class="fw-semibold fs-7 text-gray-400">{{ $t('labels.week-days') }}</div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="border border-gray-300 border-dashed rounded w-full py-3 px-4">
                                                <div class="fs-4 fw-bold counted">
                                                    <a class="text-gray-900 text-hover-primary fs-5" href="#">
                                                        <i class="ki-duotone ki-time fs-4 text-gray-900 ">
                                                            <i class="path1"></i>
                                                            <i class="path2"></i>
                                                        </i>
                                                        {{ time(model?.group?.time_start) }} - {{ time(model?.group?.time_end) }}
                                                    </a>
                                                </div>
                                                <div class="fw-semibold fs-7 text-gray-400">{{ $t('labels.time') }}</div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="flex flex-col gap-6">

                                        <h4 class="m-0">{{ $t('labels.registrations') }}</h4>

                                        <ul class="list-unstyled d-flex flex-col gap-4 m-0">

                                            <li class="child-item p-6 rounded child-item--active">

                                                <div class="d-flex align-items-center justify-content-between gap-6">
                                                    <label class="d-flex align-items-center gap-2 w-100 pe-none">
                                                        <div class="form-check form-check--dark">
                                                            <input class="form-check-input" type="checkbox" checked>
                                                        </div>
                                                        <div class="d-flex items-center justify-between gap-6 fw-semibold fs-7 w-100">
                                                            <RouterLink v-if="model?.child" :to="{ name: Route.CHILD_VIEW, params: { id: model?.child?.id }}">
                                                                <span>{{ model?.child?.first_name }} {{ model?.child?.last_name }}</span>
                                                            </RouterLink>
                                                            <div class="badge badge-danger ml-1" v-if="model?.status == 'waiting'">{{ $t('labels.waiting-list') }}</div>
                                                        </div>
                                                    </label>
                                                </div>

                                                <div v-if="model?.options?.length > 0" class="bg-white text-dark rounded p-4 mt-4">
                                                    <ul class="list-unstyled d-flex flex-col gap-4">
                                                        <li v-for="option in model?.options" :key="option.id">
                                                            <label class="flex items-center pe-none">
                                                                <div class="form-check form-check--dark">
                                                                    <input class="form-check-input" :value="option.id" type="checkbox" checked>
                                                                </div>
                                                                <div class="d-flex items-center justify-between gap-6 fw-semibold fs-7 ml-2 w-100">
                                                                    <span>{{ trans({ en: option.name_en, fr: option. name_fr }) }}</span>
                                                                </div>
                                                            </label>                                                                
                                                        </li>
                                                    </ul>
                                                </div>  

                                            </li>

                                        </ul>

                                    </div> 

                                </div>
                            
                            </div>
                            
                        </div>

                    </div>
                    
                    
                </div>

            </LoadingOverlay>

        </div>

    </div>

    <BaseContainer v-if="isAdmin">

        <header class="flex flex-col md:flex-row md:items-center md:justify-between gap-4 md:gap-6 mb-6">

            <div class="block">
                <h1 class="text-gray-800 font-bold text-xl leading-none mb-2" v-text="$t('labels.registrations')"></h1>
                <Breadcrumbs :current="Route.REGISTRATION_VIEW"/>
            </div>

            <div v-if="isAdmin" class="flex flex-col md:flex-row md:justify-end gap-4">
                <BaseButton @click.prevent="edit()">{{ $t('labels.registration-edit') }}</BaseButton>
                <BaseButton variant="secondary" @click.prevent="index()">{{ $t('labels.back') }}</BaseButton>
            </div>

        </header>

        <div class="flex flex-col gap-6">
                
            <LoadingOverlay :loading="loading">
            
                <BaseCard>

                    <template #header>
                        <span class="card-title fs-6 fw-bold">{{ $t('labels.general') }}</span>
                    </template>

                    <div class="flex flex-col">
                        <div class="py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.status') }}</dt>
                            <dd class="block">
                                {{ $t(`enum.registration-status.${model.status}`) }}
                            </dd>
                        </div>
                        <div class="py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.child') }}</dt>
                            <dd class="block">
                                <RouterLink v-if="model?.child" :to="{ name: Route.CHILD_VIEW, params: { id: model.child.id } }">
                                    {{ model?.child?.first_name }} {{ model?.child?.last_name }}
                                </RouterLink>
                            </dd>
                        </div>
                        <div class="py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.client') }}</dt>
                            <dd class="block">
                                <RouterLink v-if="model?.group?.activity?.client" :to="{ name: Route.CLIENT_VIEW, params: { id: model.group.activity.client.id } }">
                                    {{ model.group.activity.client.name }}
                                </RouterLink>
                                <span v-else>-</span>
                            </dd>
                        </div>
                        <div class="py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.course') }}</dt>
                            <dd class="block">
                                {{ course }}                                
                            </dd>
                        </div>
                        <div class="py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.options') }}</dt>
                            <dd class="block">
                                {{ options(model?.options) || '-' }}
                            </dd>
                        </div>
                    </div>

                </BaseCard>

            </LoadingOverlay>

            <div v-if="isAdmin" class="flex md:justify-end gap-2 mb-6">
                <BaseButton @click.prevent="edit()">{{ $t('labels.registration-edit') }}</BaseButton>
                <BaseButton variant="secondary" @click.prevent="index()">{{ $t('labels.back') }}</BaseButton>
            </div>

        </div>

    </BaseContainer>

    <BaseModal ref="modal" :title="$t('labels.order-pay')" class="max-w-[480px]">
        -
    </BaseModal>

</template>

<style scoped>
    .grade-list li:not(:first-child) {
        margin-left: -5px;
    }

    .grade-list .symbol-label {
        position: relative;
        z-index: 1;
    }
    .grade-list li .symbol-label {
        background-color: #f0f6ff;
    }

    .grade-list li:nth-child(even) .symbol-label {
        background-color: #a2a5b7;
        color: #fff;
    }

    .child-item {
        background-color: #f9f9f9;        
    }

    .child-item--active {
        background-color: var(--bs-primary);
        color: #fff;
    }

    .form-check--dark .form-check-input {
        background-color: #e1e3eb;
    }

    .form-check--dark .form-check-input:checked {
        background-color: #1a1c31;
    }
</style>

<script setup>
    import Route from '@/constants/route'
    import { useAuth } from '@/composables/auth'
    import { date, time } from '@/composables/dates'
    import { useTrans } from '@/composables/i18n'
    import { useModelView } from '@/composables/model-ui'
    import { useRegistrationStore } from '@/store/model'
    import { getEndDate, getStartDate, getWeekdays } from '@/composables/activities'

    const store = useRegistrationStore()
    const trans = useTrans()
    const { isAdmin, isParent } = useAuth()

    const { loading, model, index, edit } = useModelView({ 
        store: store, 
        routes: {
            edit: Route.REGISTRATION_EDIT,
            index: Route.REGISTRATION_INDEX
        }
    })

    const unpaid = computed(() => isParent.value && 'pending' === model?.status)

    const course = computed(() => {
        if (!model.value) {
            return '-'
        }

        let components = []

        if (model.value?.group?.activity?.course) {
            components.push(trans({ en: model.value.group.activity.course.name_en, fr: model.value.group.activity.course.name_en }))
        }

        if (model.value?.group?.session) {
            components.push(model.value.group.session.name)
        }
        
        return components.join(' - ')
    })

    /**
     * Format an array of children.
     * 
     * @param {any} options 
     * @return {string}
     */
    function children(children) {
        if (!children) {
            return '-'
        }

        return children.join(',')
    }

    /**
     * Format an array of options.
     * 
     * @param {any} options 
     * @return {string}
     */
    function options(options) {
        if (!options) {
            return '-'
        }

        return options.map(option => trans({ en: option.name_en, fr: option.name_fr })).join(', ')
    }

    function imageStyle(activity) {
        if (!activity?.course?.attachment)
            return { 'aspect-ratio': '16/9' }

        return { 
            'aspect-ratio': '16/9',
            'background-image': `url(${activity.course.attachment.url})` 
        }
    }

    /**
     * Get the course description.
     * 
     * @param {string} course 
     */
     function description(course) {
        if (!course) {
            return ''
        }

        const html = trans({ en: course.description_en, fr: course.description_fr })

        return html?.replace(/<p>(\s|&nbsp;)*<\/p>/gi, '')
    }

    /**
     * Format a price value.
     * 
     * @param {number|string} value 
     * @return {string}
     */
     function price(value) {
        let prefix = '$'
        let suffix = ''

        // if (taxed.value) {
            // suffix = t('labels.tax')
        // }

        return `${prefix}${value} ${suffix}`.trim()
    }
</script>
