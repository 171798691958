
<template>

    <BaseContainer>

        <header class="mb-6">
            
            <h1 class="text-gray-800 font-bold text-xl leading-none mb-2" v-text="$t('labels.administrators')"></h1>
        
            <Breadcrumbs :current="Route.USER_INDEX"/>
        
        </header>

        <DataTable
            v-model:page="page"
            id="users"
            :columns="columns"
            :rows="rows"
            :transform="transform"
            :to="to"
            :from="from"
            :total="total"
            :pages="pages"
            :loading="loading"
            :filter="filter"            
            @view="view"
            @edit="edit"
            @destroy="destroy">

            <template #actions>
                <BaseButton @click.prevent="create()"><i class="fa-duotone fa-circle-plus mr-2"></i>{{ $t('labels.user-create') }}</BaseButton>
            </template>

        </DataTable>

    </BaseContainer>

</template>

<script setup>
    import { useAdminStore } from '@/store/model'
    import Route from '@/constants/route'
    import { useI18n, useTrans, useUserRoles } from '@/composables/i18n'
    import { useModelIndex } from '@/composables/model-ui'
            
    const { t } = useI18n()
    const trans = useTrans()
    const store = useAdminStore()
    const router = useRouter()

    const { rows, from, to, total, page, pages, search, filter, destroy, loading } = useModelIndex('user', store, {
        created: t('message.user-created'),
        updated: t('message.user-updated'),
        destroyed: t('message.user-destroyed')
    })

    const columns = computed(() => [
        { id: 'id', text: t('labels.id') },
        { id: 'first_name', text: t('labels.name-first') },
        { id: 'last_name', text: t('labels.name-last') },
        { id: 'email', text: t('labels.email') },
        { id: 'phone1', text: t('labels.phone1') },
        { id: 'phone2', text: t('labels.phone2') },
        { id: 'phone3', text: t('labels.phone3') },
        { id: 'address1', text: t('labels.address-line1') },
        { id: 'address2', text: t('labels.address-line2') },
        { id: 'city', text: t('labels.address-city') },
        { id: 'region', text: t('labels.address-region') },
        { id: 'postal_code', text: t('labels.address-postal') },
        { id: 'country', text: t('labels.address-country') },
        { id: 'created_at', text: t('labels.created') },
        { id: 'updated_at', text: t('labels.updated') },
    ])

    const form = ref()
    const create = () => router.push({ name: Route.USER_CREATE })
    const edit = (id) => router.push({ name: Route.USER_EDIT, params: { id } })
    const view = (id) => router.push({ name: Route.USER_VIEW, params: { id } })

    /**
     * Transform the specified row.
     *
     * @param {string} column
     * @param {object} row
     * @return {string}
     */
    function transform (column, row) {
        switch (column) {
            case 'role': return t(`user.role.${row.role}`)
            case 'has_car': return row.has_car ? t('enum.boolean.true') : t('enum.boolean.false')
        }

        return row[column]
    }
</script>
