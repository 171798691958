import { isEmail, isNullish } from '@/composables/utility'

export function useRules(rules, t) {
    let errors = {}

    const errorIf = (condition, key, message) => {
        if (!condition) {
            return
        }

        if (!Array.isArray(errors[key])) {
            errors[key] = []
        }
        

        errors[key].push(message)
    }

    const instance = {
        apply(values) {
            errors = {}

            Object.entries(rules).forEach(([key, rules]) => {
                let name = key
                    .replace('_id', '')
                    .replace('_', '-')

                rules.split('|').forEach(rule => {
                    switch (rule) {
                        case 'email':
                            errorIf(!isEmail(values?.[key]), key, t('validation.email').replace(':name', t(`fields.${name}`)))
                            break

                        case 'required':
                            errorIf(isNullish(values?.[key]), key, t('validation.required').replace(':name', t(`fields.${name}`)))
                            break
                    }
                })
            })

            return instance
        },

        success(callback) {
            if (instance.valid()) {
                callback?.()
            }

            return instance
        },

        fail(callback) {
            if (!instance.valid()) {
                callback?.(errors)
            }

            return instance
        },

        valid() {
            return 0 === Object.keys(errors).length
        }
    }
    
    return instance
}
