<template>

    
    <BaseCard class="w-full">
                                                        
        <template #header>
            <span class="card-title fs-6 fw-bold">{{ $t('labels.dates') }}</span>
        </template>
                                                        
        <div class="row gx-10">
                                                                
            <div class="col-12 col-lg-6">
                <Calendar ref="calendar" v-model="dates" :highlighted="highlighted" :enable-holidays="true" :multiple="true" :inverse="true" class="shadow-sm"/>
            </div>

            <div class="col-12 col-lg-6">

                <div class="d-flex flex-col gap-6">

                    <div class="border border-success border-dashed rounded min-w-125px py-3 px-4 bg-success-light">
                        <div class="d-flex align-items-center gap-4">
                            <i class="ki-duotone ki-calendar-add fs-4x text-success">
                                <i class="path1"></i>
                                <i class="path2"></i>
                                <i class="path3"></i>
                                <i class="path4"></i>
                                <i class="path5"></i>
                                <i class="path6"></i>
                            </i>
                            <div>
                                <dl class="m-0">
                                    <div>
                                        <dt class="font-bold fs-8">{{ $t('labels.date-start') }}</dt>
                                        <dd class="text-muted fs-8">{{ start }}</dd>
                                    </div>
                                    <div>
                                        <dt class="font-bold fs-8">{{ $t('labels.date-end') }}</dt>
                                        <dd class="text-muted fs-8">{{ end }}</dd>
                                    </div>
                                </dl>
                            </div>      
                        </div>                                                              
                    </div>

                    <div class="border border-gray-500 border-dashed rounded min-w-125px py-3 px-4 bg-gray-light">
                        <div class="d-flex align-items-center gap-4">
                            <i class="ki-duotone ki-calendar-remove fs-4x text-gray">
                                <i class="path1"></i>
                                <i class="path2"></i>
                                <i class="path3"></i>
                                <i class="path4"></i>
                                <i class="path5"></i>
                                <i class="path6"></i>
                            </i>
                            <div>
                                <div class="fw-semibold fs-6 fw-bold mb-2">{{ $t('labels.holidays') }}</div>
                                <dl v-if="$refs.calendar?.currentHolidays?.length > 0">
                                    <div v-for="(holiday, i) in $refs.calendar.currentHolidays" :key="i">
                                        <dt class="font-bold fs-8">{{ trans({ en: holiday.en, fr: holiday.fr }) }}</dt>
                                        <dd class="text-muted fs-8">{{ date(holiday.d) }}</dd>
                                    </div>
                                </dl>
                                <span v-else>-</span>
                            </div>      
                        </div>                                                              
                    </div>

                </div>

            </div>

        </div>
        
        <template #footer>
            <div class="d-flex flex-col flex-md-row align-items-md-center gap-6">
                <div class="flex items-center gap-2">
                    <div class="bg-gray-200 w-30px h-30px rounded"></div>
                    <div class="fw-bold">{{ $t('labels.holiday') }}</div>
                </div>
                <div class="flex items-center gap-2">
                    <div class="bg-warning w-30px h-30px rounded"></div>
                    <div class="fw-bold">{{ $t('labels.scheduled') }}</div>
                </div>                
                <div class="flex items-center gap-2 mr-auto">
                    <div class="bg-success w-30px h-30px rounded"></div>
                    <div class="fw-bold">{{ $t('labels.assigned') }}</div>
                </div>
                <div class="fw-bold">{{ dates?.length ||  0 }} {{ $t('labels.dates-assigned') }}</div>
            </div>
        </template>

    </BaseCard>
    
   
</template>

<script setup>
    import { uniq, values } from 'lodash'
    import { date } from '@/composables/dates'
    import { useTrans } from '@/composables/i18n'
    import { isNullish } from '@/composables/utility'

    const emit = defineEmits(['update:modelValue'])

    const props = defineProps({
        modelValue: { required: true },
        user: { required: true }
    })

    const user = toRef(props, 'user')

    const trans = useTrans()
    const model = useVModel(props, 'modelValue', emit)

    const calendar = ref()
    const ignore = ref()
    const dates = ref([])
    const highlighted = ref([])

    const start = computed(() => find('start'))
    const end = computed(() => find('end'))

    watch(model, (value) => syncModel(value), { deep: true })
    watch(dates, value => syncDates(value), { deep: true })     

    watch(user, (newValue, oldValue) => {
        model.value?.groups?.forEach?.(group => {
            group.dates?.forEach?.(date => {
                date.users = date.users?.filter(id => id !== oldValue)
            })
        })
    })

    const find = (type) => {
        const d = dates.value  
            ?.map(d => dayjs(d))
            ?.sort((a, b) => a.isAfter(b) ? 1 : -1)
            
        if (d?.length >= 2) {
            
            d.splice(1, d.length - 2)

            switch (type) {
                case 'start': return d?.[0] ? date(d[0]) : '-'
                case 'end':   return d?.[0] ? date(d[1]) : '-'
            }
        }

        if (1 === d?.length) {
            return date(d[0])

        }
        
        return '-'
    }

    const syncModel = (value) => {
        console.log('syncModel')

        if ('model' === ignore.value) {
            ignore.value = undefined
            return
        }

        ignore.value = 'dates'

        if (!isNullish(value)) {
            let _dates = []
            let _highlighted = []

            value?.groups?.forEach?.(group => {
                group.dates?.forEach?.(item => {
                    _highlighted.push(item.date)

                    if (item.users?.includes?.(user.value)) {
                        _dates.push(item.date)
                    }                    
                })
            })

            dates.value = uniq(_dates)
            highlighted.value = uniq(_highlighted)
        } else {
            highlighted.value = []
            dates.value = []
        }
    }

    const syncDates = (value) => {
        console.log('syncDates')

        if ('dates' === ignore.value) {
            ignore.value = undefined
            return
        }

        ignore.value = 'model'
        
        model.value?.groups?.forEach?.(group => {
            group.dates?.forEach?.(date => {
                if (!value?.includes?.(date.date)) {
                    date.users = uniq(date.users.filter(id => id != user.value)) 
                    return
                }

                if (!Array.isArray(date.users)) {
                    date.users = [user.value]
                } else {                    
                    date.users = uniq([...date.users, user.value])
                }                
            })
        })

        return
    }
    
    onMounted(() => {
        if (model.value) {
            syncModel(model.value)
        }
    })
</script>