<template>

    <div class="relative">

        <div v-if="!standalone" class="bg-gray-200 relative aspect-square rounded-md overflow-hidden">
            <img v-if="url" class="absolute top-0 left-0 w-full h-full object-cover object-center" :src="url">
        </div>

        <div v-if="!standalone" class="flex justify-end gap-3 absolute inset-x-0 inset-y-0 p-3">

            <button class="inline-flex items-center justify-center bg-white w-8 h-8 rounded-full shadow text-sm"
                @click.prevent="open()">
                <i class="fa-duotone fa-search"></i>
            </button>

            <button class="inline-flex items-center justify-center bg-white w-8 h-8 rounded-full shadow text-sm"
                @click.prevent="clear()">
                <i class="fa-duotone fa-trash"></i>
            </button>

        </div>

        <BaseModal ref="modal" :title="$t('component.media-library.title')" :fullscreen="true">

            <template #body>

                <div v-show="editing" class="flex flex-col md:flex-row relative">

                    <div ref="viewport" class="flex flex-col items-center justify-center bg-viewport p-6 grow relative">

                        <div class="max-w-[800px] w-full">
                            <Cropper v-if="editing" ref="cropper" class="cropper" :debounce="false" :src="editing.url"
                                :stencil-props="stencil" @change="onCropperChanged" />
                        </div>

                        <div v-if="preview"
                            class="flex flex-col bg-gray-900/80 absolute bottom-6 right-6 w-24 shadow-lg">
                            <img :src="preview">
                            <div class="block p-2 text-[10px] text-white text-center">
                                {{ coordinates.width }}x{{ coordinates.height }}
                            </div>
                        </div>

                    </div>

                    <div class="flex flex-col gap-6 bg-white p-6 w-full md:w-96 z-10">

                        <BaseInputGroup :label="$t('labels.aspect')">
                            <BaseListbox v-model="stencil.aspectRatio" :options="ratios" :clearable="false"
                                :searchable="false" />
                        </BaseInputGroup>

                        <BaseInputGroup :label="$t('labels.name')">
                            <BaseInput v-model="attachmentName" />
                        </BaseInputGroup>

                        <BaseInputGroup :label="$t('labels.url')">
                            <BaseInput v-model="editing.url" :disabled="true" />
                        </BaseInputGroup>

                        <div class="flex gap-4">
                            <BaseButton @click.prevent="apply(editing)">{{ $t('labels.apply') }}</BaseButton>
                            <BaseButton variant="secondary" @click.prevent="edit(false)">{{ $t('labels.back') }}
                            </BaseButton>
                        </div>

                    </div>

                </div>

                <TabGroup ref="tabs" v-show="!editing">

                    <div class="px-6">
                        <TabList>

                            <template #default="{ active }">

                                <div v-if="active === 1"
                                    class="flex items-center justify-between bg-white overflow-hidden p-3 md:w-64">

                                    <i class="fa-duotone fa-magnifying-glass text-sm mr-3"></i>

                                    <input v-model="query"
                                        class="block w-full outline-none text-sm focus:placeholder-transparent text-sm"
                                        :placeholder="$t('labels.search')">
                                    <BaseButton @click.prevent="handleSelectedItem" size="sm">
                                        Select
                                    </BaseButton>
                                </div>

                            </template>

                        </TabList>
                    </div>

                    <TabPanel :title="$t('component.media-library.upload')">

                        <LoadingOverlay :loading="uploading">

                            <div class="bg-gray-100 p-6 relative">

                                <div
                                    class="flex items-center justify-center border-2 border-dashed border-gray-300 hover:border-gray-400 p-6 rounded-md cursor-pointer text-center h-64">

                                    <div v-bind="getRootProps()">
                                        <input v-bind="getInputProps()">
                                        <i class="fa-duotone fa-cloud-arrow-up text-3xl text-gray-400 mb-4"></i>
                                        <p class="text-gray-400 text-sm text-semibold leading-5"
                                            v-text="$t('component.media-library.instructions')"></p>
                                        <p class="text-gray-400 text-xs leading-5"
                                            v-text="$t('component.media-library.formats')"></p>
                                        <button
                                            class="bg-white text-gray-600 hover:bg-gray-900 hover:text-white font-medium p-2 rounded-md text-xs z-0 mt-4"
                                            @click="open" v-text="$t('component.media-library.select')"></button>
                                    </div>

                                </div>

                                <div class="flex mt-6">
                                    <BaseToggle v-model="autocropping">{{ $t('labels.autocropping') }}</BaseToggle>
                                </div>

                            </div>

                        </LoadingOverlay>

                    </TabPanel>

                    <TabPanel :title="$t('component.media-library.browse')">

                        <MediaList :query="query" :editedAttachment="editedAttachment" ref="exposedMediaListFunction" @select="select"
                            :edit="edit" :clear="clear" v-model="model" />
                    </TabPanel>

                </TabGroup>

            </template>

        </BaseModal>

    </div>

</template>

<script setup>
    import { Cropper } from 'vue-advanced-cropper'
    import { useDropzone } from 'vue3-dropzone'
    import { attachments as api } from '@/api'
    import ToastType from '@/constants/toast-type'
    import { useI18n } from '@/composables/i18n'
    import { toast } from '@/composables/toast'
    import MediaList from '@/views/media/partials/MediaList.vue'
    
    const emit = defineEmits(['select', 'update:modelValue'])

    const props = defineProps({
        modelValue: { required: true },
        standalone: { type: Boolean, default: false },
        threshold: { type: Number, default: 80 }
    })

    const standalone = toRef(props, 'standalone')

    const { t } = useI18n()
    const model = useVModel(props, 'modelValue', emit)
    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop })

    const modal = ref(null)
    const tabs = ref()
    const attachments = ref([])
    const query = ref(null)
    const url = ref()
    const uploading = ref(false)
    const editing = ref(false)
    const attachmentName = ref('')
    const cropper = ref()
    const viewport = ref(null)
    const coordinates = ref(null)
    const preview = ref(null)
    const editedAttachment = ref(null)
    const autocropping = useLocalStorage('media:autocropping', true)

    const stencil = useLocalStorage('media:stencil', {
        aspectRatio: 1/1
    })
    const exposedMediaListFunction = ref()

    const ratios = [
        { id: 1/1, text: '1:1' },
        { id: 2/1, text: '2:1' },
        { id: 3/2, text: '3:2' },
        { id: 4/3, text: '4:3' },
        { id: 16/9, text: '16:9' },
        ]

    const ids = computed(() => attachments.value.map(item => item.id))
   

    watch(model, async (id) => {
        if (!id) {
            return
        }
  
        const attachment = await load(id)

        if (attachment) {
            url.value = attachment.thumbnail
        }
    })

    watch(stencil, () => {
        cropper.value?.refresh()
    }, { deep: true })

    
    useResizeObserver(viewport, (entries) => {
        cropper.value?.refresh()
    })

    defineExpose({ open, close })

    function onCropperChanged(args) {
        coordinates.value = args.coordinates
        preview.value = args.canvas?.toDataURL()
    }

    /**
     * Open the media library.
     * 
     * @return {void}
     */
    function open() {
        // Reset the editing state.
        editing.value = false

        // Open the model.
        modal.value?.open()        
    }

    /**
     * Close the media library.
     * 
     * @return {void}
     */
    function close() {
        modal.value?.close()
    }

    /**
     * Clear the selected attachment.
     * 
     * @return {void}
     */
    function clear() {
        model.value = null
        url.value = null
}


    /**
     * Load the specified attachment if it was not previously loaded.
     * 
     * @param {number} id 
     * @return {object}
     */
    async function load(id) {
        let media = exposedMediaListFunction.value.getMediaById(id);
        if (media) {  
            return media
        }

        const response = await api.show(id)

        if (response.data?.data) {
            exposedMediaListFunction.value.addMedia(response.data.data)
        }

        return response.data?.data
    }

    /**
     * Upload the accepted files.
     * 
     * @param {*} acceptFiles 
     * @param {*} rejectReasons 
     * @return {void}
     */
    function onDrop(acceptFiles, rejectReasons) {
        uploading.value = true

        api.store(acceptFiles).then(response => {
            const { data } = response.data

            data?.forEach(attachment => {
                exposedMediaListFunction.value.addMedia(attachment, true)
                
                if (1 === data.length) {
                   // select(attachment)
                    model.value = attachment.id
                    if (autocropping.value) {
                        edit(attachment)
                    }
                }
            })

            tabs.value?.setActive(1)
            toast(ToastType.SUCCESS, t('message.attachment-created'))      
        }).catch(error => {
            if (error.response?.data?.message) {
                toast(ToastType.ERROR, error.response?.data?.message)
            }
        }).finally(() => {
            uploading.value = false
        })
    }


    /**
     * Indicates if the specified attachment is selected.
     * 
     * @param {object} attachment
     * @return {boolean}
     */
    function isSelected(attachment) {
        return model.value == attachment.id
    }

    /**
     * Select the specified attachment.
     * 
     * @param {object} attachment
     * @return {void}
     */
    function select(attachment) {
        model.value = attachment.id
        url.value = attachment.thumbnail

        emit('select', attachment)
        close()
    }
    function handleSelectedItem() {
        exposedMediaListFunction.value.selectClickedItem()
    }
    /**
     * Edit the specified attachment.
     * 
     * @param {object} attachment 
     * @return {void}
     */
     function edit(attachment) {
        if (!attachment) {
            editing.value = false
            return
         }
         attachmentName.value = attachment.name;

        editing.value = attachment
}


    /**
     * Apply the edits.
     * 
     * @param {object} attachment 
     * @return {void}
     */
      function  apply(attachment) {
        if (!attachment) {
            return
        }

        const { canvas } = cropper.value?.getResult()

        if (!canvas) {
            return
        }        

        const form = new FormData()

        canvas.toBlob(blob => {                        
            form.append('image', blob)
            form.append('name', attachmentName.value)

          api.update(editing.value?.id, form).then(response => {
                const data = response.data?.data
                           
                exposedMediaListFunction.value.edited(data);
                editedAttachment.value = data;
                    if (isSelected(data)) {
                        select(data)
                    }

              // This function should update the UI so it reflect the new name changes and Cropped image on Library but it does not.
              
                    // Leave editing mode.
                    editing.value = false

                // Display the success message.
                toast(ToastType.SUCCESS, t('message.attachment-updated'))
            }).catch(error => {
                if (error.response?.data?.message) {
                    toast(ToastType.ERROR, error.response?.data?.message)
                }
            })
       
        })
    }
</script>
