<template>

    <BaseContainer>

        <header class="mb-6">

            <h1 class="text-gray-800 font-bold text-xl leading-none mb-2" v-text="$t('labels.activities')"></h1>

            <Breadcrumbs :current="Route.ACTIVITY_EDIT"/>

        </header>

        <ActivityForm ref="form" @success="$router.push({ name: Route.ACTIVITY_INDEX })"/>

    </BaseContainer>

</template>

<script setup>
    import Route from '@/constants/route'

    const form = ref()
    const route = useRoute()

    onMounted(() => nextTick(() => form.value.edit(route.params.id)))
</script>
