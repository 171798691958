<template>
    <div class="bg-gray-200 p-6">
        <div class="md:flex justify-between ">
            <div>
                {{ $t('showing') }} 1 to {{ paginationMeta?.to }} out of {{
                paginationMeta?.total }}
            </div>
            <div>
                <div class="flex justify-end -mt-6 mb-2">
                    <BaseInputGroup>
                        <BaseListbox v-model="sorting.field" :options="sortingField" :clearable="false"
                            :searchable="false" />
                    </BaseInputGroup>
                    <BaseInputGroup>
                        <BaseListbox v-model="sorting.orderBy" :options="orderBy" :clearable="false"
                            :searchable="false" />
                    </BaseInputGroup>

                </div>
            </div>
        </div>
        <div ref="scrollbar"
            class="grid md:grid-cols-3 lg:grid-cols-6 gap-3 max-h-screen  scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-gray-500 scrollbar-track-gray-300 overflow-y-scroll pr-5">

            <template v-if="filtered.length > 0">
                <MediaCard @selected="select" @edited="edited" @destroyed="destroyed" @crop="edit"
                    @clicked="handleClicked" v-for="item in filtered" v-bind="item" :key="item.id"
                    :class="isSelected(item) ? 'border-success' : 'border-primary'" />
            </template>

            <template v-else>

                <div
                    class="flex items-center bg-white border-l-4 border-warning p-3 text-sm md:col-span-3 lg:col-span-6">
                    <i class="fa-duotone fa-circle-exclamation mr-3 text-warning"></i>{{
                    $t('component.media-library.empty') }}
                </div>

            </template>

            <div v-show="loading" class="flex items-center bg-white p-6 md:col-span-3 lg:col-span-6">
                <BaseSpinner class="h-6 w-6 mr-3" />
            </div>

        </div>

    </div>
</template>


<script setup>
import { defineProps, defineEmits, ref, computed, watch, defineExpose, toRef, onMounted, onUnmounted } from 'vue'
import MediaCard from '@/views/media/partials/MediaCard.vue'

import { attachments as api } from '@/api'
import { useAttachmentSortBy, useOrderBy, useI18n } from '@/composables/i18n'
const { t } = useI18n()
import { useInfiniteScroll } from '@vueuse/core'

const props = defineProps({
    modelValue: { type: Number },
    threshold: { type: Number, default: 70 },
    edit: { type: Function },
    clear: { type: Function},
    editedAttachment: { type: Object },
    query: { type: Object}
})
const threshold = toRef(props, 'threshold')
const emit = defineEmits(['select', 'update:modelValue'])
const model = useVModel(props, 'modelValue', emit)

const attachments = ref([])
const nextPage = ref(1)
const paginationMeta = ref({})
const query = toRef(props, 'query')
const editing = ref(false)
const scrollbar = ref()
const loading = ref(false)

const sortingField = useAttachmentSortBy()
const orderBy = useOrderBy()
const clicked = ref()
const sorting = reactive({
    field: 'created_at',
    orderBy: 'desc',
    perPage: 12
})
const filtered = computed(() => {
    if (query.value) {
        const search = query.value.toLowerCase()

        return attachments.value.filter(item => item.name.toLowerCase().includes(search))
    }

    return attachments.value
})
const ids = computed(() => attachments.value.map(item => item.id))

async function loadMore() {
    try {
        if (loading.value || nextPage.value === null) {
            return
        }
        loading.value = true;
        const params = { ...sorting, q:query.value };

        if (nextPage.value) {
            params.page = nextPage.value
        }
        const response = await api.index(params);
        response.data.data.forEach(attachment => {
            if (!ids.value.includes(attachment.id)) {
                attachments.value.push(attachment)
            }
        });
        paginationMeta.value = response.data?.meta
        nextPage.value = response.data?.meta.current_page < response.data?.meta.last_page ? parseInt(nextPage.value) + parseInt(1) : null

      
    } catch (e) {
        //Display error logs   
    } finally {
        loading.value = false;
    }

}
async function filterChange() {
    try {
        if (loading.value) {
            return
        }
        loading.value = true;
        const params={...sorting,q:query.value}
        const response = await api.index(params);
        attachments.value = response.data.data;
        paginationMeta.value = response.data?.meta
        nextPage.value = response.data?.meta.current_page < response.data?.meta.last_page ? 2 : null

    } catch (e) {
        //Display error logs   
    } finally {
        loading.value = false;
    }

}

watch([sorting,query], () => {
    filterChange();
}, { immediate: true });

let intervalId = null;

onMounted(() => {
  intervalId = setInterval(() => {
    if (attachments.value.length > 0 && scrollbar.value) {
      const scrollbarElement = scrollbar.value;
      const currentHeight = scrollbarElement.offsetHeight;
      if (currentHeight > 0) {
        const newMaxHeight = currentHeight - (currentHeight * 0.1);
        scrollbarElement.style.maxHeight = `${newMaxHeight}px`;
        clearInterval(intervalId);
      }
    }
  }, 100);
});

onUnmounted(() => {
  if (intervalId) {
    clearInterval(intervalId);
  }
});

useEventListener(scrollbar, 'scroll', (event) => {
    const height = event.target.clientHeight
    const scrollHeight = event.target.scrollHeight - height
    const scrollTop = event.target.scrollTop
    const percentage = Math.floor(scrollTop / scrollHeight * 100)

    if (nextPage.value === 1) {
        loadMore()
    } else {
        if (percentage > threshold.value && loading.value === false) {
            loadMore()
        } 
    }

})

const destroyed = (item) => {
    const index = ids.value.findIndex(id => id == item.id)

    if (index !== -1) {
        attachments.value.splice(index, 1);
    }
    if (isSelected(item)) {
        props.clear()
    }
}

/**
 * Indicates if the specified attachment is selected.
 * 
 * @param {object} attachment
 * @return {boolean}
 */
function isSelected(attachment) {
    return model.value == attachment.id || clicked.value?.id == attachment.id;
}

/**
 * Select the specified attachment.
 * 
 * @param {object} attachment
 * @return {void}
 */
function select(attachment) {
    model.value = attachment.id

    emit('select', attachment)
}

function handleClicked(attachment) {
    clicked.value = attachment;
}


function selectClickedItem() {
    select(clicked.value);
}


function edited(item) {
    const index = ids.value.findIndex(id => id == item.id)

    if (index !== -1) {
        attachments.value[index] = item;
        console.log('Media Updated')
    } else {
        console.log('Media not found for update!')
    }

}

function addMedia(item, unshift = false) {
    if (unshift) {
        attachments.value.unshift(item);
    } else {
        attachments.value.push(item);
    }
   console.log('Media added')
}


function getMediaById(id) {
    if (ids.value.includes(id)) {
        return attachments.value.find(attachment => attachment.id === id)
    }
    return undefined;
}

defineExpose({ attachments, getMediaById, addMedia, edited, selectClickedItem })
</script>