<template>
    <div ref="el" v-show="active">
        <slot/>
    </div>
</template>

<script setup>
    const props = defineProps({
        title: { type: String, default: "" },
    })
    
    const form = inject('form', null)
    const tabGroup = inject('tabGroup', null)

    const el = ref()
    const title = toRef(props, 'title')
    const errors = ref([])
    const index = computed(() => tabGroup.findPanelIndex(tabPanel))
    const active = computed(() => tabGroup.isPanelActive(tabPanel))

    const tabPanel = {
        el: readonly(el),
        title: readonly(title),
        index: readonly(index),
        active: readonly(active),
        error: computed(() => 0 !== errors.value.length),
        errors: errors
    }

    provide('tabPanel', tabPanel)

    onMounted(() => tabGroup.registerPanel(tabPanel))
    onUnmounted(() => tabGroup.unregisterPanel(tabPanel))
</script>
