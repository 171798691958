<template>

    <BaseContainer>

        <header class="flex flex-col md:flex-row md:items-center md:justify-between gap-4 md:gap-6 mb-6">

            <div class="block">
                <h1 class="text-gray-800 font-bold text-xl leading-none mb-2" v-text="$t('labels.supplier')"></h1>
                <Breadcrumbs :current="Route.SUPPLIER_VIEW"/>
            </div>

            <div class="flex flex-col md:flex-row md:justify-end gap-4">
                <BaseButton @click.prevent="edit()">{{ $t('labels.employee-edit') }}</BaseButton>
                <BaseButton variant="secondary" @click.prevent="index()">{{ $t('labels.back') }}</BaseButton>
            </div>

        </header>

        <div class="flex flex-col gap-6">
            
            <LoadingOverlay :loading="loading">
                    
                <BaseCard>

                    <template #header>
                        <span class="card-title fs-6 fw-bold">{{ $t('labels.general') }}</span>
                    </template>

                    <dl class="flex flex-col">

                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block text-xs font-semibold mb-2">{{ $t('labels.name') }}</dt>
                            <dd class="block text-sm">{{ model?.name || '-' }}</dd>
                        </div>

                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block text-xs font-semibold mb-2">{{ $t('labels.email') }}</dt>
                            <dd class="block text-sm">{{ model?.email || '-' }}</dd>
                        </div>

                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block text-xs font-semibold mb-2">{{ $t('labels.phone1') }}</dt>
                            <dd class="block text-sm">{{ model?.phone1 || '-' }}</dd>
                        </div>

                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block text-xs font-semibold mb-2">{{ $t('labels.phone2') }}</dt>
                            <dd class="block text-sm">{{ model?.phone2 || '-' }}</dd>
                        </div>

                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block text-xs font-semibold mb-2">{{ $t('labels.phone3') }}</dt>
                            <dd class="block text-sm">{{ model?.phone3 || '-' }}</dd>
                        </div>

                    </dl>

                </BaseCard>
            
            </LoadingOverlay>

            <LoadingOverlay :loading="loading">
                    
                <BaseCard>

                    <template #header>
                        <span class="card-title fs-6 fw-bold">{{ $t('labels.address') }}</span>
                    </template>

                    <dl class="flex flex-col">

                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block text-xs font-semibold mb-2">{{ $t('labels.address-line1') }}</dt>
                            <dd class="block text-sm">{{ model?.address1 || '-' }}</dd>
                        </div>

                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block text-xs font-semibold mb-2">{{ $t('labels.address-line2') }}</dt>
                            <dd class="block text-sm">{{ model?.address2 || '-' }}</dd>
                        </div>

                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block text-xs font-semibold mb-2">{{ $t('labels.address-city') }}</dt>
                            <dd class="block text-sm">{{ model?.city || '-' }}</dd>
                        </div>

                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block text-xs font-semibold mb-2">{{ $t('labels.address-region') }}</dt>
                            <dd class="block text-sm">{{ model?.region || '-' }}</dd>
                        </div>

                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block text-xs font-semibold mb-2">{{ $t('labels.address-postal') }}</dt>
                            <dd class="block text-sm">{{ model?.postal_code || '-' }}</dd>
                        </div>

                        <div class="px-6 py-3 border-t border-gray-200 first:border-none">
                            <dt class="block text-xs font-semibold mb-2">{{ $t('labels.address-country') }}</dt>
                            <dd class="block text-sm">{{ model?.country || '-' }}</dd>
                        </div>

                    </dl>

                </BaseCard>

            </LoadingOverlay>

            <LoadingOverlay :loading="loading">
            
                <BaseCard>

                    <template #header>
                        <span class="card-title fs-6 fw-bold">{{ $t('labels.files') }}</span>
                    </template>

                    <ul class="px-6 py-3">

                        <li>
                            <a class="inline-flex items-center text-sm hover:text-primary" href="#" @click.prevent="$refs.report?.generate('timesheet', { employee: model?.id })">
                                <i class="fa-duotone fa-file-pdf mr-2"></i>{{ $t('enum.report.timesheet') }}
                            </a>
                        </li>

                    </ul>

                </BaseCard>

            </LoadingOverlay>

            <div class="flex md:justify-end gap-2 mb-6">

                <BaseButton @click.prevent="edit()">{{ $t('labels.employee-edit') }}</BaseButton>

                <BaseButton variant="secondary" @click.prevent="index()">{{ $t('labels.back') }}</BaseButton>

            </div>

        </div>

    </BaseContainer>

    <ReportModal ref="report"/>

</template>

<script setup>
    import Route from '@/constants/route'
    import { date, time } from '@/composables/dates'
    import { useTrans } from '@/composables/i18n'
    import { useModelView } from '@/composables/model-ui'
    import { useEmployeeStore} from '@/store/model'

    const store = useEmployeeStore()
    const trans = useTrans()

    const { loading, model, index, edit } = useModelView({ 
        store: store, 
        routes: {
            edit: Route.EMPLOYEE_EDIT,
            index: Route.EMPLOYEE_INDEX
        }
    })
</script>
