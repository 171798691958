<template>

    <!--LoadingOverlay :loading="isLoading"-->   

        <!--div v-if="updateRequired" class="flex flex-col md:flex-row md:items-center md:justify-between bg-white border-l-4 border-danger p-3 text-sm md:col-span-3 lg:col-span-6 mb-6">

            <div class="mb-3 md:mb-0 mr-4">
                <i class="fa-duotone fa-circle-exclamation mr-3 text-danger"></i>{{ $t('message.checkout-update-required') }}
            </div>

            <BaseButton @click.prevent="$router.push({ name: Route.CHILD_INDEX })">
                {{ $t('labels.children') }}
            </BaseButton>

        </div>

        <div v-if="!hasChildren" class="flex flex-col md:flex-row md:items-center md:justify-between bg-white border-l-4 border-danger p-3 text-sm md:col-span-3 lg:col-span-6 mb-6">

            <div class="mb-3 md:mb-0 mr-4">
                <i class="fa-duotone fa-circle-exclamation mr-3 text-danger"></i>{{ $t('message.checkout-children-empty') }}
            </div>

            <BaseButton @click.prevent="$router.push({ name: Route.CHILD_CREATE })">
                <i class="fa-duotone fa-circle-plus mr-2"></i>{{ $t('labels.child-create') }}
            </BaseButton>
            
        </div>
        
        <div v-if="hasChildren && !hasClients" class="flex items-center bg-white border-l-4 border-warning p-3 text-sm md:col-span-3 lg:col-span-6 mb-6">
            <i class="fa-duotone fa-circle-exclamation mr-3 text-warning"></i>{{ $t('labels.empty') }}
        </div>

        <div class="grid gap-6 md:grid-cols-3">

            <template v-for="client in clients" :key="client.id">

                <div class="flex flex-col cursor-pointer bg-white transition hover:shadow-md" @click.prevent="select(client)">

                    <div class="aspect-square relative bg-gray-100">
                        <img v-if="client.attachment" class="absolute top-0 left-0 w-full h-full object-cover object-center" :src="client.attachment?.url" :alt="client.name">
                    </div>

                    <div class="p-4">
                        <h4 class="font-medium">{{ client.name }}</h4>
                    </div>

                </div>

            </template>

        </div-->

        <div v-if="!hasChildren" class="bg-warning border border-dashed border-dark d-flex align-items-center p-5 mb-10">
            <i class="ki-duotone ki-shield-tick fs-2hx text-dark me-4"><span class="path1"></span><span class="path2"></span></i>
            <div class="d-flex flex-column">
                <h4 class="mb-1 text-dark">{{ $t('labels.notice') }}</h4>
                <span>{{ $t('labels.child-warning') }}</span>
            </div>
        </div>

        <div v-if="hasChildren" class="d-flex align-items-center mb-7">

            <h3 class="text-dark me-6">{{ $t('labels.schools') }}</h3>

            <form data-kt-search-element="form" class="w-100 mw-200px position-relative" autocomplete="off">	    
                <i class="ki-duotone ki-magnifier fs-3 text-gray-500 position-absolute top-50 translate-middle-y ms-0 z-index-3" style="left:0.75rem"><span class="path1"></span><span class="path2"></span></i>
                <input v-model="search" class="search-input form-control ps-10" :placeholder="$t('labels.search') + '...'">    
                <i @click="clearSearch" v-show="search" class="bi bi-x-lg fs-3 text-gray-500 position-absolute top-50 translate-middle-y ms-0 z-index-3" style="right:0.75rem"><span class="path1"></span><span class="path2"></span></i>

            </form>

        </div>

        <div v-if="hasChildren" class="row g-10">   
            
            <div v-for="client in filtered" :key="client.id" class="col-md-4">

                <div class="card-xl-stretch me-md-6">
                    <a class="d-block overlay" data-fslightbox="lightbox-hot-sales" href="#" @click.prevent="select(client)">
                        <div class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px bg-primary" :style="imageStyle(client)"></div>                
                        <div class="overlay-layer card-rounded bg-dark bg-opacity-25">
                            <i class="ki-duotone ki-eye fs-2x text-white">
                                <span class="path1"></span>
                                <span class="path2"></span>
                                <span class="path3"></span>
                            </i>
                        </div>      
                    </a>
                    <div class="d-flex justify-content-between mt-5">
                        <div>
                            <div class="mb-2">
                                <a href="#" class="fs-4 text-dark fw-bold text-hover-primary text-dark lh-base" @click.prevent="select(client)">{{ client.name || '-' }}</a>               
                                <div class="fw-semibold fs-7 text-gray-600 text-dark">{{ location(client) }}</div>
                            </div>
                            <div class="badge-group">
                                <div v-for="(child, index) in client.children" :key="child.id" class="badge text-white px-3" :style="badgeStyle(index)">
                                    {{ child.first_name }}
                                </div>
                            </div>
                        </div>
                        <div class="border border-gray-300 border-dashed rounded py-3 px-4">
                            <div class="d-flex align-items-center">
                                <i class="ki-duotone ki-lovely fs-3 me-2 text-gray-900 ">
                                    <i class="path1"></i>
                                    <i class="path2"></i>
                                </i>
                                <div class="fs-2 fw-bold counted">{{ groups(client) }}</div>
                            </div>
                            <div class="fw-semibold fs-6 text-gray-400">{{ $t('labels.activities') }}</div>
                        </div>
                    </div>
                </div>

            </div>

            <div v-if="0 === filtered.length && !shop.loading" class="col-md-12">
                <div class="alert alert-dismissible bg-danger d-flex flex-column flex-sm-row w-100 p-5 mb-10">
                    <div class="d-flex align-items-center">
                        <i class="ki-duotone ki-message-text-2 fs-2hx text-light me-4 mb-5 mb-sm-0"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                        <div class="text-light">{{ $t('labels.empty') }}</div>
                    </div>
                </div>
            </div>
        
        </div>

    <!--/LoadingOverlay-->

</template>

<style scoped>
    .badge-group .badge:not(:first-child) {
        margin-left: -15px;
    }

    .badge-group .badge {
        position: relative;
    }

    .badge-group .badge:hover {
        z-index: 9999;
    }
</style>

<script setup>
    import { products } from '@/api'
    import ToastType from '@/constants/toast-type'        
    import Route from '@/constants/route'
    import { useI18n } from '@/composables/i18n'
    import { toast } from '@/composables/toast'
    import { useAuthStore } from '@/store/auth'
    import { useShopStore } from '@/store/shop'

    const auth = useAuthStore()
    const store = useShopStore()
    const router = useRouter()
    const { t } = useI18n()

    const shop = inject('shop', null)    

    const clients = ref([])
    const taxed = ref(false)
    const search = ref()    
    const user = computed(() => auth.user)
    const updateRequired = computed(() => user.value?.children?.filter(child => child.update_required).length > 0)
    const hasChildren = computed(() => user.value?.children?.length > 0)
    const hasClients = computed(() => clients.value?.length > 0)

    const isLoading = computed(() => shop.loading.value)

    const colors = [
        '#9448fb',
        '#6295fc',
        '#e9c231',
        '#ff6384',
        '#36a2eb',
        '#ffce56',
        '#4bc0c0',
        '#9966ff',
        '#ff9f40'
    ]
    
    const filtered = computed(() => {
        const q = search.value?.toLowerCase() || ''

        return clients.value?.filter(client => {
            if (q) {
               let words= q.trim().split(" ");
               let uniquewords = [...new Set(words)];

               let searchStr=[];
               uniquewords.forEach(element => {
                    searchStr.push("\\b"+element);
                    searchStr.push(element+"\\b");
                    searchStr.push(element);
                });

                var regex = new RegExp( searchStr.join("|") , "gim");

                let result=[...client.name?.matchAll?.(regex)]



                return result.length >= uniquewords.length

            }

            return true
        })
    })

    const clearSearch=()=>{
        search.value='';
    };

    onMounted(() => {
        shop?.setStep(2)
        shop?.setLoading(true)

        auth.refresh().then(() => {
            products.index().then(response => {
                const { data } = response
    
                clients.value = data.data.clients
                taxed.value = data.data.taxed
            }).finally(() => {
                shop?.setLoading(false)
            })                
        }).catch(() => {
            shop?.setLoading(false)
        })
    })

    function select(client) {
        if (updateRequired.value) {
            toast(ToastType.ERROR, t('message.checkout-update-required'))
            return
        }

        store.setClient(client, taxed.value)
        router.push({ name: Route.SHOP_STEP_3 })
    }

    function location(client) {   
        if (client.city && client.province) {
            return `${client.city}, ${client.province}`
        } else if (client.province) {
            return client.province
        }

        return '-'
    }

    function imageStyle(client) {
        if (!client.attachment)
            return {}

        return { 'background-image': `url(${client.attachment.url})` }
    }

    function badgeStyle(index) {
        const color = colors[index % colors.length]

        return {
            'background-color': color
        }
    }

    function groups(client) {
        const activities = client.activities?.filter?.(activity => {
            if (activity.type !== 'extracurricular') {
                return false
            }

            const groups = activity.groups?.filter(group => group.public)

            return groups?.length > 0
        })

        return activities.length?.toLocaleString() || '0'
    }
</script>