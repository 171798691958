<template>

        <ul class="pagination">
            
            <li class="page-item previous" :class="1 === page ? 'disabled' : ''">
                <a href="#" class="page-link" @click.prevent="prev()">
                    <i class="previous"></i>
                </a>
            </li>

            <li v-for="(link, index) in links" :key="index" :class="classes(link)">
                <a href="#" class="page-link" @click.prevent="set(link)">{{ link }}</a>
            </li>

            <li class="page-item next" :class="page >= pages ? 'disabled' : ''">
                <a href="#" class="page-link" @click.prevent="next()">
                    <i class="next"></i>
                </a>
            </li>

        </ul>

</template>

<script setup>
    import { generate } from '@bramus/pagination-sequence'

    const emit = defineEmits(['update:modelValue'])

    const props = defineProps({
        onEachSide: { type: Number, default: 3 },
        modelValue: { type: Number, default: 1 },
        pages: { type: Number },
    })

    const onEachSide = toRef(props, 'onEachSide')
    const modelValue = toRef(props, 'modelValue')
    const pages = toRef(props, 'pages')

    const page = useVModel(props, 'modelValue', emit)
    const links = computed(() => pages.value > 1 ? generate(page.value, pages.value, 1, 1) : [])

    const set = (value) => page.value = value

    const classes = (link) => {
        const current = link == page.value

        return {
            'page-link': true,
            'active': current
        }
    }

    function prev() {
        page.value = Math.max(page.value - 1, 1)
    }
    
    function next() {
        page.value = Math.min(page.value + 1, pages.value)
    }
</script>
