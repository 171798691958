
<template>

    <div v-if="isParent" class="w-100 mb-5 mb-lg-10">

        <!--div id="kt_app_toolbar" class="app-toolbar  py-3 py-lg-6 ">
            <div id="kt_app_toolbar_container" class="app-container container-xxl d-flex flex-stack ">
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                    <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{{ $t('labels.children') }}</h1>
                    <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li class="breadcrumb-item text-muted">
                            <RouterLink :to="{ name: Route.HOME }" class="text-muted text-hover-primary">{{ $t('component.breadcrumbs.home') }}</RouterLink>
                        </li>                    
                        <li class="breadcrumb-item"><span class="bullet bg-gray-400 w-5px h-2px"></span></li>        
                        <li class="breadcrumb-item text-muted">{{ $t('labels.children') }}</li>
                    </ul>
                </div>
            </div>
        </div-->

        <!-- div id="kt_app_content_container" class="app-container container-xxl" -->

            <!-- ParentHeader/ -->

            <div class="d-flex flex-column flex-md-row align-items-md-center justify-content-between mb-7">
                <h3 class="text-dark me-6">{{ $t('labels.children') }}</h3>
                <div class="d-flex justify-content-end">
                    <div class="d-flex align-items-end mr-4">
                        <BaseButton @click.prevent="$refs.form?.create?.()">
                            <i class="ki-duotone ki-plus-circle me-1 fs-2"><i class="path1"></i><i class="path2"></i></i>{{ $t('labels.child-create') }}
                        </BaseButton>
                    </div>
                    <div>
                        <button class="btn btn-flex btn-primary px-6" @click.prevent="nextStep()" :disabled="!rows.length">
                            <i class="ki-duotone ki-double-right fs-4x">
                                <span class="path1"></span>
                                <span class="path2"></span>
                            </i>
                            <span class="d-flex flex-column align-items-start">
                                <span class="fs-1 fw-bold">{{ $t('steps.next-top') }}</span>
                                <span class="fs-5">{{ $t('steps.next-bottom') }}</span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>

            <div v-if="updateRequired" class="d-flex align-items-center gap-4 bg-warning border border-dashed border-dark rounded p-4 mb-6 de-wobble">
                <i class="ki-duotone ki-information flex-shrink-0 fs-4x text-dark">
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                </i>
                <div class="d-flex flex-col">
                    <h4 class="text-dark">{{ $t('labels.update-required') }}</h4>
                    <p class="text-dark fs-7 m-0">{{ $t('message.update-required') }}</p>
                    <p class="text-dark fs-7 m-0" v-html="problem"></p>
                </div>
            </div>

            <div class="row g-10">   
                
                <div v-for="(row, index) in rows" :key="row.id" class="col-md-4">

                    <div class="card w-100 d-flex flex-col">
                        <div class="card-body">
                            <div class="text-center mb-7">
                                <div class="badge text-white px-3 py-2 mb-7" :style="badgeStyle(index)">{{ row.first_name }} {{ row.last_name }}</div>
                                <h4>{{ row.client?.name }}</h4>
                                <h5 class="fs-7 text-muted">{{ location(row.client) }}</h5>
                            </div>
                            <div class="row g-12 mb-7">
                                <div class="col">
                                    <div class="border border-gray-300 border-dashed rounded w-full py-3 px-4">
                                        <div class="fs-4 fw-bold counted text-nowrap">
                                            <i class="ki-duotone ki-calendar fs-3 me-2"><i class="path1"></i><i class="path2"></i></i>{{ row.grade ? trans({ en: row.grade.name_en, fr: row.grade.name_fr }) : '' }}
                                        </div>
                                        <div class="fw-semibold fs-6 text-gray-400">{{ $t('labels.grade') }}</div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="border border-gray-300 border-dashed rounded w-full py-3 px-4">
                                        <div class="fs-4 fw-bold counted">
                                            <i class="ki-duotone ki-pulse fs-3 me-2"><i class="path1"></i><i class="path2"></i></i>{{ row.conditions?.length?.toLocaleString() || '0' }}
                                        </div>
                                        <div class="fw-semibold fs-6 text-gray-400">Conditions</div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between gap-2">
                                <div>
                                    <span v-if="row.update_required" class="badge badge-light-danger">{{ $t('labels.update-required-label') }}</span>
                                    <span v-if="!row.update_required" class="badge badge-light-success">{{ $t('labels.up-to-date') }}</span>
                                </div>
                                <div class="d-flex align-items-center gap-2">
                                    <BaseButton size="sm" @click.prevent="$refs.form?.edit?.(row.id)">
                                        <i class="ki-duotone ki-pencil fs-3"><i class="path1"></i><i class="path2"></i></i>
                                        <span>{{ $t('labels.edit') }}</span>
                                    </BaseButton>
                                    <Popconfirm @confirm="archive(row.id)">                                                                            
                                        <BaseButton size="sm" variant="secondary">
                                            <i class="ki-duotone ki-trash fs-3"><i class="path1"></i><i class="path2"></i><i class="path3"></i><i class="path4"></i><i class="path5"></i></i>
                                            <span>{{ $t('labels.archive') }}</span>
                                        </BaseButton>
                                    </Popconfirm>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                </div>

                <div v-if="!rows.length" class="col-md-12">
                    <div class="bg-warning border border-dashed border-dark d-flex align-items-center p-5 mb-7">
                        <i class="ki-duotone ki-shield-tick fs-2hx text-dark me-4"><span class="path1"></span><span class="path2"></span></i>
                        <div class="d-flex flex-column">
                            <h4 class="mb-1 text-dark">{{ $t('labels.notice') }}</h4>
                            <span>{{ $t('labels.child-warning') }}</span>
                        </div>
                    </div>
                </div>
            
            </div>

        <!-- /div -->

    </div>

    <BaseContainer v-if="!isParent">

        <header class="mb-6">
            
            <h1 class="text-gray-800 font-bold text-xl leading-none mb-2" v-text="$t('labels.children')"></h1>
        
            <Breadcrumbs :current="Route.CHILD_INDEX"/>
        
        </header>

        <DataTable
            v-model:page="page"
            id="children"
            :columns="columns"
            :rows="rows"
            :transform="transform"
            :to="to"
            :from="from"
            :total="total"
            :pages="pages"
            :loading="loading"
            :filter="filter"
            :archivable="true"
            @view="view"
            @edit="edit"
            @destroy="destroy">

            <template #actions>
                <BaseButton @click.prevent="create()"><i class="fa-duotone fa-circle-plus mr-2"></i>{{ $t('labels.child-create') }}</BaseButton>
            </template>

            <template v-if="isAdmin" #client="{ row, value }">
                <RouterLink v-if="row.client_id" class="text-primary" :to="{ name: Route.CLIENT_VIEW, params: { id: row.client_id } }">{{ value }}</RouterLink>
            </template>

            <template v-if="isAdmin" #grade="{ row, value }">
                {{ value }}
            </template>

            <template v-if="isAdmin" #user_name="{ row, value }">
                <RouterLink v-if="row.user_id" class="text-primary" :to="{ name: Route.PARENT_VIEW, params: { id: row.user_id } }">{{ value }}</RouterLink>
            </template>

            <template #update_required="{ row, value }">
                <i class="mr-2" :class="row.update_required ? 'fa-duotone fa-circle-exclamation text-danger' : 'fa-duotone fa-circle-check text-success'"></i>
                <span>{{ value }}</span>
            </template>

        </DataTable>

        <BaseDrawer :title="$t($refs.conditions?.mode === 'edit' ? 'labels.condition-edit' : 'labels.condition-create')">
            <ConditionForm ref="conditions"/>
        </BaseDrawer>

        <BaseDrawer :title="$t($refs.grades?.mode === 'edit' ? 'labels.grade-edit' : 'labels.grade-create')">
            <GradeForm ref="grades"/>
        </BaseDrawer>

    </BaseContainer>

    <BaseModal v-if="isParent" :title="$t($refs?.form?.mode === 'edit' ? 'labels.child-edit' : 'labels.child-create')" size="lg">
        <ChildStepperForm ref="form"/>
    </BaseModal>

</template>

<script setup>  
    import Route from '@/constants/route'
    import { useAuth } from '@/composables/auth'
    import { date } from '@/composables/dates'
    import { useBoolean, useI18n, useReturnTypes, useTrans } from '@/composables/i18n'
    import { useModelIndex } from '@/composables/model-ui'
    import { useCartStore } from '@/store/cart'
    import { useChildStore } from '@/store/model'    
    import { useShopStore } from '@/store/shop'
    import ToastType from '@/constants/toast-type'
    import { toast } from '@/composables/toast'
    import { children } from '@/api'
        
    const { t } = useI18n()
    const trans = useTrans()
    const cart = useCartStore()        
    const store = useChildStore()
    const router = useRouter()
    const { user, isAdmin, isParent } =  useAuth()
    
    const boolean = useBoolean().map(item => ({ value: item.id, text: item.text }))
    const returnTypes = useReturnTypes().map(item => ({ value: item.id, text: item.text }))

    const available = computed(() => cart.available)

    const shop = inject('shop', null)

    const updateRequired = computed(() => rows.value.filter(child => child.update_required).length > 0)
    
    const { rows, from, to, total, page, pages, loading, search, filter, destroy, list } = useModelIndex('child', store, {
        created: t('message.child-created'),
        updated: t('message.child-updated'),
        destroyed: t('message.child-destroyed')
    })

    const columns = computed(() => [
        { id: 'id', text: t('labels.id') },
        { id: 'update_required', text: t('labels.update-required'), options: boolean },
        { id: 'first_name', text: t('labels.name-first') },
        { id: 'last_name', text: t('labels.name-last') },
        { id: 'birthdate', text: t('labels.birthdate') },
        { id: 'client', text: t('labels.client') },
        { id: 'grade', text: t('labels.grade') },
        { id: 'bus_number', text: t('labels.bus-number') },
        { id: 'group', text: t('labels.group') },
        { id: 'return_type', text: t('labels.return-type'), options: returnTypes },
        { id: 'conditions', text: t('labels.conditions') },        
        { id: 'user_name', text: t('labels.parent'), condition: () => isAdmin.value },
        { id: 'educator', text: t('labels.educator') },
        { id: 'created_at', text: t('labels.created') },
        { id: 'updated_at', text: t('labels.updated') },
    ])

    const colors = [
        '#9448fb',
        '#6295fc',
        '#e9c231',
        '#ff6384',
        '#36a2eb',
        '#ffce56',
        '#4bc0c0',
        '#9966ff',
        '#ff9f40'
    ]

    const form = ref()
    const create = () => router.push({ name: Route.CHILD_CREATE })
    const edit = (id) => router.push({ name: Route.CHILD_EDIT, params: { id } })
    const view = (id) => router.push({ name: Route.CHILD_VIEW, params: { id } })

    const problem = computed(() => t(`instance.${parasco_template}.update_required`))

    onMounted(() => {
        if (isParent.value) {
            shop?.setStep(1)
            store.index({ perPage: -1 })
        }
    })

    function badgeStyle(index) {
        const color = colors[index % colors.length]

        return {
            'background-color': color
        }
    }

    function location(client) {   
        if (!client) {
            return '-'
        }
        
        if (client.city && client.province) {
            return `${client.city}, ${client.province}`
        } else if (client.province) {
            return client.province
        }

        return '-'
    }

    /**
     * Transform the specified row.
     *
     * @param {String} column
     * @param {Object} row
     * @return {String}
     */
    function transform (column, row) {
        console.log(column)

        switch (column) {
            case 'birthdate': return row.birthdate ? date(row.birthdate) : ''
            case 'return_type': return row.return_type ? t(`enum.return-type.${row.return_type}`) : ''
            case 'update_required': return row.update_required ? t('enum.boolean.true') : t('enum.boolean.false')
            case 'user_name': return row.user ? `${row.user.first_name} ${row.user.last_name}` : ''
            case 'grade': return row.grade ? trans({ en: row.grade.name_en, fr: row.grade.name_fr }) : ''
            case 'client': return row.client?.name
            case 'conditions': return row.conditions 
                ? list(row.conditions.filter(item => item).map(item => trans({ en: item.name_en, fr: item.name_fr }))) 
                : ''
        }

        return row[column]
    }

    function nextStep() {
        if (updateRequired.value) {
            toast(ToastType.ERROR, t('message.checkout-update-required'))
            return
        }

        router.push({ name: Route.SHOP_STEP_2 })
    }

    async function archive(id) {
        try {
            await children.archive(id, { archived: true })
            toast(ToastType.SUCCESS, t('message.child-archived'))
        } catch (error) {

        } finally {
            await store.refresh()
            await useAuth().store.refresh()
        }
    }
</script>
