<template>
    <div class="container mx-auto" :class="{'container-admin' : isAdmin}">
        <slot/>
    </div>
</template>

<script setup>
    import { useAuth } from '@/composables/auth'    

    const { isAdmin } =  useAuth()
</script>