<template>
  <!--begin::Wrapper-->
  <div class="w-lg-500px">
    <!--begin::Form-->
    <BaseForm ref="form" @submitted="handle()" @success="resolved()">
      <LoadingOverlay :loading="loading">
        <!--begin::Heading-->
        <div class="text-center mb-10">
          <!--begin::Title-->
          <h1 class="text-dark mb-3">{{ $t('labels.password-reset') }}</h1>
          <!--end::Title-->

          <!--begin::Link-->
          <div class="text-gray-400 fw-semobold fs-4">
            {{ $t('labels.password-reset-intro') }}
          </div>
          <!--end::Link-->
        </div>
        <!--begin::Heading-->

        <!--begin::Input group-->
        <div class="fv-row mb-10">
          <!--begin::Input-->
          <BaseInputGroup :label="$t('labels.email')" :error="form?.error('email')">
              <BaseInput v-model="email"/>
          </BaseInputGroup>
          <!--end::Input-->
        </div>
        <!--end::Input group-->

        <!--begin::Actions-->
        <div class="text-center d-flex">
          <!--begin::Submit button-->
          <button
            tabindex="3"
            type="submit"
            ref="submitButton"
            id="kt_sign_in_submit"
            class="btn btn-lg btn-primary mb-5 me-5 w-100"
          >
            <span class="indicator-label">{{ $t('labels.password-reset') }}</span>

            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
          <!--end::Submit button-->

          <!--begin::Submit button-->
          <button
            tabindex="3"
            type=""
            ref="submitButton"
            id="kt_sign_in_submit"
            class="btn btn-lg btn-secondary mb-5 w-100"
            @click.prevent="$router.push({ name: Route.LOGIN })"
          >
            <span class="indicator-label">{{ $t('labels.back') }}</span>

            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
          <!--end::Submit button-->
        </div>
        <!--end::Actions-->
      </LoadingOverlay>
    </BaseForm>
    <!--end::Form-->
  </div>
  <!--end::Wrapper-->
</template>

<script setup>
    import { password } from '@/api'
    import { toast } from '@/composables/toast'
    import Route from '@/constants/route'
    import ToastType from '@/constants/toast-type'
    import { useAuthStore } from '@/store/auth'

    const authStore = useAuthStore()
    const router = useRouter()
    const form = ref()
    const email = ref()
    const loading = ref(false)

    /**
     * Handle the password reset request.
     * 
     * @return {void}
     */
    async function handle () {
        loading.value = true

        try {
            const response = await password.reset(unref(email))

            form.value?.resolve(response)

            toast(ToastType.SUCCESS, response.data.message)            
        } catch (error) {
            loading.value = false
            form.value?.reject(error)
        }
    }

    function resolved() {
        router.push({ name: Route.LOGIN })
    }
</script>
