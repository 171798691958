<template>

    <BaseForm ref="form" @submitted="submit()">

        <LoadingOverlay :loading="loading">

            <TabGroup class="mb-6">

                <TabList class="-mt-6 -mx-6 mb-6"/>

                <div class="flex flex-col lg:flex-row gap-6">

                    <div class="flex-1">

                        <TabPanel :title="$t('labels.general')">

                            <div class="grid gap-6">

                                <div class="grid gap-6 md:grid-cols-3">

                                    <BaseInputGroup :label="$t('labels.name')" :error="form?.error('name')">
                                        <BaseInput v-model="payload.name"/>
                                    </BaseInputGroup>

                                    <BaseInputGroup :label="$t('labels.type')" :error="form?.error('type')">
                                        <BaseListbox v-model="payload.type" :searchable="false" :options="clientTypes"/>
                                    </BaseInputGroup>

                                    <BaseInputGroup :label="$t('labels.status')"  :error="form?.error('status')">
                                        <BaseListbox v-model="payload.status" :searchable="false" :options="statuses"/>
                                    </BaseInputGroup>

                                </div>

                                <div class="grid gap-6 md:grid-cols-2">

                                    <BaseInputGroup :label="$t('labels.category')" :error="form?.error('categories')">
                                        <CategoryListbox v-model="payload.categories"/>
                                    </BaseInputGroup>

                                    <BaseInputGroup :label="$t('labels.language')" :error="form?.error('language')">
                                        <BaseListbox v-model="payload.language" :searchable="false" :options="languages"/>
                                    </BaseInputGroup>

                                </div>

                                <div class="grid gap-6 md:grid-cols-2">

                                    <BaseInputGroup :label="$t('labels.sector')" :error="form?.error('sector_id')">
                                        <SectorListbox ref="sector" v-model="payload.sector_id" :creatable="true" :create="() => $refs.sectors.create()"/>
                                    </BaseInputGroup>

                                    <BaseInputGroup :label="$t('enum.client-type.schoolboard')" :error="form?.error('schoolboards')">
                                        <CategoryListbox v-model="payload.schoolboards"/>
                                    </BaseInputGroup>

                                </div>

                                <div class="grid gap-6 md:grid-cols-2">

                                    <BaseInputGroup :label="$t('labels.email')" :error="form?.error('email')">
                                        <BaseInput v-model="payload.email"/>
                                    </BaseInputGroup>

                                    <BaseInputGroup :label="$t('labels.phone')" :error="form?.error('phone')">
                                        <BaseInput v-model="payload.phone" v-maska="MASK_PHONE" placeholder="(###) ###-#### x ######"/>
                                    </BaseInputGroup>

                                </div>

                                <BaseInputGroup :label="$t('labels.payments-enable')" :error="form?.error('payments_enable')">
                                    <BaseToggle v-model="payload.payments_enable">{{ $t('enum.boolean.true') }}</BaseToggle>
                                </BaseInputGroup>

                            </div>

                        </TabPanel>

                        <TabPanel :title="$t('labels.address')">

                            <div class="grid gap-6">

                                <BaseInputGroup :label="$t('labels.address-line1')" :error="form?.error('address1')">
                                    <BaseInput v-model="payload.address1"/>
                                </BaseInputGroup>

                                <BaseInputGroup :label="$t('labels.address-line2')" :error="form?.error('address2')">
                                    <BaseInput v-model="payload.address2"/>
                                </BaseInputGroup>

                                <div class="grid gap-6 md:grid-cols-2">

                                    <BaseInputGroup :label="$t('labels.address-city')" :error="form?.error('city')">
                                        <BaseInput v-model="payload.city"/>
                                    </BaseInputGroup>

                                    <BaseInputGroup :label="$t('labels.address-region')" :error="form?.error('region')">
                                        <BaseInput v-model="payload.province"/>
                                    </BaseInputGroup>

                                    <BaseInputGroup :label="$t('labels.address-postal')" :error="form?.error('postal_code')">
                                        <BaseInput v-model="payload.postal_code"/>
                                    </BaseInputGroup>

                                    <BaseInputGroup :label="$t('labels.address-country')" :error="form?.error('country')">
                                        <BaseInput v-model="payload.country"/>
                                    </BaseInputGroup>

                                </div>

                            </div>

                        </TabPanel>

                        <TabPanel :title="$t('labels.contacts')">

                            <Repeater
                                v-model="contacts"
                                :create="$t('labels.contact-create')"
                                :empty="$t('labels.empty')"
                                :title="$t('labels.contacts')">

                                <template #default="{ index, item }">

                                    <div class="flex flex-1 items-center gap-6">

                                        <BaseInputGroup>
                                            <BaseToggle v-model="item.is_primary">{{ $t('labels.primary') }}</BaseToggle>
                                        </BaseInputGroup>

                                        <BaseInputGroup class="flex-1" :error="form?.error(`contacts.${index}.name`)">
                                            <BaseInput v-model="item.name" :placeholder="$t('labels.name')"/>
                                        </BaseInputGroup>

                                        <BaseInputGroup class="flex-1" :error="form?.error(`contacts.${index}.email`)">
                                            <BaseInput v-model="item.email" :placeholder="$t('labels.email')"/>
                                        </BaseInputGroup>

                                        <BaseInputGroup class="flex-1" :error="form?.error(`contacts.${index}.phone`)">
                                            <BaseInput v-model="item.phone" :placeholder="$t('labels.phone')" v-maska="MASK_PHONE" placeholder="(###) ###-#### x ######"/>
                                        </BaseInputGroup>

                                        <BaseInputGroup class="flex-1" :error="form?.error(`contacts.${index}.role`)">
                                            <BaseListbox v-model="item.role" :searchable="false" :options="roles"/>
                                        </BaseInputGroup>

                                    </div>

                                </template>

                            </Repeater>

                        </TabPanel>

                        <TabPanel :title="$t('labels.taxes')">

                            <Repeater
                                v-model="payload.tax_rates"
                                :create="$t('labels.taxrate-add')"
                                :empty="$t('labels.taxrate-empty')"
                                :title="$t('labels.taxrates')"
                                :row="true">

                                <template #item="{ index, item, remove }">

                                    <div class="tax-rate col-md-4">

                                        <BaseCard>

                                                <BaseInputGroup :label="$t('labels.taxrate')" :error="form?.error(`tax_rates.${index}.id`)">
                                                    <TaxRateListbox v-model="item.id" :creatable="true" :create="() => $refs.tax_rates.create()" @select="option => onTaxRateChanged(item, option)"/>
                                                </BaseInputGroup>

                                            <div class="d-flex justify-content-end gap-6 mt-8">
                                                <BaseButton @click.prevent="remove()">{{ $t('labels.delete') }}</BaseButton>
                                            </div>

                                        </BaseCard>

                                    </div>

                                </template>

                            </Repeater>

                        </TabPanel>

                        <TabPanel :title="$t('labels.notes')">

                            <div class="grid gap-6">

                                <BaseInputGroup :label="$t('labels.notes')" :error="form?.error('notes')">
                                    <Tiptap v-model="payload.notes"/>
                                </BaseInputGroup>

                                <BaseInputGroup :label="$t('labels.procedures')" :error="form?.error('procedures')">
                                    <Tiptap v-model="payload.procedures"/>
                                </BaseInputGroup>

                            </div>

                        </TabPanel>

                    </div>

                    <div class="grow-0 shrink-0 lg:w-1/4">
                        <h4 class="mb-4 text-sm font-semibold">{{ $t('labels.featured-media') }}</h4>
                        <MediaLibrary ref="media" v-model="payload.attachment_id"/>
                    </div>

                </div>

            </TabGroup>

            <BaseButton :disabled="submitting" :loading="submitting">
                {{ $t(action) }}
            </BaseButton>

        </LoadingOverlay>

        <BaseDrawer :title="$t('labels.sector-create')">
            <SectorForm ref="sectors"/>
        </BaseDrawer>

        <BaseDrawer :title="$t('labels.taxrate-create')">
            <TaxRateForm ref="tax_rates"/>
        </BaseDrawer>

    </BaseForm>

</template>

<script setup>
    import ToastType from '@/constants/toast-type'
    import { MASK_PHONE } from '@/constants/mask'
    import { useClientTypes, useContactRoles, useLanguages, useI18n } from '@/composables/i18n'
    import { useModelForm } from '@/composables/model-ui'
    import { useClientStore, useTaxRateStore } from '@/store/model'
    import { toast } from '@/composables/toast'

    const { t } = useI18n()
    const store = useClientStore()
    const clientTypes = useClientTypes()
    const roles = useContactRoles()
    const languages = useLanguages()
    const taxRateStore = useTaxRateStore()

    const statuses = computed(() => ([
        { id: 'public', text: t('enum.client-status.public') },
        { id: 'private', text: t('enum.client-status.private') },
    ]))

    const { form, id, payload, loading, submitting, create, edit, submit, mode } = useModelForm({
        store: store,
        messages: {
            created: t('message.client-created'),
            updated: t('message.client-updated'),
            destroyed: t('message.client-destroyed')
        },
        editing: (data) => {
            console.log(payload)

            if (payload.value.categories) {
                payload.value.categories = payload.value.categories.map(item => item.id)
            }
            if (payload.value.schoolboards) {
                payload.value.schoolboards = payload.value.schoolboards.map(item => item.id)
            }
            if (payload.value.taxrates) {
                payload.value.taxrates = payload.value.taxrates.map(item => item.id)
            }
        }
    })

    const sector = ref()
    const sectors = ref()
    const tax_rates = ref()

    const contacts = computed({
        get: () => payload.value?.contacts,
        set: (value) => { payload.value.contacts = value }
    })

    const primaryContacts = computed(() => contacts.value?.map(contact => contact.is_primary))
    const action = computed(() => id.value  ? 'labels.submit-client-edit' : 'labels.submit-client-create')

    defineExpose({ mode, create, edit })

    watch(() => primaryContacts.value, (newValue, oldValue) => {
        newValue?.forEach((primary, i) => {
            if (primary === oldValue?.[i]) {
                return
            }

            if (primary) {
                contacts.value.forEach((contact, j) => { contact.is_primary = i === j })
            }
        })
    })

    function onTaxRateChanged(item, option) {
        const matching = payload.value?.tax_rates?.filter?.(tax_rate => tax_rate.id == option.value)

        if (matching?.length > 1) {
            item.id = null
            toast(ToastType.ERROR, t('message.tax-rate-already-present'))
        }
    }
</script>
