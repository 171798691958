<template>

    <div class="relative max-w-full">

        <div ref="top" class="block overflow-x-auto max-w-full scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-300" @scroll.passive="handleScroll('top')">
            <div :style="scrollStyle"></div>
        </div>

        <div ref="wrapper" class="block overflow-x-hidden">
            <table ref="table" class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">

                <thead v-if="$slots.header" class="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                    <slot name="header"/>
                </thead>

                <tbody class="text-gray-600 fw-semibold">
                    <slot/>
                </tbody>

                <tfoot v-if="$slots.footer">
                    <slot name="footer"/>
                </tfoot>

            </table>
        </div>

        <div ref="bottom" class="block overflow-x-auto max-w-full scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-300" @scroll.passive="handleScroll('bottom')">
            <div :style="scrollStyle"></div>
        </div>

    </div>

</template>

<script setup>
    const table = ref()
    const wrapper = ref()
    const top = ref()
    const bottom = ref()
    
    // Synchronize scrollbar width with the table wdth.
    const { width, height } = useElementSize(table)

    const scrollStyle = computed(() => ({
        height: '1px',
        width: `${width.value}px`
    }))

    /**
     * Synchronize element scrolling.
     * 
     * @param {string} source
     * @return {void}
     */
    function handleScroll(source) {
        switch (source) {
            case 'top':
                wrapper.value.scrollLeft = top.value.scrollLeft
                bottom.value.scrollLeft = top.value.scrollLeft
                break

            case 'bottom':
                wrapper.value.scrollLeft = bottom.value.scrollLeft
                top.value.scrollLeft = bottom.value.scrollLeft
                break

            default:
                console.warn(`Invalid source: ${source}`)
        }        
    }
</script>