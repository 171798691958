<template>

    <BaseContainer>

        <header class="mb-6">

            <div class="flex flex-col md:flex-row md:items-center md:justify-between gap-6">

                <div class="block">
                    <h1 class="text-gray-800 font-bold text-xl leading-none mb-2" v-text="$t('labels.employees')"></h1>
                    <Breadcrumbs :current="Route.EMPLOYEE_INDEX"/>
                </div>

                <div class="block">
                    <BaseButton @click.prevent="$refs.timesheetModal.open()">{{ $t('labels.timesheet') }}</BaseButton>
                </div>

            </div>

        </header>

        <DataTable
            v-model:page="page"
            id="employees"
            :archivable="true"
            :columns="columns"
            :rows="rows"
            :transform="transform"
            :to="to"
            :from="from"
            :total="total"
            :pages="pages"
            :loading="loading"
            :filter="filter"
            @view="view"
            @edit="edit"
            @destroy="destroy">

            <template #actionsAppend="{ row }">
                <button
                    class="flex items-center justify-center w-8 h-8 hover:bg-action-edit text-action-edit hover:text-white focus:ring-2 focus:ring-offset-2 focus:ring-action-edit focus:outline-none"
                    @click.prevent="impersonate(row.id)">
                    <i class="fa-solid fa-play"></i>
                </button>
            </template>

            <template #actions>
                <BaseButton @click.prevent="create()"><i class="fa-duotone fa-circle-plus mr-2"></i>{{ $t('labels.employee-create') }}</BaseButton>
            </template>

        </DataTable>

    </BaseContainer>

    <TimesheetModal ref="timesheetModal" role="employee"/>

</template>

<script setup>
    import Route from '@/constants/route'
    import { useImpersonate } from '@/composables/impersonate'
    import { useI18n, useTrans } from '@/composables/i18n'
    import { useModelIndex } from '@/composables/model-ui'
    import { useEmployeeStore } from '@/store/model'

    const { t } = useI18n()
    const trans = useTrans()
    const store = useEmployeeStore()
    const router = useRouter()
    const { impersonate } = useImpersonate()

    const { rows, from, to, total, page, pages, loading, search, filter, destroy } = useModelIndex('employee', store, {
        created: t('message.employee-created'),
        updated: t('message.employee-updated'),
        destroyed: t('message.employee-destroyed')
    })

    const columns = computed(() => [
        { id: 'id', text: t('labels.id') },
        { id: 'first_name', text: t('labels.name-first') },
        { id: 'last_name', text: t('labels.name-last') },
        { id: 'email', text: t('labels.email') },
        { id: 'phone1', text: t('labels.phone1') },
        { id: 'phone2', text: t('labels.phone2') },
        { id: 'phone3', text: t('labels.phone3') },
        { id: 'has_car', text: t('labels.has-car') },
        { id: 'sector', text: t('labels.sector') },
        { id: 'created_at', text: t('labels.created') },
        { id: 'updated_at', text: t('labels.updated') },
    ])
    
    const form = ref()
    const create = () => router.push({ name: Route.EMPLOYEE_CREATE })
    const edit = (id) => router.push({ name: Route.EMPLOYEE_EDIT, params: { id } })
    const view = (id) => router.push({ name: Route.EMPLOYEE_VIEW, params: { id } })

    /**
     * Transform the specified row.
     *
     * @param {String} column
     * @param {Object} row
     * @return {String}
     */
    function transform (column, row) {
        switch (column) {
            case 'role': return row.role ? t(`enum.role.${row.role}`) : ''
            case 'has_car': return row.has_car ? t('enum.boolean.true') : t('enum.boolean.false')
            case 'sector': return row.sector?.name || ''
        }

        return row[column]
    }
</script>
