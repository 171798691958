import Store from '@/constants/store'

export const useShopStore = defineStore(Store.SHOP, {
    state: () => ({
        activity: undefined,
        children: [],
        client: undefined,
        groups: undefined,
        options: [],
        step: 1,
        taxed: false
    }),
    
    getters: {

    },

    actions: {
        setActivity(activity) {
            this.activity = activity
            this.children = []
            this.options = []
            this.groups = []                        
        },


        setChildren(children) {
            this.children = children
        },

        setClient(client, taxed = false) {
            this.client = client
            this.activity = undefined
            this.children = []
            this.options = []
            this.groups = []
            this.taxed = taxed
        },

        setOptions(options) {
            this.options = options
        },

        setGroups(groups) {
            this.groups = groups
        },

        setStep(value) {            
            this.step = value
        }        
    }
})
