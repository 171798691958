import Route from '@/constants/route'
import ToastType from '@/constants/toast-type'
import { toast } from '@/composables/toast'
import { useAuthStore } from '@/store/auth'
import { useRouter } from 'vue-router'
import { useCartStore } from '@/store/cart'

export function useImpersonate() {
    const store = useAuthStore()
    const router = useRouter()
    const route = useRoute()
    const cartStore = useCartStore()

    const impersonate = async (id) => {
        try {
            await store.impersonate(id, route)
            await cartStore.refresh()

            router.push({ name: Route.HOME })

            toast(ToastType.SUCCESS, `Currently impersonating user.`)
        } catch (e) {
            toast(ToastType.ERROR, 'Error impersonating user.')
        }
    }

    return { impersonate }
}
