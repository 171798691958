<template>
    
    <component :is="component" :class="classes">

        <i v-if="loading" class="fa-duotone fa-spinner fa-spin text-white mr-3"></i>

        <slot/>

    </component>

</template>

<script setup>
    const props = defineProps({
        disabled: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
        filled: { type: Boolean, default: true },
        outlined: { type: Boolean, default: false },
        variant: { type: String, default: 'primary' },
        modifier: { type: String, default: 'filled' },
        size: { type: String, default: 'md' }
    })

    const attrs = useAttrs()

    const disabled = toRef(props, 'disabled')
    const modifier = toRef(props, 'modifier')
    const variant = toRef(props, 'variant')
    const size = toRef(props, 'size')

    const component = computed(() => attrs?.href ? 'a' : 'button')

    const classes = computed(() => ({
        'btn': true,
        'btn-sm': 'sm' === size.value,
        'btn-lg': 'lg' === size.value,
        'btn-primary': 'filled' === modifier.value && 'primary' === variant.value,
        'btn-light-primary': 'light' === modifier.value && 'primary' === variant.value,
        'btn-secondary': 'filled' === modifier.value && 'secondary' === variant.value,
        'btn-light-secondary': 'light' === modifier.value && 'secondary' === variant.value,
        'disabled': disabled.value
    }))
</script>
