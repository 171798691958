<template>
  <!--begin::Authentication Layout -->
  <div class="d-flex flex-column flex-lg-row flex-column-fluid">
    <!--begin::Body-->
    <div
      class="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1 position-relative"
    >


  <div class="position-absolute menu-language pr-10">
    <div class="d-flex">

      <BaseDropdown>

          <template v-slot:activator="{ active }">
            <button class="btn btn-icon btn-custom w-30px h-30px w-md-40px h-md-40px position-relative" :class="active ? 'bg-primary text-white' : 'text-dark'">
                    <i class="ki-duotone ki-flag fs-1">
                        <span class="path1"></span>
                        <span class="path2"></span>
                    </i>
                    
                    <span class="position-absolute bottom-[2px] right-[10px] fs-9">
                        <span class="text-uppercase">{{ locale }}</span>
                    </span>
              </button>
          </template>

          <div class="bg-white shadow-lg md:w-48 overflow-hidden border-t-4 border-primary">
              
              <ul class="pl-0">

                  <li class="flex items-center justify-between px-4 py-2 font-medium text-gray-600 hover:bg-light hover:text-primary cursor-pointer" @click.prevent="setLocale('en')">
                      <span>{{ $t('enum.locale.en') }}</span>
                      <i v-show="isLocale('en')" class="fa-solid fa-circle-check text-sm text-success ml-3"></i>
                  </li>

                  <li class="flex items-center justify-between px-4 py-2 font-medium text-gray-600 hover:bg-light hover:text-primary cursor-pointer" @click.prevent="setLocale('fr')">
                      <span>{{ $t('enum.locale.fr') }}</span>
                      <i v-show="isLocale('fr')" class="fa-solid fa-circle-check text-sm text-success ml-3"></i>
                  </li>

              </ul>

          </div>

      </BaseDropdown>

    </div>
  </div>

      <!--begin::Form-->
      <div class="d-flex flex-center flex-column flex-lg-row-fluid">
        <!--begin::Wrapper-->
        <div class="p-10">
          <router-view></router-view>
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Form-->

      <!--begin::Footer-->
      <div class="d-flex flex-center flex-wrap px-5">
        <!--begin::Links-->
        <div class="d-flex fw-semibold text-primary fs-base">
          <a href="#" class="px-5" target="_blank">{{ $t('labels.terms') }}</a>

          <a href="#" class="px-5" target="_blank">{{ $t('labels.contact-us') }}</a>
        </div>
        <!--end::Links-->
      </div>
      <!--end::Footer-->
    </div>
    <!--end::Body-->

    <!--begin::Aside-->
    <div
      class="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2 auth-bg"
    >
      <!--begin::Content-->
      <div
        class="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100"
      >
        <!--begin::Logo-->
        <router-link to="/" class="mb-0 mb-lg-12">
          <img
            alt="Logo"
            :src="'/img/' + parasco_template + '/logo.png'"
            :class="'h-60px h-lg-90px ' + parasco_template"
          />
        </router-link>
        <!--end::Logo-->

        <!--begin::Image-->
        <img
          class="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20"
          src="/img/isolated-kids.png"
          alt=""
        />
        <!--end::Image-->

        <!--begin::Title-->
        <h1 :class="'d-none text-white fs-2qx fw-bolder text-center mb-7' + ((isLocale('en')) ? ' d-lg-block' : '')">
          Welcome {{ parascoNameEn }}!
        </h1>
        <h1 :class="'d-none text-white fs-2qx fw-bolder text-center mb-7' + ((isLocale('fr')) ? ' d-lg-block' : '')">
          Bienvenue {{ parascoNameFr }}!
        </h1>
        <!--end::Title-->

        <!--begin::Text-->
        <div :class="'intro-text d-none text-white fs-base' + ((isLocale('en')) ? ' d-lg-block' : '')">
          <div>Please log in to access your online account and register your child for an extracurricular activity.</div>
          <div>
            <div><strong>How to register</strong></div>
            <ul class="steps">
              <li>Create or update your child’s account.</li>
              <li>View the activities and select your child’s activity.</li>
              <li>Confirm registration and proceed to payment (you will receive a registration confirmation email and a payment confirmation email).</li>
            </ul>
          </div>
          <div>
            <div v-if="parascoLegacyLink2">
              <div>To access your previous account and get precedent years receipts (legacy platform). Click on one of the following links:</div>
              <ul>
                <li><a class="opacity-75-hover text-warning fw-bold" :href="parascoLegacyLink1">{{ parascoLegacyLink1 }}</a></li>
                <li><a class="opacity-75-hover text-warning fw-bold" :href="parascoLegacyLink2">{{ parascoLegacyLink2 }}</a></li>
              </ul>
            </div>
            <div v-else>To access your previous account and get precedent years receipts (legacy platform). Click on the following link: <a class="opacity-75-hover text-warning fw-bold" :href="parascoLegacyLink1">{{ parascoLegacyLink1 }}</a></div>
          </div>
        </div>
        <div :class="'intro-text d-none text-white fs-base' + ((isLocale('fr')) ? ' d-lg-block' : '')">
          <div>Veuillez vous connecter pour accéder à votre nouveau compte en ligne et procéder à l’inscription de votre enfant à une activité parascolaire.</div>
          <div>
            <div><strong>Comment s'inscrire</strong></div>
            <ul class="steps">
              <li>Créer le compte de votre enfant ou le mettre à jour.</li>
              <li>Consultez les activités et sélectionnez celle de votre enfant.</li>
              <li>Confirmez l'inscription et procédez au paiement (vous recevrez un courriel de confirmation d'inscription et un courriel de confirmation de paiement).</li>
            </ul>
          </div>
          <div>
            <div v-if="parascoLegacyLink2">
              <div>Pour accéder à votre ancien compte et vos reçus des années précédentes,(ancienne plateforme). Veuillez cliquer sur un des liens suivants:</div>
              <ul>
                <li><a class="opacity-75-hover text-warning fw-bold" :href="parascoLegacyLink1">{{ parascoLegacyLink1 }}</a></li>
                <li><a class="opacity-75-hover text-warning fw-bold" :href="parascoLegacyLink2">{{ parascoLegacyLink2 }}</a></li>
              </ul>
            </div>
            <div v-else>Pour accéder à votre ancien compte et vos reçus des années précédentes,(ancienne plateforme). Veuillez cliquer sur le lien suivant: <a class="opacity-75-hover text-warning fw-bold" :href="parascoLegacyLink1">{{ parascoLegacyLink1 }}</a></div>
          </div>
        </div>
        <!--end::Text-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Aside-->
  </div>
  <!--end::Authentication Layout -->
</template>

<script lang="ts" setup>
    import { useI18n, useLocale } from '@/composables/i18n'
    import { useMetronic } from '@/composables/keenthemes'
    import Route from '@/constants/route'    
    import { getAssetPath } from "@/core/helpers/assets"
    import LayoutService from '@/core/services/LayoutService'
    import { useBodyStore } from '@/stores/body'      

    const { locale } = useI18n()
    const { isLocale, setLocale } = useLocale()
    const store = useBodyStore()

    const parascoNameFr = ref()
    const parascoNameEn = ref()
    const parascoLegacyLink1 = ref()
    const parascoLegacyLink2 = ref()

    parascoNameEn.value = parasco_name_en
    parascoNameFr.value = parasco_name_fr
    parascoLegacyLink1.value = parasco_legacy_link_1
    parascoLegacyLink2.value = parasco_legacy_link_2

    useMetronic()
</script>