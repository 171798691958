<template>

    <div class="block">
            
        <div class="flex flex-row items-stretch border border-gray-300">

            <ul v-if="editor" class="flex flex-col items-center divide-y divide-gray-300 border-r border-gray-300 list-unstyled m-0">

                <li class="flex flex-col">

                    <button :class="classes('bold')" @click.prevent="command('bold')">
                        <i class="fa-duotone fa-bold"></i>
                    </button>

                    <button :class="classes('italic')" @click.prevent="command('italic')">
                        <i class="fa-duotone fa-italic"></i>
                    </button>

                </li>

                <li class="flex flex-col">

                    <button :class="classes('h1')" @click.prevent="command('h1')">
                        <i class="fa-duotone fa-h1"></i>
                    </button>

                    <button :class="classes('h2')" @click.prevent="command('h2')">
                        <i class="fa-duotone fa-h2"></i>
                    </button>

                    <button :class="classes('h3')" @click.prevent="command('h3')">
                        <i class="fa-duotone fa-h3"></i>
                    </button>

                </li>

                <li class="flex flex-col">

                    <button :class="classes('align-left')" @click.prevent="command('align-left')">
                        <i class="fa-duotone fa-align-left"></i>
                    </button>
                    <button :class="classes('align-center')" @click.prevent="command('align-center')">
                        <i class="fa-duotone fa-align-center"></i>
                    </button>

                    <button :class="classes('align-right')" @click.prevent="command('align-right')">
                        <i class="fa-duotone fa-align-right"></i>
                    </button>

                    <button :class="classes('align-justify')" @click.prevent="command('align-justify')">
                        <i class="fa-duotone fa-align-justify"></i>
                    </button>

                </li>

                <li v-if="enableImages" class="flex flex-col">
                    <button :class="classes('image')" @click.prevent="$refs.mediaLibrary?.open?.()">
                        <i class="fa-duotone fa-image"></i>
                    </button>
                </li>

            </ul>

            <div class="grow max-h-[28rem] prose prose-sm w-full max-w-none relative" @click.prevent="focus()">
                <div class="absolute inset-0 scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-neutral-300 scrollbar-track-neutral-200 overflow-y-auto  p-3">
                    <EditorContent :editor="editor"/>
                </div>
            </div>

        </div>

        <MediaLibrary
            v-if="enableImages"
            ref="mediaLibrary"
            :standalone="true"
            @select="onSelectImage"/>

    </div>

</template>

<script setup>
    import { useEditor, Editor, EditorContent } from '@tiptap/vue-3'
    import Bold from '@tiptap/extension-bold'
    import Italic from '@tiptap/extension-italic'
    import Document from '@tiptap/extension-document'
    import Paragraph from '@tiptap/extension-paragraph'
    import Text from '@tiptap/extension-text'
    import Heading from '@tiptap/extension-heading'
    import BulletList from '@tiptap/extension-bullet-list'
    import ListItem from '@tiptap/extension-list-item'
    import TextAlign from '@tiptap/extension-text-align'
    import Link from '@tiptap/extension-link'
    import Image from '@tiptap/extension-image'

    const emit = defineEmits(['update:modelValue'])

    const props = defineProps({
        enableImages: { type: Boolean, default: false },
        modelValue: { type: String, default: '' },
    })

    const modelValue = toRef(props, 'modelValue')

    const model = computed({
        get() { return modelValue.value },
        set(value) { emit('update:modelValue', value) },
    })

    const mediaLibrary = ref(null)

    const editor = new Editor({
        onUpdate: () => model.value = editor.getHTML(),
        content: model.value,
        editorProps: {
            attributes: { class: 'focus:outline-none' }
        },
        extensions: [
            Bold,
            BulletList,
            Document,
            Heading.configure({ levels: [1, 2, 3] }),
            Italic,
            ListItem,
            Paragraph,
            Text,
            TextAlign.configure({ types: ['heading', 'paragraph'] }),
            Link,
            Image,
        ],
    })

    watch(model, (value) => {
        if (value !== editor.getHTML()) {
            editor.commands.setContent(value, false)
        }
    })

    onBeforeUnmount(() => editor.destroy())

    const classes = (name) => {
        const state = active(name)

        return {
            'flex items-center justify-center w-10 h-10 text-xs': true,
            'bg-gray-900 text-white': state,
            'ease-expo transition-all duration-100': true,
        }
    }

    const active = (name) => {
        switch (name) {
            case 'align-left': return editor.isActive({ textAlign: 'left' })
            case 'align-center':  return editor.isActive({ textAlign: 'center' })
            case 'align-right': return editor.isActive({ textAlign: 'right' })
            case 'align-justify': return editor.isActive({ textAlign: 'justify' })
            case 'bold': return editor.isActive('bold')
            case 'h1': return editor.isActive('heading', { level: 1 })
            case 'h2': return editor.isActive('heading', { level: 2 })
            case 'h3': return editor.isActive('heading', { level: 3 })
            case 'italic': return editor.isActive('italic')
        }
    }

    const command = (name) => {
        const chain = editor.chain().focus()

        switch (name) {
            case 'align-left':
                active('align-left') ? chain.unsetTextAlign().run() : chain.setTextAlign('left').run()
                break

            case 'align-center':
                active('align-center') ? chain.unsetTextAlign().run() : chain.setTextAlign('center').run()
                break

            case 'align-right':
                active('align-right') ? chain.unsetTextAlign().run() : chain.setTextAlign('right').run()
                break

            case 'align-justify':
                active('align-justify') ? chain.unsetTextAlign().run() : chain.setTextAlign('justify').run()
                break

            case 'bold':
                chain.toggleBold().run()
                break

            case 'h1':
                chain.toggleHeading({ level: 1 }).run()
                break

            case 'h2':
                chain.toggleHeading({ level: 2 }).run()
                break

            case 'h3':
                chain.toggleHeading({ level: 3 }).run()
                break

            case 'italic':
                chain.toggleItalic().run()
                break
        }
    }

    const focus = () => {
        editor.chain().focus().run()
    }

    const onSelectImage = (attachment) => {
        editor.chain().focus().setImage({ src: attachment.url }).run()        
        mediaLibrary.value.close()
    }
</script>
