<template>

    <div>

        <label class="relative inline-flex items-center group">

            <input type="checkbox" class="absolute left-0 top-0 w-full h-full peer appearance-none z-10 cursor-pointer"
                v-model="model"
                :true-value="valueTrue"
                :false-value="valueFalse"
                :value="value">
                
            <span class="flex items-center flex-shrink-0 bg-gray-300 rounded-full duration-300 ease-expo after:bg-gray-500 peer-checked:after:bg-success after:rounded-full after:duration-100 peer-checked:after:translate-x-full after:hover-shadow" :class="classes"></span>

            <span v-if="$slots.default" class="ml-4">
                <slot/>
            </span>

        </label>

    </div>

</template>

<script setup>
    const emit = defineEmits(['update:modelValue'])

    const props = defineProps({
        modelValue: { default: '' },
        value: { default: 'On' },
        valueTrue: { default: true },
        valueFalse: { default: false },
        size: { type: String, default: 'md' },
    })

    const value = toRef(props, 'value')
    const valueTrue = toRef(props, 'valueTrue')
    const valueFalse = toRef(props, 'valueFalse')
    const size = toRef(props, 'size')
    const model = useVModel(props, 'modelValue', emit)

    const classes = computed(() => ({
        'w-12 h-4': 'md' === size.value,
        'after:w-6 after:h-6': 'md' === size.value,
        'w-8 h-3': 'sm' === size.value,
        'after:w-4 after:h-4': 'sm' === size.value,
    }))
</script>
