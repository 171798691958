<template>

    <div class="flex items-center justify-between relative">

        <ul class="flex flex-col md:flex-row py-2 md:py-0 pl-0 mb-0">

            <li v-for="item, index in items" :key="index" ref="elements" class="me-6">

                <a class="flex items-center py-2 md:py-4 font-medium"
                    :class="item.active ? 'text-gray-600' : 'text-gray-400'"
                    href="#"
                    @click.prevent="handler(index)">

                    <span v-if="item.error" class="text-danger mr-3">
                        <i class="fa-duotone fa-circle-exclamation"></i>
                    </span>

                    <span class="fs-6" v-text="item.title"></span>

                </a>
        
            </li>
      
        </ul>

        <div v-if="$slots.default" class="ml-4">
            <slot :active="activeIndex"/>
        </div>

        <span class="hidden md:block h-[2px] absolute -bottom-[2px] ease-expo duration-100 transition-all bg-gray-600" :style="{ left: ink.left, width: ink.width }"></span>

    </div>

</template>

<script setup>
    const emit = defineEmits(['change'])

    const props = defineProps({
        links: { type: Array, default: [] },
        mode: { type: String, default: 'tabgroup' },
        orientation: { type: String, default: 'horizontal' },
    })

    const links = toRef(props, 'links')
    const mode = toRef(props, 'mode')
    const orientation = toRef(props, 'orientation')

    const elements = ref([])
    const ink = ref({ left: 0, width: 0 })

    const tabGroup = inject('tabGroup', null)

    const items = computed(() => {
        if ('tabgroup' === mode.value) {
            return tabGroup.panels.value
        }

        return links
    })

    const activeIndex = computed(() => items.value.findIndex(item => item.active))
    const activePanel = computed(() => activeIndex.value !== -1 ? elements.value[activeIndex.value] : null)

    watch(activePanel, () => update())       
    watch(items, () => nextTick(() => update()))

    /**
     * Handles item click events.
     * 
     * @param {number} index 
     * @return {void}
     */
    function handler(index) {
        if ('tabgroup' === mode.value) {
            tabGroup.setActive(index)
        }

        emit('change', index)
    }

    /**
     * Update the user interface.
     * 
     * @return {void}
     */
    function update() {
        const el = activePanel.value
        const rect = el?.getBoundingClientRect()

        if (el) {
            ink.value.left = `${el.offsetLeft}px`
            ink.value.width = `${rect.width}px`
        }
    }
</script>
