<template>

    <div class="card mb-6">
        <div class="card-body pt-9 pb-0">
            <div class="d-flex flex-wrap flex-sm-nowrap">
                <div class="flex-grow-1">
                    <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                        <div class="d-flex flex-column">
                            <div class="d-flex align-items-center mb-2">
                                <a href="#" class="text-gray-900 text-hover-primary fs-2 fw-bold me-1">{{ user.first_name }} {{ user.last_name }}</a>
                                <a href="#"><i class="ki-duotone ki-verify fs-1 text-primary"></i></a>
                            </div>
                            <div class="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                <a href="#" class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                    <i class="ki-duotone ki-profile-circle fs-4 me-1"></i>{{ $t('labels.parent-account') }}
                                </a>
                                <a href="#" class="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                                    <i class="ki-duotone ki-sms fs-4 me-1"></i>{{ user.email }}
                                </a>
                            </div>
                        </div>
                        <div class="d-flex mb-4 w-100 justify-content-end">
                            <div class="d-flex align-items-end mr-4">
                                <RouterLink :to="{ name: Route.SETTINGS }" class="btn btn-sm btn-primary">{{ $t('labels.profile') }}</RouterLink>
                            </div>
                            <div>
                                <RouterLink :to="{ name: Route.ORDER_INDEX }" class="btn btn-flex btn-primary px-6">
                                    <i class="ki-duotone ki-cheque fs-4x">
                                        <span class="path1"></span>
                                        <span class="path2"></span>
                                        <span class="path3"></span>
                                        <span class="path4"></span>
                                        <span class="path5"></span>
                                        <span class="path6"></span>
                                        <span class="path7"></span>
                                    </i>
                                    <span class="d-flex flex-column align-items-start">
                                        <span class="fs-1 fw-bold">{{ $t('labels.invoices') }}</span>
                                        <span class="fs-5">{{ $t('labels.receipts-and') }}</span>
                                    </span>
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap flex-stack">
                        <div class="d-flex flex-column flex-grow-1">
                            <div class="d-flex flex-wrap d-flex flex-wrap justify-content-center justify-content-md-start">
                                <div class="border border-gray-300 border-dashed rounded min-w-100px py-3 px-4 me-3 mb-3">
                                    <div class="d-flex align-items-center">
                                        <i class="ki-duotone ki-plus fs-3 text-danger me-2" v-if="available > 0"></i>
                                        <i class="ki-duotone ki-minus fs-3 text-success me-2" v-if="available <= 0"></i>
                                        <div class="fs-2 fw-bold counted" data-kt-countup="true">${{ Math.abs(available).toFixed(2) }}</div>
                                    </div>
                                    <div class="fw-semibold fs-6 text-gray-400 parent-header-line">
                                        {{ $t('labels.balance') }}
                                        <button v-if="debit > 0" class="btn btn-sm btn-primary" id="pay-now" @click.prevent="balancePay()">{{ $t('labels.pay') }}</button>
                                    </div>
                                </div>
                                <div class="border border-gray-300 border-dashed rounded min-w-100px py-3 px-4 me-0 mb-3 mr-0 me-md-3">
                                    <div class="d-flex align-items-center">
                                        <div class="fs-2 fw-bold counted" data-kt-countup="true">${{ Math.abs(scheduled).toFixed(2) }}</div>
                                    </div>
                                    <div class="fw-semibold fs-6 text-gray-400 parent-header-line" v-html="$t('labels.scheduled-payments')"></div>
                                </div>
                                <div class="border border-gray-300 border-dashed rounded min-w-100px py-3 px-4 me-3 mb-3">
                                    <div class="d-flex align-items-center">
                                        <i class="ki-duotone ki-people fs-3 me-2"></i>
                                        <div class="fs-2 fw-bold counted parent-header-line">{{ user.children?.length?.toLocaleString() || '0' }}</div>
                                    </div>
                                    <div class="fw-semibold fs-6 text-gray-400">{{ $t('labels.children') }}</div>
                                </div>
                                <div class="border border-gray-300 border-dashed rounded min-w-100px py-3 px-4 me-0 mb-3 mr-0 me-md-3">
                                    <div class="d-flex align-items-center">
                                        <i class="ki-duotone ki-calendar fs-3 me-2"></i>
                                        <div class="fs-2 fw-bold counted parent-header-line">{{ registrations }}</div>
                                    </div>
                                    <div class="fw-semibold fs-6 text-gray-400">{{ $t('labels.registrations') }}</div>
                                </div>
                            </div>
                        </div>
                        <!-- div class="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
                            <div class="d-flex justify-content-between w-100 mt-auto mb-2">
                                <span class="fw-semibold fs-6 text-gray-400">{{ $t('labels.profile-completion') }}</span>
                                <span class="fw-bold fs-6">50%</span>
                            </div>
                            <div class="h-5px mx-3 w-100 bg-light mb-3">
                                <div class="bg-success rounded h-5px" role="progressbar" style="width: 50%;" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div -->
                    </div>
                </div>
            </div>
            <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                <li class="nav-item mt-2">
                    <RouterLink :to="{ name: Route.SHOP_STEP_1 }" class="nav-link text-active-primary ms-0 me-md-10 me-5 py-5" active-class="active">{{ $t('labels.activities') }}</RouterLink>
                </li>
                <li class="nav-item mt-2">
                    <RouterLink :to="{ name: Route.REGISTRATION_INDEX }" class="nav-link text-active-primary ms-0 me-md-10 me-5 py-5" active-class="active">{{ $t('labels.registrations') }}</RouterLink>
                </li>
                <li class="nav-item mt-2">
                    <RouterLink :to="{ name: Route.ORDER_INDEX }" class="nav-link text-active-primary ms-0 me-md-10 me-5 py-5" active-class="active">{{ $t('labels.invoices') }}</RouterLink>
                </li>
            </ul>
        </div>
    </div>

</template>

<script setup>
    import { useAuth } from '@/composables/auth'
    import Route from '@/constants/route'
    import { useCartStore } from '@/store/cart'

    const cart = useCartStore()
    const { user } = useAuth()
    const router = useRouter()

    const available = computed(() => cart.available)
    const debit = computed(() => cart.debit)
    const scheduled = computed(() => cart.scheduled)

    const registrations = computed(() => {
        let results = []

        user.value.children?.forEach(child => {
            //console.log(child)
            child.active_registrations?.forEach(registration => {
                results.push(registration)
            })
        })

        return results.length.toLocaleString()
    })

    function balancePay() {
        router.push({ name: Route.CHECKOUT })
    }
</script>