<template>

    <div class="flex flex-col space-y-2">

        <div class="flex items-center justify-between">

            <label v-if="displayedLabel" v-text="displayedLabel" class="form-label fs-6 fw-bold text-dark"></label>

            <slot name="labelAppend"></slot>

            <ul v-if="translatable" class="flex divide-x-2 divide-gray-300">
                <li>
                    <button @click.prevent="setLocale('en')" class="flex items-center text-sm uppercase px-2 leading-none" :class="isLocale('en') ? 'text-grey-600' : 'text-gray-400'">
                        <i v-if="hasError('en')" class="fa-duotone fa-circle-exclamation text-danger mr-2"></i> EN
                    </button>
                </li>
                <li>
                    <button @click.prevent="setLocale('fr')" class="flex items-center text-sm uppercase px-2 leading-none" :class="isLocale('fr') ? 'text-grey-600' : 'text-gray-400'">
                        <i v-if="hasError('fr')" class="fa-duotone fa-circle-exclamation text-danger mr-2"></i> FR
                    </button>
                </li>
            </ul>

        </div>

        <div class="flex flex-col" :class="colClass">

            <slot :isLocale="isLocale"/>

            <span v-if="displayedError" v-text="displayedError" class="block text-danger mt-2"></span>

        </div>            

    </div>

</template>

<script setup>
    const props = defineProps({ 
        error: { type: String },
        label: { type: String },
        translatable: { type: Boolean, default: false },
        translatableErrors: { type: Object },
        translatableLabels: { type: Object },
        colClass: { type: String },
    })

    const error = toRef(props, 'error')
    const label = toRef(props, 'label')    
    const translatable = toRef(props, 'translatable')
    const translatableErrors = toRef(props, 'translatableErrors')
    const translatableLabels = toRef(props, 'translatableLabels')

    const locale = ref('en')
    const translatedError = computed(() => value(translatableErrors, locale))
    const translatedLabel = computed(() => value(translatableLabels, locale))
    const displayedLabel = computed(() => translatable.value ? translatedLabel.value : label.value)
    const displayedError = computed(() => translatable.value ? translatedError.value : error.value)

    const tabPanel = inject('tabPanel', null)

    // Synchronize standard errors.
    watch(error, (newValue, oldValue) => syncTabPanel(newValue, oldValue))

    // Synchronize translatable errors.
    watch(translatableErrors, (newValue, oldValue) => {
        syncTabPanel(newValue.en, oldValue.en)
        syncTabPanel(newValue.fr, oldValue.fr)
    }, { deep: true })
    
    function isLocale (value) {
        return locale.value === value
    }

    function setLocale (value) {
        locale.value = value
    }

    function hasError(id) {
        return value(translatableErrors, id, '') !== ''
    }

    function value(object, key, defaultValue = '') {
        object = unref(object)
        key = unref(key)
        defaultValue = unref(defaultValue)

        if (!object) {
            return defaultValue
        }        

        return key in object ? object[key] : defaultValue
    }

    function syncTabPanel(newValue, oldValue) {
        if (!tabPanel) {
            return
        }

        if (newValue) {
            tabPanel.errors.value.push(newValue)
            return
        }

        const index = tabPanel.errors.value.indexOf(oldValue)

        if (index !== -1) {
            tabPanel.errors.value.splice(index, 1)
        }
    }
</script>
