<template>

    <BaseListbox
        v-model="model"
        :ajax="true"
        :filter="filter"
        :min-chars="minChars"
        :searchable="true"
        :value="option => option.id"
        :label="option => option.name"
        :search="search"/>

</template>

<script setup>
    import { options } from '@/api'

    const emit = defineEmits(['update:modelValue'])

    const props = defineProps({
        modelValue: { required: true },
        searchRequired: { type: Boolean, default: false }
    })

    const modelValue = toRef(props, 'modelValue')
    const searchRequired = toRef(props, 'searchRequired')

    const model = useVModel(props, 'modelValue', emit)
    
    const filter = computed(() => searchRequired.value ? false : true)
    const minChars = computed(() => searchRequired.value ? 3 : -1)

    const search = async (args) => {
        let params = {}

        if (args.query) {
            params.search = args.query
        }

        if (args.values) {
            params.ids = args.values
        }

        const response = await options.clients(params)

        return response.data
    }

    defineExpose({ search })  
</script>
