import { defineStore } from 'pinia'
import { unref } from 'vue'

import { auth } from '@/api'
import Route from '@/constants/route'
import Store from '@/constants/store'
import router from '@/router'
import { useRoute } from 'vue-router'

export const useAuthStore = defineStore(Store.AUTH, {
    state: () => ({
        user: null,
        return: null,
        impersonating: false // Be careful with this one - won't show the correct value if the page is refreshed
    }),

    getters: {
        isAuthenticated: (state) => state.user != null
    },

    actions: {
        async login (email, password, remember) {
            const { data } = await auth.login(unref(email), unref(password), unref(remember))

            this.user = data
        },

        async logout (redirect = true) {
            const response = await auth.logout()

            if (response.data) {    
                const name = this.return?.name || Route.HOME
                const params = this.return?.params || {}                
                
                this.user = response.data
                this.return = null
                this.impersonating = false

                if (redirect) {
                    router.push({ name, params })
                }

                return
            }

            this.user = null

            if (redirect) {
                router.push({ name: Route.LOGIN })
            }
        },

        async register (payload) {
            const { data } = await auth.register(unref(payload))

            this.user = data
        },

        async refresh () {
            try {
                const { data } = await auth.check()

                this.user = data
            } catch (error) {
                this.user = null
            }

            return this.isAuthenticated
        },

        async check () {
            //console.log(router.current)
            try {
                return await this.refresh()
            } catch (e) {
            }

            return this.isAuthenticated
        },

        async impersonate(id, route) {
            const { data } = await auth.impersonate(id)
    
            this.user = data
            this.return = { name: unref(route.name), params: unref(route.params) }
            this.impersonating = true
        }
    }
})
