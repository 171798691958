<template>
  <!--begin::Wrapper-->
  <div class="w-lg-500px">
    <!--begin::Form-->
    <BaseForm ref="form" @submitted="register" @success="$router.push({ name: Route.SHOP_STEP_1 })">
      <!--begin::Heading-->
      <div class="text-center mb-10">
        <!--begin::Title-->
        <h1 class="text-dark mb-3" v-text="$t('labels.register')"></h1>
        <!--end::Title-->

        <!--begin::Link-->
        <div class="text-gray-400 fw-semobold fs-4">
          {{ $t('labels.register-intro') }}

          <router-link :to="{ name: Route.LOGIN }" class="link-primary fw-bold">
            {{ $t('labels.login') }}
          </router-link>
        </div>
        <!--end::Link-->
      </div>
      <!--begin::Heading-->

      <!--begin::Input group-->
      <div class="fv-row mb-10">
        <!--begin::Input-->
        <BaseInputGroup :label="$t('labels.name-first')" :error="form?.error('first_name')">
            <BaseInput v-model="payload.first_name"/>
        </BaseInputGroup>
        <!--end::Input-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="fv-row mb-10">
        <!--begin::Input-->
        <BaseInputGroup :label="$t('labels.name-last')" :error="form?.error('last_name')">
            <BaseInput v-model="payload.last_name"/>
        </BaseInputGroup>
        <!--end::Input-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="fv-row mb-10">
        <!--begin::Input-->
        <BaseInputGroup :label="$t('labels.email')" :error="form?.error('email')">
            <BaseInput v-model="payload.email"/>
        </BaseInputGroup>
        <!--end::Input-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="fv-row mb-10">
        <!--begin::Input-->
        <BaseInputGroup :label="$t('labels.phone1')" :error="form?.error('phone1')">
            <BaseInput v-model="payload.phone1" v-maska="'(###) ###-####'"/>
        </BaseInputGroup>
        <!--end::Input-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="fv-row mb-10">
        <!--begin::Input-->
        <BaseInputGroup :label="$t('labels.password')" :error="form?.error('password')">
            <BasePassword v-model="payload.password"/>
        </BaseInputGroup>
        <!--end::Input-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="fv-row mb-10">
        <!--begin::Input-->
        <BaseInputGroup :label="$t('labels.preference-emails-marketing')" :error="form?.error('preference_emails_marketing')">
          <BaseToggle v-model="payload.preference_emails_marketing"/>
        </BaseInputGroup>
        <span class="explanation-label">{{ $t('labels.preference-emails-marketing-explanation') }}</span>
        <!--end::Input-->
      </div>
      <!--end::Input group-->

      <!--begin::Actions-->
      <div class="text-center">
        <!--begin::Submit button-->
        <button
          tabindex="3"
          type="submit"
          ref="submitButton"
          id="kt_sign_in_submit"
          class="btn btn-lg btn-primary w-100 mb-5"
        >
          <span class="indicator-label">{{ $t('labels.register') }}</span>

          <span class="indicator-progress">
            Please wait...
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </button>
        <!--end::Submit button-->
      </div>
      <!--end::Actions-->
    </BaseForm>
    <!--end::Form-->
  </div>
  <!--end::Wrapper-->
</template>

<script setup>    
    import Route from '@/constants/route'
    import { useAuthStore } from '@/store/auth'
    import router from '@/router'
    
    const authStore = useAuthStore()
    
    const form = ref()
    const email = ref()
    const password = ref()
    const remember = ref(true)
    const payload = ref({ preference_emails_marketing: true })
    

    /**
     * Handle the register request.
     * 
     * @return {void}
     */
    async function register() {
        try {
            const response = await authStore.register(payload)

            form.value?.resolve(response)
        } catch (error) {
            form.value?.reject(error)
        }
    }
</script>
