import { useAuthStore } from '@/store/auth'
import { computed } from '@vue/reactivity'

export function useAuth () {
    const store = useAuthStore()

    const isRole = (role) => store.user?.role === role
    const isImpersonating = computed(() => store.impersonating) // Be careful with this one - won't show the correct value if the page is refreshed

    const user = computed(() => store.user)
    const isAdmin = computed(() => isRole('admin'))
    const isEmployee = computed(() => isRole('employee'))
    const isParent = computed(() => isRole('parent'))
    const isSupplier = computed(() => isRole('supplier'))

    return { store, user, isAdmin, isEmployee, isParent, isSupplier, isImpersonating }
}
