import { computed, inject, onMounted, ref, watch } from 'vue'
import { toast } from '@/composables/toast'
import ToastType from '@/constants/toast-type'
import { useRoute, useRouter } from 'vue-router'

const DEFAULT_TIMEOUT = 3000

export function useModelForm (context) {
    const form = ref()
    const id = ref()
    const payload = ref({})
    const promised = ref({})
    const mode = computed(() => id.value ? 'edit' : 'create')
    const loading = ref(false)
    const submitting = ref(false)

    const modal = inject('modal', null)

    if (modal) {
        watch(modal.active, (value) => form?.value?.reset())
    }

    const promise = () => {
        return new Promise((resolve, reject) => {
            promised.value = { resolve, reject }
        })
    }

    const create = () => {
        id.value = null
        payload.value = {}

        modal?.open?.()
        context?.reset?.()
        context?.creating?.()

        return promise()
    }

    const edit = async (arg) => {
        loading.value = true

        try {
            modal?.open?.()
            
            const data = await context.store.show(arg)

            id.value = data.id
            payload.value = data
            
            context?.reset?.()
            context?.editing?.(data)
        } finally {
            loading.value = false
        }

        return promise()
    }

    const submit = async (opts = {}) => {
        const options = Object.assign({
            resolve: true,
            toast: true
        }, opts)

        const mode = id.value ? 'update' : 'create'

        submitting.value = true

        try {
            let response = null
            let result = null
            let message = null
                        
            const messages = unref(context.messages)

            switch (mode) {
                case 'create':
                    response = await context.store.store(payload, true)
                    result = response.status?.replace?.(/.*\./, '')
                    message = messages?.[result]
                    break

                case 'update':
                    response = await context.store.update(id, payload, true)
                    result = response.status?.replace?.(/.*\./, '') 
                    message = messages?.[result]
                    break
            }

            if (typeof context.callback === 'function') {
                context.callback()
            }

            if (options.resolve) {
                form.value?.resolve?.(response)
                promised.value?.resolve?.(response)
                modal?.close?.()
            }

            if (options.toast) {
                if (message.hasOwnProperty('type') && message.hasOwnProperty('message')) {
                    toast(message.type, message.message, DEFAULT_TIMEOUT)
                } else {
                    toast(ToastType.SUCCESS, message, DEFAULT_TIMEOUT)
                }
            }
        } catch (error) {
            if (options.resolve) {
                form.value?.reject?.(error)
            }

            if (error.response?.status !== 422) {
                promised.value?.reject?.(error)
            }
        } finally {
            submitting.value = false
        }
    }

    return { form, id, payload, loading, submitting, create, edit, submit, mode }
}

export function useModelIndex (id, store, messages = {}) {
    const rows = computed(() => store.rows)
    const from = computed(() => store.from)
    const to = computed(() => store.to)
    const total = computed(() => store.total)
    const pages = computed(() => store.pages)
    const loading = computed(() => store.loading)

    const page = computed({
        get: () => store.page,
        set: (page) => store.index({ page })
    })

    const destroy = async (id) => {
        await store.destroy(id)
        await store.refresh()

        if (messages?.destroyed.hasOwnProperty('type') && messages?.destroyed.hasOwnProperty('message')) {
            toast(messages?.destroyed.type, messages?.destroyed.message, DEFAULT_TIMEOUT)
        } else {
            toast(ToastType.SUCCESS, messages?.destroyed, DEFAULT_TIMEOUT)
        }
    }

    const filter = (filters) => {
        store.$reset()
        store.filter(filters)
    }

    const list = (items, limit = 2) => {
        if (!Array.isArray(items)) {
            return ''
        }

        const visible = items.sort().splice(0, limit).join(', ')

        if (items.length > 0) {
            return `${visible} (+${items.length})`
        }

        return visible
    }

    return { filter, rows, from, to, total, page, pages, destroy, list, loading }
}

export function useModelView (context) {
    const route = useRoute()
    const router = useRouter()

    const loading = ref(false)
    const model = ref(false)

    const load = (id = route.params.id) => {
        loading.value = true

        context.store.show(id).then(result => {
            model.value = result
            loading.value = false
        })
    }

    onMounted(() => load())

    const index = () => router.push({ name: context?.routes?.index })
    const edit = () => router.push({ name: context?.routes?.edit, params: { id: route.params.id } })

    return { loading, model, index, edit, load }
}
