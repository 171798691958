<template>

    <BaseForm ref="form" @submitted="submit()">

        <LoadingOverlay :loading="loading">
                
            <TabGroup class="mb-6">

                <TabList class="-mt-6 -mx-6 mb-6"/>

                <TabPanel :title="$t('labels.general')">
                
                    <div class="grid gap-6">
                        
                        <div class="grid gap-6 md:grid-cols-2">
                            
                            <BaseInputGroup :label="$t('labels.name-first')" :error="form?.error('first_name')">
                                <BaseInput v-model="payload.first_name"/>
                            </BaseInputGroup>

                            <BaseInputGroup :label="$t('labels.name-last')" :error="form?.error('last_name')">
                                <BaseInput v-model="payload.last_name"/>
                            </BaseInputGroup>

                        </div>

                        <BaseInputGroup :label="$t('labels.email')" :error="form?.error('email')">
                            <BaseInput v-model="payload.email" type="email"/>
                        </BaseInputGroup>

                        <div class="grid gap-6 md:grid-cols-3">
                                        
                            <BaseInputGroup :label="$t('labels.phone1')" :error="form?.error('phone1')">
                                <BaseInput v-model="payload.phone1" v-maska="MASK_PHONE" placeholder="(###) ###-#### x ######"/>
                            </BaseInputGroup>

                            <BaseInputGroup :label="$t('labels.phone2')" :error="form?.error('phone2')">
                                <BaseInput v-model="payload.phone2" v-maska="MASK_PHONE" placeholder="(###) ###-#### x ######"/>
                            </BaseInputGroup>

                            <BaseInputGroup :label="$t('labels.phone3')" :error="form?.error('phone3')">
                                <BaseInput v-model="payload.phone3" v-maska="MASK_PHONE" placeholder="(###) ###-#### x ######"/>
                            </BaseInputGroup>

                        </div>

                        <div class="grid gap-6 md:grid-cols-2">

                            <BaseInputGroup :label="$t('labels.password')" :error="form?.error('password')">
                                <BasePassword v-model="payload.password"/>
                            </BaseInputGroup>

                            <BaseInputGroup :label="$t('labels.password')" :error="form?.error('password_confirmation')">
                                <BasePassword v-model="payload.password_confirmation"/>
                            </BaseInputGroup>
                            
                        </div>

                    </div>

                </TabPanel>

                <TabPanel :title="$t('labels.notes')">
                    
                    <div class="grid gap-6">

                        <BaseInputGroup :label="$t('labels.notes')" :error="form?.error('notes')">
                            <Tiptap v-model="payload.notes"/>
                        </BaseInputGroup>

                    </div>
                    
                </TabPanel>

            </TabGroup>

            <BaseButton :disabled="submitting" :loading="submitting">
                {{ $t(action) }}
            </BaseButton>

        </LoadingOverlay>

    </BaseForm>

</template>

<script setup>
    import { MASK_PHONE } from '@/constants/mask'
    import { useI18n } from '@/composables/i18n'
    import { useModelForm } from '@/composables/model-ui'
    import { useParentStore } from '@/store/model'

    const { t } = useI18n()
    const store = useParentStore()

    const { form, id, payload, loading, submitting, create, edit, submit, mode } = useModelForm({ 
        store: store,
        messages: {
            created: t('message.parent-created'),
            updated: t('message.parent-updated'),
            destroyed: t('message.parent-destroyed')
        }
    })

    const action = computed(() => id.value  ? 'labels.submit-parent-edit' : 'labels.submit-parent-create')
    
    defineExpose({ mode, create, edit })
</script>
