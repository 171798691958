<template>
    <BaseInput v-model="model" :disabled="disabled" :type="type">
        <template #groupAppend>
            <button 
                class="btn btn-outline" 
                type="button"
                @click.prevent="toggle()">
                <i :class="iconClass"></i>
            </button>
        </template>
    </BaseInput>
</template>

<script setup>
    const emit = defineEmits(['update:modelValue'])

    const props = defineProps({
        disabled: { type: Boolean, default: false },
        modelValue: { type: [Number, String] }
    })

    const model = useVModel(props, 'modelValue', emit)

    const visible = ref(false)
    const type = computed(() => visible.value ? 'text' : 'password')
    const iconClass = computed(() => visible.value ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye')

    function toggle() {
        visible.value = !visible.value
    }
</script>
