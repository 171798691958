<template>

    <Teleport to="body">

        <div ref="el" class="modal" tabindex="-1">
            <div class="modal-dialog" :class="classes">
                <slot name="content">
                    <div class="modal-content">
                        <div v-if="!hideHeader" class="modal-header">
                            <slot name="title">
                                <h5 class="modal-title">{{ title }}</h5>
                            </slot>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <slot name="body">
                            <div class="modal-body">
                                <slot/>
                            </div>
                        </slot>
                        <div v-if="$slots.footer" class="modal-footer">
                            <slot name="footer"/>
                        </div>
                    </div>
                </slot>
            </div>
        </div>

    </Teleport>

</template>

<script setup>
    const emit = defineEmits(['closed', 'opened'])

    const props = defineProps({
        fullscreen: { type: Boolean, default: false },
        position: { type: String, default: 'center' },
        hideHeader: { type: Boolean, default: false },
        size: { type: String, default: 'md' },
        title: { type: String, default: '' },
        options: { type: Object, default: {}}
    })

    const fullscreen = toRef(props, 'fullscreen')
    const position = toRef(props, 'position')
    const size = toRef(props, 'size')
    const title = toRef(props, 'title')
    const options = toRef(props, 'options')

    const el = ref()
    const active = ref(false)
    const modal = ref(null)

    const classes = computed(() => ({
        'modal-fullscreen': fullscreen.value,
        'modal-dialog-centered': 'center' === position.value,
        'modal-sm': !fullscreen.value && 'sm' === size.value,
        'modal-lg': !fullscreen.value && 'lg' === size.value,
        'modal-xl': !fullscreen.value && 'xl' === size.value
    }))

    useEventListener(el, 'shown.bs.modal', (e) => {
        // Set the active state.
        active.value = true

        // Emit the "opened" event.
        emit('opened')
    })

    useEventListener(el, 'hidden.bs.modal', (e) => {
        // Set the active state.
        active.value = false

        // Emit the "closed" event.
        emit('closed')
    })

    onMounted(() => {
        modal.value = new bootstrap.Modal(el.value, options.value)
    })

    const open = () => {
        modal.value?.show?.()
    }

    const close = () => {
        modal.value?.hide?.()
    }

    const resolve = () => {
        close()
    }

    const reject = () => {
        close()
    }
    
    onClickOutside(el, (event) => {
        if (event.target.contains(el.value)) {
            close()
        }
    })

    // Provide a createModal compatible interface.
    provide('modal', { 
        active: readonly(active), 
        open, 
        close,
        resolve, 
        reject
    })

    defineExpose({ active, open, close })
</script>

<script>export default { inheritAttrs: false }</script>
